import { Link } from "../../util";

export default function GoogleMapsLink(props) {
  const { address } = props;

  return (
    <Link
      href={`https://maps.google.com/?q=${encodeURIComponent(address)}`}
      isNewTab
    >
      {address}
    </Link>
  );
}
