import { MediaSize } from "../types/attributes.type";

const mediaSizes: Record<MediaSize, number> = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
};

const breakpoints: Record<string, (size: MediaSize) => string> = {
  up: (size: MediaSize) => `(min-width: ${mediaSizes[size]}px)`,
  down: (size: MediaSize) => `(max-width: ${mediaSizes[size]}px)`,
};

export default breakpoints;
