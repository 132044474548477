import { ComponentSize } from "./../../../../types/attributes.type";
import { TextFieldTypes } from "./../jfsTextField/jfsTextField";
import { ContainerProps } from "./../../container/container";
import * as Styled from "./styles.css";

const JfsInputControl = ({
  children,
  grow,
  size,
  type,
  ...otherProps
}: JfsInputControlProps) => {
  return (
    <Styled.JfsInputControl
      $grow={grow}
      $size={size}
      $type={type}
      {...otherProps}
    >
      {children && children}
    </Styled.JfsInputControl>
  );
};

interface JfsInputControlProps extends ContainerProps {
  grow?: boolean;
  size: ComponentSize;
  type: TextFieldTypes;
}

JfsInputControl.defaultProps = {
  grow: false,
  size: "md",
  type: "text",
};

export default JfsInputControl;
