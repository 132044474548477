import { ControlProps } from "react-select";
import * as Styled from "./styles.css";

const ComboboxControl = ({ children, ...otherProps }: ControlProps) => {
  return (
    <Styled.ControlContainer {...otherProps}>
      {otherProps.selectProps.isSearchable && (
        <Styled.ControlPrependIcon icon="search" size="sm" />
      )}
      {children}
    </Styled.ControlContainer>
  );
};

ComboboxControl.defaultProps = {};

export default ComboboxControl;
