import { WidgetProps } from "@rjsf/utils";
import * as Styled from "./styles.css";

const JfsInput = ({ onBlur, onFocus, ...otherProps }: JfsInputProps) => {
  return <Styled.JfsInput {...otherProps} />;
};

export interface JfsInputProps extends WidgetProps {}

JfsInput.defaultProps = {};

export default JfsInput;
