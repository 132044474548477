import styled from "styled-components";
import { breakpoints, theme } from "./../../../../styles";

export const JfsInput = styled("input").attrs((props: any) => ({
  fullWidth: props.fullWidth,
}))`
  font-size: ${theme.typography.body.fontSize};
  font-weight: ${theme.typography.body.fontWeight};
  letter-spacing: ${theme.typography.body.letterSpacing};
  line-height: ${theme.typography.body.lineHeight};

  width: ${props => (props.fullWidth ? "100%" : "auto")};

  ::placeholder {
    color: ${theme.color.surface.text.default.placeholder};
  }

  @media ${breakpoints.up("lg")} {
    font-size: ${theme.typography.body.desktop.fontSize};
    line-height: ${theme.typography.body.desktop.lineHeight};
  }
`;
