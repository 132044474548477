import { useQuery } from "react-query";
import { makeStyles } from "@material-ui/core";
import {
  Button,
  Card,
  Grid,
  CircularProgress,
  Typography,
} from "../../components/util";
import { ListMissing } from "../../components/content";
import Faq from "./faq";
import prismicService from "../../services/prismic/prismic.service";
import { useHistory } from "react-router-dom";
import { theme } from "../../styles";

const styles = makeStyles(() => ({
  title: {
    marginTop: theme.spacing,
    marginBottom: `calc(${theme.spacing} / 3 * 2)`,
  },
  header: {
    alignItems: "center",
  },
  subtitle: {
    color: theme.color.surface.text.default.default,
  },
  faqContainer: {
    marginBottom: theme.spacing,
  },
  faqHeader: {
    textAlign: "center",
    margin: `${theme.spacing} 0 calc(${theme.spacing} * 2) 0`,
  },
  img: {
    maxWidth: "200px",
  },
}));

const Faqs = () => {
  const history = useHistory();
  const classes = styles();

  const { isLoading, isError, data } = useQuery(
    "Faqs",
    prismicService.getFaqs,
    { retry: 0 },
  );

  if (isLoading) {
    return <CircularProgress contained />;
  } else if (isError || data == null) {
    return <ListMissing icon="info" type="FAQs" />;
  }

  return (
    <Grid container spacing={3}>
      <Grid container item classes={{ root: classes.header }}>
        <Grid item sm xs={12}>
          <Typography variant="displayMedium">
            Frequently asked questions
          </Typography>
        </Grid>
        <Grid item>
          <Button onClick={(evt: any) => history.goBack()} variant="outlined">
            Back to virtual booking
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography paragraph>
          Enhance your score and book a virtual survey with a Care risk
          consultant. We’ll provide recommendations to improve your business,
          including quick risk fixes and for SafetyCulture inspections and
          checklists.
        </Typography>
      </Grid>

      <Grid item xs={12} container classes={{ root: classes.faqContainer }}>
        <Card isInGrid>
          <Grid item xs={12} classes={{ root: classes.faqHeader }}>
            <Typography variant="displaySmall">
              Frequently asked questions
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {data.map((faq, i) => {
              return (
                <Faq key={i} question={faq.question} answer={faq.answer} />
              );
            })}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
export default Faqs;
