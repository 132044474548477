import styled from "styled-components";
import { Link } from "../../util";

export const Back = styled(Link)`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  text-decoration: none;
  span {
    margin-right: 6px;
  }
`;
