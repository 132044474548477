import { EvariPolicy } from "../../../services/organisation/organisation.service";
import { TStyleConnotation } from "../../../types";
import { tInvitationContext } from "../../../types/invitation.type";
import { Button } from "../../util";
import * as Styled from "./styles.css";

const InviteButton = ({
  context,
  color,
  data,
  onClick,
  text,
  type,
  variant,
  ...otherProps
}: InviteButtonProps) =>
  context === "overflow" ? (
    <Styled.MoreOptionSelectOption onClick={onClick}>
      {text ?? `Invite ${type}`}
    </Styled.MoreOptionSelectOption>
  ) : (
    <Button color={color} variant={variant} onClick={onClick} {...otherProps}>
      {text ?? `Invite ${type}`}
    </Button>
  );

interface InviteButtonProps {
  button?: React.ReactNode;
  color: TStyleConnotation;
  context?: tInvitationContext;
  data?: EvariPolicy;
  onClick: () => void;
  text?: string;
  type: "user" | "client";
  variant: "text" | "contained";
}

export default InviteButton;
