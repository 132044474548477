import styled from "styled-components";
import { theme } from "../../../../styles";
import { Container, Icon, ListItem, ListItemIcon } from "../..";
import SelectMenu from "../../selectMenu/selectMenu";

export const Select = styled(Container).attrs((props: any) => ({
  $error: props.$error,
  $formless: props.$formless,
  $isOpen: props.$isOpen,
  $size: props.$size,
  $unselected: props.$unselected,
}))`
  align-items: center;
  background-color: ${theme.color.surface.background.default.default};
  border: 1px solid ${theme.color.surface.border.weak.default};
  color: ${props =>
    props.$unselected
      ? theme.color.surface.text.default.placeholder
      : theme.color.surface.text.default.default};
  cursor: pointer;
  display: flex;
  font-size: inherit;
  justify-content: space-between;
  line-height: inherit;
  min-width: 120px;

  ${(props: any) => {
    switch (props.$size) {
      case "md":
        return `
          ${
            props.$isError || props.$isOpen
              ? "padding: 4px 14px 4px 14px;"
              : "padding: 5px 15px 5px 15px;"
          };
        `;
      case "lg":
        return `
          ${
            props.$isError || props.$isOpen
              ? "padding: 8px 14px 8px 14px;"
              : "padding: 9px 15px 9px 15px;"
          };
        `;
    }
  }}

  ${(props: any) => {
    if (props.$formless) {
      return `
        background-color: ${theme.color.surface.background.weakest.default};
        border-color: ${theme.color.surface.border.weaker.default};
      `;
    }
  }}

  ${(props: any) => {
    if (props.$isError) {
      return `
        background-color: ${theme.color.negative.background.weakest.default};
        border: 2px solid ${theme.color.negative.border.default.default};
      `;
    }
  }}

  ${(props: any) => {
    if (props.$isOpen) {
      return `
        border: 2px solid ${theme.color.accent.border.default.default};
      `;
    }
  }}

  ${(props: any) => {
    if (props.$error) {
      return `
        &, &:hover {
          background-color: ${theme.color.negative.background.weakest.default};
          border: 2px solid ${theme.color.negative.border.default.hover};
            ${props.$size === "md" ? "padding: 4px 14px 4px 14px" : ""};
            ${props.$size === "lg" ? "padding: 8px 14px 8px 14px" : ""};
        }
      `;
    }
  }}

   &:disabled {
    background-color: ${theme.color.surface.background.default.disabled};
    color: ${theme.color.surface.text.default.disabled};
  }

  & li {
    padding: 0;
  }
`;

export const SelectIcon = styled(Icon).attrs((props: any) => ({}))`
  margin-left: 8px;
`;

export const SelectSelectMenu = styled(SelectMenu).attrs((props: any) => ({}))`
  max-height: 500px;
  overflow: auto;
`;

export const SelectOption = styled(ListItem).attrs((props: any) => ({}))`
  padding: 6px 16px;
  color: ${theme.color.surface.text.strong.default};
  cursor: pointer;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  :hover {
    background-color: ${theme.color.surface.background.default.hover};
  }
`;

export const ItemIcon = styled(ListItemIcon).attrs((props: any) => ({}))`
  margin-right: 8px;
`;
