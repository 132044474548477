import { useQuery, useQueryClient } from "react-query";
import { useState } from "react";
import { Header, Section } from "../../../components/layout";
import { PaginatedResults } from "../../../components/content";

import {
  Button,
  CircularProgress,
  TableCell,
  TableRow,
  Modal,
  FormRow,
  Typography,
  Link,
  Popover,
} from "../../../components/util";

import toolkitService, {
  Toolkit,
} from "../../../services/toolkits/toolkits.service";

import * as Styled from "./styles.css";

const Toolkits = ({ orgId }: { orgId: string }) => {
  const queryClient = useQueryClient();

  const { data, isFetching } = useQuery(`toolkits_${orgId}`, async () =>
    toolkitService.getToolkitForOrg(orgId),
  );

  const [modalVisible, setModalVisible] = useState(false);
  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  const ToolkitRow = (toolkits: Toolkit[]) => (
    <>
      {toolkits.map(toolkit => (
        <TableRow key={toolkit.id}>
          <TableCell>
            <Link href={`/toolkits/${toolkit.id}`}>{toolkit.name}</Link>
          </TableCell>
          <TableCell>{toolkit.code}</TableCell>
          <TableCell>
            <OverflowMenuButton id={toolkit.id} />
          </TableCell>
        </TableRow>
      ))}
    </>
  );

  const OverflowMenuButton = ({ id }: { id: Toolkit["id"] }) => (
    <Popover
      alignment="end"
      trigger={
        <Styled.MoreOptionIconButton icon="kebab-horizontal">
          Pop
        </Styled.MoreOptionIconButton>
      }
    >
      <Styled.MoreOptionSelectMenu>
        <Styled.MoreOptionSelectOption>
          <Styled.LinkButton onClick={event => removeToolkit(event, id)}>
            Remove Toolkit
          </Styled.LinkButton>
        </Styled.MoreOptionSelectOption>
      </Styled.MoreOptionSelectMenu>
    </Popover>
  );

  const removeToolkit = async (event, toolkitId) => {
    event.preventDefault();
    await toolkitService.removeToolkitFromOrg(orgId, toolkitId);
    await queryClient.invalidateQueries(`toolkits_${orgId}`);
  };

  return (
    <>
      <Section>
        <Header
          title="Toolkits"
          titleIcon="tools"
          titleIconPosition="start"
          type="section"
          actions={
            <Button onClick={openModal}>
              Add a toolkit
              <Styled.PlusIcon icon="plus" size="sm" />
            </Button>
          }
        />

        {isFetching ? (
          <CircularProgress contained />
        ) : (
          <PaginatedResults
            id={`toolkits-org_id-${orgId}`}
            inputResults={{
              hits: data ?? [],
              total: data?.length ?? 0,
            }}
            tableColumns={[
              { title: "Name", expand: true },
              { title: "Code", expand: true },
              { title: "", collapse: true },
            ]}
            tableRows={ToolkitRow}
            missing={{
              icon: "pencil",
              message: "No toolkits associated to organisation",
              type: "toolkits",
            }}
          />
        )}
      </Section>

      {modalVisible && (
        <AddToolkitModal orgId={orgId} closeModal={closeModal} />
      )}
    </>
  );
};

const AddToolkitModal = ({
  orgId,
  closeModal,
}: {
  orgId: string;
  closeModal: () => void;
}) => {
  const queryClient = useQueryClient();
  const [isEditable, setIsEditable] = useState(true);

  const addToolkit = async (data: { code: string }) => {
    setIsEditable(false);

    const { code } = data;

    if (!code || code.length === 0) {
      throw new Error("Toolkit code is required.");
    }

    try {
      await toolkitService.addToolkitToOrg(orgId, code);
      await queryClient.invalidateQueries(`toolkits_${orgId}`);

      setIsEditable(true);
    } catch (error) {
      setIsEditable(true);
      throw error;
    }
  };

  return (
    <Modal
      isOpen={true}
      onClose={closeModal}
      headerComponent={
        <Typography variant="displaySmall">Add toolkit</Typography>
      }
      headerDivider
    >
      <Styled.ModalBody>
        <Styled.ModalForm
          fullWidth
          submitAlign="right"
          submitInline
          submitText="Add toolkit"
          validationErrors={[{ code: false }]}
          validationFields={[{ code: "" }]}
          onSubmit={{
            service: addToolkit,
            onSuccess: closeModal,
          }}
        >
          <FormRow>
            <Styled.ModalTextField
              editable={isEditable}
              label="Toolkit code"
              id="code"
              type="text"
              value=""
              helpText="Only specific codes can be used. Toolkits cannot be removed from a customers account."
            />
          </FormRow>
        </Styled.ModalForm>
      </Styled.ModalBody>
    </Modal>
  );
};

export default Toolkits;
