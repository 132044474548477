import styled from "styled-components";
import { AspectRatios } from "./index";

export const Picture = styled("picture")<PictureStyleProps>`
  overflow: hidden;

  ${({ aspectRatio }) =>
    !aspectRatio
      ? `
        // align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
      `
      : `
        display: block;
        height: 0;
        position: relative;
        width: 100%;
    `}

  padding-top: ${({ aspectRatio }) => {
    switch (aspectRatio) {
      case "1:1":
        return "100%;";
      case "4:3":
        return "75%;";
      case "3:2":
        return "66.67%;";
      case "16:9":
        return "56.25%;";
      default:
        return "0;";
    }
  }};

  ${({ isInCard }) =>
    isInCard &&
    `
      margin: -24px -24px 24px -24px;
      width: calc(100% + 48px);
  `}
`;

export const Img = styled("img")<ImgStyleProps>`
  ${({ aspectRatio }) =>
    !aspectRatio
      ? `
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
      `
      : `
      left: 0;
      max-width: 100%;
      min-height: 100%;
      min-width: 100%;
      position: absolute;
      top: 0;
    `}
`;

interface PictureStyleProps {
  aspectRatio: AspectRatios;
  isInCard: boolean;
}

interface ImgStyleProps {
  aspectRatio: AspectRatios;
}
