import { FlowSteppedFormSchema, JfsFormData } from "../../../types";
import { CustomErrorMsg } from "../../util/jfsForm/jfsForm";
import { FlowTab, FlowTabs } from "./../../../components/content";
import FlowSteps from "./flowSteps";

const Flow = ({
  id,
  schema,
  name,
  customErrMsg,
  onSubmit,
  prefill,
  ...otherProps
}: FlowProps) => {
  return (
    <FlowTabs
      id={`flow-${id}`}
      name={name}
      tabs={Object.keys(schema).map((step: string, i: number) => (
        <FlowTab
          id={step}
          label={step.charAt(0).toUpperCase() + step.slice(1)}
          key={i}
          i={i}
          disabled
        />
      ))}
    >
      <FlowSteps
        id={id}
        schema={schema}
        customErrorMsg={customErrMsg}
        name={name}
        onSubmit={onSubmit}
        prefill={prefill}
      />
    </FlowTabs>
  );
};

export interface FlowProps {
  id: string;
  name: string;
  customErrMsg?: CustomErrorMsg;
  onSubmit: (submitData: JfsFormData) => void;
  prefill?: Record<any, any>;
  schema: FlowSteppedFormSchema;
}

export default Flow;
