import * as React from "react";
import { Automation } from "../../../../types/automations.type";
import {
  Table,
  TableCell,
  TableRow,
  TabPanel,
  Typography,
  Link,
} from "../../../../components/util";
import { Header } from "../../../../components/layout";

type Props = { automation: Automation };

export const AutomationDetailsToolkitTabPanel: React.FC<Props> = ({
  automation,
}) => {
  return (
    <TabPanel id="details">
      <Header title="Toolkits" type="section" />
      <Table columns={[{ title: "Name" }, { title: "Code" }]}>
        {automation.toolkits?.map(toolkit => (
          <TableRow key={automation.id}>
            <TableCell>
              <Link
                href={`/toolkits/${toolkit.id}`}
                router
                title={toolkit.name}
              >
                <Typography styled={{ text: { case: "title" } }}>
                  {toolkit.name}
                </Typography>
              </Link>
            </TableCell>
            <TableCell>
              <Typography styled={{ text: { case: "title" } }}>
                {toolkit.code}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </TabPanel>
  );
};
