import { IOrganisation } from "../../services/admin/admin.service";
import {
  TableRow,
  TableCell,
  Typography,
  Popover,
  Button,
  Icon,
  Chip,
} from "../../components/util";
import { PaginatedResults } from "../../components/content";
import * as Styled from "./styles.css";
import organisationService from "../../services/organisation/organisation.service";
import { useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import queryString from "query-string";
import OrgModal from "./details/modals/orgModal";
import Header from "../../components/layout/header";
import OrgTypeChip from "./OrgTypeChip";

const OrganisationsList = ({
  policy_id,
  enableEditOption,
}: {
  policy_id?: string;
  enableEditOption?: boolean;
}) => {
  const { search } = useLocation();
  const urlParams: Record<string, any> = useMemo(
    () => queryString.parse(search),
    [search],
  );

  const [searchTerm, setSearchTerm] = useState<string>(
    urlParams?.searchTerm ? urlParams?.searchTerm : "",
  );

  const serviceParams = { policy_id };
  const [modalType, setModalType] = useState<tOrgModalType>();
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const toggleModal = () => {
    setModalVisible(!isModalVisible);
    return;
  };

  const refetchQuery = `organisations-all${
    policy_id ? `-policy-${policy_id}` : ""
  }`;

  useEffect(() => {
    setSearchTerm(urlParams?.searchTerm);
  }, [urlParams?.searchTerm]);

  const OverflowMenuButtonOrganisation = () => {
    return (
      <>
        <Popover
          alignment="end"
          trigger={
            <Styled.OrgMoreOptionIconButton icon="kebab-horizontal"></Styled.OrgMoreOptionIconButton>
          }
        >
          <Styled.MoreOptionSelectMenu>
            <Styled.MoreOptionOrgSectionSelectOption>
              <Styled.MoreOptionButton
                variant="unstyled"
                type="button"
                onClick={() => {
                  setModalType("Update");
                  setModalVisible(!isModalVisible);
                  return;
                }}
              >
                Update
              </Styled.MoreOptionButton>
            </Styled.MoreOptionOrgSectionSelectOption>

            <Styled.MoreOptionOrgSectionSelectOption>
              <Styled.MoreOptionButton
                variant="unstyled"
                type="button"
                onClick={() => {
                  setModalType("Remove");
                  setModalVisible(!isModalVisible);
                  return;
                }}
              >
                Remove
              </Styled.MoreOptionButton>
            </Styled.MoreOptionOrgSectionSelectOption>
          </Styled.MoreOptionSelectMenu>
        </Popover>
      </>
    );
  };

  const ActionButton = (
    <>
      <Button
        onClick={() => {
          setModalType("Add");
          setModalVisible(!isModalVisible);
          return;
        }}
      >
        <Styled.AddOrgIcon>Add organisation </Styled.AddOrgIcon>
        <Icon icon="plus" />
      </Button>
      {isModalVisible && (
        <OrgModal
          data={{ policy_id: policy_id }}
          handleModal={toggleModal}
          refetchQuery={refetchQuery}
          modalType={modalType}
        />
      )}
    </>
  );

  const organisationsRow = (orgsSlice: IOrganisation[]) => {
    return (
      <>
        {orgsSlice.map((org: IOrganisation, i: number) => (
          <TableRow key={org.id}>
            <TableCell>
              <Styled.Link
                href={`/organisations/${org.id}`}
                router
                title={org.name}
              >
                {org.name}
              </Styled.Link>
            </TableCell>
            <TableCell>
              <Typography styled={{ text: { case: "title" } }}>
                {!!org.industry?.occupation && <>{org.industry.occupation}, </>}
                {org.industry?.name}
              </Typography>
            </TableCell>
            <TableCell justifyContent="end">
              <Typography styled={{ text: { align: "right" } }}>
                {org.associated_policies?.length || 0}
              </Typography>
            </TableCell>
            <TableCell justifyContent="end">
              <Typography styled={{ text: { align: "right" } }}>
                {org.users?.length || 0}
              </Typography>
            </TableCell>
            <TableCell>
              <OrgTypeChip org_type={org.type} />
            </TableCell>
            {enableEditOption ? (
              <>
                <TableCell>
                  <OverflowMenuButtonOrganisation />
                  {isModalVisible && (
                    <OrgModal
                      data={{
                        policy_id: policy_id,
                        org_id: org.id,
                        org_name: org.name,
                      }}
                      handleModal={toggleModal}
                      refetchQuery={refetchQuery}
                      modalType={modalType}
                    />
                  )}
                </TableCell>
              </>
            ) : (
              ""
            )}
          </TableRow>
        ))}
      </>
    );
  };

  const tableColumns = [
    { title: "Name", expand: true },
    { title: "Industry", collapse: true },
    { title: "Policies", collapse: true },
    { title: "Users", collapse: true },
    { title: "Type", collapse: true },
  ];

  if (enableEditOption) tableColumns.push({ title: "", collapse: true });

  return (
    <>
      <PaginatedResults
        id={refetchQuery}
        displayMiscButton={
          enableEditOption &&
          ((actButton: boolean) => (
            <Header
              title="Organisation"
              type="section"
              actions={actButton && ActionButton}
            />
          ))
        }
        searchTerm={searchTerm}
        tableColumns={tableColumns}
        service={organisationService.getAllOrganisations}
        searchService={organisationService.getAllOrganisationsBySearchTerm}
        serviceParams={serviceParams}
        tableRows={organisationsRow}
        missing={{
          icon: "organization",
          type: "organisations",
          message: "This is where we’ll show all Organisations.",
        }}
      />
    </>
  );
};

export default OrganisationsList;
export type tOrgModalType = "Add" | "Update" | "Remove" | undefined;
