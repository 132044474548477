import styled from "styled-components";
import { theme } from "./../../../styles";
import {
  Button,
  TabPanel,
  JfsForm,
  Container,
} from "./../../../components/util";

export const QuoteTabPanel = styled(TabPanel).attrs((props: any) => ({}))`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
`;

export const QuoteFormSchema = styled(JfsForm).attrs((props: any) => ({}))``;

export const BackNav = styled(Container).attrs((props: any) => ({}))`
  width: 700px;
  min-height: 90px;
  button {
    color: ${theme.color.accent.text.default.default};
  }
  span {
    margin-right: 8px;
  }
`;

export const BackButton = styled(Button).attrs((props: any) => ({}))`
  padding-left: 0px;
  color: ${theme.color.accent.text.default.default};
  span {
    margin-right: 8px;
  }
  padding-top: 32px;
`;

export const StepButton = styled(Button).attrs((props: any) => ({}))`
  &:first-of-type {
    margin-right: 24px;
    & span {
      margin-right: 8px;
    }
  }
  &:last-of-type {
    & span {
      margin-left: 8px;
    }
  }
`;
