import { Grid } from "../../../../../components/util";
import * as Styled from "./styles.css";
export const ScoreMeterSkeleton = () => {
  return (
    <Grid container>
      <Grid item xs={1}>
        <Styled.ScoreSkeleton />
      </Grid>
      <Grid item xs={9}></Grid>
      <Styled.BestScoreSkeleton item xs={2}>
        <Styled.ScoreSkeleton />
        <Styled.AverageSkeleton />
      </Styled.BestScoreSkeleton>
      <Grid item xs={12}>
        <Styled.SMProgressBarSkeleton />
      </Grid>
    </Grid>
  );
};
