import axios from "../../axios";

const CalendlyService = {
  async createBookingActivity(data: BookingDetails): Promise<void> {
    try {
      axios.post("/calendly/new", data);
    } catch (err) {
      throw new Error("Unable to record Activity");
    }
  },
};

export interface BookingDetails {
  booked_by: string;
  event: string;
  invitee: string;
  organisation_id: string;
  user_id: string;
}

export default CalendlyService;
