import * as React from "react";
import { useParams } from "react-router-dom";
import { useAutomationEntity } from "../useAutomationEntity";
import * as Styled from "../styles.css";
import { Header } from "../../../components/layout";
import { AutomationStatusChip } from "../AutomationStatusChip";
import { Summary } from "../../../components/content";
import { formatDateTime } from "../../../helpers";
import { AutomationDetailsTabs } from "./AutomationDetailsTabs";

export const AutomationDetailsPage: React.FC = () => {
  const { automation_id } = useParams<{ automation_id: string }>();
  const automation = useAutomationEntity(automation_id);

  return (
    <Styled.Grid $fullHeight>
      <Header
        title={automation.data?.name}
        titleIcon="dependabot"
        titleIconPosition="end"
        type="article"
        withLink={{ path: "/automations", text: "All Automations" }}
      />
      <Summary
        data={[
          {
            Enabled: (
              <AutomationStatusChip enabled={automation?.data?.enabled} />
            ),
          },
          {
            Created: automation?.data
              ? formatDateTime(automation.data.created_at.toString(), "verbose")
              : "",
          },
          {
            Updated: automation?.data
              ? formatDateTime(automation.data.updated_at.toString(), "verbose")
              : "",
          },
        ]}
      />
      {!!automation.data && (
        <AutomationDetailsTabs automation={automation.data} />
      )}
    </Styled.Grid>
  );
};
