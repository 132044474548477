import styled from "styled-components";
import { TypographyProps } from ".";
import { breakpoints, theme } from "../../../styles";
import {
  IComponentStyleProps,
  TStyleConnotation,
  TypographyVariant,
} from "../../../types";
import Container from "../container/container";

interface TypographyStyleProps extends TypographyProps {
  $paragraph: boolean;
  styled?: IComponentStyleProps;
  $variant: TypographyVariant;
  color: TStyleConnotation | "inherit";
}

export const Typography = styled(Container).attrs(
  (props: TypographyStyleProps) => ({
    color:
      props.styled?.text?.color ||
      props.color ||
      !!(props.shade || props.styled?.text?.shade)
        ? props.styled?.text?.color || props.color || "surface"
        : "inherit",
    shade: props.styled?.text?.shade || props.shade || "default",
    truncate: props.truncate,
  }),
)<TypographyStyleProps>`
  font-family: ${theme.typography.fontFamily.sans};

  color: ${({ color, shade }) => {
    if (color === "inherit") {
      return "inherit";
    } else {
      return theme.color?.[color]?.text?.[shade]?.default;
    }
  }};

  font-weight: ${({ styled }) =>
    styled?.text?.weight
      ? theme.typography.weight[styled.text.weight]
      : "inherit"};

  ${(props: any) => {
    switch (props.$variant) {
      case "displayLarge":
        return `
          font-size: ${theme.typography.displayLarge.fontSize};
          font-weight: ${
            props.$bold
              ? theme.typography.displayLarge.fontWeight + 200
              : theme.typography.displayLarge.fontWeight
          };
          letter-spacing: ${theme.typography.displayLarge.letterSpacing};
          line-height: ${theme.typography.displayLarge.lineHeight};
          @media ${breakpoints.up("lg")} {
            font-size: ${theme.typography.displayLarge.desktop.fontSize};
            font-weight: ${
              props.$bold
                ? theme.typography.displayLarge.desktop.fontWeight + 200
                : theme.typography.displayLarge.desktop.fontWeight
            };
            letter-spacing: ${
              theme.typography.displayLarge.desktop.letterSpacing
            };
            line-height: ${theme.typography.displayLarge.desktop.lineHeight};
          }
        `;
      case "displayMedium":
        return `
          font-size: ${theme.typography.displayMedium.fontSize};
          font-weight: ${
            props.$bold
              ? theme.typography.displayMedium.fontWeight + 200
              : theme.typography.displayMedium.fontWeight
          };
          letter-spacing: ${theme.typography.displayMedium.letterSpacing};
          line-height: ${theme.typography.displayMedium.lineHeight};
          @media ${breakpoints.up("lg")} {
            font-size: ${theme.typography.displayMedium.desktop.fontSize};
            font-weight: ${
              props.$bold
                ? theme.typography.displayMedium.desktop.fontWeight + 200
                : theme.typography.displayMedium.desktop.fontWeight
            };
            line-height: ${theme.typography.displayMedium.desktop.lineHeight};
            letter-spacing: ${
              theme.typography.displayMedium.desktop.letterSpacing
            };
          }
        `;
      case "displaySmall":
        return `
          font-size: ${theme.typography.displaySmall.fontSize};
          font-weight: ${
            props.$bold
              ? theme.typography.displaySmall.fontWeight + 200
              : theme.typography.displaySmall.fontWeight
          };
          letter-spacing: ${theme.typography.displaySmall.letterSpacing};
          line-height: ${theme.typography.displaySmall.lineHeight};
          @media ${breakpoints.up("lg")} {
            font-size: ${theme.typography.displaySmall.desktop.fontSize};
            font-weight: ${
              props.$bold
                ? theme.typography.displaySmall.desktop.fontWeight + 200
                : theme.typography.displaySmall.desktop.fontWeight
            };
            letter-spacing: ${
              theme.typography.displaySmall.desktop.letterSpacing
            };
            line-height: ${theme.typography.displaySmall.desktop.lineHeight};
          }
        `;
      case "heading":
        return `
          font-size: ${theme.typography.heading.fontSize};
          font-weight: ${
            props.$bold
              ? theme.typography.heading.fontWeight + 200
              : theme.typography.heading.fontWeight
          };
          letter-spacing: ${theme.typography.heading.letterSpacing};
          line-height: ${theme.typography.heading.lineHeight};
          @media ${breakpoints.up("lg")} {
            font-size: ${theme.typography.heading.desktop.fontSize};
            font-weight: ${
              props.$bold
                ? theme.typography.heading.desktop.fontWeight + 200
                : theme.typography.heading.desktop.fontWeight
            };
            letter-spacing: ${theme.typography.heading.desktop.letterSpacing};
            line-height: ${theme.typography.heading.desktop.lineHeight};
          }
        `;
      case "subHeading":
        return `
          font-size: ${theme.typography.subheading.fontSize};
          font-weight: ${
            props.$bold
              ? theme.typography.subheading.fontWeight + 200
              : theme.typography.subheading.fontWeight
          };
          letter-spacing: ${theme.typography.subheading.letterSpacing};
          line-height: ${theme.typography.subheading.lineHeight};
          text-transform: uppercase;
          @media ${breakpoints.up("lg")} {
            font-size: ${theme.typography.subheading.desktop.fontSize};
            font-weight: ${
              props.$bold
                ? theme.typography.subheading.desktop.fontWeight + 200
                : theme.typography.subheading.desktop.fontWeight
            };
            letter-spacing: ${
              theme.typography.subheading.desktop.letterSpacing
            };
            line-height: ${theme.typography.subheading.desktop.lineHeight};
          }
        `;
      case "h6":
        return `
          color: ${theme.color.surface.text.strong.default};
          font-size: ${theme.typography.h6.fontSize};
          font-weight: ${
            props.$bold
              ? theme.typography.h6.fontWeight + 200
              : theme.typography.h6.fontWeight
          };
          letter-spacing: ${theme.typography.h6.letterSpacing};
          line-height: ${theme.typography.h6.lineHeight};
          @media ${breakpoints.up("lg")} {
            font-size: ${theme.typography.h6.desktop.fontSize};
            font-weight: ${
              props.$bold
                ? theme.typography.h6.desktop.fontWeight + 200
                : theme.typography.h6.desktop.fontWeight
            };
            letter-spacing: ${theme.typography.h6.desktop.letterSpacing};
            line-height: ${theme.typography.h6.desktop.lineHeight};
          }
       `;
      case "body1":
        return `
          font-size: ${theme.typography.body.fontSize};
          letter-spacing: ${theme.typography.body.letterSpacing};
          line-height: ${theme.typography.body.lineHeight};
          @media ${breakpoints.up("lg")} {
            font-size: ${theme.typography.body.desktop.fontSize};
            letter-spacing: ${theme.typography.body.desktop.letterSpacing};
            line-height: ${theme.typography.body.desktop.lineHeight};
          }
       `;
      case "body2":
        return `
          font-size: ${theme.typography.small.fontSize};
          letter-spacing: ${theme.typography.small.letterSpacing};
          line-height: ${theme.typography.small.lineHeight};
          @media ${breakpoints.up("lg")} {
            font-size: ${theme.typography.small.desktop.fontSize};
            letter-spacing: ${theme.typography.small.desktop.letterSpacing};
            line-height: ${theme.typography.small.desktop.lineHeight};
          }
       `;
      default:
        return `
          font-size: ${theme.typography.body.fontSize};
          letter-spacing: ${theme.typography.body.letterSpacing};
          line-height: ${theme.typography.body.lineHeight};
          @media ${breakpoints.up("lg")} {
            font-size: ${theme.typography.body.desktop.fontSize};
            letter-spacing: ${theme.typography.body.desktop.letterSpacing};
            line-height: ${theme.typography.body.desktop.lineHeight};
          }
        `;
    }
  }}

  margin-bottom: ${props => {
    if (props.$paragraph) {
      switch (props.$variant) {
        case "heading":
          return "8px";
        case "subHeading":
          return "4px";
        default:
          return `12px`;
      }
    }
    return "0px";
  }};

  & {
    ${({ styled }) => {
      switch (styled?.text?.case) {
        case "sentence":
          return `
            &:first-letter {
              text-transform: capitalize;
            }
          `;
        case "title":
          return "text-transform: capitalize;";
        case "upper":
          return "text-transform: uppercase;";
        case "lower":
          return "text-transform: lowercase;";
        case "none":
        default:
          return "";
      }
    }};
  }

  text-align: ${({ styled }) => styled?.text?.align};

  ${({ truncate }) =>
    truncate &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `};
`;
