import { IOrganisation } from "../../../../services/admin/admin.service";
import {
  TableRow,
  TableCell,
  Typography,
  Popover,
} from "../../../../components/util";
import { PaginatedResults } from "../../../../components/content";
import organisationService from "../../../../services/organisation/organisation.service";
import { TabOrgProps } from "./parentOrgs";
import * as Styled from "../styles.css";

const ChildOrgs = ({ orgId }: TabOrgProps) => {
  const organisationsRow = (orgsSlice: IOrganisation[]) => {
    return (
      <>
        {orgsSlice.map((org: IOrganisation, i: number) => (
          <TableRow key={org.id}>
            <TableCell>
              <Styled.Link
                href={`/organisations/${org.id}?t=general`}
                router
                title={org.name}
              >
                {org.name}
              </Styled.Link>
            </TableCell>
            <TableCell>
              <Typography>{org.email}</Typography>
            </TableCell>
            <TableCell>
              <Typography styled={{ text: { case: "sentence" } }}>
                {org.users?.length}
              </Typography>
            </TableCell>
            <TableCell>
              <OverflowMenuButton />
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  const OverflowMenuButton = () => {
    return (
      <Popover
        alignment="end"
        trigger={
          <Styled.MoreOptionIconButton disabled icon="kebab-horizontal">
            Pop
          </Styled.MoreOptionIconButton>
        }
      >
        <Styled.MoreOptionSelectMenu></Styled.MoreOptionSelectMenu>
      </Popover>
    );
  };
  return (
    <Styled.SpacedGrid>
      <PaginatedResults
        id={`${orgId}-organisations-child`}
        tableColumns={[
          { title: "Name", expand: true },
          { title: "Primary contact", collapse: true },
          { title: "Users", collapse: true },
          { title: "", collapse: true },
        ]}
        service={organisationService.getAllChild}
        tableRows={organisationsRow}
        missing={{
          icon: "organization",
          type: "organisations",
          message: "This is where we’ll show all Organisations.",
        }}
      />
    </Styled.SpacedGrid>
  );
};

export default ChildOrgs;
