import styled from "styled-components";
import { breakpoints, theme } from "../../styles";
import {
  Grid as ComponentGrid,
  Link as ComponentLink,
  Table,
  Typography,
} from "../../components/util";
import { GridProps } from "@material-ui/core";

export interface StyledGridProps extends GridProps {
  $fullHeight?: boolean;
}

export const Tabs = styled(ComponentGrid)``;

export const SubHeader = styled(Typography)`
  margin-top: 8px;
  color: ${theme.color.surface.text.strong.default};
  font-weight: normal;
`;

export const Link = styled(ComponentLink)`
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media ${breakpoints.up("md")} {
    max-width: 100px;
  }
  @media ${breakpoints.up("lg")} {
    max-width: 200px;
  }
`;

export const Grid = styled(ComponentGrid)<StyledGridProps>`
  height: ${props => (props.$fullHeight ? "100%" : "initial")};
`;

export const ResultCount = styled(Typography).attrs(() => ({
  variant: "body1",
}))`
  font-weight: 600;
  margin: ${theme.spacing}px 0;
`;

export const PoliciesTable = styled(Table)`
  @media ${breakpoints.down("sm")} {
    grid-template-columns: 1fr;
  }
  th:not(:first-of-type) {
    display: none;
    @media ${breakpoints.up("md")} {
      display: flex;
    }
  }
  td:not(:first-of-type) {
    display: none;
    @media ${breakpoints.up("md")} {
      display: flex;
    }
  }
`;
