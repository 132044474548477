import { Color, TIconShape, TStyleConnotation } from "../../../types";
import { ContainerProps } from "../container/container";
import * as Styled from "./styles.css";

const Alert = ({
  children,
  icon,
  isFullWidth,
  color,
  defaultIcon,
  type,
  ...otherProps
}: AlertProps) => {
  return (
    <Styled.Alert
      $icon={!!icon}
      $isFullWidth={isFullWidth}
      $color={color}
      $type={type}
      {...otherProps}
    >
      {icon && <Styled.AlertIcon icon={icon} color="negative" />}
      {!icon && color && defaultIcon && (
        <Styled.AlertIcon icon={SeverityToIconMapping[color]} color={color} />
      )}
      {children && children}
    </Styled.Alert>
  );
};

interface AlertProps extends ContainerProps {
  defaultIcon?: boolean;
  icon?: TIconShape;
  isFullWidth: boolean;
  type?: "text" | "boxed";
  color: TStyleConnotation;
}

Alert.defaultProps = {
  defaultIcon: true,
  isFullWidth: false,
  color: "information",
  type: "boxed",
};

const SeverityToIconMapping: Partial<Record<Color, TIconShape>> = {
  success: "check-circle",
  info: "info",
  warning: "shield",
  error: "alert",
};

export default Alert;
