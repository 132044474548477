import styled from "styled-components";
import { theme } from "../../styles/themes";
import { Button, Grid, Typography } from "../../components/util";

export const SubHeader = styled(Typography)`
  margin-top: 8px;
  color: ${theme.color.surface.text.strong.default};
  font-weight: normal;
`;

export const SectionHeader = styled(Grid)`
  padding: 32px 0;
`;

export const RiskAndSafety = styled(Grid)`
  border-top: 1px solid ${theme.color.surface.border.weak.default};
  margin-top: 24px;
  padding-top: 42px;
`;

export const SelfAssessment = styled(Grid)``;

export const IAuditor = styled(Grid)`
  margin-top: 32px;
`;

export const IAuditorButton = styled(Button)`
  margin-top: 16px;
`;
