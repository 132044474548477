import styled from "styled-components";
import { theme } from "../../../styles";
import { TStyleConnotation, TStyleSize } from "../../../types";
import { Container } from "../../util";

interface ILogoStyle {
  $color?: TStyleConnotation;
  $height: number;
  $width: number;
  $size: TStyleSize;
}

export const LogoSvg = styled(Container)<ILogoStyle>`
  height: ${({ $height }) => `${$height}px;`};
  width: ${({ $width }) => `${$width}px;`};
`;

interface IMittiLogoStyle {
  $color: TStyleConnotation;
  $height: number;
  $width: number;
}

export const MittiLogo = styled("svg")<IMittiLogoStyle>`
  ${({ $color }) => {
    switch ($color) {
      case "surface":
        return `
          fill: ${theme.color.surface.background.default.default};
        `;
      case "accent":
      default:
        return `
          fill: ${theme.color.accent.background.default.pressed};
        `;
    }
  }}
`;
