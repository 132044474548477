import { CardProps as MuiCardProps } from "@material-ui/core";
import * as Styled from "./styles.css";

interface CardProps extends MuiCardProps {
  isFlat?: boolean;
  isInGrid?: boolean;
  isPadded?: boolean;
  noOutline?: boolean;
}

const Card = ({
  children,
  isInGrid,
  isPadded,
  isFlat,
  noOutline,
  ...otherProps
}: CardProps) => {
  return (
    <Styled.Card
      $isFlat={isFlat}
      $isInGrid={isInGrid}
      $isPadded={isPadded}
      $noOutline={noOutline}
      {...otherProps}
    >
      {children && children}
    </Styled.Card>
  );
};

Card.defaultProps = {
  isFlat: false,
  isInGrid: false,
  isPadded: false,
};
export default Card;
