import { ScoreFactor, Scores } from "../../../services/scores/scores.service";
import { Grid, Typography } from "../../../components/util";
import {
  SelfAssessmentMeter,
  SelfAssessmentFactors,
} from "../../../components/content";
import * as Styled from "./styles.css";

const ScoreBreakdown = ({
  score,
  scoreFactors,
  scoreMessage,
  scoreVersion,
}: BreakdownProps) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} xxl={6}>
        <SelfAssessmentMeter
          data={score}
          isError={false}
          footnote={<Typography variant="body2">{scoreMessage}</Typography>}
        />
      </Grid>
      {scoreFactors && (
        <Styled.FactorsContainer item xs={12} xxl={6}>
          <SelfAssessmentFactors
            showActions
            data={scoreFactors}
            v2={scoreVersion === 2}
          />
        </Styled.FactorsContainer> //
      )}
    </Grid>
  );
};

interface BreakdownProps {
  score?: Scores;
  scoreFactors?: ScoreFactor[];
  scoreMessage: string;
  scoreVersion?: 1 | 2;
}

export default ScoreBreakdown;
