import styled from "styled-components";
import { breakpoints, theme } from "../../../styles";
import { Grid, Typography } from "../../../components/util";

export const Title = styled(Typography)`
  margin-bottom: 10px;
`;

export const FactorsContainer = styled(Grid)`
  @media ${breakpoints.up("md")} {
    &.MuiGrid-grid-md-6 {
      margin-top: 40px;
    }
  }
`;

export const IAuditorBreakdownContainer = styled(Grid)`
  border-top: 1px ${theme.color.surface.border.weak.default};
  margin-top: 48px;
`;
