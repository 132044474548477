import { WidgetProps } from "@rjsf/utils";
import JfsList from "./../../../../content/list/jfsList/jfsList";
import JfsListItem from "./../../../../content/list/jfsListItem/jfsListItem";
import { JfsCheckBox } from "./../../..";

const JfsCheckBoxes = ({
  onChange,
  id,
  value,
  ...otherProps
}: JfsCheckBoxProps) => {
  const handleChange = (val: string) => {
    let newArray: string[] = JSON.parse(JSON.stringify(value));
    newArray.includes(val)
      ? newArray.splice(newArray.indexOf(val), 1)
      : newArray.push(val);
    onChange(newArray);
  };
  const options = otherProps.options.enumOptions;

  return (
    <JfsList id={id} corners="rounded" elevation="low" outlined>
      {options &&
        options.map((checkbox: any, i: number) => (
          <JfsListItem
            checked={value && value.includes(checkbox.value)}
            key={`${id}-${i}`}
            onClick={(e: any) => {
              handleChange(checkbox.value);
            }}
          >
            <JfsCheckBox
              id={`${id}-${checkbox.value}`}
              onChange={handleChange}
              value={value && value.includes(checkbox.value)}
              title={checkbox.label}
              {...otherProps}
            />
          </JfsListItem>
        ))}
    </JfsList>
  );
};

interface JfsCheckBoxProps extends WidgetProps {}

JfsCheckBoxes.defaultProps = {};

export default JfsCheckBoxes;
