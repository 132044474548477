import * as Styled from "./styles.css";

const FormRow = ({
  children,
  container,
  textAlign,
  stickyField,
  ...otherProps
}: FormRowProps) => {
  return (
    <Styled.FormRow
      container={container}
      spacing={container ? 3 : undefined}
      $textAlign={textAlign}
      $stickyField={stickyField}
      {...otherProps}
    >
      {children && children}
    </Styled.FormRow>
  );
};

interface FormRowProps {
  children?: React.ReactNode;
  className?: string;
  container?: boolean;
  textAlign?: "left" | "center" | "right";
  stickyField?: boolean;
}

FormRow.defualtProps = {
  textAlign: "left",
  sticyField: false,
};

export default FormRow;
