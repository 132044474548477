import { useState } from "react";
import { ActionMeta, OptionProps } from "react-select";
import { WidgetProps } from "@rjsf/utils";

import { isValidEmail, arrayify } from "../../../helpers";
import * as Styled from "./styles.css";

const EmailSelect = ({
  value,
  uiSchema,
  onChange,
  rawErrors,
  ...otherProps
}: EmailSelectProps) => {
  const { emails = [], isMulti } = uiSchema["ui:options"];
  const [createdEmails, setCreatedEmails] = useState<Email[]>([]);
  const allEmails: Email[] = [...emails, ...createdEmails];
  const options = mapEmailsToOptions(allEmails);

  const getOptions = (
    value?: Option["value"] | Option["value"][],
  ): Option | Option[] => {
    const selectedOptions = options?.filter(option =>
      arrayify<Option["value"]>(value).includes(option.value),
    );

    return isMulti ? selectedOptions ?? [] : selectedOptions?.[0] ?? null;
  };

  const handleCreate = (input?: string): void => {
    if (
      input != null &&
      isValidEmail(input) &&
      !allEmails.find(email => email.email === input)
    ) {
      const email: Email = { name: "created", email: input };

      setCreatedEmails(emails => [...emails, email]);
    }
  };

  const handleChange = (
    selected: Option | Option[],
    { action }: ActionMeta<OptionProps>,
  ): void => {
    switch (action) {
      case "select-option":
      case "remove-value":
        return selectOption(selected);
      case "create-option":
        return createOption(selected);
      case "clear":
        return clearOption();
    }
  };

  const selectOption = (selected: Option | Option[]) => {
    if (isMulti && Array.isArray(selected)) {
      onChange(selected.map(x => x.value));
    } else if (!isMulti && !Array.isArray(selected)) {
      onChange(selected.value);
    }
  };

  const createOption = (selected: Option | Option[]) => {
    if (isMulti && Array.isArray(selected)) {
      handleCreate(selected.find(x => x.__isNew__)?.value);
      onChange(selected.map(x => x.value));
    } else if (!isMulti && !Array.isArray(selected)) {
      handleCreate(selected.value);
      onChange(selected.value);
    }
  };

  const clearOption = () => onChange(undefined);

  return (
    <Styled.EmailSelect
      {...otherProps}
      isClearable
      isMulti={isMulti}
      onChange={handleChange}
      options={options}
      value={getOptions(value)}
      getOptionValue={option => option.value}
      isValid={!rawErrors}
    />
  );
};

const mapEmailToOption = (email: Email): Option => ({
  label: [email.email, email.name ? `(${email.name})` : undefined]
    .filter(Boolean)
    .join(" "),
  value: email.email,
});

const mapEmailsToOptions = (emails: Email[]): Option[] =>
  emails.map(mapEmailToOption);

export type EmailSelectProps = WidgetProps & {
  uiSchema: {
    "ui:options": {
      emails: Email[];
      isMulti?: boolean;
    };
  };
};

type Email = {
  name?: string;
  email: string;
};

type Option = {
  value: string;
  label: string;
  __isNew__?: boolean;
};

export default EmailSelect;
