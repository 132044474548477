import { useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { AuthContext } from "../contexts";
import { getMostPrivlidgedRole, hasAllowedRoles } from "../helpers";
import { tUserRole } from "../types";
import { PageNotFound } from "../pages";
import { IUserRolesAccess } from "./routes";

const ProtectedRoute = ({
  children,
  redirect,
  roles,
  ...otherProps
}: ProtectedRouteProps) => {
  const auth = useContext(AuthContext);
  if (
    redirect &&
    auth.user?.roles &&
    !hasAllowedRoles(auth.user?.roles, roles)
  ) {
    return <Redirect to={redirect[getMostPrivlidgedRole(auth.user.roles)]} />;
  }

  return (
    <Route
      {...otherProps}
      render={({ location }) =>
        auth.user ? (
          hasAllowedRoles(auth.user.roles, roles) ? (
            children
          ) : (
            <PageNotFound />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export interface ProtectedRouteProps extends RouteProps {
  redirect?: Record<tUserRole, string>;
  roles?: IUserRolesAccess;
}

export default ProtectedRoute;
