import styled from "styled-components";
import { theme } from "../../../styles/themes";
import Container from "../container/container";
import { IconProps } from "./icon";

interface IconStyleProps extends IconProps {}

export const Icon = styled(Container).attrs((props: IconStyleProps) => ({
  size: props.size || "md",
  color: props.styled?.icon?.color || props.color || "surface",
  shade: props.styled?.icon?.shade || props.shade || "default",
}))<IconStyleProps>`
  display: inline-block;
  vertical-align: middle;
  & svg,
  path {
    display: block;
    fill: ${({ color, shade }) => theme.color[color]?.icon[shade]?.default};
  }
  ${({ size }) => {
    switch (size) {
      case "xs":
      case "sm":
        return `
          width: 16px;
          height: 16px;
        `;
      case "lg":
        return `
          width: 28px;
          height: 28px;
        `;
      case "xl":
        return `
          width: 38px;
          height: 38px;
        `;
      case "md":
      default:
        return `
          width: 20px;
          height: 20px;
        `;
    }
  }};
`;
