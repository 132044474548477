import styled from "styled-components";
import { theme } from "../../../styles/themes";
import {
  Form,
  FormRow,
  Grid,
  SelectMenu,
  SelectOption,
  TextField,
  Typography,
} from "../../../components/util";
import { GridProps } from "@material-ui/core";
import { buildBoxShadow } from "../../../helpers";

export interface StyledGridProps extends GridProps {
  $fullHeight?: boolean;
}

export const HeaderGrid = styled(Grid)<StyledGridProps>`
  border-bottom: 1px solid ${theme.color.surface.border.default.default};
  padding: 20px 24px;
  margin-bottom: 10px;
`;
export const Title = styled(Typography)`
  padding: 5px 0px;
`;
export const ClientTitle = styled(Typography)`
  padding: 0px;
`;
export const Body = styled(Grid)`
  padding: 24px;
  padding-top: 12px;
  padding-bottom: 0px;
  width: 70%;
  justify-content: end;
  overflow-y: scroll;

  body {
    overflow: hidden !important;
  }
`;
export const BodyTitle = styled(Grid)<StyledGridProps>`
  padding-top: 0px;
  padding-bottom: 25px;
`;

export const OrgForm = styled(Form)`
  & > div:nth-last-child(2) {
    margin-bottom: 48px;
  }
`;

export const OrgTextField = styled(TextField)`
  height: 44px;
`;

export const BodyComponentFooter = styled(Grid)<StyledGridProps>`
  margin-bottom: 25px;
`;

export const MoreOptionSelectMenu = styled(SelectMenu)`
  transform: translate3d(10px, 10px, 0);
  z-index: 99;
  border-radius: 6px;
  box-shadow: ${buildBoxShadow("high", "default")};
`;

export const MoreOptionSelectOption = styled(SelectOption)`
  padding: 8px 15px;
  background-color: ${theme.color.surface.background.default.default};
`;

export const FormFooter = styled(FormRow)`
  padding-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
`;

export const Footer = styled.hr`
  position: relative;
  border-bottom: 1px solid ${theme.color.surface.border.weaker.default};
  bottom: 90px;
`;
