import { TitleFieldProps } from "@rjsf/utils";
import { Typography } from "./../../..";
import * as Styled from "./styles.css";

const JfsHeader = ({ title, uiSchema, ...otherProps }: JfsHeaderProps) => {
  return (
    <Styled.HeaderContainer element="header" item xs={12} {...otherProps}>
      <Styled.ContentContainer $type="plain">
        {title && (
          <Typography
            variant={
              otherProps.id !== "root__title" ? "heading" : "displayMedium"
            }
          >
            {title}
          </Typography>
        )}
      </Styled.ContentContainer>
    </Styled.HeaderContainer>
  );
};

export interface JfsHeaderProps extends TitleFieldProps {}

JfsHeader.defaultProps = {};

export default JfsHeader;
