import { FieldTemplateProps } from "@rjsf/utils";
import { JfsInputHelpText, JfsInputLabel } from "./../../..";
import * as Styled from "./styles.css";

const JfsFormRow = ({
  children,
  container,
  description,
  errors,
  help,
  id,
  label,
  onKeyChange,
  onDropPropertyClick,
  required,
  textAlign,
  ...otherProps
}: JfsFormRowProps) => {
  return (
    <Styled.FormRow
      container={container}
      spacing={container ? 3 : undefined}
      $textAlign={textAlign}
    >
      {otherProps.displayLabel && (
        <JfsInputLabel
          id={id}
          htmlFor={id || ""}
          required={required}
          showOptional={!required}
          title={label}
          {...otherProps}
        />
      )}
      {description.props.description && description}
      {children && children}
      {errors?.props?.errors && (
        <JfsInputHelpText
          id={id}
          label={label}
          error
          helpList={errors.errors}
          errorList={errors.props.errors}
          {...otherProps}
        />
      )}
      {help && help}
    </Styled.FormRow>
  );
};

interface JfsFormRowProps extends FieldTemplateProps {
  className?: string;
  container?: boolean;
  description?: any;
  required?: boolean;
  textAlign?: "left" | "center" | "right";
  displayLabel?: boolean;
  errors?: any;
  help?: any;
}

JfsFormRow.defualtProps = {
  textAlign: "left",
};

export default JfsFormRow;
