import styled from "styled-components";
import { theme } from "./../../../../../styles";
import Container from "../../../container/container";

export const Prepend = styled(Container).attrs((props: any) => ({
  $size: props.$size,
}))`
  display: flex;
  position: absolute;
  ${(props: any) => {
    return `
      padding:
        ${theme.input.text.padding.vertical[props.$size] + 1}px
        ${theme.input.text.padding.horizontal[props.$size] + 1}px;
    `;
  }}
  color: ${theme.color.surface.text.weak.default};
`;
