import { JfsSchema, JfsUiSchema } from "../../../types";

export const schema: JfsSchema = {
  // title: "Invite",
  type: "object",
  required: ["name", "code"],
  properties: {
    name: {
      type: "string",
      title: "Name",
    },
    code: {
      type: "string",
      title: "Code",
      maxLength: 8,
    },
    tools: {
      type: "array",
      title: "Tools",
      items: {
        type: "object",
        required: ["name", "template_id"],
        properties: {
          name: {
            type: "string",
            title: "Name",
          },
          template_id: {
            type: "string",
            title: "Template ID",
          },
        },
      },
    },
  },
};

export const uiSchema: JfsUiSchema = {
  "ui:order": ["name", "code", "tools"],
  name: {
    "ui:autofocus": true,
  },
  code: {},
  tools: {
    items: {
      name: {},
      template_id: {},
    },
    "ui:options": {
      addButtonText: "Add Tool",
    },
  },
};
