import { JfsUiSchema } from "../../../../types";
import Icon from "../../icons/icon";
import { ContainerProps } from "./../../container/container";
import * as Styled from "./styles.css";

const JfsInputHelpText = ({
  children,
  element,
  error,
  errorList,
  helpList,
  label,
  paragraph,
  validationList,
  ...otherProps
}: JfsInputHelpTextProps) => {
  if (errorList) {
    return (
      <>
        {errorList.map((key: any, i: number) => (
          <Styled.JfsInputHelpTypography
            key={i}
            variant="body2"
            paragraph={paragraph}
            styled={{
              text: {
                color: error ? "negative" : undefined,
                shade: error ? undefined : "weak",
                case: "sentence",
              },
            }}
            {...otherProps}
          >
            <Icon color="negative" icon="alert" size="sm" />
            {otherProps.uiSchema?.["ui:options"]?.errorLabel
              ? otherProps.uiSchema?.["ui:options"]?.errorLabel + " "
              : snakeToCaptilze(otherProps.id?.substring(5))}
            {key}
          </Styled.JfsInputHelpTypography>
        ))}
      </>
    );
  }
  return <></>;
};

interface JfsInputHelpTextProps extends ContainerProps {
  error: boolean;
  errorList?: any;
  helpList?: any;
  label?: string;
  paragraph?: boolean;
  required?: boolean;
  validationList?: any;
  uiSchema?: JfsUiSchema;
}

JfsInputHelpText.defaultProps = {
  error: false,
  paragraph: false,
  required: false,
};

export default JfsInputHelpText;

const snakeToCaptilze = (str: String | undefined) => {
  let res = "";
  if (str) {
    res = str.charAt(0).toUpperCase() + str.replaceAll("_", " ").slice(1) + " ";
  }
  return res;
};
