import axios from "../../axios";
import toastEvent from "../../components/util/toast/toast.event";
import { ApiResponse } from "../types";
import {
  Automation,
  AutomationCreatePayload,
  AutomationCreateResponse,
} from "../../types/automations.type";

const create = async (
  automation: AutomationCreatePayload,
): Promise<AutomationCreateResponse | undefined> => {
  try {
    const response = await axios.post("/automations", automation);

    toastEvent.success("Automation successfully added");

    return response.data;
  } catch (err: any) {
    if (err.response?.data?.error) {
      const { data } = err.response;

      toastEvent.error(`${data.error.title}: ${data.error.detail}`);
    } else {
      toastEvent.error("An unknown error occurred");
    }
  }
};

const getAll = async (
  page?: number,
  limit?: number,
): Promise<ApiResponse<Automation>> => {
  const res = await axios.get("/automations", {
    params: {
      page,
      limit,
      order: "desc", // by default sort by most recent
      sort: "created_at",
    },
  });

  return res.data;
};

const searchAll = async (
  search?: string,
  page?: number,
  limit?: number,
): Promise<ApiResponse<Automation>> => {
  const res = await axios.get("/automations", {
    params: {
      search,
      page,
      limit,
      order: "asc", // on name search, sort alphabetically
      sort: "name",
    },
  });

  return res.data;
};

export default { create, getAll, searchAll };
