import React from "react";
import clsx from "clsx";
import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { theme as mittiTheme } from "../../styles/themes";

const useStyles = makeStyles<Theme, AvatarProps>(_theme => ({
  avatar: {
    fontFamily: `${mittiTheme.typography.fontFamily.sans}`,
  },
  rightAlign: {
    margin: 0,
  },
}));

interface AvatarProps extends MuiAvatarProps {
  right?: Boolean | "true" | "false";
}

const Avatar = (props: AvatarProps) => {
  const classes = useStyles(props);
  return (
    <MuiAvatar
      {...props}
      classes={{
        root: clsx(props.classes && props.classes.root, classes.avatar, {
          [classes.rightAlign]: props.right,
        }),
      }}
    >
      {props.children && props.children}
    </MuiAvatar>
  );
};

Avatar.defaultProps = {};

export default Avatar;
