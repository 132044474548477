import * as Styled from "./styles.css";

const JfsListItem = ({
  checked,
  children,
  ...otherProps
}: JfsListItemProps) => {
  return (
    <Styled.JfsListItem $checked={checked} {...otherProps}>
      {children && children}
    </Styled.JfsListItem>
  );
};

interface JfsListItemProps {
  children?: any;
  checked?: boolean;
  onClick?: any;
}

JfsListItem.defaultProps = {};

export default JfsListItem;
