import { IconButton } from "../../../util";
import { AddressSearch } from "../../../content";
import toastEvent from "../../../util/toast/toast.event";
import { textDefaultSchema } from "../../../../validations";
import * as Styled from "./styles.css";

const EditAddress = ({
  address,
  field,
  onClose,
  onSubmitService,
  onSubmitRefresh,
  record,
  recordType,
  toggleEditing,
  validationSchema,
  ...otherProps
}: iEditAddressProps) => {
  const setErrors = (editDetails: any): Record<string, boolean> => {
    const errors = {};
    for (const key in editDetails) {
      if (
        key === "address_1" ||
        key === "city" ||
        key === "state" ||
        key === "postcode"
      ) {
        errors[key] = editDetails[key]?.length > 0 ? false : true;
      } else {
        errors[key] = false;
      }
    }
    return errors as Record<string, boolean>;
  };

  const editErrorsInit = setErrors(record);
  const editDataInit: Record<string, string> = {
    ...record,
  };

  const handleSubmit = async formData => {
    try {
      await onSubmitService(formData);
    } catch (error: any) {
      throw Error(error.message);
    }
  };

  return (
    <>
      <Styled.GroupForm
        fullWidth={false}
        onSubmit={{
          service: handleSubmit,
          onSuccess: () => {
            onSubmitRefresh();
            toastEvent.success(`${recordType} updated`);
            toggleEditing();
          },
        }}
        validationErrors={[editErrorsInit]}
        validationFields={[editDataInit]}
        submitInline
        submitAlign="right"
        submitStartDisabled
        submitText="Save"
      >
        <AddressSearch
          addressVisible
          id="userAddress"
          size="lg"
          validationType={textDefaultSchema}
        />
      </Styled.GroupForm>
      <IconButton iconShade="weaker" icon="x" onClick={() => toggleEditing()} />
    </>
  );
};

interface iEditAddressProps {
  address?: boolean;
  field: string;
  onClose: () => void;
  onSubmitService: (any) => Promise<any>;
  onSubmitRefresh: () => Promise<void>;
  toggleEditing: () => void;
  record: Record<string, any>;
  recordType: string;
  validationSchema: any;
}

export default EditAddress;
