import { PageMissing } from "../../components/content";
import * as Styled from "./styles.css";

const PageNotFound = () => {
  return (
    <Styled.PageNotFoundContainer>
      <PageMissing />
    </Styled.PageNotFoundContainer>
  );
};

export default PageNotFound;
