import { IconButtonProps } from "@rjsf/utils";

import Button from "../../../button";
import Icon from "../../../icons/icon";
import type { TIconShape } from "../../../../../types/icon.type";

const BaseButton = ({ shape, onClick }: BaseButtonProps) => (
  <Button color="neutral" variant="outlined" onClick={onClick}>
    <Icon icon={shape} />
  </Button>
);

type BaseButtonProps = {
  shape: TIconShape;
} & IconButtonProps;

export const CopyButton = (props: IconButtonProps) => (
  <BaseButton shape="duplicate" {...props} />
);

export const MoveUpButton = (props: IconButtonProps) => (
  <BaseButton shape="chevron-up" {...props} />
);

export const MoveDownButton = (props: IconButtonProps) => (
  <BaseButton shape="chevron-down" {...props} />
);

export const RemoveButton = (props: IconButtonProps) => (
  <BaseButton shape="x" {...props} />
);
