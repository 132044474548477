import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

export const LocationContextProvider = ({
  children,
}: LocationContextProviderProps) => {
  const urlLocation = useLocation();
  const [urlParams, setUrlParams] = useState<Record<string, any> | undefined>(
    queryString.parse(urlLocation.search),
  );

  useEffect(() => {
    if (
      JSON.stringify(queryString.parse(urlLocation.search)) !==
      JSON.stringify(urlParams)
    ) {
      setUrlParams(queryString.parse(urlLocation.search));
    }
  }, [urlLocation.search, urlParams]);

  return (
    <LocationContext.Provider
      value={{
        location: urlLocation,
        urlParams: urlParams,
      }}
    >
      {children && children}
    </LocationContext.Provider>
  );
};

const LocationContext = React.createContext<LocationContextInterface>({
  location: {},
  urlParams: undefined,
});

interface LocationContextInterface {
  location: Record<string, any>;
  urlParams?: Record<string, any>;
}

interface LocationContextProviderProps {
  children: React.ReactNode;
}

export default LocationContext;
