import styled from "styled-components";
import { Icon, Grid, Form, TextField } from "../../../util";
import { GridProps } from "@material-ui/core";

export interface StyledGridProps extends GridProps {
  $fullHeight?: boolean;
}

export const PlusIcon = styled(Icon)`
  margin-left: 8px;
`;

export const ModalBody = styled(Grid)`
  padding: 24px;
  padding-top: 12px;
  padding-bottom: 0px;
`;
export const ModalBodyTitle = styled(Grid)<StyledGridProps>`
  padding-top: 0px;
  padding-bottom: 25px;
`;

export const ModalForm = styled(Form)`
  & > div:nth-last-child(2) {
    margin-bottom: 48px;
  }
`;

export const ModalTextField = styled(TextField)`
  height: 44px;
`;
