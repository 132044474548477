import { Icon, Typography } from "./../../util";
import { Alignment, TIconShape } from "../../../types";
import * as Styled from "./styles.css";

type HeaderProps = {
  actions?: React.ReactNode;
  className?: string;
  title?: string;
  titleIcon?: TIconShape;
  titleIconPosition: Alignment;
  children?: React.ReactNode;
  type: "plain" | "section" | "sectionMeter" | "article";
  withLink?: { path: string; text: string };
};

const Header = ({
  actions,
  children,
  title,
  titleIcon,
  titleIconPosition,
  type,
  withLink,
  ...otherProps
}: HeaderProps) => {
  return (
    <Styled.HeaderContainer
      element="header"
      item
      xs={12}
      {...otherProps}
      $actions={actions}
      $back={!!withLink}
      $type={type}
    >
      <Styled.ContentContainer $type={type}>
        {!!withLink && (
          <Styled.Back href={withLink.path} router>
            <Icon icon="arrow-left" size="sm" />
            {withLink.text}
          </Styled.Back>
        )}
        {title && (
          <Typography
            variant={type === "article" ? "displayMedium" : "displaySmall"}
          >
            {titleIcon && titleIconPosition === "start" && (
              <>
                <Icon icon={titleIcon} shade="weak" size="lg" />{" "}
              </>
            )}
            {title}
            {titleIcon && titleIconPosition === "end" && (
              <>
                {" "}
                <Icon icon={titleIcon} shade="weak" size="lg" />
              </>
            )}
          </Typography>
        )}
        {!!children && children}
      </Styled.ContentContainer>
      {actions && (
        <Styled.ActionsContainer $type={type} $back={!!withLink}>
          {actions}
        </Styled.ActionsContainer>
      )}
    </Styled.HeaderContainer>
  );
};

Header.defaultProps = {
  titleElement: undefined,
  titleIconPosition: "start",
  type: "section",
};

export default Header;
