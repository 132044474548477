import styled from "styled-components";
import { breakpoints } from "../../../styles";
import { Grid as MuiGrid } from "@material-ui/core";

export const Grid = styled(MuiGrid)<GridStyleProps>`
  @media ${breakpoints.up("xxl")} {
    .MuiGrid-grid-xxl-6 {
      flex-grow: 0;
      max-width: 50%;
      flex-basis: 50%;
    }
    .MuiGrid-grid-xxl-12 {
      flex-grow: 0;
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  > ul {
    list-style: none;
  }
`;

interface GridStyleProps {}
