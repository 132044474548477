import { Header, Section } from "../../components/layout";
import { InvitationsList } from "./InvitationsList";
import * as Styled from "./styles.css";

const Invitations = () => (
  <Styled.Grid $fullHeight={true}>
    <Header
      title="Invitations"
      titleIcon="mail"
      titleIconPosition="start"
      type="article"
    />

    <Section>
      <InvitationsList />
    </Section>
  </Styled.Grid>
);

export default Invitations;
