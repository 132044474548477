import axios from "../../axios";
import { ApiResponse } from "../types";

import {
  OrderType,
  PaginationResult,
  SortType,
} from "../../types/pagination.type";

export interface Industry {
  id: string;
  name: string;
  occupation: string;
  stats: IndustryStat;
  factors: IndustryFactor[];
}

export interface IndustryStat {
  average?: number;
  best_practice?: number;
}

export interface IndustryFactor {
  industry_id: string;
  name: string;
  max: number;
  score?: string;
}

export type IndustrySearchAllRow = {
  id: string;
  name?: string;
  anzsic?: string;
  occupation?: string;
  created_at: string;
  updated_at: string;
};

const IndustriesService = {
  async getAllIndustries(): Promise<any> {
    const result = await axios.get("/industries");
    return result.data;
  },
  async searchAll(
    searchTerm: string,
  ): Promise<ApiResponse<IndustrySearchAllRow>> {
    const order_by: OrderType = "desc";
    const sort_type: SortType = "created_at";

    const result = await axios.get("/industries", {
      params: {
        searchTerm: searchTerm.trim(),
        order_by,
        sort_type,
        limit: 1000,
        page_number: 0,
      },
    });

    return result.data;
  },
  async getIndustry(id: string): Promise<Industry> {
    const response = await axios.get(`/industries/${id}`);
    const industry = {
      name: response.data.name || "Unknown Industry",
      stats: {
        average: Math.round(response.data.industryStat.average || 0),
        best_practice: Math.round(
          response.data.industryStat.best_practice || 0,
        ),
      },
      factors: response.data.industryStat.industryStatFactors?.map(
        (factor: any): IndustryFactor =>
          ({
            industry_id: factor.industry_stat_id,
            name: factor.name,
            max: Math.round(factor.score.toString()),
          } || undefined),
      ),
    };
    return industry as Industry;
  },
};

export default IndustriesService;
