import styled from "styled-components";
import { Icon } from "..";
import { theme } from "../../../styles/themes";
import Container from "../container/container";

export const ToastContainer = styled(Container).attrs({
  elevation: "high",
})`
  color: ${theme.color.surface.text.inverse.default};
  background-color: ${theme.color.surface.background.stronger.default};
  padding: 12px 20px 12px 16px;
  position: fixed;
  bottom 24px;
  right: 105px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

export const Message = styled(Container)`
  margin: 0 12px;
`;

export const SuccessIcon = styled(Icon).attrs(props => ({
  icon: "check-circle-fill",
  size: props.size ? props.size : "md",
  color: "success",
}))``;

export const ErrorIcon = styled(Icon).attrs(props => ({
  icon: "alert",
  size: props.size ? props.size : "md",
  color: "error",
}))``;
