import axios from "../../axios";
import {
  formatFactorIAuditor,
  formatScoresIAuditor,
  ScoreInfo,
} from "../scores/scores.service";

class SafetyCultureService {
  private static instance: SafetyCultureService;

  public static initialise() {
    if (!SafetyCultureService.instance) {
      SafetyCultureService.instance = new SafetyCultureService();
    }
    return SafetyCultureService.instance;
  }

  public formatIAuditorInfo = (iAuditorInfo: any): ScoreInfo => {
    const info: ScoreInfo = {
      scores: formatScoresIAuditor({
        current: Math.round(iAuditorInfo.safety_score * 100),
        median: Math.round(iAuditorInfo.safety_score_population_median * 100),
        best_practice: Math.round(
          iAuditorInfo.safety_score_population_best_practice * 100,
        ),
      }),
      factors: [
        formatFactorIAuditor({
          name: "outstanding_inspections",
          current:
            iAuditorInfo.general_stats.num_audit -
            iAuditorInfo.general_stats.num_audit_finished,
        }),
        formatFactorIAuditor({
          name: "incomplete_actions",
          current:
            iAuditorInfo.general_stats.num_action -
            iAuditorInfo.general_stats.num_action_unresolved,
        }),
      ],
    };
    return info as ScoreInfo;
  };

  async fetchIAuditorRiskAndSafetyScore(userId: string): Promise<any> {
    const result = await axios({
      method: "get",
      url: `/users/${userId}/iauditor-risk-and-safety-score`,
    });
    if (result.data) {
      return this.formatIAuditorInfo(result.data);
    } else {
      return;
    }
  }

  getiAuditorInspectionLink() {
    let url = process.env.REACT_APP_IAUDITOR_LINK || "";
    url = url.replace("login.html", "inspections");
    return url;
  }

  getiAuditorTemplateLink() {
    let url = process.env.REACT_APP_IAUDITOR_LINK || "";
    url = url.replace("login.html", "templates");
    return url;
  }
}

export default SafetyCultureService.initialise();
