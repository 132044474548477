import { TabProps } from "../../util/tabs/tab";
import * as Styled from "./styles.css";

const OnboardingTab = ({ ...otherProps }: IAuditorModalProps) => {
  return <Styled.OnboardingTab {...otherProps} />;
};

interface IAuditorModalProps extends TabProps {}

export default OnboardingTab;
