import { ListProps } from "../lists/list";
import * as Styled from "./styles.css";

const SelectMenu = ({ children, ...otherProps }: SelectMenuProps) => {
  return (
    <Styled.MenuContainer
      corners="rounded"
      elevation="high"
      outlined
      variant="plain"
      {...otherProps}
    >
      {children && children}
    </Styled.MenuContainer>
  );
};

interface SelectMenuProps extends ListProps {}

SelectMenu.defaultProps = {};

export default SelectMenu;
