import { useContext, useState, useEffect, useRef, useCallback } from "react";
import { createWidget } from "@typeform/embed";
import authService from "../../services/auth/auth.service";
import ActivityService from "../../services/activity/activity.service";
import { AuthContext } from "../../contexts";
import {
  Alert,
  Grid,
  CircularProgress,
  Typography,
} from "../../components/util";
import "@typeform/embed/build/css/widget.css";
import { recordAnalyticsEvent } from "../../analytics";
import * as Styled from "./styles.css";

const TypeForm = () => {
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const container = useRef<HTMLDivElement>(null);
  const [typeformId, setTypeformId] = useState<TypeformID | TypeformIDV2>(
    auth.user.organisations[0].typeform_id &&
      isIDV2(auth.user.organisations[0].typeform_id)
      ? auth.user.organisations[0].typeform_id
      : process.env.REACT_APP_TYPEFORM_ID_SELF_ASSESSMENT,
  );

  const handleReset = (): void => {
    setTypeformId(getTypeformId("first"));
  };

  const handleUpdateId = useCallback(async () => {
    const user = await authService.getMittiUser();
    // check ID has updated from webhook
    if (
      user.organisations[0].typeform_id !== getTypeformId("next", typeformId)
    ) {
      if (
        process.env.REACT_APP_TYPEFORM_ID_SELF_ASSESSMENT !==
        getTypeformId("next", typeformId)
      ) {
        setLoading(false);
        setError("Oh no, something went wrong! Please try submitting again.");
      }
    } else {
      setError("");
      setLoading(false);
      auth.updateUser(user);
      setTypeformId(user.organisations[0].typeform_id);
    }
  }, [auth, typeformId]);

  const handleSubmit = useCallback(async () => {
    if (
      typeformId === getTypeformId("first") ||
      typeformId === getTypeformId("last")
    ) {
      await ActivityService.createUserActivity({
        type: "Self Assessment",
        action: typeformId === getTypeformId("first") ? "Started" : "Completed",
      });
      auth.refreshUser();
      recordAnalyticsEvent(
        "action",
        typeformId === getTypeformId("first")
          ? "Self Assessment Started"
          : "Self Assessment Completed",
        {
          assessment_id: typeformId,
          form_version: isIDV2(typeformId) ? "2" : "1",
        },
      );
    }
    if (typeformId !== getTypeformId("complete")) {
      setLoading(true);
      setTimeout(() => handleUpdateId(), 5000);
    }
  }, [auth, handleUpdateId, typeformId]);

  useEffect(() => {
    const createTypeform = (element: HTMLDivElement) => {
      createWidget(`${typeformId}`, {
        container: element,
        hidden: {
          email: auth.user.email,
          industry: auth.user.organisations[0].industry.name,
        },
        onSubmit: handleSubmit,
        medium: "embed-snippet",
      });
    };
    if (container.current) {
      createTypeform(container.current);
      setLoading(false);
    }
  }, [auth, typeformId, error, handleSubmit]);

  return loading ? (
    <Styled.PaddedContainer item xs={12}>
      <Alert color="information">
        <Typography>
          <Typography element="span" styled={{ text: { weight: "heavy" } }}>
            Form submitted successfully!{" "}
          </Typography>{" "}
          Please wait while we refresh your results.
        </Typography>
      </Alert>
      <CircularProgress contained />
    </Styled.PaddedContainer>
  ) : (
    <>
      {error.length > 0 && (
        <Styled.PaddedContainer item xs={12}>
          <Alert color="negative">{error}</Alert>
        </Styled.PaddedContainer>
      )}
      {typeformId !== getTypeformId("complete") && (
        <Grid item xs={12}>
          <Styled.TypeformContainer
            corners="rounded"
            elevation="low"
            outlined
            ref={container}
          />
        </Grid>
      )}
      {typeformId === getTypeformId("complete") && (
        <Styled.CompleteContainer item xs={12}>
          <Typography
            paragraph
            variant="displaySmall"
            styled={{ text: { align: "center" } }}
          >
            You made it!
          </Typography>
          <Typography
            paragraph
            variant="heading"
            styled={{ text: { align: "center" } }}
          >
            All sections have now been completed, head to the Breakdown to view
            your score.
          </Typography>
          <Styled.ViewButton
            color="accent"
            href="risk-and-safety?t=breakdown"
            router
            variant="contained"
          >
            View my score
          </Styled.ViewButton>
          <Styled.ResetButton onClick={handleReset} variant="outlined">
            Retake Self Assesment
          </Styled.ResetButton>
        </Styled.CompleteContainer>
      )}
    </>
  );
};

const getTypeformId = (
  action: "first" | "last" | "complete" | "next" = "first",
  currentId?: TypeformID | TypeformIDV2,
): TypeformID | TypeformIDV2 => {
  let isTypeformV2: boolean = currentId ? isIDV2(currentId) : true;
  let newId: TypeformID | TypeformIDV2 = isTypeformV2 ? "IJnpPPHA" : "wqkBs1";
  if (action === "next" && currentId) {
    newId = isTypeformV2
      ? typeformSequenceV2[currentId]
      : typeformSequence[currentId];
  }
  if (action === "last") {
    newId = isTypeformV2 ? "JUVBojof" : "aR66j2Na";
  }
  if (action === "complete") {
    newId = isTypeformV2 ? "v2_complete" : "aR66j2Na";
  }
  return newId;
};

const typeformSequence: Record<TypeformID, TypeformID | TypeformIDV2> = {
  wqkBs1: "R54bJnVF",
  R54bJnVF: "Yl2u5Y9r",
  Yl2u5Y9r: "iKs3KaJf",
  iKs3KaJf: "aR66j2Na",
  aR66j2Na: "IJnpPPHA", // Move to v2 upon completion of v1
};

const typeformSequenceV2: Record<TypeformIDV2, TypeformIDV2> = {
  IJnpPPHA: "UMIz1v8n",
  UMIz1v8n: "dLP0DJl9",
  dLP0DJl9: "JUVBojof",
  JUVBojof: "v2_complete",
  v2_complete: "IJnpPPHA", // back to the start again
};

type TypeformID = "wqkBs1" | "R54bJnVF" | "Yl2u5Y9r" | "iKs3KaJf" | "aR66j2Na";

const IDsV2 = [
  "IJnpPPHA",
  "UMIz1v8n",
  "dLP0DJl9",
  "JUVBojof",
  "v2_complete",
] as const;
type TypeformIDV2Tuple = typeof IDsV2;
type TypeformIDV2 = TypeformIDV2Tuple[number];

export function isIDV2(
  value: TypeformID | TypeformIDV2 | undefined | null,
): value is TypeformIDV2 {
  if (!value) {
    return true;
  }
  return IDsV2.includes(value as TypeformIDV2);
}

export default TypeForm;
