export const TOAST_SUCCESS = "TOAST_SUCCESS";
export const TOAST_FAILURE = "TOAST_FAILURE";

export interface iToastEvent {
  message: string;
}

type subscribeArgs = (event: CustomEvent<iToastEvent>) => void;

class ToastEvent extends EventTarget {
  private static instance: ToastEvent;

  static initialize() {
    if (!ToastEvent.instance) {
      ToastEvent.instance = new ToastEvent();
    }
    return ToastEvent.instance;
  }

  private emit(name: string, message: string) {
    this.dispatchEvent(
      new CustomEvent<iToastEvent>(name, { detail: { message } }),
    );
  }

  success(message: string): void {
    this.emit(TOAST_SUCCESS, message);
  }

  error(message: string): void {
    this.emit(TOAST_FAILURE, message);
  }

  subscribe(type: string, cb: subscribeArgs): void {
    return this.addEventListener(type, cb as any);
  }
}

export default ToastEvent.initialize();
