import React from "react";
import {
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps as MuiAccordionSummaryProps,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { theme } from "../../../styles";

const styles = makeStyles(() => ({
  root: {
    padding: `0 ${theme.spacing}`,
    "&.Mui-expanded": {
      minHeight: "0px",
    },
    "& p": {
      color: theme.color.surface.text.default.default,
      fontWeight: theme.typography.body.fontWeight,
    },
  },
  content: {
    "&.Mui-expanded": {
      margin: `${theme.spacing} 0 calc(${theme.spacing} / 2) 0`,
    },
  },
}));

export interface AccordionSummaryProps extends MuiAccordionSummaryProps {}

const AccordionSummary = (props: AccordionSummaryProps) => {
  const classes = styles();
  return (
    <MuiAccordionSummary classes={{ ...classes, ...props.classes }} {...props}>
      {props.children && props.children}
    </MuiAccordionSummary>
  );
};

AccordionSummary.defaultProps = {
  expandIcon: <ExpandMoreIcon />,
};
export default AccordionSummary;
