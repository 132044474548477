import { ReactNode } from "react";

import { JfsFormData, JfsSchema, JfsUiSchema } from "../../../types";
import { Icon } from "./../../../components/util";
import * as Styled from "./styles.css";
import JfsForm, { CustomErrorMsg } from "../../util/jfsForm/jfsForm";

const Form = ({
  id,
  schema,
  onChange,
  onSubmit,
  formData,
  uiSchema,
  disabled = false,
  customErrorMsg,
  cancelButton,
  submitButtonText = "Submit",
}: FlowStepsProps) => (
  <JfsForm
    id={id}
    customErrorMsg={customErrorMsg}
    schema={schema}
    uiSchema={uiSchema}
    formData={formData}
    onChange={onChange}
    onSubmit={onSubmit}
    disabled={disabled}
    readonly={disabled}
    cancelButton={cancelButton}
    submitButtons={
      <Styled.StepButton color="accent" type="submit" disabled={disabled}>
        {submitButtonText}
        <Icon icon="chevron-right" shade="inverse" size="sm" />
      </Styled.StepButton>
    }
  />
);

export interface FlowStepsProps {
  id: string;
  name: string;
  onChange: ({ formData }: { formData?: any }) => void;
  onSubmit: (submitData: JfsFormData) => void;
  formData?: Record<any, any>;
  schema: JfsSchema;
  uiSchema: JfsUiSchema;
  disabled?: boolean;
  customErrorMsg?: CustomErrorMsg;
  cancelButton?: ReactNode;
  submitButtonText?: string;
  width?: string;
}

export default Form;
