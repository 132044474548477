import React, { useContext } from "react";
import { FormErrorStep, FormFieldStep } from "../../../contexts/formContext";
import { FormContextProvider, LocationContext } from "./../../../contexts";
import FormBody from "./formBody";
import { tSubmitButtonAccent } from "./formSubmit";

const Form = ({
  children,
  enableSubmitAlways,
  error,
  errorMessage,
  formStep,
  footer,
  fullWidth,
  miscButton,
  miscButtonName,
  miscButtonOnClick,
  onSubmit,
  prefill,
  resetAfterSubmit,
  steppedForm,
  submitText,
  submitStartDisabled,
  submitButtonAccent,
  stickySubmit,
  hideSubmitButton,
  validationErrors,
  validationFields,
  validationForm,
  ...otherProps
}: FormProps) => {
  const { urlParams } = useContext(LocationContext);
  // Deep copy initial data/errors format for reset purposes
  const resetFields = JSON.parse(JSON.stringify(validationFields));
  const resetErrors = JSON.parse(JSON.stringify(validationErrors));
  let newValidationFields = JSON.parse(JSON.stringify(validationFields));
  let newValidationErrors = JSON.parse(JSON.stringify(validationErrors));

  if (prefill && urlParams) {
    newValidationFields = prefill(urlParams, newValidationFields);
    newValidationErrors = prefill(urlParams, undefined, newValidationErrors);
  }

  return (
    <FormContextProvider
      initialFields={resetFields}
      initialErrors={resetErrors}
      submitStartDisabled={!!submitStartDisabled}
      validationFields={newValidationFields}
      validationErrors={newValidationErrors}
    >
      <FormBody
        enableSubmitAlways={enableSubmitAlways}
        errorMessage={errorMessage}
        fullWidth={fullWidth}
        miscButton={miscButton}
        miscButtonName={miscButtonName}
        miscButtonOnClick={miscButtonOnClick}
        onSubmit={onSubmit}
        steppedForm={steppedForm}
        submitText={submitText}
        stickySubmit={stickySubmit}
        submitButtonAccent={submitButtonAccent}
        hideSubmitButton={hideSubmitButton}
        validationForm={validationForm}
        {...otherProps}
      >
        {children && children}
      </FormBody>
    </FormContextProvider>
  );
};

export type FormError = undefined | string | React.ReactHTMLElement<any>;

interface FormProps {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  enableSubmitAlways?: boolean;
  error?: boolean;
  errorMessage?: any;
  formStep?: number;
  footer?: React.ReactNode;
  fullWidth?: boolean;
  inline?: boolean;
  miscButton?: boolean;
  miscButtonName?: string;
  miscButtonOnClick?: any;
  onSubmit: any;
  prefill?: (
    urlParams,
    formData?,
    formErrors?,
  ) => Record<string, any>[] | Record<string, boolean>[] | undefined;
  resetAfterSubmit?: boolean;
  steppedForm?: boolean;
  submitInline?: boolean;
  submitDisabled?: boolean;
  submitStartDisabled?: boolean;
  submitButtonAccent?: tSubmitButtonAccent;
  submitAlign?: "left" | "center" | "right";
  submitText?: string;
  stickySubmit?: boolean;
  hideSubmitButton?: boolean;
  validationErrors: FormErrorStep[];
  validationFields: FormFieldStep[];
  validationForm?: any;
}

Form.defualtProps = {
  error: false,
  fullWidth: false,
  inline: false,
  isSubmitDisabled: true,
  submitDisabled: false,
  submitStartDisabled: false,
  steppedForm: false,
  submit: "Submit",
};

export default Form;
