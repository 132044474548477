import styled from "styled-components";
import { transparentize } from "polished";
import { theme } from "../../../styles/themes";
import List from "../lists/list";
import ListItem from "../lists/listItem";
import ListItemIcon from "../lists/listItemIcon";

export const MenuContainer = styled(List).attrs((props: any) => ({
  $error: props.$error,
  $size: props.$size,
  $unselected: props.$unselected,
}))`
  color: ${props =>
    props.$unselected
      ? transparentize(0.6, theme.color.surface.background.inverse.default)
      : theme.color.surface.background.inverse.default};
  background-color: ${theme.color.surface.background.default.default};
`;

export const SelectOption = styled(ListItem).attrs((props: any) => ({}))`
  padding: 6px 16px;
  color: ${theme.color.surface.text.weaker.default};
  cursor: pointer;
  border-radius: 6px;
  width: 100%;
  :hover {
    background-color: ${theme.color.surface.background.weakest.hover};
  }
`;

export const ItemIcon = styled(ListItemIcon).attrs((props: any) => ({}))`
  margin-right: 8px;
`;
