import React, { useState } from "react";

export const GuidanceContextProvider = ({
  children,
}: GuidanceContextProviderProps) => {
  const [newBrokerClientProgress, setNewBrokerClientProgress] =
    useState<boolean>(false);
  const [newBrokerVirtualSurvey, setNewBrokerVirtualSurvey] =
    useState<boolean>(false);

  return (
    <GuidanceContext.Provider
      value={{
        newBrokerClientProgress: newBrokerClientProgress,
        newBrokerVirtualSurvey: newBrokerVirtualSurvey,
        setNewBrokerClientProgress: setNewBrokerClientProgress,
        setNewBrokerVirtualSurvey: setNewBrokerVirtualSurvey,
      }}
    >
      {children && children}
    </GuidanceContext.Provider>
  );
};

const GuidanceContext = React.createContext<GuidanceContextInterface>({
  newBrokerClientProgress: false,
  newBrokerVirtualSurvey: false,
  setNewBrokerClientProgress: () => {},
  setNewBrokerVirtualSurvey: () => {},
});

interface GuidanceContextInterface {
  newBrokerClientProgress: boolean;
  newBrokerVirtualSurvey: boolean;
  setNewBrokerClientProgress: (input: boolean) => void;
  setNewBrokerVirtualSurvey: (input: boolean) => void;
}

interface GuidanceContextProviderProps {
  children: React.ReactNode;
}

export default GuidanceContext;
