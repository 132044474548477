import { Typography } from "../../../components/util";
import AuthContainer from "../authContainer";
import AuthErrorFooter from "./authErrorFooter";

const InvitationExpired = () => {
  return (
    <AuthContainer
      headerTitle="Invitation expired"
      footerContent={<AuthErrorFooter />}
      preventRedirect
    >
      <Typography styled={{ text: { shade: "weak" } }}>
        Your invitation has expired. Contact your broker to get a new
        invitation.
      </Typography>
    </AuthContainer>
  );
};

export default InvitationExpired;
