import { CircularProgressProps as MuiCircularProgressProps } from "@material-ui/core";
import * as Styled from "./styles.css";

const CircularProgress = ({ contained, ...otherProps }: ProgressProps) => {
  return contained ? (
    <Styled.CircularContainer>
      <Styled.CircularProgress {...otherProps} />
    </Styled.CircularContainer>
  ) : (
    <Styled.CircularProgress {...otherProps} />
  );
};

interface ProgressProps extends Omit<MuiCircularProgressProps, ""> {
  contained?: boolean;
}

CircularProgress.defaultProps = {
  color: "inherit",
  contained: false,
};

export default CircularProgress;
