import styled from "styled-components";
import { transparentize } from "polished";
import { theme } from "../../../../styles/themes";
import { Chip, ListItem } from "../../../util";
import Link from "../../../util/link";

interface DrawerStyleProps {
  $isDesktop: boolean;
  $isOpen: boolean;
}

export const Drawer = styled("div")<DrawerStyleProps>`
  position: fixed;
  background-color: ${transparentize(
    0.9,
    theme.color.surface.background.inverse?.default,
  )};
  bottom: 0;
  left: 0;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  top: 56px;
  right: 0;
  transition: opacity 0.3s cubic-bezier(0.6, 0, 0.3, 1);
  z-index: 9999;

  ${({ $isOpen }) =>
    $isOpen
      ? `
      opacity: 1;
      transform: translate(0%, 0);
    `
      : `
      transform: translate(-100%, 0);
      transition: transform 0s cubic-bezier(0.6, 0, 0.3, 1) 0.2s,
        opacity 0.3s cubic-bezier(0.6, 0, 0.3, 1);
    `}

  ${({ $isOpen }) =>
    $isOpen &&
    `
      opacity: 1;
      transform: translate(0%, 0);
    `}

  ${({ $isDesktop }) =>
    $isDesktop &&
    `
      top: 0;
      width: 256px;
    `}
`;

interface SidebarStyleProps {
  $isDesktop: boolean;
  $isOpen: boolean;
}

export const Sidebar = styled("nav")<SidebarStyleProps>`
  background-color: ${theme.color.surface.background.default.default};
  border-right: 1px solid ${theme.color.surface.border.weaker.default};
  bottom: 0;
  color: inherit;
  display: flex;
  flex-direction: column;
  opacity: 0.5;
  padding: 24px 0;
  position: absolute;
  top: 0;
  transition: transform 0.3s cubic-bezier(0.6, 0, 0.3, 1),
    opacity 0.2s cubic-bezier(0.6, 0, 0.3, 1);
  transform: translate(-100%, 0);
  width: 256px;

  ${({ $isOpen }) =>
    $isOpen &&
    `
      opacity: 1;
      transform: translate(0%, 0);
    `}

  ${({ $isDesktop }) =>
    $isDesktop &&
    `
      transition: none;
      transform: none;
    `}
`;

export const MainNav = styled("div")`
  flex-grow: 1;
`;

export const NavItem = styled(ListItem)`
  padding-right: 8px;
  width: 100%;

  & a {
    align-items: center;
    border-left: 3px solid transparent;
    border-radius: 0 ${theme.border.radius.md} ${theme.border.radius.md} 0;
    color: ${theme.color.surface.text.weak.default};
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 10px 6px 10px 29px;
    text-decoration: none;
    width: 100%;
    &:visited {
      color: inherit;
    }
    &:hover {
      background-color: ${theme.color.surface.background.weakest.default};
      border-left-color: ${theme.color.surface.border.default.pressed};
      text-decoration: none;
    }
    &:active {
      background-color: ${theme.color.surface.background.weakest.default};
    }
  }

  ${(props: any) => {
    if (props.selected) {
      return `
        background-color: transparent;
        a {
          background-color: ${theme.color.accent.background.weakest.default};
          border-left-color: ${theme.color.accent.border.default.pressed};
          color: ${theme.color.accent.text.weak.default};
          &:visited {
            color: ${theme.color.accent.text.weak.default};
          }
          &:hover, &:active:hover {
            background-color: ${theme.color.accent.background.weakest.default};
            border-left-color: ${theme.color.accent.border.default.pressed};
            text-decoration: none;
          }
        }
      `;
    }
  }}
`;

export const FooterNav = styled("div")`
  background-color: ${theme.color.surface.background.default.default};
`;

export const RoleChip = styled(Chip)`
  margin-right: 8px;
`;

export const Logo = styled(Link)`
  display: flex;
  padding: 32px 0 40px 32px;
  position: relative;
  width: 100%;
  & span {
    bottom: 36px;
    position: absolute;
    right: 8px;
  }
`;
