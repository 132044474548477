import { JfsSchema, JfsUiSchema } from "../../types";
import { JfsAsyncCombobox } from "../../components/util";
import EmailSelect from "../../components/jsf/EmailSelect";
import Roles from "../../components/jsf/roles";
import { EmailCcPrefill } from "../../services/invitation/invitation.service";
import { IOrganisation } from "../../services/admin/admin.service";

const privilegedFields = ["role", "sender_org_id", "email_bcc"];

export const withSchema = ({
  type,
  isPrivilegedUser,
}: WithSchemaParams): JfsSchema => ({
  ...schemaData,
  required: isPrivilegedUser
    ? schemaData.required
    : schemaData.required.filter(field => !privilegedFields.includes(field)),
  properties: isPrivilegedUser
    ? schemaData.properties
    : Object.entries(schemaData.properties).reduce((acc, [key, value]) => {
        if (!privilegedFields.includes(key)) {
          acc[key] = value;
        }

        return acc;
      }, {}),
  title: `${schemaData.title} ${type}`,
});

export const withUiSchema = ({
  senderOrgId,
  emailCc, // defaults for both CC and BCC list
  isPrivilegedUser,
}: WithUiSchemaParams): JfsSchema => ({
  ...uiSchemaData,
  "ui:order": isPrivilegedUser
    ? uiSchemaData["ui:order"]
    : uiSchemaData["ui:order"].filter(
        field => !privilegedFields.includes(field),
      ),
  policy_number: {
    ...uiSchemaData.policy_number,
    "ui:disabled": !isPrivilegedUser,
  },
  sender_org_id: {
    ...uiSchemaData.sender_org_id,
    "ui:options": {
      ...uiSchemaData.sender_org_id["ui:options"],
      ...senderOrgId,
      onChange: isPrivilegedUser ? senderOrgId.onChange : undefined,
    },
  },
  email_cc: {
    ...uiSchemaData.email_cc,
    "ui:options": {
      ...uiSchemaData.email_cc["ui:options"],
      ...emailCc,
    },
  },
  ...(isPrivilegedUser
    ? {
        email_bcc: {
          ...uiSchemaData.email_bcc,
          "ui:options": {
            ...uiSchemaData.email_bcc["ui:options"],
            ...emailCc,
          },
        },
      }
    : undefined),
});

export const updateEmails = (
  state: JfsSchema | undefined,
  type: "email_cc" | "email_bcc",
  emails: EmailCcPrefill | undefined,
) => ({
  ...state,
  [type]: {
    ...state?.[type],
    "ui:options": {
      ...state?.[type]?.["ui:options"],
      emails,
    },
  },
});

const schemaData: JfsSchema = {
  title: "Invite",
  type: "object",
  required: [
    "firstname",
    "lastname",
    "email",
    "role",
    "sender_org_id",
    "sender_first_name",
    "sender_last_name",
  ],
  properties: {
    firstname: {
      type: "string",
      title: "First name",
    },
    lastname: {
      type: "string",
      title: "Last name",
    },
    email: {
      type: "string",
      title: "Email",
      format: "email",
    },
    policy_number: {
      type: "string",
      title: "Policy number",
    },
    scheme: {
      type: "string",
      title: "Scheme code",
    },
    role: {
      type: "string",
      title: "Role",
    },
    sender_org_id: {
      title: "Inviter organisation",
      type: "string",
    },
    sender_first_name: {
      title: "First name",
      type: "string",
    },
    sender_last_name: {
      title: "Last name",
      type: "string",
    },
    email_cc: {
      title: "CC'd emails",
      type: "array",
      items: {
        type: "string",
      },
    },
    email_bcc: {
      title: "BCC'd emails",
      type: "array",
      items: {
        type: "string",
      },
    },
  },
};

export const uiSchemaData: JfsUiSchema = {
  "ui:order": [
    "firstname",
    "lastname",
    "email",
    "policy_number",
    "scheme",
    "role",
    "sender_org_id",
    "sender_first_name",
    "sender_last_name",
    "email_cc",
    "email_bcc",
  ],
  "ui:options": {
    sections: [
      {
        key: "main",
        fields: [
          "firstname",
          "lastname",
          "email",
          "policy_number",
          "scheme",
          "role",
        ],
      },
      {
        key: "inviterInfo",
        title: "Inviter Information",
        fields: [
          "sender_org_id",
          "sender_first_name",
          "sender_last_name",
          "email_cc",
          "email_bcc",
        ],
        standout: true,
      },
    ],
  },
  firstname: {
    "ui:autofocus": true,
  },
  lastname: {},
  policy_number: {
    "ui:disabled": true,
  },
  scheme: {},
  role: {
    "ui:widget": Roles,
  },
  sender_first_name: {},
  sender_last_name: {},
  sender_org_id: {
    "ui:widget": JfsAsyncCombobox,
    "ui:options": {
      debounce: 750,
    },
  },
  email_cc: {
    "ui:widget": EmailSelect,
    "ui:options": {
      isMulti: true,
    },
  },
  email_bcc: {
    "ui:widget": EmailSelect,
    "ui:options": {
      isMulti: true,
    },
  },
};

type WithSchemaParams = {
  type: string;
  isPrivilegedUser: boolean;
};

type WithUiSchemaParams = {
  senderOrgId: {
    search: (input: string) => Promise<IOrganisation[] | undefined>;
    onChange?: (selectedOrgId: string) => Promise<void>;
    getOptionLabel: (item: IOrganisation) => string;
    getOptionSubLabel: (item: IOrganisation) => string;
    getOptionValue: (item: IOrganisation) => string;
    initialSelected?: Pick<IOrganisation, "id" | "name">;
  };
  emailCc: {
    emails?: EmailCcPrefill;
  };
  isPrivilegedUser: boolean;
};
