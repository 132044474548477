import { useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Button,
} from "../../../../components/util";
import OrganisationService from "../../../../services/organisation/organisation.service";
import { IOrganisation } from "../../../../services/admin/admin.service";
import { JfsFormData } from "../../../../types";
import { schemaData, withUiSchema } from "./orgModelSchema";
import { useQueryClient } from "react-query";
import { JSFForm, ModalBody, ModalTitle } from "./styles.css";
import policyService from "../../../../services/policy/policy.service";
import { OrgInfo } from "./orgModal";

const PolicyOrg = ({
  orgName,
  oldOrgId,
  policyId,
  modalType,
  handleModal,
  refetchQuery,
}: iPolicyOrgMod) => {
  const [formData, setFormData] = useState<JfsFormData>();
  const schema = schemaData;
  const queryClient = useQueryClient();
  const handleChange = ({ formData }: { formData?: any }) =>
    setFormData(formData);

  const uiSchema = withUiSchema({
    org_id: {
      search: async (input: string): Promise<IOrganisation[] | undefined> =>
        (
          await OrganisationService.getAllOrganisationsBySearchTerm(
            input,
            0,
            100,
            { filter: ["users"] },
          )
        )?.hits,
      getOptionLabel: (item: IOrganisation) => item.name,
      getOptionSubLabel: (item: IOrganisation) => item.id,
      getOptionValue: (item: IOrganisation) => item.id,
      initialSelected: { id: oldOrgId || "", name: orgName || "" },
    },
  });

  const onSubmit = async (submitData: JfsFormData): Promise<void> => {
    try {
      const data: OrgInfo = {
        policy_id: policyId,
        org_id: submitData?.org_id,
        old_org_id: modalType.toLocaleLowerCase() === "update" ? oldOrgId : "",
      };
      await policyService.policyOrgPivot(modalType.toLowerCase(), data);

      queryClient.refetchQueries(refetchQuery);
      handleModal();
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data?.error) {
        throw new Error(error?.response.data?.error);
      } else {
        throw error;
      }
    }
  };

  return (
    <ModalBody>
      <Grid container>
        <Grid item xs={11}>
          <Typography variant="displayMedium">{`${modalType} Organisation`}</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton icon="x" onClick={() => handleModal()}></IconButton>
        </Grid>
      </Grid>
      {modalType.toLocaleLowerCase() === "remove" ? (
        <>
          <ModalTitle>
            The Organisation will be removed from the policy and will not see
            the policy information.
          </ModalTitle>
          <Button
            onClick={async () => {
              const data: OrgInfo = {
                policy_id: policyId,
                org_id: oldOrgId,
              };
              await policyService.policyOrgPivot(modalType.toLowerCase(), data);

              queryClient.refetchQueries(refetchQuery);
              handleModal();
            }}
            variant="contained"
            color="negative"
          >
            Remove
          </Button>
        </>
      ) : (
        <JSFForm
          id="add-org"
          name="Add Organisation"
          schema={schema}
          onChange={handleChange}
          onSubmit={onSubmit}
          formData={formData}
          uiSchema={uiSchema}
        />
      )}
    </ModalBody>
  );
};

export interface iPolicyOrgMod {
  oldOrgId?: string;
  orgName?: string;
  policyId: string;
  handleModal: any;
  modalType: string;
  refetchQuery: string;
}
export default PolicyOrg;
