import prismicDom from "prismic-dom";
import parser from "html-react-parser";
import { BenchmarksOverviewInterface } from "../../../services/prismic/prismic.service";
import { Card, Grid, Typography } from "../../../components/util";

const Overview = ({
  overview,
}: Record<string, BenchmarksOverviewInterface | undefined>) => {
  return (
    <Grid item xs={12}>
      <Card isInGrid isPadded>
        <Typography paragraph variant="displaySmall">
          Industry Benchmarks
        </Typography>
        {parser(prismicDom.RichText.asHtml(overview))}
      </Card>
    </Grid>
  );
};

export default Overview;
