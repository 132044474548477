import styled from "styled-components";
import Grid from "../grid";
import { Button } from "../../../components/util/";
import { theme } from "../../../styles/themes";

export const Form = styled("form").attrs((props: any) => ({
  $fullWidth: props.$fullWidth,
  $inline: props.$inline,
}))`
  width: ${(props: any) => (props.$fullWidth ? "100%" : "inherit")};
  ${(props: any) =>
    props.$inline &&
    `
      display: flex;
      & button[type="submit"] {
        margin-left: 4px;

      }
  `};
`;

export const SubmitButton = styled(Button).attrs((props: any) => ({
  $textColor: props.$textColor,
  $visibility: props.$visibility,
}))`
  margin-right: 10px;

  ${(props: any) =>
    props.$visibility &&
    `
      display: none;
    `};

  ${props => {
    switch (props.$textColor) {
      case "warning":
        return `
        color: ${theme.color.negative.text.default.default};
        border: 1px solid ${theme.color.negative.border.default.default};
        :hover {
          background-color: ${theme.color.negative.text.default.default};
          color: #fff;
        }
        `;
      default:
        return ``;
    }
  }};
`;

export const FormRow = styled(Grid).attrs((props: any) => ({
  $textAlign: props.$textAlign,
  $stickyField: props.$stickyField,
}))`
  margin-bottom: ${(props: any) => (props.container ? "12px" : "24px")};

  ${props =>
    props.$stickyField &&
    `
    margin-bottom: 0px;
    padding:24px 0px;
    bottom: 0px;
    position: absolute;
    background: white;
    width: 90%;
  `};

  text-align: ${props => {
    switch (props.$textAlign) {
      case "left":
        return "left";
      case "center":
        return "center";
      case "right":
        return "right";
    }
  }};
`;
