import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { WidgetProps } from "@rjsf/utils";

import { AuthContext } from "../../../contexts";
import AdminService from "../../../services/admin/admin.service";
import CircularProgress from "../../util/progress/circular";
import * as Styled from "./styles.css";

const Roles = ({ onChange, rawErrors }: RolesProps) => {
  const { user } = useContext(AuthContext);
  const [selected, setSelected] = useState<{ value: string; label: string }>();

  const roles = useQuery(
    ["invitation", "roles"],
    async () => {
      const roles = await AdminService.fetchAllRolesAllowedForUser(user);

      setSelected(roles?.[0]);

      return roles;
    },
    { cacheTime: 0, retry: false },
  );

  if (roles.isLoading) {
    return <CircularProgress />;
  } else if (roles.isError) {
    return <div>{roles.isError}</div>;
  }

  const handleChange = option => {
    setSelected(option);
    onChange(option.label);
  };

  return (
    <Styled.Roles
      onChange={handleChange}
      options={roles.data}
      value={selected}
      isSearchable={false}
      isValid={!rawErrors}
    />
  );
};

export type RolesProps = WidgetProps;

export default Roles;
