import { recordAnalyticsEvent } from "../../../analytics";
import activityService, {
  Activity,
} from "../../../services/activity/activity.service";
import { formatDateTime } from "../../../helpers";
import { CircularProgress, TableCell, TableRow, Typography } from "../../util";
import { DateTime, PaginatedResults } from "../../content";
import * as Styled from "./styles.css";
import { useQuery } from "react-query";
import { Header, Section } from "../../layout";

const ActivityTable = ({ policy_id, user_id }: ActivityTableProps) => {
  const { data, isLoading } = useQuery(
    `activity_${policy_id || user_id}`,
    policy_id
      ? activityService.getActivityByPolicyId.bind(null, policy_id)
      : activityService.getActivityByUserId.bind(null, user_id),
  );

  const activityRow = (activitySlice: Activity[]) => {
    return (
      <>
        {activitySlice.map((activity: Activity, i: number) => (
          <TableRow key={i}>
            <TableCell>
              {TypeColumn(activity)}
              {TypeColumnAction(activity)}
            </TableCell>
            <TableCell>
              <Styled.UserNameLink href={``} title={UserColumn(activity)}>
                {UserColumn(activity)}
              </Styled.UserNameLink>
            </TableCell>
            <TableCell>
              {activity.date && (
                <DateTime input={activity.date} outputFormat="short" />
              )}
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <Section>
      <Header title="Activity" type="section" />
      {!isLoading && data && (
        <PaginatedResults
          id={`client-activity-all-${policy_id}`}
          inputResults={{
            hits: data ? data : [],
            total: data ? data.length : 0,
          }}
          tableColumns={[
            { title: "Activity type", expand: true },
            { title: "User", collapse: true },
            { title: "Date", collapse: true },
          ]}
          tableRows={activityRow}
          missing={{
            icon: "pulse",
            type: "activity",
            message:
              "This is where we’ll show all of your client’s activity. Check back later to see their progress.",
          }}
        />
      )}
      {isLoading && <CircularProgress />}
    </Section>
  );
};

interface ActivityTableProps {
  policy_id?: string;
  user_id?: string;
}

ActivityTable.defaultProps = {};

const sendToAnalytics = (inspectionName: string) => {
  recordAnalyticsEvent("action", "Inspection", {
    action: "inspection_completed",
    name: inspectionName,
  });
};

const TypeColumn = (activity: Activity) => {
  switch (activity.type) {
    case "iAuditor Inspection":
      return (
        <Styled.ActionLink
          href={``}
          onClick={(event: any) => {
            event.preventDefault();
            if (
              activity.type === "iAuditor Inspection" &&
              activity.action === "Completed"
            ) {
              sendToAnalytics(`${activity?.data?.template?.name} Completed`);
            }
          }}
          router
          title={activity.type}
        >
          {`Inspection: ${activity?.data?.template?.name}`}
        </Styled.ActionLink>
      );
    // Legacy, remove when possible
    case "Self Assessment":
      return "Self assessment";
    // Legacy, remove when possible
    case "Virtual Survey":
      return "Virtual survey";
    case "Virtual survey":
      return (
        <>
          <Typography element="span">
            {`${activity.type} ${activity.action?.toLowerCase()} `}
          </Typography>
          <Typography element="span" styled={{ text: { shade: "weak" } }}>
            {`- ${formatDateTime(activity.data?.booking?.date)}`}
          </Typography>
        </>
      );
    default:
      return `${activity.type} `;
  }
};

const TypeColumnAction = (activity: Activity) => {
  switch (activity.type) {
    case "Account":
      return ` ${activity.action?.toLowerCase()}`;
    // Legacy, remove when possible
    case "iAuditor":
      return ` ${activity.action?.toLowerCase()}`;
    case "iAuditor Inspection":
      return;
    // Legacy, remove when possible
    case "Self Assessment":
    case "Self assessment":
      return (
        <Styled.ActionStatus
          label={activity.action}
          size="md"
          color={activity.action === "Started" ? "information" : "positive"}
        />
      );
    case "Policy creation date":
      return;
    // Legacy, remove when possible
    case "Virtual Survey":
      return ` ${activity.action?.toLowerCase()}`;
    case "Virtual survey":
      return;
    default:
      return activity.action?.toLowerCase();
  }
};

const UserColumn = (activity: Activity) => {
  switch (activity.type) {
    case "iAuditor Inspection":
      return activity?.data?.author?.name;
    case "Virtual survey":
      return activity.data?.booking?.booked_by
        ? activity.data.booking.booked_by
        : activity?.data?.booking?.name;
    default:
      return activity?.data?.name;
  }
};

export default ActivityTable;
export const PAGE_PARAM_NAME = "activityPage";
export const PAGE_LIMITS_ALLOWED = [25, 50, 100];
