import { WidgetProps } from "@rjsf/utils";
import { Label } from "./../..";
import JfsListItem from "./../../../content/list/jfsListItem/jfsListItem";
import * as Styled from "./styles.css";

const JfsRadioButton = ({
  onChange,
  id,
  type,
  value,
  ...otherProps
}: JfsRadioButtonProps) => {
  const handleChange = (e?: any) => {
    onChange(e.target ? e.target.value : e); // this is the Form's onChange prop
  };
  const options = otherProps.options.enumOptions;

  return (
    <Styled.JfsRadioList id={id} element="fieldset" corners="rounded" outlined>
      {options &&
        options.map((radio: any, i: number) => (
          <JfsListItem
            key={`${id}-${i}`}
            onClick={() => handleChange(radio.value)}
            checked={value === radio.value}
          >
            <Styled.JfsRadioButton
              checked={value === radio.value}
              value={radio.value}
              onChange={(event: any) => {
                onChange(event.target.value);
              }}
              type={"radio"}
              name={id}
            />
            <Label htmlFor={id} title={radio.label} inline />
          </JfsListItem>
        ))}
    </Styled.JfsRadioList>
  );
};

interface JfsRadioButtonProps extends WidgetProps {}

JfsRadioButton.defaultProps = {};

export default JfsRadioButton;
