import { useContext } from "react";
import { useQuery } from "react-query";
import { AuthContext } from "../../contexts";
import safetycultureService from "../../services/safetyculture/safetyculture.service";
import ScoreService from "../../services/scores/scores.service";
import { Header, Section } from "./../../components/layout";
import { Button, Grid } from "../../components/util";
import {
  IAuditorMeter,
  LatestNews,
  OnboardingNewUser,
  SelfAssessmentMeter,
} from "../../components/content";
import * as Styled from "./styles.css";

const Dashboard = () => {
  const auth = useContext(AuthContext);

  const {
    data: selfAssessmentData,
    isError: isSelfAssessmentError,
    isLoading: isSelfAssessmentLoading,
  } = useQuery(
    "SelfAssessmentScore",
    () => ScoreService.getScore(auth.user.organisations[0].id),
    { retry: 0 },
  );

  const {
    data: iAuditorData,
    isError: isIAuditorError,
    isLoading: isIAuditorLoading,
  } = useQuery(
    "iAuditorScore",
    () => safetycultureService.fetchIAuditorRiskAndSafetyScore(auth.user.id),
    { retry: 0 },
  );

  const currentHour = new Date().getHours();
  const greetingMessage = (userName: string) => {
    let message: string =
      currentHour >= 5 && currentHour < 12
        ? "Good morning, "
        : currentHour >= 12 && currentHour <= 17
        ? "Good afternoon, "
        : currentHour > 17 || currentHour < 5
        ? "Good evening, "
        : currentHour >= 24 || currentHour < 5
        ? "Good Morning "
        : "Welcome ";
    return currentHour >= 24 || currentHour < 5
      ? message + userName + ", you're up early! ☕"
      : message + userName + "!";
  };

  return (
    <Grid container>
      <Header title={greetingMessage(auth.user.firstname)} type="article">
        <Styled.SubHeader element="span" variant="heading">
          Here's how you can mitigate risk today.
        </Styled.SubHeader>
      </Header>
      <Section>
        <OnboardingNewUser />
        <Header
          actions={
            <Button href="/risk-and-safety" router variant="outlined">
              Improve my score
            </Button>
          }
          title="Risk and safety rating"
          type="section"
        />

        <Styled.SelfAssessment item xs>
          <SelfAssessmentMeter
            data={selfAssessmentData?.scores}
            isError={isSelfAssessmentError}
            titleVariant="heading"
            isLoading={isSelfAssessmentLoading}
          />
        </Styled.SelfAssessment>

        <Styled.IAuditor item xs>
          <IAuditorMeter
            data={iAuditorData?.scores}
            isError={isIAuditorError}
            titleVariant="heading"
            isLoading={isIAuditorLoading}
          />
        </Styled.IAuditor>
      </Section>
      <Section>
        <LatestNews />
      </Section>
    </Grid>
  );
};
export default Dashboard;
