import styled from "styled-components";
import { Element } from "react-scroll";
import { theme } from "../../../styles/themes";
import { buildBoxShadow } from "../../../helpers";

export const Container = styled(Element).attrs((props: any) => ({
  as: props.as,
  $constrained: props.$constrained,
  $corners: props.$corners,
  $padding: props.$padding,
  $disabled: props.$disabled,
  $elevation: props.$elevation || "flat",
  $outlined: props.$outlined,
}))`
  ${(props: any) => {
    if (props.$constrained) {
      return `
        overflow: hidden;
      `;
    }
  }}

  ${(props: any) => {
    if (props.$corners === "rounded") {
      return `
        border-radius: 6px;
      `;
    }
  }}

  ${(props: any) => {
    if (props.$padding) {
      return `
        padding: 8px;
      `;
    }
  }}

  ${(props: any) => {
    if (props.$outlined) {
      return `
        border: 1px solid ${theme.color.surface.border.weak.default};
      `;
    }
  }}

  ${(props: any) => {
    switch (props.$elevation) {
      case "flat":
        return ``;
      case "low":
        return `
          box-shadow: ${buildBoxShadow("low")};
          z-index: 999;
        `;
      case "high":
        return `
          box-shadow: ${buildBoxShadow("high")};
          z-index: 9999;
        `;
    }
  }}

  ${(props: any) => {
    if (props.$disabled) {
      return `
        cursor: default;
        opacity: 0.25;
        pointer-events: none;
      `;
    }
  }}
`;
