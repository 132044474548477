import * as React from "react";
import { Automation } from "../../../types/automations.type";
import { Tab, TabPanel, Tabs } from "../../../components/util";
import { Header } from "../../../components/layout";
import { AutomationDetailsAnzsicTabPanel } from "./tabs/AutomationDetailsAnzsicTabPanel";
import { AutomationDetailsToolkitTabPanel } from "./tabs/AutomationDetailsToolkitTabPanel";

type Props = { automation: Automation };

export const AutomationDetailsTabs: React.FC<Props> = ({ automation }) => {
  return (
    <Tabs
      id={`automation-${automation.id}`}
      tabs={[<Tab id="details" label={"Details"} />]}
    >
      <AutomationDetailsToolkitTabPanel automation={automation} />
      <AutomationDetailsAnzsicTabPanel automation={automation} />
    </Tabs>
  );
};
