import { Scores } from "../../../../../services/scores/scores.service";
import { Header } from "./../../../../layout";
import { Chip, Grid, LinearProgress, Typography } from "../../../../util";
import { ScoreMeterSkeleton } from "./skeletons";
import { TStyleConnotation } from "../../../../../types";
import * as Styled from "./styles.css";

const getAverageStatus = (
  score: Scores = { current: 0, best_practice: 0, median: 0 },
) => {
  let avg: number = 0;
  let avgMsg: string = "";
  let avgColor: TStyleConnotation | undefined;

  const ind_average = score.median || score.best_practice;
  if (score.current && ind_average) {
    avg = score.current - ind_average;
    if (avg < 0) {
      avgMsg = `${Math.round(Math.abs(avg))}% below`;
      avgColor = "negative";
    } else if (avg === 0) {
      avgMsg = "Equal to";
    } else {
      avgMsg = `${Math.round(Math.abs(avg))}% above`;
      avgColor = "positive";
    }
  }
  return { avgMsg: avgMsg, avgColor: avgColor };
};

const ScoreMeter = ({
  isLoading,
  score,
  title,
  titleVariant,
  fallback,
  footnote,
}: ScoreMeterProps) => {
  const averageMessage: string = getAverageStatus(score).avgMsg;
  const averageColor: any = getAverageStatus(score).avgColor;

  return (
    <Grid container>
      {titleVariant === "section" && (
        <Header title={title} type="sectionMeter" />
      )}
      {titleVariant === "heading" && (
        <Styled.Title item xs={12}>
          <Typography variant={titleVariant}>{title}</Typography>
        </Styled.Title>
      )}
      {isLoading ? (
        <ScoreMeterSkeleton />
      ) : (
        <>
          <Styled.ScoreAverage container>
            <Grid item xs>
              <Typography element="span" variant="displayMedium">
                {score?.current ? Math.round(score.current) : 0}
              </Typography>
            </Grid>
            {averageMessage.length > 0 && (
              <Grid item>
                <Chip label={averageMessage} color={averageColor} />
                <Typography variant="body1">&nbsp;industry average</Typography>
              </Grid>
            )}
            {averageMessage.length <= 0 &&
              score?.current === 0 &&
              score?.median && (
                <Grid item>
                  <Typography variant="body1">
                    industry average: {score.median}
                  </Typography>
                </Grid>
              )}
          </Styled.ScoreAverage>
          <Styled.ProgressBestPractice
            container
            $bestPractice={
              score?.best_practice ? Math.round(score?.best_practice) : 0
            }
          >
            <Grid item xs={12}>
              <LinearProgress
                title={title}
                variant="determinate"
                value={score?.current}
              />
              <Styled.BestPractice
                variant="body2"
                $bestPractice={
                  score?.best_practice && Math.round(score?.best_practice)
                }
              >
                Best practice:&nbsp;
                {score?.best_practice && Math.round(score?.best_practice)}
              </Styled.BestPractice>
            </Grid>
          </Styled.ProgressBestPractice>
        </>
      )}

      {footnote && <Styled.Footnote>{footnote}</Styled.Footnote>}
    </Grid>
  );
};

interface ScoreMeterProps {
  score?: Scores;
  fallback?: React.ReactNode;
  footnote?: React.ReactNode;
  isLoading?: boolean;
  title: string;
  titleVariant: "heading" | "section";
}

ScoreMeter.defaultProps = {};

export default ScoreMeter;
