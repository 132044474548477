import axios from "axios";
import registerAuthInterceptor from "./auth.interceptor";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

registerAuthInterceptor(instance);

export default instance;
