import { useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { AuthContext } from "../../contexts";
import ScoreService from "../../services/scores/scores.service";
import { Header } from "../../components/layout";
import {
  Grid,
  CircularProgress,
  Tab,
  TabPanel,
  Tabs,
} from "../../components/util";
import Breakdown from "./breakdown/index";
import SelfAssessment from "./selfAssessment";
import * as Styled from "./styles.css";

const Score = () => {
  const auth = useContext(AuthContext);
  const queryClient = useQueryClient();
  const [typeformId, setTypeformId] = useState<string | undefined>();
  const { data: selfAssessmentData, isLoading: isSelfAssessmentLoading } =
    useQuery(
      "SelfAssessmentScore",
      () => ScoreService.getScore(auth.user.organisations[0].id),
      { retry: 0 },
    );

  useEffect(() => {
    if (typeformId !== auth.user.organisations[0].typeform_id) {
      (async () => {
        setTypeformId(auth.user.organisations[0].typeform_id);
        queryClient.invalidateQueries("SelfAssessmentScore");
      })();
    }
  }, [typeformId, auth.user.organisations, queryClient]);
  return (
    <Grid container>
      <Header title="Risk and safety rating" type="article" />
      <Styled.Tabs item xs={12}>
        <Tabs
          id="score"
          tabs={[
            <Tab id="breakdown" label={"Breakdown"} />,
            <Tab id="self-assessment" label={"Self assessment"} />,
          ]}
          scrolling
        >
          <TabPanel id="breakdown">
            {isSelfAssessmentLoading ? (
              <CircularProgress />
            ) : (
              <Breakdown
                score={selfAssessmentData}
                selfAssessmentScore={selfAssessmentData?.scores}
                selfAssessmentScoreFactors={selfAssessmentData?.factors}
              />
            )}
          </TabPanel>
          <TabPanel id="self-assessment" spaced>
            <SelfAssessment />
          </TabPanel>
        </Tabs>
      </Styled.Tabs>
    </Grid>
  );
};
export default Score;
