import { useState, useContext } from "react";
import Downshift from "downshift";
import AddressService, {
  getExternalPlaceDetails,
  iAddressFields,
  iAddressListOption,
} from "../../../../services/address/address.service";
import { FormContext } from "../../../../contexts";
import { FormRow, SelectOption, TextField } from "../../../util";
import AddressGroup from "./addressGroup";
import { textDefaultSchema } from "../../../../validations";
import { commaSeparated } from "../../../../helpers";
import * as Styled from "./styles.css";

const AddressSearch = ({
  addressVisible,
  id,
  label,
  optional,
  size,
  validationType,
  ...otherProps
}: AddressSearchProps) => {
  const { formData, formStep, setFormData, setFormErrors } =
    useContext(FormContext);

  const [isAddressFieldsHidden, setIsAddressFieldsHidden] = useState<boolean>(
    !addressVisible,
  );

  const {
    error,
    isLoading,
    data: externalAddressList,
    getExternalAddressList,
  } = AddressService.FindExternalAddress();

  const handleAddressSearch = (event: any) => {
    if (!event.target.value) {
      return;
    }
    getExternalAddressList(event.target.value);
  };

  const handleDownshiftOnChange = async (
    selectedResult: iAddressListOption | null,
  ) => {
    let convertedData: iAddressListOption | iAddressFields;

    if (selectedResult) {
      if (
        selectedResult?.address_full &&
        selectedResult?.address_full === "Other"
      ) {
        convertedData = selectedResult;
      } else {
        convertedData = await getExternalPlaceDetails(selectedResult.value);
      }

      Object.keys(convertedData).forEach((key: string) => {
        if (key in formData[formStep - 1]) {
          if (convertedData[key].length > 0) {
            setFormData(key, convertedData[key]);
            setFormErrors(key, "");
          }
        }

        if (
          selectedResult?.address_full &&
          selectedResult?.address_full === "Other"
        ) {
          setIsAddressFieldsHidden(false);
        }
      });
    }
  };
  return (
    <>
      <FormRow>
        <Downshift
          onChange={handleDownshiftOnChange}
          itemToString={(item: iAddressListOption | null | any) =>
            item && item?.address_full ? item?.address_full : undefined
          }
          initialInputValue={commaSeparated([
            formData[formStep - 1]["address_2"],
            formData[formStep - 1]["address_1"],
            formData[formStep - 1]["city"],
            formData[formStep - 1]["state"],
            formData[formStep - 1]["post_code"],
            formData[formStep - 1]["country"],
          ])}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            isOpen,
            inputValue,
          }) => {
            return (
              <div>
                <TextField
                  autoComplete="address"
                  label={label}
                  placeholder="Search address..."
                  type="text"
                  validationType={validationType}
                  // @ts-ignore:
                  {...getInputProps({
                    id: id,
                    size: size,
                    onChange: handleAddressSearch,
                  })}
                />
                {isOpen && (
                  <Styled.FixedSelectMenu>
                    {isLoading && (
                      <SelectOption disabled>Searching...</SelectOption>
                    )}
                    {error && <SelectOption disabled>Error!</SelectOption>}
                    {inputValue &&
                      inputValue.length > 3 &&
                      !isLoading &&
                      !error &&
                      !externalAddressList.length && (
                        <SelectOption disabled>
                          No results returned
                        </SelectOption>
                      )}
                    {!isLoading &&
                      inputValue &&
                      externalAddressList[0] !== null &&
                      externalAddressList.length > 0 &&
                      externalAddressList
                        .slice(0, 10)
                        .map((item: any, index: number) => (
                          <SelectOption
                            {...getItemProps({
                              key: index,
                              index,
                              item,
                            })}
                          >
                            {item.address_full}
                          </SelectOption>
                        ))}
                    {!isLoading && (
                      <SelectOption
                        {...getItemProps({
                          key: 6,
                          index: 6,
                          item: {
                            address_full: "Other",
                          } as iAddressListOption,
                        })}
                      >
                        Other
                      </SelectOption>
                    )}
                  </Styled.FixedSelectMenu>
                )}
              </div>
            );
          }}
        </Downshift>
      </FormRow>
      <FormRow>
        <AddressGroup hidden={isAddressFieldsHidden} />
      </FormRow>
    </>
  );
};

interface AddressSearchProps {
  addressVisible?: boolean;
  id: string;
  label?: string;
  optional: boolean;
  size: SizeTypes;
  validationType?: any;
}

AddressSearch.defaultProps = {
  addressVisible: false,
  optional: false,
  size: "md",
  type: "text",
  validationType: textDefaultSchema,
};

export type SizeTypes = "md" | "lg";

export default AddressSearch;
