import { ReactNode } from "react";
import { IUserRolesAccess } from "../../../../routes/routes";
import { TIconShape } from "../../../../types";

export const sidebarMainItems: SidebarItemProps[] = [
  {
    title: "Dashboard",
    href: "/",
    icon: "home",
    roles: {
      allowed: ["customer"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Portfolio",
    href: "/portfolio",
    icon: "file-badge",
    roles: {
      allowed: ["broker"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Score",
    href: "/risk-and-safety",
    icon: "graph",
    roles: {
      allowed: ["customer"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Insights",
    href: "/risk-and-safety-insights",
    icon: "eye",
    roles: {
      allowed: ["customer", "broker"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Users",
    href: "/users",
    icon: "people",
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Organisations",
    href: "/organisations",
    icon: "organization",
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Policies",
    href: "/policies",
    icon: "file-badge",
    roles: {
      allowed: ["broker", "admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Invitations",
    href: "/invitations",
    icon: "mail",
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Virtual survey",
    href: "/book-assessment",
    icon: "calendar",
    roles: {
      allowed: ["customer"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Quotes",
    externalLink: true,
    href: process.env.REACT_APP_SALESFORCE_QUOTES_URL || "",
    icon: "checklist",
    roles: {
      allowed: ["broker"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Claims",
    href: "/claims",
    icon: "shield",
    roles: {
      allowed: ["broker", "admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Toolkits",
    href: "/toolkits",
    icon: "tools",
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Activity",
    href: "/activity",
    icon: "pulse",
    roles: {
      allowed: ["admin", "super_admin", "broker"],
      disallowed: ["unverified"],
    },
    featureFlag: "portal.activity.all",
  },
  {
    title: "Automations",
    href: "/automations",
    icon: "dependabot",
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
    featureFlag: "portal.automations.create",
  },
  {
    title: "Getting started",
    href: "/get-started",
    icon: "info",
    roles: {
      allowed: ["unverified"],
    },
  },
];

export const sidebarFooterItems: SidebarItemProps[] = [
  {
    title: "Log out",
    href: "/logout",
    icon: "sign-out",
  },
  // Profile Link added to footer in Sidebar index
];

export interface SidebarItemProps {
  component?: ReactNode;
  title: string;
  externalLink?: boolean;
  href: string;
  icon: TIconShape;
  roles?: IUserRolesAccess;
  featureFlag?: string;
}
