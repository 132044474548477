import { forwardRef, useState } from "react";
import { WidgetProps } from "@rjsf/utils";
import DatePicker from "react-datepicker";
import {
  convertDateTime,
  dateTimeCodeByFormat,
  formatDateTime,
} from "../../../../helpers";
import Button from "../../button";
import Icon from "../../icons/icon";
import * as Styled from "./styles.css";

const Chronology = ({
  onChange,
  id,
  maxDate,
  minDate,
  value,
  ...otherProps
}: JfsDateTimeProps) => {
  const handleChange = (val: string) => {
    setStartDate(convertDateTime(val, "JS"));
    if (onChange) {
      onChange(val);
    }
  };

  const [startDate, setStartDate] = useState<string | undefined>(
    convertDateTime(value, "JS"),
  );
  const [calendarOpen, setCalendarOpen] = useState(false);
  const handleCalendarOpen = () => setCalendarOpen(!calendarOpen);

  const Calendar = ({ ...otherProps }: any) => {
    return <Styled.CalendarContainer {...otherProps} />;
  };

  const Popper = ({ ...otherProps }: any) => {
    return <Styled.PopoverContainer open={calendarOpen} {...otherProps} />;
  };

  const TriggerButton = forwardRef(({ ...otherProps }: any, ref) => {
    return (
      <Button ref={ref} onClick={otherProps.onClick}>
        <Icon icon="calendar" size="sm" />
        {startDate ? formatDateTime(startDate, "long") : "Select date"}
      </Button>
    );
  });

  return (
    <Styled.PickerContainer>
      <DatePicker
        customInput={<TriggerButton />}
        dateFormat={otherProps.dateFormat || dateTimeCodeByFormat("long")}
        id={id}
        selected={startDate}
        minDate={convertDateTime(minDate, "TS")}
        maxDate={convertDateTime(maxDate, "TS")}
        onChange={(val: string) => handleChange(val)}
        popperContainer={Popper}
        calendarContainer={Calendar}
        onCalendarOpen={handleCalendarOpen}
        onCalendarClose={handleCalendarOpen}
        value={startDate}
        {...otherProps}
      />
    </Styled.PickerContainer>
  );
};

interface JfsDateTimeProps extends WidgetProps {}

Chronology.defaultProps = {};

export default Chronology;
