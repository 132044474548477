import prismicDom from "prismic-dom";
import parser from "html-react-parser";
import { BenchmarksCaseStudiesInterface } from "../../../services/prismic/prismic.service";
import { Card, Grid, Image, Typography } from "../../../components/util";
import useStyles from "./styles";

const CaseStudies = ({
  caseStudies,
}: Record<string, BenchmarksCaseStudiesInterface[] | undefined>) => {
  const classes = useStyles();
  return caseStudies && caseStudies.length > 0 ? (
    <Grid item xs={12}>
      <Typography variant="displaySmall" className={classes.title}>
        When bad becomes worse
      </Typography>
      <Grid container spacing={2}>
        {caseStudies.map((caseStudy, i) => {
          return (
            <Grid item xs={12} md={6} lg={4} key={i}>
              <Card isInGrid isPadded>
                <Image
                  src={caseStudy.image.url}
                  alt={caseStudy.image.alt}
                  isInCard
                  classes={{ picture: classes.picture }}
                />
                <Typography variant="displaySmall">
                  {caseStudy.title}
                </Typography>
                {parser(prismicDom.RichText.asHtml(caseStudy.description))}
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

export default CaseStudies;
