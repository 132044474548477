import { WidgetProps } from "@rjsf/utils";
import Combobox, { ComboboxOption } from "./combobox";

function JfsCombobox({
  id,
  options,
  value,
  uiSchema,
  onChange,
  ...otherProps
}: JfsComboboxProps) {
  const getOptions = (
    schema: Record<any, any>,
    jfsOptions: Record<any, any> | Record<any, any>[],
  ): ComboboxOption[] => {
    switch (schema.type) {
      case "array":
        return schema.items.anyOf.map(option => ({
          value: option.const,
          label: option.title,
        }));
      case "string":
      default:
        return (jfsOptions as Record<any, any>).enumOptions.map(option => ({
          value: option.value,
          label: option.label,
        })) as ComboboxOption[];
    }
  };

  const comboOptions = getOptions(otherProps.schema, options);
  const isSearchable = comboOptions?.length > 6;

  return (
    <Combobox
      id={id}
      placeholder="Select an option"
      value={value}
      isValid={!otherProps.rawErrors}
      isMulti={otherProps.schema.type === "array"}
      isSearchable={isSearchable}
      options={comboOptions}
      onChange={onChange}
      {...otherProps}
    />
  );
}

interface JfsComboboxProps extends Omit<WidgetProps, "id" | "onBlur"> {}

JfsCombobox.defaultProps = {};

export default JfsCombobox;
