import styled from "styled-components";
import { transparentize } from "polished";
import { breakpoints, theme } from "../../../styles";
import Typography from "../typography";
import Alert from "../alert";
import List from "../lists/list";
import Chip from "../chip";
import { buildBoxShadow } from "../../../helpers";
import Icon from "../icons/icon";

const checkBoxHoverFocus = `0px 0px 0px 2px ${transparentize(
  0,
  theme.color.accent.border.default.default,
)}`;

export const Input = styled("input").attrs((props: any) => ({
  fullWidth: props.fullWidth,
}))`
  font-size: ${theme.typography.body.fontSize};
  font-weight: ${theme.typography.body.fontWeight};
  letter-spacing: ${theme.typography.body.letterSpacing};
  line-height: ${theme.typography.body.lineHeight};

  width: ${props => (props.fullWidth ? "100%" : "auto")};

  ::placeholder {
    color: ${theme.color.surface.text.default.placeholder};
  }

  @media ${breakpoints.up("lg")} {
    font-size: ${theme.typography.body.desktop.fontSize};
    line-height: ${theme.typography.body.desktop.lineHeight};
  }
`;

export const InputLabel = styled("label").attrs((props: any) => ({
  $inline: props.inline,
}))`
  align-items: flex-end;
  cursor: pointer;
  display: ${(props: any) => (props.$inline ? "inline-block" : "block")};
  position: relative;
  vertical-align: baseline;
  pointer-events: none;
  width: auto;

  -webkit-tap-highlight-color: transparent;
  ${(props: any) =>
    props.$inline
      ? ``
      : `margin-bottom: 8px;
      color: ${theme.color.surface.text.weak.default};
    `};
`;

export const Optional = styled(Chip).attrs((props: any) => ({}))`
  color: ${theme.color.surface.text.weak.default};
  position: absolute;
  right: 0;
`;

export const InputControl = styled("div").attrs((props: any) => ({
  $grow: props.$grow,
  $size: props.$size,
  $type: props.$type,
}))`
  position: relative;

  ${(props: any) => {
    if (props.$grow) {
      return `
        flex-grow: 1;
      `;
    }
  }}

  ${(props: any) => {
    switch (props.$type) {
      case "password":
        return `
          input {
            padding-right: 56px;
          }
          button {
            position: absolute;
            right: 10px;
            top: ${props.$size === "md" ? "2px" : "4px"};
          }
        `;
    }
  }}
`;

export const TextField = styled(Input).attrs((props: any) => ({
  $error: props.$error,
  $size: props.$size,
  $editable: props.$editable,
}))`
  border-radius: 6px;
  ${(props: any) => {
    if (props.$editable) {
      return `
        border: 1px solid ${theme.color.surface.border.weak.default};
        box-shadow: ${buildBoxShadow("low")};
      `;
    } else {
      return `
        padding-left: 20px !important;
        border: 0px solid ${theme.color.surface.border.inverse.default};
      `;
    }
  }}

  &[type="password"] {
    letter-spacing: 1.75px;
  }

  ${(props: any) => {
    if (props.$editable) {
      switch (props.$size) {
        case "md":
          return `
          padding: 5px 15px 5px 15px;
        `;
        case "lg":
          return `
          padding: 9px 15px 9px 15px;
        `;
      }
    } else {
      return `
          padding:  5px 15px 5px 0px;
          pointer-events:none;
        `;
    }
  }}

  :focus {
    border: 2px solid ${theme.color.accent.border.default.pressed};

    ${(props: any) => {
      switch (props.$size) {
        case "md":
          return `
            padding: 4px 14px 4px 14px;
          `;
        case "lg":
          return `
            padding: 8px 14px 8px 14px;
          `;
      }
    }}
  }

  &:disabled {
    background-color: ${theme.color.surface.background.default.disabled};
    color: ${theme.color.surface.text.default.disabled};
  }

  ${(props: any) => {
    if (props.$error) {
      return `
        background-color: ${theme.color.negative.background.weakest.default};
        border: 2px solid ${theme.color.negative.border.default.default};
        ${props.$size === "md" ? "padding: 4px 14px 4px 14px" : ""};
        ${props.$size === "lg" ? "padding: 8px 14px 8px 14px" : ""};
        :focus {
          background-color: transparent;
        }
      `;
    }
  }}
`;

export const CheckboxContainer = styled("span").attrs((props: any) => ({}))`
  height: 24px;
  margin-right: 16px;
  position: relative;
  width: 24px;
  vertical-align: top;

  span {
    left: 2px;
    pointer-events: none;
    position: absolute;
    top: 0px;
  }
`;

export const CheckboxIcon = styled(Icon).attrs((props: any) => ({}))`
  padding-top: 2px;
`;

export const Checkbox = styled(Input).attrs((props: any) => ({
  $error: props.$error,
  $size: props.$size,
}))`
  border: 1px solid ${theme.color.surface.border.weak.default};
  border-radius: 6px;
  box-shadow: ${buildBoxShadow("low")};
  cursor: pointer;
  height: 24px;
  width: 24px;
  vertical-align: top;

  :checked {
    background-color: ${theme.color.accent.background.default.default};
    border: 1px solid ${theme.color.accent.border.default.default};
  }

  :focus {
    box-shadow: ${checkBoxHoverFocus};
    border-color: transparent;
  }
  :hover {
    box-shadow: ${checkBoxHoverFocus};
    border-color: transparent;
  }
`;

export const InputHelpTypography = styled(Typography)`
  margin-top: 8px;
  span {
    margin-right: 8px;
    vertical-align: middle;
  }
`;

export const InputHelpList = styled(List).attrs((props: any) => ({
  $color: props.$color,
  $columns: props.$columns,
}))`
  color: ${theme.color.surface.text.strong.default};
  margin-top: 16px;
  ${theme.typography.small};
  li {
    display: inline-block;
  }
  ${(props: any) => {
    if (props.$columns === 2) {
      return `
        columns: 2 auto;
        li, li div {
          vertical-align: top;
        }
      `;
    }
  }}
`;

export const InputHelpAlert = styled(Alert).attrs((props: any) => ({
  $color: props.$color,
}))`
  color: ${theme.color.surface.text.strong.default};
  margin-top: 16px;
  ${theme.typography.small};
`;
