import styled from "styled-components";
import { theme } from "../../../../styles/themes";
import { Grid, Typography } from "../../../../components/util";
import { GridProps } from "@material-ui/core";
import { buildBoxShadow } from "../../../../helpers";
import { Header } from "../../../../components/layout";
import Form from "../../../../components/content/flow/monoFlow";

export interface StyledGridProps extends GridProps {
  $fullHeight?: boolean;
}
export const ModalHeaderGrid = styled(Grid)<StyledGridProps>`
  border-bottom: 1px solid ${theme.color.surface.border.default.default};
  padding: 20px 24px;
  margin-bottom: 10px;
`;
export const ModalTitle = styled(Typography)`
  padding: 15px 0px;
`;
export const ModalClientTitle = styled(Typography)`
  padding: 0px;
`;

export const ModalBody = styled(Grid)`
  padding: 12px;
`;

export const JSFHeader = styled(Header)`
  width: 100%;
`;

export const JSFForm = styled(Form)`
  width: 700px;
`;
