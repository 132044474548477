import styled from "styled-components";
import { Container } from "./../../components/util";

export const PageNotFoundContainer = styled(Container)`
  position: absolute;
  margin: 0 auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
