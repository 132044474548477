import { WidgetProps } from "@rjsf/utils";
import debounceAsync from "debounce-async";

import AsyncCombobox, {
  ComboboxOption,
  AsyncComboboxProps,
} from "./AsyncCombobox";

function JfsAsyncCombobox({
  id,
  value,
  uiSchema,
  onChange,
  ...otherProps
}: JfsAsyncComboboxProps) {
  const {
    search,
    cacheOptions = false,
    defaultOptions = false,
    isMulti = false,
    getOptionLabel,
    getOptionSubLabel,
    getOptionMultiLabel,
    getOptionValue,
    debounce,
    initialSelected,
    onChange: userOnChange,
  } = uiSchema["ui:options"];

  const loadOptions = debounce ? debounceAsync(search, debounce) : search;

  const handleChange = value => {
    onChange(value);
    userOnChange?.(value);
  };

  return (
    <AsyncCombobox
      id={id}
      placeholder="Select an option"
      value={value}
      isValid={!otherProps.rawErrors}
      isMulti={isMulti}
      isSearchable
      cacheOptions={cacheOptions}
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      getOptionLabel={getOptionLabel}
      getOptionSubLabel={getOptionSubLabel}
      getOptionMultiLabel={getOptionMultiLabel}
      getOptionValue={getOptionValue}
      initialSelected={initialSelected}
      onChange={handleChange}
      {...otherProps}
    />
  );
}

export type JfsAsyncComboboxProps = Omit<WidgetProps, "id" | "onBlur"> & {
  uiSchema: {
    "ui:options": {
      search: (input: string) => Promise<ComboboxOption[] | undefined>;
      debounce?: number;
      onChange?: (msg?: string) => void;
    } & Pick<
      AsyncComboboxProps,
      | "getOptionLabel"
      | "getOptionSubLabel"
      | "getOptionMultiLabel"
      | "getOptionValue"
      | "initialSelected"
      | "cacheOptions"
      | "defaultOptions"
      | "isMulti"
    >;
  };
};

JfsAsyncCombobox.defaultProps = {};

export default JfsAsyncCombobox;
