import { ValueContainerProps } from "react-select";
import * as Styled from "./styles.css";

const ComboboxValueContainer = ({
  children,
  ...otherProps
}: ComboboxValueContainerProps) => {
  return (
    <Styled.ValueContainer $size={otherProps.selectProps.size} {...otherProps}>
      {children}
    </Styled.ValueContainer>
  );
};

export interface ComboboxValueContainerProps
  extends Omit<ValueContainerProps, "selectProps"> {
  selectProps: any;
}

ComboboxValueContainer.defaultProps = {};

export default ComboboxValueContainer;
