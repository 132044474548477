import Label, { iLabelProps } from "./label";
import { TPolicyType, TStyleConnotation } from "../../../types";

const PolicyLabel = ({ children, type, ...otherProps }: iPolicyLabelProps) => {
  return (
    <Label color={policyColor(type)} size="md" {...otherProps}>
      {policyTitle(type)}
    </Label>
  );
};

interface iPolicyLabelProps extends Omit<iLabelProps, "color" | "size"> {
  type: TPolicyType;
}

PolicyLabel.defaultValues = {
  type: "bp",
};

const policyTitle = (type: TPolicyType): string => {
  switch (type) {
    case "cpx":
      return "Commercial Package";
    case "bpk":
      return "Business Package";
    case "wc":
      return "Worker's Compensation";
    default:
      return "Unknown Policy type";
  }
};

const policyColor = (type: TPolicyType): TStyleConnotation => {
  switch (type) {
    case "cpx":
    case "bpk":
      return "information";
    case "wc":
      return "positive";
    default:
      return "surface";
  }
};

export default PolicyLabel;
