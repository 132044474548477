import React from "react";
import { TitleFieldProps } from "@rjsf/utils";
import { containsHTML } from "../../../../helpers";
import * as Styled from "./styles.css";

const JfsInputLabel = ({
  children,
  component,
  id,
  paragraph,
  required,
  showOptional,
  title,
  ...otherProps
}: JfsInputLabelProps) => {
  return (
    <Styled.JfsInputLabel
      id={id}
      dangerousChildren={typeof children === "string" && containsHTML(children)}
      title={title}
      {...otherProps}
    >
      {children && children}
      {!required && (
        <Styled.JfsOptional element="span"> (optional)</Styled.JfsOptional>
      )}
    </Styled.JfsInputLabel>
  );
};

interface JfsInputLabelProps extends TitleFieldProps {
  children?: React.ReactNode;
  component?: string;
  htmlFor: string;
  inline?: boolean;
  onClick?: any;
  paragraph?: boolean;
  required?: boolean;
  showOptional: boolean;
  title: any; // The title of the field being rendered.
  uiSchema?: any; // The uiSchema object for this field.
}

JfsInputLabel.defaultProps = {
  inline: false,
  paragraph: false,
  required: false,
};

export default JfsInputLabel;
