import { ComponentSize } from "../../../types/attributes.type";
import { TextFieldTypes } from "./textField";
import { ContainerProps } from "../container/container";
import * as Styled from "./styles.css";

const InputControl = ({
  children,
  grow,
  size,
  type,
  ...otherProps
}: InputControlProps) => {
  return (
    <Styled.InputControl $grow={grow} $size={size} $type={type} {...otherProps}>
      {children && children}
    </Styled.InputControl>
  );
};

interface InputControlProps extends ContainerProps {
  grow?: boolean;
  size: ComponentSize;
  type: TextFieldTypes;
}

InputControl.defaultProps = {
  grow: false,
  size: "md",
  type: "text",
};

export default InputControl;
