import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import { CSSReset } from "./reset";
import { MuiTheme } from "./themes";

export { theme } from "./themes";
export { default as breakpoints } from "./breakpoints";

type MuiThemeProps = {
  children?: React.ReactNode;
};

const Styles = ({ children }: MuiThemeProps) => {
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={MuiTheme}>
        <SCThemeProvider theme={MuiTheme}>
          <CSSReset />
          {children && children}
        </SCThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};
export default Styles;
