import { Button } from "../../../components/util";
import { TStyleConnotation } from "../../../types";

const CreateButton = ({
  color,
  data,
  onClick,
  text,
  variant,
  ...otherProps
}: CreateButtonProps) => {
  return (
    <Button
      color={color}
      variant={variant}
      onClick={() => onClick()}
      {...otherProps}
    >
      {text && text}
    </Button>
  );
};

interface CreateButtonProps {
  button?: React.ReactNode;
  color: TStyleConnotation;

  data?: any;
  onClick: () => void;
  text?: string;

  variant: "text" | "contained";
}

export default CreateButton;
