import styled from "styled-components";
import { breakpoints, theme } from "../../../styles";

import {
  Alert,
  Grid as ComponentGrid,
  Table as ComponentTable,
  Typography,
} from "../../../components/util";

import { GridProps } from "@material-ui/core";

export interface StyledGridProps extends GridProps {
  $fullHeight?: boolean;
}

export const SubHeader = styled(Typography)`
  margin-top: 2em;
  margin-bottom: 0.5em;
  color: ${theme.color.surface.text.weak.default};
`;

export const InfoAlert = styled(Alert)`
  margin-bottom: 40px;
`;

export const Grid = styled(ComponentGrid)<StyledGridProps>`
  height: ${props => (props.$fullHeight ? "100%" : "initial")};
`;

export const Table = styled(ComponentTable)`
  @media ${breakpoints.down("sm")} {
    grid-template-columns: 1fr;
  }
  th:not(:first-of-type) {
    display: none;
    @media ${breakpoints.up("md")} {
      display: flex;
    }
  }
  td:not(:first-of-type) {
    display: none;
    @media ${breakpoints.up("md")} {
      display: flex;
    }
  }
`;
