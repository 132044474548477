import { DateTime as LuxonDateTime } from "luxon";
import { TDateTimeCode, TDateTimeFormat } from "../types";

const unixTS = (input?: string): number | undefined => {
  if (!input) {
    return undefined;
  }
  if (LuxonDateTime.fromISO(input).isValid) {
    return LuxonDateTime.fromISO(input).toMillis();
  }
  if (LuxonDateTime.fromSQL(input).isValid) {
    return LuxonDateTime.fromSQL(input).toMillis();
  }
  if (LuxonDateTime.fromJSDate(input as unknown as Date).isValid) {
    return LuxonDateTime.fromJSDate(input as unknown as Date).toMillis();
  }
  return undefined;
};

export const convertDateTime = (
  input?: string,
  outputFormat: "TS" | "JS" | "ISO" | "UTC" | "SQL" = "TS",
): any | undefined => {
  const validDate: number | undefined = unixTS(input);
  if (validDate) {
    switch (outputFormat) {
      case "UTC":
        // ISO 8601 .toUTC is luxon equivalent of .toISOString
        return LuxonDateTime.fromMillis(validDate).toUTC();
      case "ISO":
        return LuxonDateTime.fromMillis(validDate).toISO();
      case "JS":
        return LuxonDateTime.fromMillis(validDate).toJSDate();
      case "SQL":
        return LuxonDateTime.fromMillis(validDate).toSQL();
      case "TS":
      default:
        return validDate;
    }
  } else {
    return undefined;
  }
};

export const dateTimeCodeByFormat = (
  format: TDateTimeFormat = "short",
): TDateTimeCode => {
  switch (format) {
    case "long":
      return "dd MMM yyyy 'at' HH:mm";
    case "verbose":
      return "dd MMM yyyy 'at' HH:mm:ss";
    case "short":
    default:
      return "dd/MM/yyyy";
  }
};

export const formatDateTime = (
  input?: string,
  outputFormat: TDateTimeFormat = "short",
): string => {
  return LuxonDateTime.fromISO(convertDateTime(input, "ISO")).toFormat(
    dateTimeCodeByFormat(outputFormat),
  );
};

export const createDateTime = (day: "today", when: "start" | "end" = "end") => {
  let newDate = LuxonDateTime.local().endOf("day").toISO();
  // if (when === "end") {
  //   newDate = newDate.endOf("day");

  // }

  return newDate;
};
