import { DeepdiveTyskInterface } from "../../../services/prismic/prismic.service";
import { Box } from "@material-ui/core";
import { Card, Grid, Typography } from "../../../components/util";
import Point from "./point";

interface TyskPropsInterface {
  tysks?: DeepdiveTyskInterface[];
}

const Tysk = ({ tysks }: TyskPropsInterface) => {
  return (
    <Grid item xs={12} lg={7}>
      <Card isInGrid isPadded>
        <Typography variant="displaySmall">Things you should know</Typography>
        {tysks?.map((tysk: DeepdiveTyskInterface, i) => (
          <Box key={i}>
            <Typography paragraph>{tysk.title}</Typography>
            {tysk.description && <Typography>{tysk.description}</Typography>}
            {tysk.points &&
              tysk.points.map((point, j) => <Point point={point} key={j} />)}
          </Box>
        ))}
      </Card>
    </Grid>
  );
};

export default Tysk;
