export const organisationTypeMap = [
  {
    title: "Orphan",
    value: "orphan",
  },
  {
    title: "Child",
    value: "child",
  },
  {
    title: "Parent",
    value: "parent",
  },
];
