import Joi from "joi";
import {
  emailSchema,
  firstNameSchema,
  isExistingAuth0UserSchema,
  lastNameSchema,
  passwordSchema,
  phoneNumberSchema,
} from "./fields.schemas";

const customMessage = (
  msgName: string,
  field?: string,
): Record<string, string> => {
  let errors: Record<string, string> = {
    "string.empty": `${msgName} is required`,
    "string.max": `${msgName} is too long`,
  };
  errors[
    "any.only"
  ] = `Please ensure the password and the confirmation are the same.`;
  return errors;
};

export const signUpFormSchema = Joi.object({
  firstName: firstNameSchema.when("isExistingAuth0User", {
    is: false,
    then: Joi.required(),
  }),
  lastName: lastNameSchema.when("isExistingAuth0User", {
    is: false,
    then: Joi.required(),
  }),
  email: emailSchema.when("isExistingAuth0User", {
    is: false,
    then: Joi.required(),
  }),
  phoneNumber: phoneNumberSchema.when("isExistingAuth0User", {
    is: false,
    then: Joi.required(),
  }),
  password: passwordSchema.when("isExistingAuth0User", {
    is: false,
    then: Joi.required(),
  }),
  isExistingAuth0User: isExistingAuth0UserSchema,
});

export const changePasswordFormSchema = Joi.object({
  password: passwordSchema,
  passwordConfirm: Joi.string().messages(customMessage("Passwords")).required(),
  token: Joi.string().required(),
});
