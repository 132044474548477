import { useFlag } from "@unleash/proxy-client-react";
import { recordAnalyticsEvent } from "../../analytics";
import { Button } from "../../components/util";
import { Header, Section } from "../../components/layout";
import { OnboardingNewBroker } from "../../components/content";
import ClientList from "./clientList";

import * as Styled from "./styles.css";

const Portfolio = () => {
  const handleQuoteClick = () => {
    recordAnalyticsEvent("action", "Create Quote", {
      path: process.env.REACT_APP_SALESFORCE_NEWQUOTE_URL || "",
    });
  };

  return (
    <Styled.Grid $fullHeight>
      <Header
        actions={
          useFlag("portal.quotebutton") && (
            <Button
              color="accent"
              href={process.env.REACT_APP_SALESFORCE_NEWQUOTE_URL}
              isNewTab
              onClick={() => handleQuoteClick()}
              variant="contained"
            >
              Create quote
            </Button>
          )
        }
        title="Portfolio"
        type="article"
      ></Header>
      <Section>
        <OnboardingNewBroker />
        <ClientList />
      </Section>
    </Styled.Grid>
  );
};
export default Portfolio;
