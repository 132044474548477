import { useState } from "react";
import { useQueryClient } from "react-query";
import { Modal, FormRow } from "../../../../components/util";
import { OrgIdSchema } from "../../../../validations";
import UserService from "../../../../services/user/user.service";
import * as Styled from "./styles.css";

const UserOrgModal = ({
  data,
  handleModal,
  refetchQuery,
  modalType,
}: UserOrgModalProps) => {
  const queryClient = useQueryClient();
  const [orgData] = useState<Record<string, string>>({
    user_id: data?.user_id ? data?.user_id : "",
    org_id: modalType === "Remove" && data?.org_id ? data.org_id : "",
    old_org_id: modalType === "Update" && data?.org_id ? data.org_id : "",
  });

  const [errors] = useState<Record<string, boolean>>({
    user_id: false,
    org_id: false,
    old_org_id: false,
  });

  const [errorMessages] = useState<Record<string, string>>({
    user_id: "",
    org_id: "",
    old_org_id: "",
  });

  const addUserOrg = async (formData: Record<string, string>) => {
    const data: OrgInfo = {
      user_id: formData.user_id,
      org_id: formData.org_id,
    };
    await UserService.addUserOrg(data);

    handleModal();
    return;
  };

  const updateUserOrg = async (formData: Record<string, string>) => {
    const data: OrgInfo = {
      user_id: formData.user_id,
      org_id: formData.org_id,
      old_org_id: formData.old_org_id,
    };

    await UserService.updateUserOrg(data);

    handleModal();
    return;
  };

  const removeUserOrg = async (
    formData: Record<string, string>,
  ): Promise<void> => {
    const data: OrgInfo = {
      user_id: formData.user_id,
      org_id: formData.org_id,
    };
    await UserService.removeUserOrg(data);

    handleModal();
    return;
  };

  const serviceSwitch = (
    formData: Record<string, string>,
  ): Promise<void> | undefined => {
    switch (modalType) {
      case "Add":
        return addUserOrg(formData);
      case "Remove":
        return removeUserOrg(formData);
      case "Update":
        return updateUserOrg(formData);
    }
    return;
  };

  return (
    <Modal
      isOpen={true}
      onClose={() => handleModal()}
      headerComponent={
        <Styled.ModalTitle variant="displaySmall">
          {`${modalType}  organisation`}
        </Styled.ModalTitle>
      }
      headerDivider
    >
      <Styled.ModalBody container>
        <Styled.ModalBodyTitle>
          The user will only be able to access information related to the new
          organisation.
        </Styled.ModalBodyTitle>
        <Styled.ModalForm
          footer
          fullWidth
          miscButton={true}
          miscButtonName="Cancel"
          miscButtonOnClick={() => handleModal()}
          onSubmit={{
            service: serviceSwitch,
            onSuccess: () => {
              queryClient.refetchQueries(refetchQuery);
            },
          }}
          validationErrors={[errors]}
          validationFields={[orgData]}
          submitText={modalType ? modalType.toString() : ""}
          submitAlign="left"
          submitInline
          submitButtonAccent={
            modalType === "Update" || modalType === "Add" ? "" : "warning"
          }
        >
          <FormRow>
            <Styled.ModalTextField
              hidden={modalType === "Remove"}
              editable={true}
              label={modalType === "Remove" ? "" : "Organisation ID"}
              id="org_id"
              type={"text"}
              value={orgData.org_id}
              validationType={OrgIdSchema}
              validationOnChange
              helpText={errorMessages.org_id}
            />
            <Styled.ModalTextField
              hidden={true}
              editable={false}
              id="old_org_id"
              type={"text"}
              value={orgData.old_org_id}
              validationType={OrgIdSchema}
              validationOnChange
              helpText={errorMessages.old_org_id}
            />
            <Styled.ModalTextField
              hidden={true}
              editable={false}
              label={""}
              id="user_id"
              type={"text"}
              value={orgData.user_id}
              validationType={OrgIdSchema}
              validationOnChange
              helpText={errorMessages.user_id}
            />
          </FormRow>
        </Styled.ModalForm>
      </Styled.ModalBody>
      <Styled.ModalFooter />
    </Modal>
  );
};

interface UserOrgModalProps {
  data?: OrgInfo;
  handleModal: () => void;
  refetchQuery?: string;
  modalType: tOrgModalType;
}

export type tOrgModalType = "Add" | "Update" | "Remove" | undefined;

export interface OrgInfo {
  user_id: string;
  org_id?: string;
  old_org_id?: string;
}

export default UserOrgModal;
