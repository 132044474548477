import { ContainerProps } from "../container/container";
import * as Styled from "./styles.css";

const TableRow = ({ children, ...otherProps }: TableRowProps) => {
  return (
    <Styled.TableRow element="tr" {...otherProps}>
      {children && children}
    </Styled.TableRow>
  );
};

export interface TableRowProps extends ContainerProps {}

export default TableRow;
