import { ContainerProps } from "../container/container";
import * as Styled from "./styles.css";

const Footer = ({ children, ...otherProps }: FooterProps) => {
  return (
    <Styled.Footer element="footer" {...otherProps}>
      {children && children}
    </Styled.Footer>
  );
};

interface FooterProps extends ContainerProps {}

export default Footer;
