import {
  ActivityTable,
  AssociatedClaims,
  PolicyLabel,
  Summary,
  Toolkit,
} from "../../../components/content";
import { IPolicy } from "../../../types";
import { Header } from "../../../components/layout";
import {
  Link,
  TableRow,
  TableCell,
  TableRowSubhead,
} from "../../../components/util";
import * as Styled from "./styles.css";
import { Hidden } from "@material-ui/core";
import { formatDateTime } from "../../../helpers";
import PolicyTabs from "./policyTabs";
import Invites from "./invites";

const Cpx = ({ policy, back_text }: { policy: IPolicy; back_text: string }) => {
  const { policy_data } = policy;
  const policy_number = policy.policy_number;

  return (
    <>
      <Header
        title={
          policy_number
            ? policy_number
            : policy_data?.BUSINESS_NAME || policy_data?.business_name
        }
        titleIcon="file"
        titleIconPosition="end"
        type="article"
        withLink={{ path: "/policies", text: "All Policies" }}
      >
        <Hidden xsDown>
          <Styled.SubHeader element="span" variant="heading">
            {policy_data?.TRADING_NAME || policy_data?.business_name}
          </Styled.SubHeader>
        </Hidden>
      </Header>

      <Summary
        data={[
          {
            Type: <PolicyLabel type={policy.type} />,
          },
          {
            Created: formatDateTime(
              policy.created_ts || policy.created_ts || "",
              "verbose",
            ),
          },
          {
            Incepted: formatDateTime(policy.effective_from_ts || "", "verbose"),
          },
          {
            Expiry: formatDateTime(
              policy_data.POLICY_END || policy?.effective_to_ts || "",
              "verbose",
            ),
          },
        ]}
      />
      <PolicyTabs policy={policy} policyNumber={policy_number || ""}>
        <>
          <Header title="Policy" type="section" />
          <Styled.Table
            columns={[
              { title: "", expand: true },
              { title: "", expand: true },
            ]}
            orientation="horizontal"
          >
            <TableRowSubhead>Insured details</TableRowSubhead>
            <TableRow>
              <TableCell>Policy number</TableCell>
              <TableCell>{policy_number ? policy_number : "Unknown"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Insured name</TableCell>
              <TableCell>
                {policy_data?.BUSINESS_NAME || policy_data?.business_name}
              </TableCell>
            </TableRow>
            {policy_data.TRADING_NAME && (
              <TableRow>
                <TableCell>Billing entity</TableCell>
                <TableCell>{policy_data.TRADING_NAME}</TableCell>
              </TableRow>
            )}
            {(policy_data?.ANZSIC || policy_data?.anzsic) && (
              <TableRow>
                <TableCell>ANZSIC</TableCell>
                <TableCell>
                  {policy_data?.ANZSIC || policy_data?.anzsic}
                </TableCell>
              </TableRow>
            )}
            {policy_data.ANZSIC_CODE !== undefined && (
              <TableRow>
                <TableCell>ANZSIC code</TableCell>
                <TableCell>{policy_data.ANZSIC_CODE}</TableCell>
              </TableRow>
            )}
            {(policy_data?.SCHEME_CODE || policy_data?.policy_wording_ref) && (
              <TableRow>
                <TableCell>Scheme code</TableCell>
                <TableCell>
                  {policy_data?.SCHEME_CODE || policy_data?.policy_wording_ref}
                </TableCell>
              </TableRow>
            )}
            <TableRow></TableRow>
            <TableRow>
              <TableCell>Team</TableCell>
              <TableCell>
                {policy_data?.attribute?.teamId || policy_data?.team_id}
              </TableCell>
            </TableRow>

            <TableRowSubhead>Contact details</TableRowSubhead>
            {(policy_data?.CUSTOMER_FIRST_NAME || policy_data?.first_name) && (
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>
                  {`${
                    policy_data?.CUSTOMER_FIRST_NAME || policy_data?.first_name
                  } ${
                    policy_data?.CUSTOMER_LAST_NAME || policy_data?.last_name
                  }`}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>
                <Link
                  href={`mailto:${
                    policy_data?.CUSTOMER_EMAIL || policy_data.client_email
                  }`}
                >
                  {policy_data?.CUSTOMER_EMAIL || policy_data.client_email}
                </Link>
              </TableCell>
            </TableRow>
            {(policy_data?.BROKER_ASSIGNEE ||
              policy_data?.BROKER_EMAIL_ADDRESS ||
              policy_data?.agent_name ||
              policy_data?.agent_email) && (
              <>
                <TableRowSubhead>Agent details</TableRowSubhead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>
                    {policy_data?.BROKER_ASSIGNEE || policy_data?.agent_name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>
                    {policy_data?.BROKER_EMAIL_ADDRESS ||
                      policy_data?.agent_email}
                  </TableCell>
                </TableRow>
              </>
            )}

            {policy_data.agent_role && (
              <TableRow>
                <TableCell>Role</TableCell>
                <TableCell>{policy_data.agent_role}</TableCell>
              </TableRow>
            )}
            {(policy_data?.broker || policy_data?.network) && (
              <>
                <TableRowSubhead>Broker details</TableRowSubhead>
                <TableRow>
                  <TableCell>Broker name</TableCell>
                  <TableCell>
                    {policy_data?.BROKER_ASSIGNEE || policy_data?.broker}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Broker email address</TableCell>
                  <TableCell>
                    {policy_data?.BROKER_EMAIL_ADDRESS ||
                      policy_data?.broker_email_address}
                  </TableCell>
                </TableRow>
              </>
            )}
          </Styled.Table>
          {!policy.customer_org_id && !policy.invitations && (
            <Invites client={policy} organisation_id={policy.broker_org_id} />
          )}
          <AssociatedClaims
            policyNumber={policy_number}
            policyId={policy.id}
            claims={undefined}
            policyType={policy.type}
          />
          {policy.customer_org_id && <Toolkit orgId={policy.customer_org_id} />}
          {policy && <ActivityTable policy_id={policy.id} />}
        </>
      </PolicyTabs>
    </>
  );
};

export default Cpx;
