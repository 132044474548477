import { makeStyles } from "@material-ui/core";
import Benchmarks from "./benchmarks";
import Compare from "./compare/";
import Deepdive from "./deepdive/";
import { Header } from "./../../components/layout";
import { Grid, Tab, TabPanel, Tabs } from "../../components/util";
import { theme } from "../../styles";

const styles = makeStyles(() => ({
  page: {
    flexGrow: 1,
  },
  title: {
    marginTop: theme.spacing,
    marginBottom: `calc(${theme.spacing} / 3 * 2)`,
  },
  img: {
    maxHeight: "200px",
  },
  button: {
    minHeight: "500px",
  },
}));

export default function Insights() {
  const classes = styles();

  return (
    <Grid container classes={{ root: classes.page }}>
      <Header title="Insights" type="article" />
      <Grid item xs={12}>
        <Tabs
          id="insights"
          tabs={[
            <Tab id="benchmarks" label={"Benchmarks"} />,
            <Tab id="deepdive" label={"Deep dive"} />,
            <Tab id="compare" label={"Compare"} />,
          ]}
          scrolling
        >
          <TabPanel id="benchmarks">
            <Benchmarks />
          </TabPanel>
          <TabPanel id="deepdive">
            <Deepdive />
          </TabPanel>
          <TabPanel id="compare">
            <Compare />
          </TabPanel>
        </Tabs>
      </Grid>
    </Grid>
  );
}
