import { useState } from "react";
import { useQueryClient } from "react-query";

import { InvitationStatus } from "../../../../helpers/policies";
import { Header } from "../../../../components/layout";
import Form from "../../../../components/content/flow/monoFlow";
import { schema, uiSchema } from "./schema";
import type { IPolicy, JfsFormData } from "../../../../types";

import {
  Alert,
  Button,
  Icon,
  TableCell,
  TableRow,
  TableRowSubhead,
} from "../../../../components/util";

import PolicyService, {
  PolicySettings,
} from "../../../../services/policy/policy.service";

import * as Styled from "../styles.css";

type Props = {
  policyId: IPolicy["id"];
  invitationStatus: InvitationStatus;
  settings: PolicySettings;
};

const SettingsTab = ({ policyId, invitationStatus, settings }: Props) => {
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState<JfsFormData>(settings);
  const [editMode, setEditMode] = useState(false);
  const handleEditSettings = () => setEditMode(true);

  const handleCancelEditSettings = () => {
    setEditMode(false);
    setFormData(settings);
  };

  const handleChange = ({ formData }: { formData?: any }) =>
    setFormData(formData);

  const handleSubmit = async (data: JfsFormData): Promise<void> => {
    try {
      const newSettings: PolicySettings = {
        do_not_invite: data.do_not_invite,
      };

      await PolicyService.updateSettings(policyId, newSettings);
      queryClient.invalidateQueries(["portfolio-all"]);
      queryClient.invalidateQueries(["policy-details", policyId]);
      setEditMode(false);
    } catch (error: any) {
      if (error?.response.data?.error) {
        throw new Error(error?.response.data?.error);
      } else {
        throw error;
      }
    }
  };

  const Edit = () => (
    <>
      {invitationStatus === "invited" && formData.do_not_invite && (
        <Alert color="warning">
          An invitation for this policy already exists, so setting this will
          have no effect
        </Alert>
      )}

      <Form
        id="policy-settings-form"
        name="Settings"
        schema={schema}
        onChange={handleChange}
        onSubmit={handleSubmit}
        formData={formData}
        uiSchema={uiSchema}
        cancelButton={
          <Button
            color="surface"
            router
            onClick={handleCancelEditSettings}
            variant="contained"
          >
            Cancel
          </Button>
        }
        submitButtonText="Save settings"
      />
    </>
  );

  const View = () => (
    <Styled.Table
      columns={[
        { title: "Key", expand: true },
        { title: "Value", expand: true },
      ]}
      orientation="horizontal"
    >
      <TableRowSubhead>Settings</TableRowSubhead>

      <TableRow>
        <TableCell>Do Not Invite</TableCell>

        <TableCell>
          <Icon
            icon={
              settings.do_not_invite ? "check-circle-fill" : "x-circle-fill"
            }
            color={settings.do_not_invite ? "positive" : "negative"}
          />
        </TableCell>
      </TableRow>
    </Styled.Table>
  );

  return (
    <>
      <Header
        title="Communications"
        titleIcon="gear"
        titleIconPosition="start"
        type="section"
        actions={
          <Button
            color="accent"
            onClick={handleEditSettings}
            variant="contained"
            disabled={editMode}
          >
            Edit Settings
          </Button>
        }
      />

      {editMode ? <Edit /> : <View />}
    </>
  );
};

export default SettingsTab;
