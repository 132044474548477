import { CompareBenchmarksInterface } from "../../../services/prismic/prismic.service";
import { Card, Grid, Typography } from "../../../components/util";

const Benchmarks = ({
  data,
}: Record<string, CompareBenchmarksInterface | undefined>) => {
  return data ? (
    <Grid item xs={12} sm={12} md={12} lg={4}>
      <Card isInGrid isPadded>
        <Typography paragraph variant="displaySmall">
          {data.title}
        </Typography>
        <Typography paragraph>{data.text}</Typography>
      </Card>
    </Grid>
  ) : (
    <></>
  );
};
export default Benchmarks;
