import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext, LocationContext } from "../../contexts";
import { getMostPrivlidgedRole } from "../../helpers";
import { tUserRole } from "../../types";
import { Link } from "../../components/util";
import { Logo } from "../../components";
import AuthSidebar from "./authSidebar";
import { ContainerProps } from "../../components/util/container/container";
import * as Styled from "./styles.css";

const AuthContainer = ({
  backLink,
  children,
  footerContent,
  headerTitle,
  isSidebarVisible,
  preventRedirect,
  stickyFooter,
}: AuthContainerProps) => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  interface StateInterface {
    from: Location;
  }
  const { location } = useContext(LocationContext);
  const { from } = (location.state as StateInterface) || {
    from: { pathname: "/" },
  };

  useEffect(() => {
    if (!preventRedirect && auth.user) {
      if (!from || from.pathname === "/") {
        history.push(getLoginRedirectByRole(auth.user.roles));
      } else {
        history.replace(from);
      }
    }
  }, [auth.user, from, history, location, location.pathname, preventRedirect]);

  return (
    <>
      <AuthSidebar backLink={backLink} isSidebarVisible={isSidebarVisible} />
      <Styled.AuthContent
        item
        xs
        element="section"
        $isFooterVisible={!!footerContent}
        $isSidebarVisible={isSidebarVisible}
        $isStickyFooter={stickyFooter}
      >
        {!!headerTitle && (
          <>
            <Link href="/" router>
              <Logo />
            </Link>
            <Styled.AuthHeader type="article" title={headerTitle} />
          </>
        )}
        {children && children}
      </Styled.AuthContent>
      {!!footerContent && (
        <Styled.AuthFooter $isStickyFooter={stickyFooter}>
          {footerContent && footerContent}
        </Styled.AuthFooter>
      )}
    </>
  );
};

interface AuthContainerProps extends ContainerProps {
  backLink?: any;
  footerContent?: React.ReactChild;
  headerTitle?: string;
  isSidebarVisible: boolean;
  preventRedirect: boolean;
  stickyFooter: boolean;
}

AuthContainer.defaultProps = {
  isFooterVisible: false,
  isSidebarVisible: false,
  preventRedirect: false,
  stickyFooter: false,
};

const getLoginRedirectByRole = (userRoles: tUserRole[]): string => {
  const userRole = getMostPrivlidgedRole(userRoles);
  switch (userRole) {
    case "broker":
      return "/portfolio";
    case "admin":
    case "super_admin":
      return "/users";
    default:
      return "/";
  }
};

export default AuthContainer;
