import { JfsSchema, JfsUiSchema } from "../../../../types";
import { JfsAsyncCombobox } from "../../../../components/util";
import { IOrganisation } from "../../../../services/admin/admin.service";

export const withUiSchema = ({ org_id }: WithUiSchemaParams): JfsSchema => ({
  ...uiSchemaData,
  org_id: {
    ...uiSchemaData.org_id,
    "ui:options": {
      ...uiSchemaData.org_id["ui:options"],
      ...org_id,
    },
  },
});

export const schemaData: JfsSchema = {
  title: "",
  type: "object",
  required: ["org_id"],
  properties: {
    org_id: {
      title:
        "The Organisation will only be able to access information related to the policy.",
      type: "string",
    },
  },
};

export const uiSchemaData: JfsUiSchema = {
  "ui:order": ["org_id"],

  org_id: {
    "ui:widget": JfsAsyncCombobox,
    "ui:options": {
      debounce: 750,
    },
  },
};

type WithUiSchemaParams = {
  org_id: {
    search: (input: string) => Promise<IOrganisation[] | undefined>;
    getOptionLabel: (item: IOrganisation) => string;
    getOptionSubLabel: (item: IOrganisation) => string;
    getOptionValue: (item: IOrganisation) => string;
    initialSelected?: Pick<IOrganisation, "id" | "name">;
  };
};
