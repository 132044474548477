import { useHistory, useLocation } from "react-router-dom";
import { Header, Section } from "../../../components/layout";
import { Button } from "../../../components/util";
import * as Styled from "../styles.css";
import { AutomationList } from "./AutomationList";
import { useMemo } from "react";
import queryString from "query-string";

export const AutomationListPage = () => {
  const history = useHistory();
  const { search } = useLocation();

  const redirectToCreateAutomationPage = _event =>
    history.push({ pathname: "/automations/create" });

  const searchTerm = useMemo(() => {
    const params = queryString.parse(search);
    return params.searchTerm ? (params.searchTerm as string) : "";
  }, [search]);

  return (
    <Styled.Grid $fullHeight>
      <Header
        title="Automations"
        titleIcon="dependabot"
        titleIconPosition="start"
        type="article"
        actions={
          <Button
            color="accent"
            onClick={redirectToCreateAutomationPage}
            variant="contained"
          >
            Create Automation
          </Button>
        }
      />

      <Section>
        <AutomationList searchTerm={searchTerm} />
      </Section>
    </Styled.Grid>
  );
};
