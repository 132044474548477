import { containsHTML } from "../../../helpers";
import { ContainerProps } from "../container/container";
import { Typography } from "./../";
import * as Styled from "./styles.css";

const Label = ({
  id,
  childAppend,
  children,
  htmlFor,
  inline,
  onClick,
  childPrepend,
  title,
  ...otherProps
}: LabelProps) => {
  return (
    <Styled.Label
      element="label"
      htmlFor={htmlFor}
      $inline={inline}
      {...otherProps}
    >
      {childPrepend && childPrepend}
      <Typography dangerousChildren={containsHTML(title)}>
        {title}
        {children && children}
        {childAppend && childAppend}
      </Typography>
    </Styled.Label>
  );
};

interface LabelProps extends ContainerProps {
  htmlFor: string;
  inline?: boolean;
  title: string;
}

Label.defaultProps = {};

export default Label;
