import ReactModal from "react-modal";
import { Grid, IconButton } from "..";
import { ContainerProps } from "../container/container";
import { StyledModal, StyledOverlay, ModalHeaderGrid } from "./style.css";

const Modal = ({
  isOpen,
  children,
  onClose,
  hideHeader,
  headerComponent,
  headerDivider,
  shouldCloseOnOverlayClick,
  ...otherProps
}: iModalProps) => {
  const closeModal = () => {
    closeModalFn();
    onClose();
  };

  if (isOpen) {
    document.body.style.overflowY = "hidden";
  }

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      className="_"
      overlayClassName="_"
      overlayElement={(props, contentElement) => (
        <StyledOverlay {...props}>{contentElement}</StyledOverlay>
      )}
      contentElement={(props, children) => (
        <StyledModal {...props}>{children}</StyledModal>
      )}
    >
      {!hideHeader && (
        <ModalHeaderGrid container $headerDivider={headerDivider}>
          <Grid item xs={11}>
            {headerComponent && headerComponent}
          </Grid>
          <Grid item xs={1}>
            <IconButton icon="x" onClick={() => closeModal()}></IconButton>
          </Grid>
        </ModalHeaderGrid>
      )}

      {children}
    </ReactModal>
  );
};

export interface iModalProps extends ContainerProps {
  noCloseLabel: boolean;
  isOpen: boolean;
  onClose?: any;
  hideHeader?: boolean;
  headerComponent?: any;
  headerDivider?: boolean;
  shouldCloseOnOverlayClick: boolean;
  className?: string;
  overlayClassName?: string;
}

Modal.defaultProps = {
  noCloseLabel: false,
  isOpen: false,
  shouldCloseOnOverlayClick: true,
};

export default Modal;

export const closeModalFn = () => {
  document.body.style.overflowY = "auto";
};
