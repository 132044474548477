import { CompareOverviewInterface } from "../../../services/prismic/prismic.service";
import { Card, Grid, Image } from "../../../components/util";

const Overview = ({
  data,
}: Record<string, CompareOverviewInterface | undefined>) => {
  return data ? (
    <Grid item lg={8} xs={12}>
      <Card isInGrid>
        <Image src={data.image} alt={data.id} />
      </Card>
    </Grid>
  ) : (
    <></>
  );
};

export default Overview;
