import styled from "styled-components";
import { theme } from "../../../../styles";
import { Grid, Typography } from "../../../util";

export const GridContainer = styled(Grid).attrs((props: any) => ({}))`
  &:first-of-type {
    margin-top: 32px;
  }
  &:not(:first-of-type) {
    border-top: 1px solid ${theme.color.surface.border.default.default};
    padding-bottom: 4px;
    padding-top: 16px;
  }
  &:last-of-type {
    margin-bottom: 48px;
  }
`;

export const SummarySectionHeader = styled(Typography).attrs(
  (props: any) => ({}),
)`
  margin-bottom: 16px;
`;
