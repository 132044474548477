import React from "react";
import * as Styled from "./styles.css";

const InputLabel = ({
  children,
  component,
  id,
  paragraph,
  required,
  showOptional,
  ...otherProps
}: InputLabelProps) => {
  return (
    <Styled.InputLabel id={id} {...otherProps}>
      {children && children}
      {!required && showOptional && (
        <Styled.Optional label="Optional" size="sm" />
      )}
    </Styled.InputLabel>
  );
};

interface InputLabelProps {
  children?: React.ReactNode;
  className?: string;
  component?: string;
  htmlFor: string;
  id?: string;
  inline: boolean;
  onClick?: any;
  paragraph: boolean;
  required: boolean;
  showOptional: boolean;
}

InputLabel.defaultProps = {
  inline: false,
  paragraph: false,
  required: false,
};

export default InputLabel;
