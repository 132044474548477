import { ReactNode } from "react";
import { TIconShape } from "../../../types";
import { Icon, Typography } from "../../util";
import { ContainerProps } from "../../util/container/container";
import * as Styled from "./styles.css";

const Missing = ({
  icon,
  illustration,
  children,
  message,
  title,
  type = "page",
  ...otherProps
}: IMissingProps) => {
  return (
    <Styled.MissingContainer>
      {icon && (
        <Styled.BoxedIcon>
          <Icon icon={icon} shade="strong" size="md"></Icon>
        </Styled.BoxedIcon>
      )}
      {!icon && illustration && illustration}
      <Typography paragraph variant="displaySmall">
        {title ? title : `Sorry, we can't find that ${type}`}
      </Typography>
      <Styled.MessageTypography paragraph shade="weaker">
        {message}
      </Styled.MessageTypography>
      {children && children}
    </Styled.MissingContainer>
  );
};

export interface IMissingProps extends ContainerProps {
  icon?: TIconShape;
  illustration?: ReactNode;
  title?: string;
  type?: string;
  message?: string | ReactNode;
}

export default Missing;
