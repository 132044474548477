import ScoreFactors, { ScoreFactorDetails, ScoreFactorsProps } from ".";

const iAuditorFactors: Record<string, ScoreFactorDetails> = {
  outstanding_inspections: {
    title: "Outstanding inspections",
  },
  incomplete_actions: {
    title: "Incomplete actions",
  },
};

const IAuditorFactors = ({ ...otherProps }: IAuditorFactorsProps) => {
  return <ScoreFactors factors={iAuditorFactors} {...otherProps} />;
};

interface IAuditorFactorsProps extends Omit<ScoreFactorsProps, "factors"> {}

IAuditorFactors.defaultProps = {};

export default IAuditorFactors;
