import styled from "styled-components";
import { Grid } from "../../util";

export const Detail = styled(Grid)`
  && {
    margin: 24px 0 16px 0;
  }
  div {
    margin-bottom: 20px;
  }
  a {
    white-space: pre-wrap;
  }
`;
