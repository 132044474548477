import { Header, Section } from "../../components/layout";
import { Grid } from "../../components/util";
import { OnboardingUnverified } from "../../components/content";

const Unverified = () => {
  return (
    <Grid container>
      <Header title={"Getting started with Care"} type="article"></Header>
      <Section>
        <OnboardingUnverified />
      </Section>
    </Grid>
  );
};
export default Unverified;
