import { useState } from "react";
import { FormRow, Grid, Select } from "../../../components/util";
import {
  nameSchema,
  emailSchema,
  policyNumberSchema,
  textDefaultSchema,
  industrySchema,
  orgTypeSchema,
} from "../../../validations";
import organisationService from "../../../services/organisation/organisation.service";
import { organisationTypeMap } from "../../../types/organisation.type";
import { AddressSearch } from "../../../components/content";
import { industry } from "../../../services/address/address.service";
import { organisationSize } from "../../../services/auth/auth.service";
import Header from "../../../components/layout/header";
import { useHistory } from "react-router-dom";
import * as Styled from "./styles.css";

const CreateOrg = () => {
  const history = useHistory();
  const backToOrg = (): any => {
    history.push({
      pathname: `/organisations`,
    });
  };

  const [createData] = useState<any>({
    name: "",
    email: "",
    industry: "",
    type: "orphan",
    abn: "",
    phone: "",
    organisation_size: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    post_code: "",
    country: "",
  });

  const [createErrors] = useState({
    name: true,
    email: true,
    industry: true,
    type: false,
    phone: true,
    address_1: true,
    city: true,
    state: true,
    post_code: true,
    country: true,
  });

  const [createErrorMsg] = useState({
    name: "",
    email: "",
    industry: "",
    type: "",
    phone: "",
    address_1: "",
    city: "",
    state: "",
    post_code: "",
    country: "",
  });

  const submit = async (formData: any) => {
    const data = { ...formData };

    await organisationService.create(data);
  };

  return (
    <Styled.Body container>
      <Header
        title="Create Organisation"
        titleIcon="organization"
        type="article"
      />
      <Styled.OrgForm
        onSubmit={{
          service: submit,
          onSuccess: () => backToOrg(),
        }}
        validationErrors={[createErrors]}
        validationFields={[createData]}
        submitText={"Create"}
        submitAlign="right"
        stickySubmit
      >
        <FormRow>
          <Styled.OrgTextField
            label="Organiastion name"
            id="name"
            type={"text"}
            value={createData.name}
            validationType={nameSchema}
            validationOnChange
            validationOnBlur
            helpText={createErrorMsg.name}
          />
        </FormRow>
        <FormRow>
          <Styled.OrgTextField
            label="Email"
            id="email"
            type={"text"}
            value={createData.email}
            validationType={emailSchema}
            validationOnChange
            validationOnBlur
            helpText={createErrorMsg.email}
          />
        </FormRow>

        <FormRow container>
          <Grid item xs={6}>
            <Styled.OrgTextField
              label="ABN"
              id="abn"
              optional
              type={"text"}
              value={createData.abn}
              validationType={policyNumberSchema}
              validationOnChange
              validationOnBlur
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              id="organisation_size"
              fullWidth
              label="Company size"
              optional
              placeholder="Select size..."
              size="lg"
              options={Object.values(organisationSize).map((size: string) => {
                return { title: size, value: size };
              })}
            />
          </Grid>
        </FormRow>

        <FormRow container>
          <Grid item xs={6}>
            <Select
              id="industry"
              fullWidth
              label="Industry"
              placeholder="Select industry..."
              size="lg"
              validationOnBlur
              validationType={industrySchema}
              options={Object.values(industry).map((industry: string) => {
                return { title: industry, value: industry };
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              id="type"
              fullWidth
              label="Organisation type"
              placeholder="Select type..."
              size="lg"
              validationOnBlur
              validationType={orgTypeSchema}
              options={organisationTypeMap}
            />
          </Grid>
        </FormRow>

        <FormRow>
          <Styled.OrgTextField
            label="Phone Number"
            id="phone"
            type={"text"}
            value={createData.phone}
            validationType={policyNumberSchema}
            validationOnChange
            validationOnBlur
            helpText={createErrorMsg.phone}
          />
        </FormRow>

        <Styled.FormFooter>
          <AddressSearch
            id="organisationAddress"
            label="Address"
            size="lg"
            validationType={textDefaultSchema}
          />
        </Styled.FormFooter>
      </Styled.OrgForm>
    </Styled.Body>
  );
};

export default CreateOrg;
