import styled from "styled-components";
import { theme } from "../../../styles";
import { Button, Container, Image, Typography } from "../../util";

export const MissingContainer = styled(Container).attrs((props: any) => ({}))`
  margin: 0 auto;
  padding: 40px;
  width: 420px;
`;

export const BeaverImage = styled(Image).attrs((props: any) => ({}))`
  height: 100px;
  width: 100px;
  margin-bottom: 30px;
`;

export const MessageTypography = styled(Typography).attrs((props: any) => ({}))`
  margin-bottom: 16px;
`;

export const BackButton = styled(Button).attrs((props: any) => ({}))`
  margin: 20px 0 40px 0;
`;

export const BoxedIcon = styled(Container)`
  background-color: ${theme.color.surface.background.weaker.default};
  border-radius: ${theme.border.radius.md};
  display: inline-block;
  line-height: 0;
  margin-bottom: 18px;
  padding: 10px;
`;
