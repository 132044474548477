import { useContext } from "react";
import { AuthContext } from "../../contexts";
import { Header } from "../../components/layout";
import { Grid } from "../../components/util";
import { VirtualSurveyWidget } from "../../components/content";

const Assessment = () => {
  const auth = useContext(AuthContext);

  return (
    <Grid container spacing={3}>
      <Header title="Book a virtual survey" type="article" />
      <Grid item md={12} xs={12}>
        <VirtualSurveyWidget
          email={auth.user?.email}
          organisation_id={auth.user?.organisations[0]?.id}
          name={auth.user.firstname + " " + auth.user.lastname}
          user_id={auth.user.id}
        />
      </Grid>
    </Grid>
  );
};
export default Assessment;
