import * as Styled from "./styles.css";

const Image = ({
  alt,
  aspectRatio,
  classes,
  isInCard,
  src,
  ...otherProps
}: ImageProps) => {
  return (
    <Styled.Picture
      aspectRatio={aspectRatio}
      className={classes?.picture}
      isInCard={isInCard}
      {...otherProps}
    >
      <Styled.Img
        alt={alt}
        aspectRatio={aspectRatio}
        className={classes?.img}
        src={src}
      />
    </Styled.Picture>
  );
};

export type AspectRatios = "1:1" | "4:3" | "3:2" | "16:9";

interface ImageProps {
  alt?: string;
  aspectRatio: AspectRatios;
  isInCard: boolean;
  src: string;
  classes?: {
    picture?: any;
    img?: any;
  };
}

Image.defaultProps = {
  alt: "",
  aspectRatio: undefined,
  isInCard: false,
  src: "",
};

export default Image;
