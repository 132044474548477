import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import useLocalStorageState from "use-local-storage-state";
import { breakpoints } from "../../../styles";
import { AuthContext, GuidanceContext } from "../../../contexts";
import { Button, Grid, Tab, TabPanel, Tabs, Typography } from "./../../util";
import * as Styled from "./styles.css";

const OnboardingNewBroker = () => {
  const auth = useContext(AuthContext);
  const { setNewBrokerClientProgress, setNewBrokerVirtualSurvey } =
    useContext(GuidanceContext);
  const isMediaMDUp = useMediaQuery({ query: breakpoints.up("md") });

  const [NBFDismissed, setNBFDismissed] = useLocalStorageState(
    `${auth.user.id}-NBFDismissed`,
    false,
  );
  const [BCVisited] = useLocalStorageState(`${auth.user.id}-BCVisited`, false);
  const [BCVSVisited] = useLocalStorageState(
    `${auth.user.id}-BCVSVisited`,
    false,
  );

  const handleDismiss = () => {
    // TODO: replace with Activity column in DB
    setNBFDismissed(true);
  };

  if (NBFDismissed) {
    return <></>;
  }

  return (
    <Styled.OnboardingContainer
      corners={isMediaMDUp ? "rounded" : "square"}
      outlined
    >
      <Styled.Header title="Getting started with Care"></Styled.Header>
      <Grid container>
        <Tabs
          id="newbroker"
          orientation="vertical"
          variant="contained"
          tabs={[
            <Tab
              complete={BCVisited}
              icon="checklist"
              id="client-progress"
              label={"Check on client progress"}
              onComplete={["disabled", "restricted"]}
            />,
            <Tab
              complete={BCVSVisited}
              icon="calendar"
              id="virtual-survey"
              label={"Schedule virtual survey"}
              onComplete={["disabled", "restricted"]}
            />,
            <Tab
              hidden
              icon="check"
              id="complete"
              label={"Onboarding Complete"}
              onComplete={["disabled", "restricted"]}
            />,
          ]}
        >
          <TabPanel id="client-progress">
            <Typography paragraph variant="heading">
              Check on your client's progress
            </Typography>
            <Styled.PanelBodyText paragraph>
              Help your clients identify ways they can make their business safer
              with easy to implement recommendations and SafetyCulture
              templates.
            </Styled.PanelBodyText>
            <Button
              color="accent"
              scrollTo="clientCount"
              variant="contained"
              onClick={() => setNewBrokerClientProgress(true)}
            >
              View clients
            </Button>
          </TabPanel>
          <TabPanel id="virtual-survey">
            <Typography paragraph variant="heading">
              Book a virtual survey
            </Typography>
            <Styled.PanelBodyText paragraph>
              Schedule a client for their first virtual survey – a Care risk
              consultant will virtually inspect your client’s premises and
              provide recommendations on how to improve their safety, including
              quick risk fixes, SafetyCulture inspections and checklists.
            </Styled.PanelBodyText>
            <Button
              color="accent"
              scrollTo="clientCount"
              variant="contained"
              onClick={() => setNewBrokerVirtualSurvey(true)}
            >
              Schedule survey
            </Button>
          </TabPanel>
          <TabPanel id="complete">
            <Typography paragraph variant="heading">
              You're all set with Care
            </Typography>
            <Styled.PanelBodyText>
              Thank you for getting started with Care.
            </Styled.PanelBodyText>
            <Styled.PanelBodyText paragraph>
              Come back regularly to continue mitigating risks for your clients.
            </Styled.PanelBodyText>
            <Button onClick={() => handleDismiss()} variant="outlined">
              Dismiss
            </Button>
          </TabPanel>
        </Tabs>
      </Grid>
    </Styled.OnboardingContainer>
  );
};

export default OnboardingNewBroker;
