import {
  RecommendationInterface,
  CompareRecommendationsInterface,
} from "../../../services/prismic/prismic.service";
import {
  Button,
  Card,
  Grid,
  Image,
  Typography,
} from "../../../components/util";

const Recommendations = ({
  data,
}: Record<string, CompareRecommendationsInterface | undefined>) => {
  return data ? (
    <>
      {data.recommendations.map((rec: RecommendationInterface) => {
        return (
          <Grid item xs={12} md={6} lg={4} key={rec.title}>
            <Card isInGrid isPadded>
              <Image src={rec.image} alt={rec.title} isInCard />
              <Typography paragraph variant="displaySmall">
                {rec.title}
              </Typography>
              <Typography paragraph>{rec.text}</Typography>
              <Button
                href={rec.button.href}
                variant="contained"
                color="accent"
                fullWidth
              >
                {rec.button.title}
              </Button>
            </Card>
          </Grid>
        );
      })}
    </>
  ) : (
    <></>
  );
};

export default Recommendations;
