import { Invitation } from "../../../services/invitation/invitation.service";
import { Grid } from "../../../components/util";
import { InviteUser, RecordMissing } from "../../../components/content/";
import * as Styled from "./styles.css";

const Invites = ({
  client,
  organisation_id,
  ...otherProps
}: ClientDetailsProps) => {
  return (
    <>
      <Styled.InvitationSection container>
        <Grid item md={5} xs={12}>
          {!client?.invitations?.length && (
            <RecordMissing
              icon="mail"
              title="Invite client to Care"
              message="Set your client up with Care to help them identify ways they can
                make their business safer with easy to implement
                recommendations."
            >
              <InviteUser action="send" policyId={client.id} type="client" />
            </RecordMissing>
          )}

          {client?.invitations?.some(
            (invite: Invitation) => invite.status === "active",
          ) && (
            <RecordMissing
              icon="mail"
              title="Your client has been invited"
              message="We have sent an invitation email to your client. Once they
                accept the invite, they will be able to access their account."
            >
              <InviteUser
                action="resend"
                invitation={client.invitations[0]}
                type="client"
              />
              <InviteUser
                action="cancel"
                invitation={client.invitations[0]}
                type="client"
              />
            </RecordMissing>
          )}
        </Grid>
      </Styled.InvitationSection>
    </>
  );
};

interface ClientDetailsProps {
  client: any;
  organisation_id: string;
}

export default Invites;
