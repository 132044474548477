import { ListItemText } from "@material-ui/core";
import {
  Placement,
  TIconShape,
  TStyleShade,
  TStyleConnotation,
} from "../../../types";
import { ContainerProps } from "../container/container";
import { ListVariants } from "./list";
import ListItemIcon from "./listItemIcon";
import * as Styled from "./styles.css";

const ListItem = ({
  children,
  color,
  completed,
  icon,
  iconShade,
  selected,
  size,
  text,
  variant,
  iconAlignment,
  ...otherProps
}: ListItemProps) => {
  return (
    <Styled.ListItem
      data-value={otherProps.dataValue}
      $color={color}
      $completed={completed}
      $selected={selected}
      $size={size}
      $variant={variant}
      {...otherProps}
    >
      {icon && (
        <ListItemIcon
          color={color}
          shade={iconShade}
          icon={icon}
          size={size}
          placement={iconAlignment}
        />
      )}
      {text && <ListItemText>{text}</ListItemText>}
      {children && children}
    </Styled.ListItem>
  );
};

export interface ListItemProps extends ContainerProps {
  color?: TStyleConnotation;
  completed: boolean;
  dataValue?: any;
  icon?: TIconShape;
  iconShade?: TStyleShade;
  selected: boolean;
  size: "md" | "sm";
  text?: string;
  value?: number | string;
  variant: ListVariants;
  iconAlignment?: Placement;
}

ListItem.defaultProps = {
  completed: false,
  element: "li",
  selected: false,
  size: "md",
  variant: "plain",
};

export type HTMLTags = "li" | "div";

export default ListItem;
