import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";

import { AuthContext } from "../../contexts";
import { Header, Section } from "../../components/layout";
import OrganisationService from "../../services/organisation/organisation.service";
import { JfsFormData, JfsSchema } from "../../types";
import Form from "../../components/content/flow/monoFlow";
import { withSchema, withUiSchema, updateEmails } from "./inviteSchema";
import { hasAllowedRoles } from "../../helpers/roles";
import CircularProgress from "../../components/util/progress/circular";
import { RecordMissing } from "../../components/content";
import { IOrganisation } from "../../services/admin/admin.service";

import InvitationService, {
  Invitation,
  InvitationPrefill,
  EmailCcPrefill,
} from "../../services/invitation/invitation.service";
import { InviteGrid } from "./styles.css";

const InvitationCreate = () => {
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const [formData, setFormData] = useState<JfsFormData>();
  const [uiSchema, setUiSchema] = useState<JfsSchema>();
  const { search, state = {} } = useLocation();
  const query = new URLSearchParams(search);
  const orgId = query.get("orgId");
  const policyId = query.get("policyId");
  const { type, data, successRoute } = state as State;

  const isPrivilegedUser = hasAllowedRoles(user.roles, {
    allowed: ["admin", "super_admin"],
  });

  const {
    data: prefill,
    isFetching,
    isError,
  } = useQuery(
    ["invitation", "create"],
    async () => {
      let prefill: InvitationPrefill | undefined;

      if (orgId != null) {
        prefill = await InvitationService.fetchPrefillForOrg(orgId);
      } else if (policyId != null) {
        prefill = await InvitationService.fetchPrefillForPolicy(policyId);
      } else if (data != null) {
        prefill = data;
      }

      setInitialUiSchema({ prefill });

      return prefill;
    },
    { cacheTime: 0, retry: false },
  );

  const schema = withSchema({ type, isPrivilegedUser });

  const handleChange = ({ formData }: { formData?: any }) =>
    setFormData(formData);

  const senderOrgIdOnChange = async (selectedOrgId: string) => {
    let emailsCc: EmailCcPrefill | undefined;

    if (orgId != null) {
      emailsCc = await InvitationService.fetchPrefillEmailCcForOrg(
        selectedOrgId,
      );
    } else if (policyId != null) {
      emailsCc = await InvitationService.fetchPrefillEmailCcForPolicy({
        policyId,
        orgId: selectedOrgId,
      });
    }

    setUiSchema(state => updateEmails(state, "email_cc", emailsCc));

    if (isPrivilegedUser)
      setUiSchema(state => updateEmails(state, "email_bcc", emailsCc));
  };

  const generateInitialSelected = (
    prefill: InvitationPrefill | undefined,
  ): Pick<IOrganisation, "id" | "name"> | undefined => {
    if (prefill == null) return;

    // check data before prefill
    if (data != null) {
      if (
        prefill?.fields.sender_org_id != null &&
        prefill?.meta.sender_org_name != null
      ) {
        return {
          id: prefill.fields.sender_org_id,
          name: prefill.meta.sender_org_name,
        };
      }
      // check prefill after data
    } else if (prefill != null) {
      if (prefill.fields.sender_org_id != null) {
        return {
          id: prefill.fields.sender_org_id,
          name: prefill.meta.sender_org_name,
        };
      }
    }
  };

  const generateInitialEmails = (
    prefill: InvitationPrefill | undefined,
  ): EmailCcPrefill | undefined => {
    if (prefill == null) return;

    // check data before prefill
    if (data != null) {
      return prefill.fields.email_cc?.map((email: string) => ({
        email,
      }));
      // check prefill after data
    } else if (prefill != null) {
      return prefill.meta.email_cc;
    }
  };

  const setInitialUiSchema = ({
    prefill,
  }: {
    prefill: InvitationPrefill | undefined;
  }) =>
    setUiSchema(
      withUiSchema({
        senderOrgId: {
          search: async (input: string): Promise<IOrganisation[] | undefined> =>
            (
              await OrganisationService.getAllOrganisationsBySearchTerm(
                input,
                0,
                100,
                { filter: ["users"] },
              )
            )?.hits,
          onChange: senderOrgIdOnChange,
          getOptionLabel: item => item.name,
          getOptionSubLabel: item => item.id,
          getOptionValue: item => item.id,
          initialSelected: generateInitialSelected(prefill),
        },
        emailCc: {
          emails: generateInitialEmails(prefill),
        },
        isPrivilegedUser,
      }),
    );

  if (isFetching || uiSchema == null) {
    return <CircularProgress />;
  } else if (isError) {
    return (
      <RecordMissing
        icon="mail"
        message="Cannot prefill the invitation form with organisation's details."
        type="organisation's details"
      />
    );
  }

  const onSubmit = async (submitData: JfsFormData): Promise<void> => {
    const invite: Omit<Invitation, "status"> = {
      firstname: submitData.firstname,
      lastname: submitData.lastname,
      email: submitData.email,
      policy_number: isPrivilegedUser
        ? submitData.policy_number
        : prefill?.fields.policy_number,
      customer_org_name: prefill?.fields.customer_org_name,
      role: isPrivilegedUser ? submitData.role : prefill?.fields.role,
      data: {
        scheme: submitData.scheme,
      },
      sender_org_id: isPrivilegedUser
        ? submitData.sender_org_id
        : prefill?.fields.sender_org_id,
      sender_first_name: submitData.sender_first_name,
      sender_last_name: submitData.sender_last_name,
      email_cc: submitData.email_cc,
      email_bcc: submitData.email_bcc,
    };

    const created = await InvitationService.createInvitation(invite);

    if (created) {
      history.replace(successRoute);
    }
  };

  return (
    <InviteGrid>
      <Header
        title="Create Invitation"
        titleIcon="mail"
        titleIconPosition="end"
        type="article"
        withLink={{ path: "/invitations", text: "All Invitations" }}
      />

      <Section>
        <Form
          id="invitation-create"
          name="Create Invitation"
          schema={schema}
          onChange={handleChange}
          onSubmit={onSubmit}
          formData={formData ?? prefill?.fields}
          uiSchema={uiSchema}
        />
      </Section>
    </InviteGrid>
  );
};

type State = {
  type: "user" | "client";
  data?: InvitationPrefill;
  successRoute: string;
  refetchQuery?: string;
};

export default InvitationCreate;
