import styled from "styled-components";
import { theme } from "../../../../../styles";
import { Tab } from "../../../../util";

export const FlowTab = styled(Tab).attrs((props: any) => ({
  $hidden: props.$hidden,
  $orientation: props.$orientation,
  $scrolling: props.$scrolling,
  $selected: props.$selected,
  $status: props.$status,
  $variant: props.$variant,
}))`
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 12px;
  white-space: nowrap;

  border-radius: 1000px;
  background-color: ${({ $selected, $status }) => {
    switch ($status) {
      case "error":
        if ($selected) {
          return theme.color.negative.background.weakest.default;
        }
        return theme.color.surface.background.default.default;
      default:
        if ($selected) {
        }
        return theme.color.surface.background.default.default;
    }
  }};

  &:hover {
    background-color: ${({ $selected, $status }) => {
      switch ($status) {
        case "error":
          return theme.color.negative.background.weakest.hover;
        default:
          if ($selected) {
            return theme.color.negative.background.weakest.hover;
          }
          return theme.color.surface.background.default.default;
      }
    }};
  }

  & button {
    color: ${({ $selected, $status }) => {
      switch ($status) {
        case "error":
          return theme.color.negative.text.weak.default;
        default:
          if ($selected) {
            return theme.color.accent.text.default.default;
          }
          return theme.color.surface.text.weak.default;
      }
    }};
    &:hover:not([disabled]) {
      color: ${({ $selected, $status }) => {
        switch ($status) {
          case "error":
            return theme.color.negative.text.weak.default;
          default:
            if ($selected) {
              return theme.color.accent.text.default.hover;
            }
            return theme.color.surface.text.weak.hover;
        }
      }};
    }
  }
`;
