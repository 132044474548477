import { IconButton, Select } from "../../../util";
import toastEvent from "../../../util/toast/toast.event";
import { Industry } from "../../../../services/industries/industries.service";
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import * as Styled from "./styles.css";

const EditInlineSelect = ({
  field,
  getList,
  listPruner,
  onClose,
  onSubmitService,
  onSubmitRefresh,
  record,
  recordType,
  toggleEditing,
  validationSchema,
  ...otherProps
}: iEditInlineSelectProps) => {
  const listQueryString = "list-query";
  const [selectOptionData, setSelectOptionData] = useState<any>();
  const { data: listData } = useQuery<Industry | any | undefined>(
    [listQueryString],
    async () => getList(),
  );

  useEffect(() => {
    if (listData) {
      if (listPruner) {
        setSelectOptionData(listPruner(listData));
      }
    }
  }, [listData, listPruner]);

  const setErrors = (editDetails: any): Record<string, boolean> => {
    const errors = {};
    for (const key in editDetails) {
      if (key === field) {
        errors[key] = true;
      } else {
        errors[key] = false;
      }
    }
    return errors as Record<string, boolean>;
  };

  const editErrorsInit = setErrors(record);
  const editDataInit: Record<string, string> = {
    ...record,
  };

  const handleSubmit = async formData => {
    try {
      await onSubmitService(formData);
    } catch (error: any) {
      throw Error(error.message);
    }
  };

  return (
    <>
      <Styled.InlineForm
        enableSubmitAlways
        fullWidth={false}
        inline
        onSubmit={{
          service: handleSubmit,
          onSuccess: () => {
            onSubmitRefresh();
            toastEvent.success(`${recordType} updated`);
            toggleEditing();
          },
        }}
        validationErrors={[editErrorsInit]}
        validationFields={[editDataInit]}
        submitInline
        submitAlign="right"
        submitStartDisabled
        submitText="Save"
      >
        {selectOptionData && (
          <Select
            id={field}
            fullWidth
            validationOnChange
            validationType={validationSchema}
            options={selectOptionData}
            grow
          />
        )}
      </Styled.InlineForm>
      <IconButton iconShade="weak" icon="x" onClick={() => toggleEditing()} />
    </>
  );
};

interface iEditInlineSelectProps {
  field: any;
  getList: any;
  listPruner?: any;
  onClose: () => void;
  onSubmitService: (any) => Promise<any>;
  onSubmitRefresh: () => Promise<void>;
  toggleEditing: () => void;
  record: Record<string, any>;
  recordType: string;
  multiLevelForm?: string;
  validationSchema: any;
}

export default EditInlineSelect;
