import { createGlobalStyle } from "styled-components";
import { theme } from "./themes";
import breakpoints from "./breakpoints";
import "./fonts.css";

export const CSSReset = createGlobalStyle`
  * {
    border: 0;
    box-sizing: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    text-decoration: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-appearance: none;
    -moz-appearance: none;

    /* Box sizing rules */
    &::before {
      box-sizing: border-box;
    }
    &::after {
      box-sizing: border-box;
    }
  }

  html {
    box-sizing: border-box;
    display: flex;
    font-family: ${theme.typography.fontFamily.sans};
    font-size: ${theme.typography.body.fontSize};
    font-weight: ${theme.typography.body.desktop.fontWeight};
    line-height: ${theme.typography.body.lineHeight};
    color: ${theme.color.surface.text.default.default};
    min-height: 100%;
    width: 100%;
    -webkit-text-size-adjust: 100%;
    @media ${breakpoints.up("lg")} {
      font-size: ${theme.typography.body.desktop.fontSize};
      font-weight: ${theme.typography.body.desktop.fontWeight};
      letter-spacing: ${theme.typography.body.desktop.letterSpacing};
      line-height: ${theme.typography.body.desktop.lineHeight};
    }
  }

  body {
    box-sizing: border-box;
    height: 100%;
    overscroll-behavior-y: none;
    width: 100%;
    -webkit-overflow-scrolling: touch;
  }

  a {
    color: ${theme.color.accent.text.default.default};
    text-decoration: none;
    &:visited {
      color: ${theme.color.accent.text.default.default};
    }
  }

  input, button, select, textarea, optgroup, option {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
  }
`;
