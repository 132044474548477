import styled from "styled-components";
import { breakpoints } from "../../../../styles";
import { Grid, Typography } from "../../../util";

export const FactorsContainer = styled(Grid).attrs((props: any) => ({
  $orientation: props.$orientation,
}))`
  ${(props: any) => {
    switch (props.$orientation) {
      case "horizontal":
        return `
          margin-top: 32px;
        `;
      case "vertical":
        return `
          @media ${breakpoints.up("xxl")} {
            margin-top: 94px;
          }
        `;
    }
  }}
`;

export const Factor = styled(Grid)`
  justify-content: space-between;
  margin-bottom: 32px;
  &:last-of-type {
    margin-bottom: 0;
  }

  & > :not(:first-child) {
    margin-top: 8px;
  }

  @media ${breakpoints.up("md")} {
    & > :not(:first-child) {
      margin-top: 0px;
    }
  }
`;

export const Title = styled(Typography)`
  margin-bottom: 8px;
  @media ${breakpoints.up("md")} {
    margin-bottom: 10px;
  }
`;

export const Current = styled(Typography)`
  display: inline-block;
`;

export const Max = styled(Typography)`
  display: inline-block;
`;
