import { useReducer } from "react";
import { useDebouncedCallback } from "use-debounce";
import axios from "../../axios";

export enum actionTypes {
  FETCH_REQUEST = "FETCH_REQUEST",
  FETCH_SUCCESS = "FETCH_SUCCESS",
  FETCH_FAILURE = "FETCH_FAILURE",
}

/**
 *To display on the drop down
 */

const AddressService = {
  FindExternalAddress() {
    const [{ isLoading, error, data }, dispatch] = useReducer(
      reducer,
      initialAddressSearchState,
    );

    const getExternalAddressList = useDebouncedCallback(
      async (searchString: string) => {
        if (searchString.length > minSearchLength) {
          dispatch({ type: actionTypes.FETCH_REQUEST });
          try {
            const response: iAddressListOption[] | null = await axios.get(
              `/address/external/search/${encodeURIComponent(searchString)}`,
            );

            dispatch({
              type: actionTypes.FETCH_SUCCESS,
              results: response,
            });
          } catch (error) {
            dispatch({ type: actionTypes.FETCH_FAILURE, error });
          }
        }
      },
      debounceTime,
    );

    return { error, isLoading, data, getExternalAddressList };
  },
};

function reducer(state = initialAddressSearchState, action: any) {
  switch (action.type) {
    case actionTypes.FETCH_REQUEST:
      return { ...state, isLoading: true };
    case actionTypes.FETCH_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        data: action.results.data ? action.results.data : null,
      };
    case actionTypes.FETCH_FAILURE:
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export const getExternalPlaceDetails = async (placeID: string) => {
  try {
    const response = await axios.get(`/address/external/details/${placeID}`);

    return response.data;
  } catch (error) {
    return null;
  }
};

const debounceTime: number = 500;
const minSearchLength: number = 3;

let initialAddressSearchState = {
  isLoading: false,
  error: false,
  data: [],
};

export enum industry {
  CONSTRUCTION = "Construction",
  MANUFACTURING = "Manufacturing",
  RETAIL = "Retail",
  HOSPITALITY = "Hospitality",
  OTHER = "Other",
}

export default AddressService;

export interface iAddressListOption {
  title: string;
  address_full: string;
  value: string;
}

export interface iAddressFields {
  address_1: string;
  address_2?: string;
  city: string;
  state: string;
  country: string;
  post_code: string;
}
