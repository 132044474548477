import styled from "styled-components";
import Grid from "../../../grid";

export const FormRow = styled(Grid).attrs((props: any) => ({
  $textAlign: props.$textAlign,
}))`
  margin-bottom: ${(props: any) => (props.container ? "12px" : "24px")};
  text-align: ${props => {
    switch (props.$textAlign) {
      case "left":
        return "left";
      case "center":
        return "center";
      case "right":
        return "right";
    }
  }};
`;
