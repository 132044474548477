import { WidgetProps } from "@rjsf/utils";
import { handleInputValue } from "./../../../../helpers";
import * as Styled from "./styles.css";

function JfsTextarea({
  schema,
  id,
  grow,
  options,
  label,
  value,
  type,
  placeholder,
  required,
  disabled,
  readonly,
  autofocus,
  onChange,
  onBlur,
  onFocus,
  rawErrors,
  hideError,
  uiSchema,
  registry,
  size,
  formContext,
  ...otherProps
}: JfsTextareaProps) {
  const onTextChange = ({
    target: { value: val },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(handleInputValue(val, options));
  };

  return (
    <Styled.TextareaContainer data-value={value} $size={size}>
      <Styled.Textarea
        id={id}
        data-error={rawErrors}
        data-format={schema.format}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onTextChange}
        $editable={true}
        $error={rawErrors}
        $size={size}
        $type={type}
        {...otherProps}
      />
    </Styled.TextareaContainer>
  );
}

interface JfsTextareaProps extends WidgetProps {}

JfsTextarea.defaultProps = {
  disabled: false,
  editable: true,
  error: false,
  fullWidth: true,
  size: "md",
  value: "",
};

export default JfsTextarea;
