import { Link, Typography } from "../../../components/util";

const AuthErrorFooter = () => {
  return (
    <Typography variant="body2">
      Please email{" "}
      <Link
        href={`mailto:${process.env.REACT_APP_CARE_SUPPORT_EMAIL}`}
        isNewTab
      >
        {process.env.REACT_APP_CARE_SUPPORT_EMAIL}
      </Link>{" "}
      if you feel this is an error.
    </Typography>
  );
};

export default AuthErrorFooter;
