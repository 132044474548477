import { TableBody, TableHead } from "..";
import { Orientation } from "../../../types";
import { ContainerProps } from "../container/container";
import * as Styled from "./styles.css";

const Table = ({
  children,
  columns,
  orientation,
  ...otherProps
}: TableProps) => {
  return (
    <Styled.Table
      element="table"
      $columns={columns}
      $orientation={orientation}
      {...otherProps}
    >
      {orientation === "vertical" && <TableHead columns={columns} />}
      <TableBody>{children && children}</TableBody>
    </Styled.Table>
  );
};

interface TableProps extends ContainerProps {
  columns: ColumnProps[];
  orientation: Orientation;
}

export interface ColumnProps {
  collapse?: boolean;
  title: string;
  expand?: boolean;
}

Table.defaultProps = {
  orientation: "vertical",
};

export default Table;
