import { ComponentProps } from "react";
import Select from "react-select";

import { ComponentSize } from "../../../types";
import { default as Control } from "./components/control";
import { default as ValueContainer } from "./components/valueContainer";
import * as Styled from "./styles.css";

function Combobox({
  onChange,
  placeholder,
  size,
  value,
  options,
  ...otherProps
}: ComboboxProps) {
  const handleChange = (
    selectedOption: ComboboxOption | ComboboxOption[],
  ): void => {
    if ("value" in selectedOption) {
      onChange(selectedOption.value);
    } else {
      const multiArray: ComboboxValue = [];
      selectedOption.forEach((option: ComboboxOption, i: number) =>
        multiArray.push(option.value),
      );
      onChange(multiArray);
    }
  };

  const getValueFromOptions = (
    values: ComboboxValue,
    options: ComboboxOption[],
    multi: boolean,
  ): ComboboxOption | ComboboxOption[] | undefined => {
    if (value === undefined) {
      return undefined;
    }
    if (multi) {
      let newValues = values as string[];
      const selectedOptions: ComboboxOption[] = JSON.parse(
        JSON.stringify(options),
      );
      selectedOptions.forEach((option: ComboboxOption, i: number) => {
        if (!newValues.find((val: string) => val === option.value)) {
          delete selectedOptions[i];
        }
      });
      return selectedOptions;
    }
    return options.find(x => x.value === value) as ComboboxOption;
  };

  return (
    <Styled.ComboBox
      components={{ Control, ValueContainer }}
      classNamePrefix="combobox"
      name={otherProps?.schema?.title || otherProps.id}
      onChange={(selected: any) => handleChange(selected)}
      placeholder={
        placeholder || otherProps.isMulti ? "Select any..." : "Select one..."
      }
      size={size}
      value={getValueFromOptions(value, options, !!otherProps.isMulti)}
      $isValid={otherProps.isValid}
      options={options}
      isSearchable
      {...otherProps}
    />
  );
}

export interface ComboboxProps extends ComponentProps<typeof Select> {
  id: string;
  isMulti?: boolean;
  isSearchable?: boolean;
  isValid?: boolean;
  options: ComboboxOption[];
  onChange: any;
  placeholder?: string;
  schema?: Record<any, any>;
  size?: ComponentSize;
  value: any;
}

export type ComboboxValue = string | string[] | undefined;
export interface ComboboxOption {
  value: string;
  label: string;
}

Combobox.defaultProps = {
  isMulti: false,
  isSearchable: false,
  isValid: true,
  size: "md",
};

export default Combobox;
