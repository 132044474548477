import { ReactElement } from "react";
import { getDefaultRegistry } from "@rjsf/core";
import { ObjectFieldTemplateProps, getUiOptions } from "@rjsf/utils";

import * as Styled from "./styles.css";

const ObjectField = (props: ObjectFieldTemplateProps): ReactElement => {
  const registry = getDefaultRegistry();
  const { ObjectFieldTemplate } = registry.templates;
  const options = getUiOptions(props.uiSchema);
  const sections = options["sections"] as Sections;

  if (sections != null) {
    return (
      <>
        {sections.map(section => {
          const fieldProps = {
            ...props,
            title: section.title ?? props.title,
            properties: props.properties.filter(property =>
              section.fields.includes(property.name),
            ),
          };

          return section.standout ? (
            <Styled.SectionContainer
              key={section.title}
              corners="rounded"
              padding
            >
              <ObjectFieldTemplate {...fieldProps} />
            </Styled.SectionContainer>
          ) : (
            <ObjectFieldTemplate key={section.title} {...fieldProps} />
          );
        })}
      </>
    );
  } else {
    return <ObjectFieldTemplate {...props} />;
  }
};

type Sections = Array<{
  key: string;
  title?: string;
  fields: string[];
  standout?: boolean;
}>;

export default ObjectField;
