import { JfsSchema, JfsUiSchema } from "../../../../types";

export const schema: JfsSchema = {
  type: "object",
  required: ["do_not_invite"],
  properties: {
    do_not_invite: {
      type: "boolean",
      title: "Do not invite",
      default: false,
      enum: [false, true],
    },
  },
};

export const uiSchema: JfsUiSchema = {
  "ui:order": ["do_not_invite"],
  do_not_invite: {
    "ui:autofocus": true,
  },
};
