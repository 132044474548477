import { ContainerProps } from "../container/container";
import Icon from "../icons/icon";
import ListItem from "../lists/listItem";
import * as Styled from "./styles.css";

const InputHelpText = ({
  children,
  element,
  error,
  helpList,
  paragraph,
  validationList,
  ...otherProps
}: InputHelpTextProps) => {
  return !validationList ? (
    <>
      {Object.keys(helpList).map((key: any, i: number) => (
        <Styled.InputHelpTypography
          key={i}
          variant="body2"
          paragraph={paragraph}
          styled={{
            text: {
              color: error ? "negative" : undefined,
              shade: error ? undefined : "weak",
            },
          }}
          {...otherProps}
        >
          <Icon color="negative" icon="alert" size="sm" />
          <span dangerouslySetInnerHTML={{ __html: helpList[key] }} />
        </Styled.InputHelpTypography>
      ))}
    </>
  ) : (
    <>
      {helpList?.empty || helpList ? (
        <Styled.InputHelpList
          $color={error ? "error" : undefined}
          $columns={Object.keys(validationList).length > 3 ? 2 : 1}
          paragraph
          {...otherProps}
        >
          {Object.keys(validationList).map((key: any, i: number) => (
            <ListItem
              icon={
                (helpList && validationList[key] === helpList[key]) ||
                helpList.empty
                  ? "dot"
                  : "check"
              }
              completed={
                (!helpList.empty && validationList[key] !== helpList[key]) ||
                (helpList.empty && validationList[key] === helpList[key])
              }
              size="sm"
              key={i}
            >
              {validationList[key]}
            </ListItem>
          ))}
        </Styled.InputHelpList>
      ) : (
        <Styled.InputHelpAlert color="positive">
          Nice work. Your {`password`} is good.
        </Styled.InputHelpAlert>
      )}
    </>
  );
};

interface InputHelpTextProps extends ContainerProps {
  error: boolean;
  helpList?: any;
  paragraph: boolean;
  required: boolean;
  validationList?: any;
}

InputHelpText.defaultProps = {
  error: false,
  paragraph: false,
  required: false,
};

export default InputHelpText;
