import { CSSProperties } from "react";

import {
  ArrayFieldTemplateItemType,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
} from "@rjsf/utils";

import * as Styled from "./styles.css";

function ArrayFieldItemTemplate<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any,
>({
  children,
  className,
  disabled,
  hasToolbar,
  hasMoveDown,
  hasMoveUp,
  hasRemove,
  hasCopy,
  index,
  onCopyIndexClick,
  onDropIndexClick,
  onReorderClick,
  readonly,
  registry,
  uiSchema,
}: ArrayFieldTemplateItemType<T, S, F>) {
  const {
    ButtonTemplates: { CopyButton, MoveDownButton, MoveUpButton, RemoveButton },
  } = registry.templates;

  const btnStyle: CSSProperties = {
    flex: 1,
    paddingLeft: 6,
    paddingRight: 6,
    fontWeight: "bold",
  };

  return (
    <Styled.Container>
      <div className={hasToolbar ? "col-xs-9" : "col-xs-12"}>{children}</div>

      {hasToolbar && (
        <div className="col-xs-3 array-item-toolbox">
          <div
            className="btn-group"
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            {(hasMoveUp || hasMoveDown) && (
              <MoveUpButton
                style={btnStyle}
                disabled={disabled || readonly || !hasMoveUp}
                onClick={onReorderClick(index, index - 1)}
                uiSchema={uiSchema}
                registry={registry}
              />
            )}

            {(hasMoveUp || hasMoveDown) && (
              <MoveDownButton
                style={btnStyle}
                disabled={disabled || readonly || !hasMoveDown}
                onClick={onReorderClick(index, index + 1)}
                uiSchema={uiSchema}
                registry={registry}
              />
            )}

            {hasCopy && (
              <CopyButton
                style={btnStyle}
                disabled={disabled || readonly}
                onClick={onCopyIndexClick(index)}
                uiSchema={uiSchema}
                registry={registry}
              />
            )}

            {hasRemove && (
              <RemoveButton
                style={btnStyle}
                disabled={disabled || readonly}
                onClick={onDropIndexClick(index)}
                uiSchema={uiSchema}
                registry={registry}
              />
            )}
          </div>
        </div>
      )}
    </Styled.Container>
  );
}

export default ArrayFieldItemTemplate;
