import React from "react";
import {
  AccordionDetails as MuiAccordionDetails,
  AccordionDetailsProps as MuiAccordionDetailsProps,
  makeStyles,
} from "@material-ui/core";
import { theme } from "../../../styles";

const styles = makeStyles(() => ({
  root: {
    padding: `0 ${theme.spacing} ${theme.spacing} ${theme.spacing}`,
    "&.Mui-expanded": {},
    "& p": {},
  },
}));

export interface AccordionDetailsProps
  extends Omit<MuiAccordionDetailsProps, ""> {}

const AccordionDetails = (props: AccordionDetailsProps) => {
  const classes = styles();
  return (
    <MuiAccordionDetails classes={{ ...classes, ...props.classes }} {...props}>
      {props.children && props.children}
    </MuiAccordionDetails>
  );
};

AccordionDetails.defaultProps = {};
export default AccordionDetails;
