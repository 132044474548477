import React, { ReactNode, useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import useHandleClickOutside from "./useHandleClickOutside";
import Container, { ContainerProps } from "../container/container";
import { Placement } from "../../../types";
import * as Styled from "./styles.css";

const Popover = ({
  alignment,
  children,
  offsetX,
  offsetY,
  onClickOutside,
  open,
  placement,
  trigger,
  ...otherProps
}: PopoverProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(!!open);
  const referenceRef = useRef(null);
  const popperRef = useRef(null);
  const { ref: DropDownRef } = useHandleClickOutside(setIsOpen);

  useEffect(() => {
    if (!isOpen && onClickOutside) {
      onClickOutside();
    }
  }, [isOpen, onClickOutside]);

  const { styles, attributes, update } = usePopper(
    referenceRef.current,
    popperRef.current,
    {
      placement: `${placement}${alignment && "-" + alignment}` as Placement,
      modifiers: [
        {
          name: "offset",
          enabled: true,
          options: {
            offset: [offsetX, offsetY],
          },
        },
      ],
    },
  );

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
    update && update();
  };

  function handleDropdownClick(e: any) {
    e.preventDefault();
    toggleIsOpen();
  }

  return (
    <Container ref={DropDownRef} {...otherProps}>
      {trigger &&
        React.Children.map<ReactNode, ReactNode>(trigger, (child, i) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              ref: referenceRef,
              onClick: handleDropdownClick,
            } as any);
          }
        })}
      <Styled.PopoverContainer
        open={isOpen}
        ref={popperRef}
        style={{
          ...styles.popper,
          minWidth: DropDownRef?.current?.scrollWidth,
        }}
        {...attributes.popper}
      >
        {children &&
          React.Children.map<ReactNode, ReactNode>(children, child => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                onClick: handleDropdownClick,
              } as any);
            }
          })}
      </Styled.PopoverContainer>
    </Container>
  );
};

export interface PopoverProps extends ContainerProps {
  alignment: "start" | "end" | "";
  offsetX?: number;
  offsetY?: number;
  onClickOutside?: () => void;
  open?: boolean;
  placement: Placement;
  trigger?: React.ReactNode;
}

Popover.defaultProps = {
  alignment: "",
  offsetX: 0,
  offsetY: 0,
  placement: "bottom",
};

export default Popover;
