import { Typography } from "../../../components/util";
import AuthContainer from "../authContainer";
import AuthErrorFooter from "./authErrorFooter";
import * as Styled from "./styles.css";

const InvitationClaimed = () => {
  return (
    <AuthContainer
      headerTitle="Invitation already claimed"
      footerContent={<AuthErrorFooter />}
      preventRedirect
    >
      <Typography paragraph styled={{ text: { shade: "weak" } }}>
        Looks like your invitation has already been claimed.
      </Typography>
      <Typography paragraph styled={{ text: { shade: "weak" } }}>
        Contact your broker to get a new invitation.
      </Typography>
      <Styled.LastButton href="/login" variant="outlined">
        Go to Log in page
      </Styled.LastButton>
    </AuthContainer>
  );
};

export default InvitationClaimed;
