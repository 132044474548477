import styled from "styled-components";
import { theme } from "../../../../../styles";
import { Container, Typography } from "../../..";

export const DescriptionContainer = styled(Container).attrs(
  (props: any) => ({}),
)`
  margin-bottom: 16px;
`;

export const Description = styled(Typography).attrs((props: any) => ({}))`
  color: ${theme.color.surface.text.strong.default};
  font-size: 14px;
  line-height: 20px;
`;
