import styled from "styled-components";
import { theme } from "../../../../../styles/themes";
import { Button, Grid, Skeleton, Typography } from "../../../../util";
import { TypographyProps as MuiTypographyProps } from "@material-ui/core";

interface BestPracticeProps extends MuiTypographyProps {
  $bestPractice?: number;
}

export const Title = styled(Grid)`
  margin-bottom: 20px;
`;

export const ScoreAverage = styled(Grid)`
  margin-bottom: 16px;
  & .MuiGrid-item {
    display: flex;
    align-items: center;
  }
`;

export const ProgressBestPractice = styled(Grid)<ProgressBestPracticeProps>`
  height: 40px;
  height: ${({ $bestPractice }) => ($bestPractice > 0 ? `40px` : `auto`)};
  position: relative;
`;

export const BestPractice = styled(Typography)<BestPracticeProps>`
  border-right: 1px solid ${theme.color.surface.border.strong.default};
  padding-right: 8px;
  padding-top: 20px;
  position: absolute;
  top: 0px;
  right: ${({ $bestPractice }) => $bestPractice && `${100 - $bestPractice}%`};

  ${({ $bestPractice }) =>
    (!$bestPractice || $bestPractice === 0) &&
    `
      display: none;
  `};
`;

export const Footnote = styled(Grid)`
  color: ${theme.color.surface.text.weaker.default};
  margin-top: 16px;
  max-width: 520px;
`;

export const ScoreSkeleton = styled(Skeleton)`
  width: 40px;
  height: 30px;
`;

export const AverageSkeleton = styled(Skeleton)`
  width: 120px;
  height: 16px;
  margin-left: 5px;
  border-radius: 10px;
`;

export const BestScoreSkeleton = styled(Grid)`
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
`;

export const SMProgressBarSkeleton = styled(Skeleton)`
  margin-top: 20px;
  height: 12px;
  margin-bottom: 100px;
`;

export const InspectionButton = styled(Button)`
  margin-top: 10px;
`;

interface ProgressBestPracticeProps {
  $bestPractice: number;
}
