import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Styled from "./styles.css";

const SearchBar = ({ searchTerm }: iSearchBarProps) => {
  const history = useHistory();
  const [xVisibility, setXVisibility] = useState<boolean>(true);
  const [searchText, setSearchText] = useState(searchTerm);

  const handleInputChange = (event: any) => {
    setSearchText(event.target.value);
  };

  const handleClear = (event: any) => {
    event.preventDefault();
    setXVisibility(true);
    setSearchText("");
    history.push({
      pathname: history.location.pathname,
    });
  };

  useEffect(() => {
    if (searchText && searchText.length > 0) setXVisibility(false);
    else setXVisibility(true);
  }, [searchText]);

  const searchHanlder = () => {
    if (searchText)
      history.push({
        pathname: history.location.pathname,
        search: `?searchTerm=${encodeURIComponent(searchText)}`,
      });
    else
      history.push({
        pathname: history.location.pathname,
      });
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      searchHanlder();
    }
  };

  return (
    <Styled.FormContainer>
      <Styled.SearchIcon
        iconShade="weak"
        icon="search"
        onClick={searchHanlder}
      />
      <Styled.SearchTextfield
        type="text"
        value={searchText}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        placeholder="Search"
      />
      <Styled.Close
        iconShade="weak"
        icon="x"
        $visibility={xVisibility}
        onClick={handleClear}
      />
    </Styled.FormContainer>
  );
};

interface iSearchBarProps {
  searchTerm: string;
}

export default SearchBar;
