import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useUnleashClient } from "@unleash/proxy-client-react";

import { RouteInterface } from "./routes";
import ProtectedRoute from "./protectedRoute";
import Framing from "./../components/layout/framing";
import { PageNotFound } from "../pages";
import { LocationContextProvider } from "../contexts";

const Router = ({ routes }: RouterProps) => {
  const unleashClient = useUnleashClient();

  return (
    <BrowserRouter basename="/">
      <Switch>
        {routes
          .filter(
            route =>
              route.featureFlag == null ||
              unleashClient.isEnabled(route.featureFlag),
          )
          .map((route, i) => {
            if (route.roles) {
              return (
                <ProtectedRoute
                  exact
                  path={route.path}
                  redirect={route.pathRedirect}
                  roles={route.roles}
                  key={i}
                >
                  <LocationContextProvider>
                    <Framing route={route} routes={routes}>
                      {route.component}
                    </Framing>
                  </LocationContextProvider>
                </ProtectedRoute>
              );
            } else {
              return (
                <Route exact path={route.path} key={i}>
                  <LocationContextProvider>
                    <Framing route={route} routes={routes}>
                      {route.component}
                    </Framing>
                  </LocationContextProvider>
                </Route>
              );
            }
          })}
        <Route>{<PageNotFound />}</Route>
      </Switch>
    </BrowserRouter>
  );
};

type RouterProps = {
  routes: RouteInterface[];
};

export default Router;
