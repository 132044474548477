import { ScoreInfo } from "../../../services/scores/scores.service";
import { CircularProgress, Grid } from "../../../components/util";
import { IAuditorFactors, IAuditorMeter } from "../../../components/content";
import * as Styled from "./styles.css";

const IAuditorBreakdown = ({
  data,
  isError,
  isLoading,
}: IAuditorBreakdownProps) => {
  return !isLoading ? (
    <Grid container spacing={4}>
      <Grid item xs={12} xxl={6}>
        <IAuditorMeter data={data?.scores} isError={isError} display />
      </Grid>
      <Styled.FactorsContainer item xs={12} xxl={6}>
        <IAuditorFactors showActions data={data?.factors} />
      </Styled.FactorsContainer>
    </Grid>
  ) : (
    <CircularProgress />
  );
};

interface IAuditorBreakdownProps {
  data: ScoreInfo;
  isError: boolean;
  isLoading: boolean;
}

export default IAuditorBreakdown;
