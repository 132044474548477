import { IconButtonProps } from "@rjsf/utils";

import Button from "../../../button";
import Icon from "../../../icons/icon";

const AddButton = ({ onClick, value }: IconButtonProps) => (
  <Button color="neutral" variant="outlined" onClick={onClick}>
    <Icon icon="plus" /> {value}
  </Button>
);

export default AddButton;
