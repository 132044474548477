import { useQuery, UseQueryResult } from "react-query";
import axios from "../../axios";
import { Automation } from "../../types/automations.type";

export const useAutomationEntity = (
  automation_id: string,
): UseQueryResult<Automation> => {
  const fetchAutomation = async (): Promise<Automation | undefined> => {
    if (!automation_id) {
      return undefined;
    }
    const response = await axios.get<Automation>(
      `/automations/${automation_id}`,
    );
    return response.data;
  };

  return useQuery({
    queryKey: ["automation-entity", automation_id],
    queryFn: fetchAutomation,
  });
};
