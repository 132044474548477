import { useContext, useEffect, useState } from "react";
import { Popover, Typography } from "../..";
import SelectOption from "./selectOption";
import { FormContext } from "../../../../contexts";
import {
  getSchemaErrors,
  textNumberDefaultSchema,
} from "../../../../validations";
import { InputProps } from "../index";
import InputControl from "../inputControl";
import InputHelpText from "../inputHelpText";
import InputLabel from "../inputLabel";
import { ComponentSize } from "../../../../types/attributes.type";
import { TextFieldTypes } from "../textField";
import * as Styled from "./styles.css";

const Select = ({
  children,
  component,
  disabled,
  error,
  helpText,
  id,
  label,
  onBlur,
  onChange,
  onFocus,
  optional,
  options,
  paragraph,
  placeholder,
  size,
  type,
  grow,
  formlessInput,
  validationList,
  validationOnBlur,
  validationOnChange,
  validationOnFocus,
  validationType,
  value,
  ...otherProps
}: iSelectProps) => {
  const { formData, formStep, setFormData, setFormErrors } =
    useContext(FormContext);

  const inputLabelId = label && id ? `${id}-label` : undefined;
  const [helpList, setHelpList] = useState<any>();
  const [isInputError, setIsInputError] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [formlessTitle, setFormlessTitle] = useState<string | number>(
    chooseOptionTitle(options, value),
  );

  const [formlessValue, setFormlessValue] = useState<string | number>(
    value ? value : "",
  );

  const handleValidation = async (inputValue?: string | number) => {
    setHelpList(await getSchemaErrors(validationType, inputValue));
  };

  const handleSelectOption = async (id: string, event: any) => {
    setIsOpen(false);
    if (formlessInput) {
      setFormlessTitle(event.target.innerText);
      setFormlessValue(event.target.value);
    } else {
      setFormData(id, event.target.attributes["value"].value, formStep);
      handleValidation(
        formlessInput ? formlessValue : formData[formStep - 1][id],
      );
    }
    if (onChange) {
      onChange(event.target.value || formData[formStep - 1][id]);
    }
  };

  // Set Input Error and Form Errors
  useEffect(() => {
    if (!formlessInput && isActive && helpList !== undefined) {
      setIsInputError(helpList);
      setFormErrors(id, helpList);
    }
  }, [helpList, id, isActive, setFormErrors, formlessInput]);

  return (
    <>
      {label && (
        <InputLabel
          htmlFor={id}
          id={inputLabelId}
          showOptional={optional}
          required={validationType._flags.presence === "required"}
        >
          {label}
        </InputLabel>
      )}
      <InputControl size={size} type={type} grow={grow}>
        <Popover
          alignment="end"
          onClick={async (event: any) => {
            setIsOpen(!isOpen);
            setIsActive(true);
          }}
          onClickOutside={async () => {
            setIsOpen(false);
          }}
          placement="bottom"
          trigger={
            <Styled.Select
              disabled={disabled}
              outlined
              elevation={formlessInput ? "flat" : "low"}
              corners="rounded"
              id={id}
              role="select"
              $formless={formlessInput}
              $isOpen={isOpen}
              $error={isInputError}
              $size={size}
              $unselected={
                (value && value === "") ||
                (!formlessInput && formData[formStep - 1][id] === "")
              }
            >
              {placeholder &&
                formlessValue === "" &&
                formData[formStep - 1][id] === "" && (
                  <Typography element="span">{placeholder}</Typography>
                )}
              <Typography element="span">
                {formlessInput
                  ? formlessTitle
                  : chooseOptionTitle(options, formData[formStep - 1][id])}
              </Typography>
              <Styled.SelectIcon icon="chevron-down" size="sm" />
            </Styled.Select>
          }
        >
          <Styled.SelectSelectMenu>
            {options.map((selectOption: iSelectOption) => (
              <SelectOption
                key={selectOption.value}
                value={selectOption.value}
                selected={
                  (value && formlessValue === selectOption.value) ||
                  (!formlessInput &&
                    formData[formStep - 1][id] === selectOption.value)
                }
                onClick={async (event: any) => {
                  setIsOpen(false);
                  handleSelectOption(id, event);
                }}
              >
                {selectOption.title}
              </SelectOption>
            ))}
          </Styled.SelectSelectMenu>
        </Popover>
      </InputControl>
      {(helpList || validationList) && isActive && (
        <InputHelpText
          error={isInputError}
          helpList={helpList}
          validationList={validationList}
        />
      )}
    </>
  );
};

export interface iSelectProps extends InputProps {
  helpText?: string;
  id: string;
  fullWidth: boolean;
  label?: string;
  onBlur?: any;
  onChange?: any;
  onFocus?: any;
  optional: boolean;
  options: iSelectOption[];
  paragraph: boolean;
  size: ComponentSize;
  type: TextFieldTypes;
  grow?: boolean;
  validationList?: any;
  validationOnBlur: boolean;
  validationOnChange: boolean;
  validationOnFocus: boolean;
  validationType?: any;
  value?: string | number;
}

Select.defaultProps = {
  autoFocus: false,
  disabled: false,
  error: false,
  fullWidth: true,
  optional: false,
  paragraph: false,
  size: "md",
  type: "text",
  validationOnBlur: false,
  validationOnChange: true,
  validationOnFocus: false,
  validationType: textNumberDefaultSchema,
};

export const chooseOptionTitle = (options: iSelectOption[], value) => {
  if (value) {
    let val = options.find(option => value === option.value);
    if (val) {
      return val.title;
    }
    val = options.find(option => value === option.title);
    return val ? val.title : "";
  }
  return "";
};

export interface iSelectOption {
  title: string;
  value: string | number;
}

export default Select;
