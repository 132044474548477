import { formatDateTime } from "../../../helpers";
import { TDateTimeFormat } from "../../../types";

const DateTime = ({
  input,
  outputFormat = "short",
  ...otherProps
}: DateTimeProps) => {
  return <>{formatDateTime(input, outputFormat)}</>;
};

export interface DateTimeProps {
  input: string;
  outputFormat?: TDateTimeFormat;
}

DateTime.defaultProps = {
  outputFormat: "short",
};

export default DateTime;
