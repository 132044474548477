import React from "react";
import {
  TButtonType,
  TIconShape,
  TStyleConnotation,
  TStyleShade,
} from "../../../types";
import Icon from "../icons/icon";
import Link from "../link";
import { ButtonBaseProps } from "./buttonBase";
import * as Styled from "./styles.css";

const IconButton = React.forwardRef(
  (
    {
      iconColor,
      iconShade,
      disabled,
      router,
      href,
      icon,
      isLoading,
      isNewTab,
      onClick,
      scrollTo,
      type = "button",
      ...otherProps
    }: ButtonProps,
    ref,
  ) => {
    const IconAsButton = () => {
      return (
        <Styled.IconButton
          element="button"
          componentType={type}
          disabled={isLoading || disabled}
          onClick={onClick}
          ref={ref}
          {...otherProps}
        >
          <Icon icon={icon} color={iconColor} shade={iconShade} />
        </Styled.IconButton>
      );
    };

    if (scrollTo) {
      return (
        <Styled.ScrollLink
          to={scrollTo}
          smooth={true}
          offset={-40}
          duration={500}
          containerId="main"
        >
          {IconAsButton()}
        </Styled.ScrollLink>
      );
    }
    if (href) {
      return (
        <Link
          disabled={disabled}
          isButton
          router={router}
          href={disabled ? undefined : href}
          isNewTab={isNewTab}
        >
          {IconAsButton()}
        </Link>
      );
    }
    return <>{IconAsButton()}</>;
  },
);

interface ButtonProps extends ButtonBaseProps {
  iconColor?: TStyleConnotation;
  iconShade?: TStyleShade;
  router?: boolean;
  href?: string | object;
  icon: TIconShape;
  isNewTab?: boolean;
  isLoading?: boolean;
  scrollTo?: string;
  type?: TButtonType;
}

IconButton.defaultProps = {
  isNewTab: false,
  isLoading: false,
};
export default IconButton;
