import { useContext, useEffect, useState } from "react";
import { recordAnalyticsEvent } from "./../../../analytics";
import {
  FlowSteppedFormSchema,
  JfsFormData,
  JfsMarkupSchema,
  JfsSchema,
} from "../../../types";
import { getAdjacentKey } from "../../../helpers";
import { AuthContext, LocationContext, TabsContext } from "./../../../contexts";
import { Icon } from "./../../../components/util";
import FlowMarkup from "./flowMarkup/flowMarkup";
import * as Styled from "./styles.css";
import { CustomErrorMsg } from "../../util/jfsForm/jfsForm";
import { Section } from "../../layout";

const FlowSteps = ({
  id,
  schema,
  onSubmit,
  name,
  prefill,
  customErrorMsg,
  ...otherProps
}: FlowStepsProps) => {
  const { location } = useContext(LocationContext);
  const { setCurrentTab, setTabStatus } = useContext(TabsContext);
  let { user } = useContext(AuthContext);

  const [formData, setFormData] = useState<JfsFormData | undefined>(
    prefill ? prefill : undefined,
  );

  useEffect(() => {
    setFormData(prefill);
  }, [prefill]);

  // Set FormData and Tab Status based on router state
  useEffect(() => {
    if (location.state?.formData) {
      setFormData(location.state.formData);
    }
  }, [location.state]);

  const handleTabChange = (
    currentTab: string,
    direction: "next" | "previous",
    data?: any,
  ): void => {
    let nextTab: string | number = getAdjacentKey(
      currentTab,
      schema,
      direction,
    );
    setCurrentTab(nextTab as string);
    if (direction === "next") {
      setTabStatus(currentTab, "completed");
    }
    setFormData(() => {
      if (formData) {
        if (data) {
          return { ...formData, [currentTab]: data };
        }
      } else {
        if (data) {
          return { [currentTab]: data };
        }
      }
      return formData;
    });
  };

  /** Hot fix: Remove setFormData handler from changeHandler so prod users can still use claims form */
  const handleChange = ({ formData }: { formData?: any }) => {};
  // setFormData(formData); // this triggers cascading effects clearing all form data.

  const handleNext = (data: Record<any, any>, formStep: string): void => {
    handleTabChange(formStep, "next", data);
    recordAnalyticsEvent("action", `Form Continue Click`, {
      path: location.pathname,
      form: id,
      form_step: formStep,
      user_type: user.roles[0],
    });
    window.scrollTo(0, 0);
  };

  return (
    <>
      {Object.keys(schema).map((step: string, i: number) => (
        <Styled.QuoteTabPanel id={step} spaced key={i}>
          <div>
            <Styled.BackNav>
              {i > 0 && (
                <Styled.BackButton
                  color="accent"
                  variant="unstyled"
                  onClick={(evt: any) => handleTabChange(step, "previous")}
                >
                  <Icon icon="arrow-left" color="accent" size="sm" />
                  Back
                </Styled.BackButton>
              )}
            </Styled.BackNav>
            {schema?.[step]?.schema && schema?.[step]?.uiSchema && (
              <Styled.QuoteFormSchema
                id={step}
                customErrorMsg={customErrorMsg}
                schema={schema[step].schema as JfsSchema}
                uiSchema={schema[step].uiSchema}
                formData={formData ? formData[step] : undefined}
                onChange={handleChange}
                onSubmit={handleNext}
                steppedForm
                submitButtons={
                  <Styled.StepButton color="accent" type="submit">
                    Continue
                    <Icon icon="chevron-right" shade="inverse" size="sm" />
                  </Styled.StepButton>
                }
              />
            )}
            {!!schema[step]?.markupSchema && (
              <FlowMarkup
                formData={formData}
                markupSchema={schema[step].markupSchema as JfsMarkupSchema}
                childAppend={
                  <Styled.StepButton
                    color="accent"
                    onClick={() => formData && onSubmit(formData)}
                    type="submit"
                  >
                    Confirm and Submit
                  </Styled.StepButton>
                }
              />
            )}
          </div>
        </Styled.QuoteTabPanel>
      ))}
    </>
  );
};

export interface FlowStepsProps {
  id: string;
  name: string;
  onSubmit: (submitData: JfsFormData) => void;
  prefill?: Record<any, any>;
  schema: FlowSteppedFormSchema;
  customErrorMsg?: CustomErrorMsg;
}

export default FlowSteps;
