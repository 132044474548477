import { ContainerProps } from "../../../container/container";
import * as Styled from "./styles.css";

const JfsFormContainer = ({
  children,
  ...otherProps
}: JfsFormContainerProps) => {
  return (
    <Styled.FormSection {...otherProps}>
      {children && children}
    </Styled.FormSection>
  );
};

export interface JfsFormContainerProps extends ContainerProps {}

JfsFormContainer.defaultProps = {};

export default JfsFormContainer;
