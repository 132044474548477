import React from "react";
import { DeepdiveOverviewInterface } from "../../../services/prismic/prismic.service";
import { Box, makeStyles } from "@material-ui/core";
import { Card, Grid, Iframe, Typography } from "../../../components/util";

interface OverviewProps {
  overview?: DeepdiveOverviewInterface;
}

const useStyles = makeStyles(theme => ({
  iframeContainer: {
    marginBottom: 0,
  },
  description: {
    padding: `${theme.spacing} 0`,
  },
}));

const Overview = ({ overview }: OverviewProps) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Card isInGrid isPadded>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Typography variant="displaySmall">{overview?.title}</Typography>
            <Box className={classes.description}>{overview?.description}</Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Iframe
              classes={{ container: classes.iframeContainer }}
              type="youtube"
              src={`https://www.youtube.com/embed/${overview?.youtubeId}`}
            />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default Overview;
