import styled from "styled-components";
import { Container } from "../../util";

export const WidgetContainer = styled(Container).attrs((props: any) => ({}))`
  @media (min-width: 690px) and (max-width: 767px) {
    margin-top: -66px;
  }
  @media (min-width: 1051px) and (max-width: 1279px) {
    margin-top: -66px;
  }
  @media (min-width: 1339px) and (max-width: 1341px) {
    margin-top: -66px;
  }
  @media (min-width: 1342px) {
    margin-top: -66px;
  }
`;
