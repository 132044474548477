import { IConfig } from "@unleash/proxy-client-react";

const config: IConfig = {
  url: process.env.REACT_APP_UNLEASH_PROXY_URL || "",
  clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY || "",
  refreshInterval: 3600, // 1 hour
  appName: process.env.REACT_APP_UNLEASH_APPNAME || "",
  environment: process.env.REACT_APP_UNLEASH_ENV || "",
};

export default config;
