import { useEffect, useState, useContext } from "react";
import { getSchemaErrors, textDefaultSchema } from "../../../validations";
import { FormContext } from "../../../contexts";
import IconButton from "../button/iconButton";
import { InputProps } from "./index";
import InputControl from "./inputControl";
import InputHelpText from "./inputHelpText";
import InputLabel from "./inputLabel";
import * as Styled from "./styles.css";

const TextField = ({
  autoComplete,
  autoFocus,
  children,
  component,
  dependentFields,
  error,
  editable,
  grow,
  helpText,
  id,
  label,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  optional,
  paragraph,
  size,
  type,
  validationList,
  validationOnBlur,
  validationOnChange,
  validationOnFocus,
  validationOnKeyDown,
  validationType,
  value,
  ...otherProps
}: TextFieldProps) => {
  const {
    formData,
    formErrors,
    formStep,
    focusCount,
    handleFormEvent,
    setFormErrors,
    incrementFocusCount,
  } = useContext(FormContext);

  const inputLabelId = label && id ? `${id}-label` : undefined;
  const [helpList, setHelpList] = useState<any>();
  const [isInputError, setIsInputError] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [inputType, setInputType] = useState(type);
  const [isPasswordRevealed, setIsPasswordRevealed] = useState<boolean>(
    type !== "password",
  );

  const handleTypeChange = (event: any) => {
    setInputType(inputType === "password" ? "text" : "password");
    setIsPasswordRevealed(!isPasswordRevealed);
  };

  const handleValidation = async (value?: string) => {
    const context = {};

    if (dependentFields && dependentFields.length > 0) {
      dependentFields.forEach(element => {
        context[element] = formData[formStep - 1][element] || "";
      });
    }
    const schema_errors = await getSchemaErrors(
      validationType,
      value ? value : formData[formStep - 1][id],
      context,
    );

    setHelpList(schema_errors);
  };

  // Set Input Error and Form Errors
  useEffect(() => {
    if (isActive && helpList !== undefined) {
      if (!validationOnFocus) {
        setIsInputError(helpList);
      }
      setFormErrors(id, helpList);
    }
  }, [helpList, id, isActive, setFormErrors, validationOnFocus]);

  return (
    <>
      {label && (
        <InputLabel
          htmlFor={id}
          id={inputLabelId}
          required={validationType._flags.presence === "required"}
          showOptional={optional}
        >
          {label}
        </InputLabel>
      )}
      <InputControl grow={grow} size={size} type={type}>
        <Styled.TextField
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          id={id}
          onBlur={async (event: any) => {
            if (
              autoFocus &&
              focusCount <= 1 &&
              formData[formStep - 1][id].length === 0
            ) {
              return;
            }

            if (onBlur) {
              if (value) {
                if (
                  (value && value.toString().length > 0) ||
                  formData[formStep - 1][id].length > 0
                ) {
                  onBlur(event);
                }
              }
            }

            if (validationOnBlur) {
              if (
                (value && value.toString().length > 0) ||
                formData[formStep - 1][id].length > 0
              ) {
                handleValidation();
              }
            }
          }}
          onChange={async (event: any) => {
            if (onChange) {
              onChange(event);
            }
            handleFormEvent(id, event);
            if (validationOnChange) {
              if (
                (value && value.toString().length >= 0) ||
                formData[formStep - 1][id].length >= 0
              ) {
                handleValidation(event.target.value);
              }
            }
          }}
          onFocus={async (event: any) => {
            incrementFocusCount();
            if (onFocus) {
              onFocus(event);
            }
            setIsActive(true);
            if (validationOnFocus) {
              handleValidation();
            }
          }}
          onKeyDown={async (event: any) => {
            if (onKeyDown) {
              onKeyDown(event);
            }
            setIsActive(true);
            if (validationOnKeyDown) {
              handleValidation();
            }
          }}
          type={inputType}
          value={
            value
              ? value
              : formData &&
                formStep &&
                formData[formStep - 1] &&
                formData[formStep - 1][id]
              ? formData[formStep - 1][id]
              : ""
          }
          $error={
            isInputError || typeof formErrors[formStep - 1][id] === "string"
          }
          $editable={editable}
          $size={size}
          {...otherProps}
        />
        {type === "password" && (
          <IconButton
            icon={isPasswordRevealed ? "eye-closed" : "eye"}
            onClick={(event: any) => handleTypeChange(event)}
          />
        )}
      </InputControl>
      {(helpList ||
        validationList ||
        typeof formErrors[formStep - 1][id] === "string") &&
        isActive && (
          <InputHelpText
            error={isInputError || !!formErrors[formStep - 1][id]}
            helpList={helpList || { [id]: formErrors[formStep - 1][id] }}
            validationList={validationList}
          />
        )}
    </>
  );
};

interface TextFieldProps extends InputProps {
  autoComplete?: string;
  autoFocus: boolean;
  dependentFields?: string[];
  editable?: boolean;
  helpText?: string;
  id: string;
  fullWidth: boolean;
  grow?: boolean;
  label?: string;
  onBlur?: any;
  onChange?: any;
  onFocus?: any;
  onKeyDown?: any;
  optional: boolean;
  paragraph: boolean;
  setFormErrors?: Function;
  size: SizeTypes;
  type: TextFieldTypes;
  validationList?: any;
  validationOnBlur: boolean;
  validationOnChange: boolean;
  validationOnFocus: boolean;
  validationOnKeyDown: boolean;
  validationType?: any;
  value?: string | number | readonly string[];
}

TextField.defaultProps = {
  autoFocus: false,
  disabled: false,
  dependentFields: undefined,
  editable: true,
  error: false,
  fullWidth: true,
  optional: false,
  paragraph: false,
  setFormErrors: () => {},
  size: "md",
  type: "text",
  validationOnBlur: false,
  validationOnChange: false,
  validationOnFocus: false,
  validationOnKeyDown: false,
  validationType: textDefaultSchema,
};

export type TextFieldTypes = "email" | "text" | "password" | "tel";
export type SizeTypes = "md" | "lg";

export default TextField;
