import { recordAnalyticsEvent } from "../../../../analytics";
import safetycultureService from "../../../../services/safetyculture/safetyculture.service";
import { Scores } from "../../../../services/scores/scores.service";
import { Header } from "../../../layout";
import { Alert, Button, Grid, Typography, Link } from "../../../util";
import ScoreMeter from "./scoreMeter";
import { InspectionButton } from "./scoreMeter/styles.css";

const IAuditorMeter = ({
  data,
  display,
  isError,
  titleVariant,
  isLoading,
  isBroker,
}: IAuditorMeterProps) => {
  return (
    <Grid container>
      {isLoading && (
        <ScoreMeter
          isLoading={isLoading}
          title="SafetyCulture score"
          titleVariant={titleVariant}
        />
      )}
      {data && !isError && (
        <ScoreMeter
          score={{
            current: data.current,
            best_practice: data.best_practice,
            median: data.median,
          }}
          title="SafetyCulture score"
          titleVariant={titleVariant}
          footnote={
            display && (
              <>
                <Typography variant="body2">
                  {isBroker ? "customer's" : "Your"} score is calculated on 9
                  risk factors based on how your company uses SafetyCulture.
                </Typography>
                <Typography paragraph variant="body2">
                  Learn more about the&nbsp;
                  <Link href="/" router>
                    SafetyCulture Score
                  </Link>
                  .
                </Typography>
                {!isBroker && (
                  <Button
                    href={process.env.REACT_APP_IAUDITOR_LINK}
                    isNewTab
                    variant="outlined"
                  >
                    Launch SafetyCulture
                  </Button>
                )}
              </>
            )
          }
        />
      )}
      {(!data || isError) && (
        <Header title={"SafetyCulture score"} type="sectionMeter" />
      )}
      {isError && (
        <Grid item xs={12}>
          <Alert defaultIcon={false}>
            <Typography variant="heading" paragraph>
              When you initially connect SafetyCulture there may not be enough
              data to quantify a score
            </Typography>
            <Typography variant="body1" paragraph>
              Continue completing inspections and your score will be displayed
              when we have enough data.
            </Typography>
            <Typography variant="body1">
              Learn more about your <a href="/">SafetyCulture score.</a>
            </Typography>
            {!isBroker && (
              <InspectionButton
                color="accent"
                variant="contained"
                isNewTab
                href={safetycultureService.getiAuditorTemplateLink()}
                onClick={() => {
                  recordAnalyticsEvent("action", "iAuditor", {
                    type: "OnBoarding widget",
                    action: "Start inspection",
                  });
                }}
              >
                Start inspection
              </InspectionButton>
            )}
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

interface IAuditorMeterProps {
  data?: Scores;
  display?: boolean;
  displayEmpty?: boolean;
  isError: boolean;
  isLoading?: boolean;
  titleVariant: "heading" | "section";
  isBroker?: boolean;
}

IAuditorMeter.defaultProps = {
  titleVariant: "section",
  isLoading: false,
};

export default IAuditorMeter;
