import { createMuiTheme } from "@material-ui/core/styles";
import { theme } from "./index";

export const MuiTheme = createMuiTheme({
  palette: {
    primary: {
      main: theme.color.accent.background.default.default,
    },
    secondary: {
      main: theme.color.surface.background.default.hover,
    },
    text: {
      primary: theme.color.surface.text.default.default,
    },
    info: {
      light: theme.color.information.background.weakest.default,
      main: theme.color.information.background.default.default,
      dark: theme.color.information.background.strong.default,
    },
    success: {
      main: theme.color.positive.background.default.default,
    },
  },
  typography: {
    fontFamily: theme.typography.fontFamily.sans,
    htmlFontSize: parseInt(theme.typography.body.fontSize, 10),
    button: {
      fontSize: theme.typography.body.fontSize,
    },
    h1: {
      fontSize: theme.typography.displayLarge.fontSize,
      fontWeight: theme.typography.displayLarge.fontWeight,
    },
    h2: {
      fontSize: theme.typography.displayMedium.fontSize,
      fontWeight: theme.typography.displayMedium.fontWeight,
    },
    h3: {
      fontSize: theme.typography.displaySmall.fontSize,
      fontWeight: theme.typography.displaySmall.fontWeight,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      xxl: 1536,
    },
  },
  shadows: [
    "none",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "0px 1px 1px rgba(19, 25, 39, 0.06), 0px 4px 12px rgba(51, 62, 78, 0.25);",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ],
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiSelect: {
      MenuProps: {
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      },
    },
  },
  overrides: {
    MuiPopover: {
      paper: {
        border: "1px solid rgba(152, 163, 177, 0.4)",
        borderRadius: "6px",
      },
    },
  },
});

declare module "@material-ui/core/styles/createBreakpoints" {
  interface BreakpointOverrides {
    xxl: true;
  }
}

export default MuiTheme;
