import { IOrganisation } from "../../../../services/admin/admin.service";
import {
  TableRow,
  TableCell,
  Typography,
  Popover,
} from "../../../../components/util";
import { PaginatedResults } from "../../../../components/content";
import organisationService from "../../../../services/organisation/organisation.service";
import * as Styled from "../styles.css";
import { useState } from "react";

const ParentOrgs = ({ orgId }: TabOrgProps) => {
  const [tabeOrgId] = useState<string>(orgId);
  const organisationsRow = (orgsSlice: IOrganisation[]) => {
    return (
      <>
        {orgsSlice.map((org: IOrganisation, i: number) => (
          <TableRow key={org.id}>
            <TableCell>
              <Styled.Link
                href={`/organisations/${org.id}?t=general`}
                router
                title={org.name}
              >
                {org.name}
              </Styled.Link>
            </TableCell>
            <TableCell>
              <Typography>{org.email}</Typography>
            </TableCell>

            <TableCell>
              <OverflowMenuButton />
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  const OverflowMenuButton = () => {
    return (
      <Popover
        alignment="end"
        trigger={
          <Styled.MoreOptionIconButton disabled icon="kebab-horizontal">
            Pop
          </Styled.MoreOptionIconButton>
        }
      >
        <Styled.MoreOptionSelectMenu></Styled.MoreOptionSelectMenu>
      </Popover>
    );
  };
  return (
    <Styled.SpacedGrid>
      <PaginatedResults
        id={`${tabeOrgId}-organisations-parent`}
        tableColumns={[
          { title: "Name", expand: true },
          { title: "Primary contact", collapse: true },
          { title: "", collapse: true },
        ]}
        service={organisationService.getAllParents}
        tableRows={organisationsRow}
        missing={{
          icon: "organization",
          type: "organisations",
          message: "This is where we’ll show all Organisations.",
        }}
      />
    </Styled.SpacedGrid>
  );
};

export interface TabOrgProps {
  orgId: string;
}
export default ParentOrgs;
