import { Header, Section } from "../../../components/layout";
import { Invitation } from "../../../services/invitation/invitation.service";

import {
  Table,
  TableRow,
  TableCell,
  TableRowSubhead,
} from "../../../components/util";

import * as Styled from "../styles.css";
import { InviteStatusChip } from "../InvitationsList/InviteStatusChip";

interface Props {
  invitation: Invitation;
}

const General = ({ invitation }: Props) => {
  if (invitation == null) return null;

  return (
    <Styled.Grid>
      <Section>
        <Header title="Invitation" type="section" />
        <Table
          columns={[
            { title: "Key", expand: true },
            { title: "Value", expand: true },
          ]}
          orientation="horizontal"
        >
          <TableRow>
            <TableCell>Invitation ID</TableCell>
            <TableCell>{invitation.id}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>
              <InviteStatusChip status={invitation.status} />
            </TableCell>
          </TableRow>

          <TableRowSubhead>Invitee Details</TableRowSubhead>

          <TableRow>
            <TableCell>Invitee name</TableCell>
            <TableCell>
              {[invitation.firstname, invitation.lastname]
                .filter(Boolean)
                .join(" ")}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Invitee email</TableCell>

            <TableCell>{invitation.email}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Role</TableCell>
            <TableCell>{invitation.role}</TableCell>
          </TableRow>

          <TableRowSubhead>Associations</TableRowSubhead>

          <TableRow>
            <TableCell>Policy</TableCell>

            <TableCell>
              {invitation.policy_id ? (
                <Styled.Link router href={`/policies/${invitation.policy_id}`}>
                  {invitation.policy_number}
                </Styled.Link>
              ) : null}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Toolkit</TableCell>

            <TableCell>
              {invitation.data?.scheme ? (
                <Styled.Link
                  router
                  href={`/toolkits/code/${invitation.data?.scheme}`}
                >
                  {invitation.data?.scheme}
                </Styled.Link>
              ) : null}
            </TableCell>
          </TableRow>

          <TableRowSubhead>Inviter Details</TableRowSubhead>

          <TableRow>
            <TableCell>Inviter name</TableCell>
            <TableCell>
              {invitation.sender_first_name} {invitation.sender_last_name}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Inviter (CCed) emails</TableCell>
            <TableCell>
              {!!invitation.email_cc && invitation.email_cc.join(", ")}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Inviter (BCCed) emails</TableCell>
            <TableCell>
              {!!invitation.email_bcc && invitation.email_bcc.join(", ")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>From organisation</TableCell>

            <TableCell>
              {!!invitation.sender_org_id && !!invitation.org_name && (
                <Styled.Link
                  router
                  href={`/organisations/${invitation.sender_org_id}`}
                >
                  {invitation.org_name}
                </Styled.Link>
              )}
            </TableCell>
          </TableRow>
        </Table>
      </Section>
    </Styled.Grid>
  );
};

export default General;
