import { useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { recordAnalyticsEvent } from "../../../analytics";
import { TStyleConnotation, TStyleSize } from "../../../types";
import { TabsContext } from "../../../contexts";
import ListItem, { ListItemProps } from "../lists/listItem";
import Icon from "../icons/icon";
import Typography from "../typography";
import * as Styled from "./styles.css";
const Tab = ({
  complete,
  disabled,
  hidden,
  icon,
  iconColor,
  iconSize,
  id,
  onComplete,
  label,
  restricted,
  scrolling,
  steppedLabel,
  onClick,
  ...otherProps
}: TabProps) => {
  const history: Record<any, any> = useHistory();
  const { currentTab, orientation, variant, setCurrentTab, tabsId } =
    useContext(TabsContext);

  const handleIconColor = (): TStyleConnotation => {
    if (complete) {
      return "positive";
    }
    if (currentTab === id) {
      return "accent";
    }
    return "surface";
  };

  const handleTabChange = useCallback(
    (id: string) => {
      onClick?.();
      recordAnalyticsEvent("action", "Tab Click", {
        location: history.location.pathname,
        tab_set: tabsId,
        tab_name: id,
      });
      setCurrentTab(id);
      history.push({
        state: {
          ...(history?.location?.state as Record<any, any>),
          tabs: {
            ...history.location?.state?.[tabsId],
            [tabsId]: id,
          },
        },
      });
    },
    [onClick, history, setCurrentTab, tabsId],
  );

  return (
    <Styled.Tab
      element={ListItem}
      disabled={disabled || (onComplete?.includes("disabled") && complete)}
      onClick={(event: any) => (!disabled && id ? handleTabChange(id) : null)}
      $complete={complete}
      $hidden={hidden}
      $orientation={orientation}
      $scrolling={scrolling}
      $selected={currentTab === id}
      $variant={variant}
      {...otherProps}
    >
      <Styled.TabButton
        value={otherProps.value}
        variant="unstyled"
        $orientation={orientation}
        $selected={currentTab === id}
        $variant={variant}
      >
        {icon && (
          <Icon
            icon={icon}
            size={iconSize}
            color={iconColor ? iconColor : handleIconColor()}
          />
        )}
        {
          <Styled.WidgetTabContainer>
            {label}
            {orientation === "vertical" && steppedLabel && (
              <Typography variant={"small"}>{steppedLabel}</Typography>
            )}
          </Styled.WidgetTabContainer>
        }
      </Styled.TabButton>
    </Styled.Tab>
  );
};

export interface TabProps
  extends Omit<ListItemProps, "completed" | "selected" | "size" | "variant"> {
  onComplete?: ComponentStatus[];
  hidden?: boolean;
  iconColor?: TStyleConnotation;
  iconSize?: TStyleSize;
  label: string;
  restricted?: boolean;
  scrolling?: boolean;
  value?: number;
  steppedLabel?: string;
}

export type ComponentStatus = "disabled" | "hidden" | "restricted";

Tab.defaultProps = {
  scrolling: false,
};

export default Tab;
