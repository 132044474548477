import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useHistory } from "react-router-dom";
import { isJsonEqual } from "../helpers";
import LocationContext from "./locationContext";

export const FormContextProvider = ({
  children,
  initialFields,
  initialErrors,
  title,
  subtitle,
  submitStartDisabled,
  validationFields,
  validationErrors,
}: FormContextProviderProps) => {
  const history = useHistory();
  const { location } = useContext(LocationContext);

  const [formStep, setFormStep] = useState<number>(
    location.hash &&
      parseInt(location.hash.slice(-1)) <= validationFields.length
      ? parseInt(location.hash.slice(-1))
      : 1,
  );
  const formStepMemo: number = useMemo(() => formStep, [formStep]);

  const [formData, setFormData] =
    useState<Record<string, any>[]>(validationFields);
  const formDataMemo: Record<string, any>[] = useMemo(
    () => formData,
    [formData],
  );

  const [formErrors, setFormErrors] =
    useState<Record<string, boolean>[]>(validationErrors);
  const formErrorsMemo: Record<string, boolean>[] = useMemo(
    () => formErrors,
    [formErrors],
  );

  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const isSubmitDisabledMemo: boolean = useMemo(
    () => isSubmitDisabled,
    [isSubmitDisabled],
  );

  const [formSubtitle, setFormSubtitle] =
    useState<string | undefined>(subtitle);
  const formSubtitleMemo: string | undefined = useMemo(
    () => formSubtitle,
    [formSubtitle],
  );
  const [formTitle, setFormTitle] = useState<string | undefined>(title);
  const formTitleMemo: string | undefined = useMemo(
    () => formTitle,
    [formTitle],
  );

  const [focusCount, setFocusCount] = useState<number>(0);
  const focusCountMemo: number = useMemo(() => focusCount, [focusCount]);

  const handleFormStep = (event: any, direction: "next" | "previous") => {
    event.preventDefault();
    let newStep = direction === "next" ? formStep + 1 : formStep - 1;
    if (newStep <= formData.length) {
      history.push({
        search: location.search || undefined,
        hash: `step${newStep}`,
      });
    }
  };

  // setFormStep by url hash
  useEffect(() => {
    if (location.hash) {
      if (parseInt(location.hash.slice(-1)) !== formStep) {
        let hashStep: number = parseInt(location.hash.slice(-1));
        if (hashStep <= formData.length) {
          setFormStep(hashStep);
        }
      }
    } else {
      setFormStep(1);
    }
    return () => {};
  }, [formData.length, formStep, location.hash]);

  // setFormData
  const handleFormData = (
    field: string,
    value?: string | boolean,
    specifiedStep?: number,
  ) => {
    const targetStep: number = specifiedStep ? specifiedStep - 1 : formStep - 1;
    if (formData && field in formData[targetStep]) {
      if (formData[formStep - 1][field] !== value) {
        let newFormData = [...formData];
        newFormData[formStep - 1][field] = value;
        setFormData(newFormData);
      }
    }
  };

  // setFormData by Form Event
  const handleFormEvent = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    event.preventDefault();
    handleFormData(field, event.target.value);
  };

  const handleFormErrors = useCallback(
    (key: any, error: string | undefined, specifiedStep?: number) => {
      setFormErrors((prevState: any) => {
        const newErrors = { ...prevState };
        const targetStep = specifiedStep ? specifiedStep - 1 : formStep - 1;
        for (const step in prevState) {
          if (parseInt(step) === targetStep) {
            newErrors[step] = {
              ...newErrors[step],
              [key]: !!error,
            };
          }
        }
        return newErrors;
      });
    },
    [formStep],
  );

  const handleSubmitErrors = useCallback(
    (
      error: false | Record<string, string> | string,
      specifiedStep?: number,
    ) => {
      for (const [errorKey, errorValue] of Object.entries(error)) {
        setFormErrors((prevState: any) => {
          const newErrors = { ...prevState };
          const targetStep = specifiedStep ? specifiedStep - 1 : formStep - 1;
          for (const step in prevState) {
            if (parseInt(step) === targetStep) {
              newErrors[step] = {
                ...newErrors[step],
                [errorKey]: errorValue,
              };
            }
          }
          return newErrors;
        });
      }
    },
    [formStep],
  );

  const incrementFocusCount = (): void => {
    setFocusCount(value => {
      return value + 1;
    });
  };

  const [initSubmitDisabled, setInitSubmitDisabled] = useState<boolean>(
    !!submitStartDisabled,
  );

  // submitStartDisabled
  useEffect(() => {
    if (initSubmitDisabled && !isJsonEqual(formData, initialFields)) {
      setInitSubmitDisabled(false);
    }
  }, [formData, initSubmitDisabled, initialFields]);

  // Set isSubmitDisabled based on current formErrors
  useEffect(() => {
    if (!initSubmitDisabled) {
      if (formErrors && formErrors[formStep - 1]) {
        const isErrors: boolean = Object.keys(formErrors[formStep - 1]).some(
          (key: string, i: number) => {
            return formErrors[formStep - 1][key] === true;
          },
        );
        setIsSubmitDisabled(isErrors);
      }
      if (!validationFields[0] && !validationErrors[0]) {
        setIsSubmitDisabled(false);
      }
    }
  }, [
    formData,
    formErrors,
    formStep,
    initSubmitDisabled,
    isSubmitDisabled,
    validationErrors,
    validationFields,
  ]);

  const handleFormDataReset = (): void => {
    setFormData(initialFields);
    setFormErrors(initialErrors);
  };

  return (
    <FormContext.Provider
      value={{
        formData: formDataMemo,
        formErrors: formErrorsMemo,
        formStep: formStepMemo,
        formSubtitle: formSubtitleMemo,
        formTitle: formTitleMemo,
        focusCount: focusCountMemo,
        isSubmitDisabled: isSubmitDisabledMemo,
        handleFormEvent: handleFormEvent,
        setFormData: handleFormData,
        setFormErrors: handleFormErrors,
        setSubmitErrors: handleSubmitErrors,
        setFormStep: handleFormStep,
        setFormSubtitle: setFormSubtitle,
        setFormTitle: setFormTitle,
        incrementFocusCount: incrementFocusCount,
        resetForm: handleFormDataReset,
      }}
    >
      {children && children}
    </FormContext.Provider>
  );
};

const FormContext = React.createContext<FormInterface>({
  formData: [],
  formErrors: [],
  formStep: 1,
  formSubtitle: undefined,
  formTitle: undefined,
  isSubmitDisabled: true,
  focusCount: 0,
  handleFormEvent: () => {},
  setFormData: () => {},
  setFormErrors: () => {},
  setSubmitErrors: () => {},
  setFormStep: () => {},
  setFormSubtitle: () => {},
  setFormTitle: () => {},
  incrementFocusCount: () => {},
  resetForm: () => {},
});

interface FormInterface {
  formData: Record<string, any>[];
  formErrors: Record<string, boolean>[];
  formStep: number;
  formSubtitle?: string;
  formTitle?: string;
  isSubmitDisabled: boolean;
  focusCount: number;
  handleFormEvent: (field: any, event: any) => void;
  setFormData: (
    field: string,
    value?: string | boolean,
    specifiedStep?: number,
  ) => void;
  setFormErrors: (field: any, error: string, specifiedStep?: number) => void;
  setSubmitErrors: (
    error: false | Record<string, string> | string,
    specifiedStep?: number,
  ) => void;
  setFormStep: (event: any, direction: "next" | "previous") => void;
  setFormSubtitle: (value: string) => void;
  setFormTitle: (value: string) => void;
  incrementFocusCount: () => void;
  resetForm: () => void;
}

interface FormContextProviderProps {
  children: React.ReactNode;
  initialErrors: FormErrorStep[];
  initialFields: FormFieldStep[];
  subtitle?: string;
  submitStartDisabled: boolean;
  title?: string;
  validationErrors: FormErrorStep[];
  validationFields: FormFieldStep[];
}

export type FormErrorStep = Record<string, boolean>;
export type FormFieldStep = Record<string, string | boolean | undefined>;

export default FormContext;
