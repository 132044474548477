import { IPolicy, TPolicyType } from "../types";
import { Invitation } from "../services/invitation/invitation.service";

export const transformPolicyType = (type: TPolicyType): string => {
  switch (type) {
    case "wc":
      return "Workers compensation (WC)";
    case "cpx":
      return "Commercial pack extra (CPX)";
    case "bpk":
    default:
      return "Business pack (BPK)";
  }
};

export type InvitationStatus = "doNotInvite" | "invite" | "invited" | undefined;

export const invitationStatus = (policy: IPolicy): InvitationStatus => {
  const someClaimed = policy.invitations?.some(
    (invite: Invitation) => invite?.status === "claimed",
  );

  const someActive = policy.invitations?.some(
    (invite: Invitation) => invite?.status === "active",
  );

  if (policy.do_not_invite) {
    return "doNotInvite";
  } else if (!policy.customer_org_id && !someClaimed && !someActive) {
    return "invite";
  } else if (someActive) {
    return "invited";
  }
};
