import { Icon } from "../../util";
import * as Styled from "./styles.css";

const BackLink = ({ title = "Back to Care", path = "/" }: IBackLinkProps) => {
  return (
    <Styled.Back href={path} router>
      <Icon icon="arrow-left" color="accent" size="sm" />
      {title}
    </Styled.Back>
  );
};

export interface IBackLinkProps {
  title?: string;
  path?: string;
}

export default BackLink;
