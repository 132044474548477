import { useContext } from "react";
import { Typography } from "../../../../components/util";
import AuthContainer from "../../authContainer";
import AuthFooter from "../../authFooter";
import * as Styled from "../style.css";
import { AuthContext } from "../../../../contexts";

const AlreadyLoggedIn = () => {
  const auth = useContext(AuthContext);
  return (
    <AuthContainer
      headerTitle="You’re currently logged in"
      preventRedirect
      footerContent={<AuthFooter />}
      stickyFooter
    >
      <Styled.BodyTypo variant="heading" styled={{ text: { weight: "light" } }}>
        You’re currently logged in as:
      </Styled.BodyTypo>

      <Styled.EmailTypography
        color="accent"
        styled={{ text: { align: "left" } }}
      >
        {auth.user?.email}
      </Styled.EmailTypography>

      <Styled.ContinueButton
        color="accent"
        type="button"
        variant="contained"
        href={"/"}
        fullWidth
      >
        Continue as {auth.user?.firstname}
      </Styled.ContinueButton>
      <Typography styled={{ text: { align: "center" } }}>
        <Styled.LogoutLink router href="/logout">
          Log out & proceed to sign up
        </Styled.LogoutLink>
      </Typography>
    </AuthContainer>
  );
};

export default AlreadyLoggedIn;
