import { FlowSteppedFormSchema } from "../../../types";

export const quoteSchema: FlowSteppedFormSchema = {
  Disclosure: {
    schema: {
      title: "Duty of disclosure",
      type: "object",
      required: ["Disclosure1"],
      properties: {
        Disclosure1: {
          type: "boolean",
          enum: [true],
          title:
            "Please confirm you have drawn the customer's attention to their duty of dicslosure before proceeding",
          default: false,
        },
      },
    },
    uiSchema: {},
  },
  "Business Details": {
    schema: {
      title: "Business Details",
      type: "object",
      required: ["busName", "YearsInOperation", "startDate"],
      properties: {
        busName: {
          title: "What is the customer's business name?",
          type: "string",
        },
        ABN: {
          title: "What is their ABN?",
          type: "number",
          description:
            "ABN - Australian Business Number (ABN) is a unique 11 digit identifier issued to all entities registered in the Australian Business Register (ABR).",
        },
        busStructure1: {
          title: "What is the customer's business structure?",
          type: "string",
          description:
            "Business structure is the type of entity registered with an ABN.",
          enum: ["Sole Trader", "Partnership", "Company", "Trust"],
          default: "Sole Trader",
        },
        YearsInOperation: {
          title: "What year was the business established?",
          type: "number",
          description: "e.g. 2020",
          minimum: 1200,
          maximum: 2023,
        },
        startDate: {
          title: "Policy start date",
          type: "string",
          description:
            "Policy start date - date when your insurance cover starts",
          format: "date",
        },
      },
    },
    uiSchema: {},
  },
  Location: {
    schema: {
      title: "Location",
      type: "object",
      required: ["anyExtraLoc", "useMainLocation", "statebusiness"],
      properties: {
        anyExtraLoc: {
          title:
            "Does the business have any additional locations that require insurance?",
          type: "string",
          default: "no",
          oneOf: [
            { const: "yes", title: "Yes" },
            { const: "no", title: "No" },
            { const: "unsure", title: "Unsure" },
          ],
        },
        useMainLocation: {
          title:
            "Use the main location’s business type for all additional locations?",
          type: "boolean",
          enum: [true],
        },
        statebusiness: {
          title: "What is the insured’s occupation?",
          type: "string",
          enum: ["NSW", "VIC", "TAS", "QLD", "NT", "WA", "SA", "ACT"],
          default: "NSW",
        },
      },
    },
    uiSchema: {
      anyExtraLoc: {
        "ui:widget": "radio",
      },
    },
  },
  Liability: {
    schema: {
      title: "Liability",
      type: "object",
      properties: {
        liCoverReq: {
          title: "Does the Business require Broadform Liability Cover?",
          type: "boolean",
          description:
            "General & Product Liability cover protects your business against claims for personal injury or property damage that occur because of your business activities. This includes claims related to products your business supplies to customers. Care provides cover for within Australia or any territory within Australia.",
          enum: [true], // it should be radio button
        },
        liCoverLevel: {
          title: "What level of cover is required?",
          type: "string",
          description:
            "Make sure the level of cover is specific to the customers needs, particularly if the policy is packaged with other covers. ",
          enum: ["$10 million", "$20 million"],
          default: "$10 million",
        },
        liPropVal: {
          title: "What is the estimated value of the business's property(s)?",
          type: "number",
          format: "dollar",
        },
        liWrTypCon: {
          title:
            "What type(s) of work do the contractors and/or subcontractors perform for you?",
          type: "number",
          format: "dollar",
        },
      },
    },
    uiSchema: {},
  },
  Property: {
    schema: {
      title: "Property",
      type: "object",
      required: [
        "propContentValue",
        "propConstYear",
        "propFloor",
        "propFireImpact",
      ],
      properties: {
        propCover: {
          title: "What do you want insured?",
          type: "string",
          enum: ["Building", "Contents & Stock", "Building, Contents & Stock"],
          default: "Building",
        },
        propContentValue: {
          title:
            "What is the replacement value of the contents the business wants to insure?",
          type: "number",
          format: "dollar",
          maximum: 10000000,
        },
        propConstYear: {
          title: "What is the year of construction?",
          type: "number",
          minimum: 1200,
          maximum: 2023,
        },
        propFloor: {
          title: "What is the construction floor?",
          type: "string",
          enum: ["Concrete", "Timber", "Steel", "Other", "Tile"],
          default: "Concrete",
        },
        File1: {
          title: "Upload a copy of thermal scan done post purchase",
          type: "string",
          format: "file",
        },
        propFireImpact: {
          title: "How does the business prevent and reduce the impact of fire?",
          type: "array",
          items: {
            type: "string",
            anyOf: [
              {
                const: "fire-extnguishers",
                title: "Fire Extinguishers",
              },
              {
                const: "sprinkler",
                title: "Sprinkler",
              },
              {
                const: "hose-reel",
                title: "Hose Reel",
              },
              {
                const: "fire-blanket",
                title: "Fire Balnket",
              },
              {
                const: "smoke-detection-monitored",
                title: "Monitored Smoke Detection System",
              },
              {
                const: "none",
                title: "No Fire Protection",
              },
              {
                const: "other",
                title: "Other",
              },
            ],
          },
          uniqueItems: true,
        },
        propFireProtInsp: {
          title:
            "Are the installed fire protection equipment inspected and tested on a 6 monthly basis?",
          type: "string",
          enum: ["Yes", "No", "Unsure"],
          default: "Yes",
        },
      },
    },
    uiSchema: {
      propFireImpact: {
        "ui:widget": "checkboxes",
      },
    },
  },
  "Business Interruption": {
    schema: {
      title: "Business Interruption",
      type: "object",
      required: ["biGrossIncomeProtection", "biIndemnityPeriod"],
      properties: {
        biGrossIncomeProtection: {
          title: "How much gross income does the business require ?",
          type: "number",
          description:
            "This is the money paid or payment to the insured for goods sold and/or services rendered or rent received or payable (plusoutgoings as defined. or specified in any lease) to you in the course of the business less the ourchase cost of stock and uninsured working expenses. For periods up to and including 12 minths enter the month value. For periods greater than 12 months enter the grossed up pro-rata value. For details information, please reads the PDS.",
          format: "dollar",
          maximum: 10000000,
        },
        biIndemnityPeriod: {
          title:
            "What is the indemnity period (months) the business requires? ",
          type: "string",
          enum: ["3", "6", "9", "12", "18", "24"],
          default: "3",
        },
        biRequireCoverFor: {
          title: "Does the business require cover for",
          type: "array",
          items: {
            type: "string",
            anyOf: [
              {
                const: "working-costs-increase",
                title: "Increased Cost of Working",
              },
              {
                const: "costs-claims-preperation",
                title: "Claims Preparation Costs",
              },
              {
                const: "costs-accounts-outstanding",
                title: "Outstanding Accounts Costs",
              },
              {
                const: "rent-default",
                title: "Rent Default",
              },
            ],
          },
          uniqueItems: true,
        },
      },
    },
    uiSchema: {
      biRequireCoverFor: {
        "ui:widget": "checkboxes",
      },
    },
  },
  "Theft and Money": {
    schema: {
      title: "Theft and Money",
      type: "object",
      required: ["moCover", "thContents", "thBusSecurity"],
      properties: {
        moCoverRequired: {
          title: "Does the Business require Money Cover?",
          type: "string",
          enum: ["Yes", "No"], // should be radio button
          default: "Yes",
        },
        thCoverRequired: {
          title: "Does the Business require Theft Cover?",
          type: "string",
          enum: ["Yes", "No"], // should be radio button
          default: "Yes",
        },
        moCover: {
          title:
            "What is the blanket amount of money cover the business requires?",
          description: "Select an option",
          type: "string",
          enum: ["$5000", "$10000", "$15000", "$20000"],
          default: "$5000",
        },
        thContents: {
          title: "What is the value of contents the business wants to cover?",
          type: "number",
          format: "dollar",
          maximum: 2000000,
        },
        thBusSecurity: {
          title: "What security does the business have?",
          type: "array",
          items: {
            type: "string",
            anyOf: [
              {
                const: "bars",
                title: "Bars on all external windows",
              },
              {
                const: "doors-deadlocks",
                title: "Key operated deadlocks on all external doors",
              },
              {
                const: "windows-deadlocks",
                title: "Key operated window locks on all windows",
              },
              {
                const: "after-hours-security",
                title:
                  "Premises is in a retail/office complex with after-hour security and no after-hour public access",
              },
              {
                const: "none",
                title: "none of the above",
              },
            ],
          },
          uniqueItems: true,
        },
      },
    },
    uiSchema: {
      thBusSecurity: {
        "ui:widget": "checkboxes",
      },
    },
  },
  Machinery: {
    schema: {
      title: "Machinery",
      type: "object",
      required: ["miUnspecifiedVal", "miUnspecfiedCount", "miCategory"],
      properties: {
        unspecifiedMachineryCoverProd: {
          title: "Unspecified Machinery",
          type: "boolean", //checkboxes
        },
        miUnspecifiedVal: {
          title: "Blanket sum Insured (per item)",
          type: "number",
          format: "dollar",
          maxiumum: 50000,
        },
        miUnspecfiedCount: {
          title: "Number of Items",
          type: "number",
          maxiumum: 50000,
        },
        miCategory: {
          title: "Please select the relevant category for this item",
          type: "string",
          enum: [
            "Air compressor",
            "Air conditioning - ducted system",
            "Air conditioning - portable unit",
            "Air conditioning - split system",
            "Air conditioning - wall/window unit",
            "Computer",
            "Laptop",
            "Diagnostic Equipment",
            "Electro-medical Equipment",
            "Office Equipment",
            "Scanner",
            "Tuning Equipment",
            "X-ray Tomographs",
            "Boiler",
            "Auto car wash",
            "Cash register",
            "Cool room",
            "Dish washer or glass washer",
            "Dowling machine",
            "Drilling machine",
            "Dry cleaning machine",
            "Engine diagnostic unit",
            "Espresso coffee machine",
            "Fan - supply/exhaust",
            "Folding machine",
            "Forklift",
            "Refrigerator (excluding cold room)",
            "Gearbox",
            "Grinding machine",
            "Guillotine",
            "Hoist",
            "Hydro extractor",
            "Ice making machine",
            "Imagesetter machine",
            "Ironing machine",
            "Lathes",
            "Lighting plant",
            "Mills (hammer)",
            "Milling machine",
            "Microwave",
            "Planer (multi-head)",
            "Press",
            "Printer",
            "Pump - electric",
            "Pump - petrol/diesel",
            "Pump - submersible",
            "Punching machine",
            "Rectifier",
            "Refrigerant reclaim unit",
            "Sanding machine",
            "Saws (all types)",
            "Scales",
            "Scanner",
            "Shearing plant",
            "Slicing/mincing/mixing machine",
            "Soft serve ice cream units",
            "Steam Press",
            "Switchboard",
            "Temprite",
            "Thicknesser",
            "Vacuum cleaners",
            "Vat",
            "Washing machine/dryers",
            "Weigh bridge - electronic",
            "Welder - electric",
            "Wheel alignment/balancing unit",
            "Other",
          ],
          default: "Air compressor",
        },
        miSerialNumber: {
          title: "Serial number",
          type: "string",
        },
      },
    },
    uiSchema: {},
  },
  "Electronic Equipment": {
    schema: {
      title: "Electronic Equipment",
      type: "object",
      required: [
        "eqCategory",
        "eqPurchaseYr",
        "eqDescription",
        "eqReplacementVal",
      ],
      properties: {
        eqCategory: {
          title: "Please select a relevant category for this item",
          type: "string",
          enum: [
            "Audio Visual Equipment",
            "Computer, Laptop",
            "Diagnostic Equipment",
            "Electro-medical Equipment",
            "Office Equipment, Scanner",
            "Tuning Equipment",
            "X-ray Tomographs",
            "Other",
          ],
          default: "Audio Visual Equipment",
        },
        eqPurchaseYr: {
          title: "What year was the item purchased?",
          type: "number",
          minimum: 1200,
          maximum: 2023,
        },
        eqDescription: {
          title: "Please provide description of this item",
          type: "string",
        },
        eqReplacementVal: {
          title: "What is the replacement value of the item? (max. $60,000)",
          type: "number",
          description:
            "In the event of insured damage under this section we will pay you up to the sum insured less the " +
            "applicable ecess for the reasonable cost of repairs or replacement necessary to return the insured item to " +
            "its former state of operation.",
          format: "dollar",
          mimumum: 1,
          maxiumum: 60000,
        },
        eqCostOfWork: {
          title: "Increased Costs of Working",
          type: "boolean",
          enum: ["Increased Costs of Working"],
        },
      },
    },
    uiSchema: {},
  },
  Gaming: {
    schema: {
      title: "Gaming",
      type: "object",
      required: [],
      properties: {
        gmIsGamingAtVenue: {
          title: "Is there any gaming at the venue?",
          type: "string",
          format: "radio", // it should be radio button
        },
      },
    },
    uiSchema: {},
  },
  Manufacturing: {
    schema: {
      title: "Manufacturing",
      type: "object",
      properties: {
        mqWasteRemov: {
          title:
            "At what frequency is waste removed from the customer’s premise/s?",
          type: "string",
          enum: ["Daily", "Weekly", "Fortnightly", "Monthly"],
          default: "Daily",
        },
        mqPlaEquManu: {
          title: "Does the customer use a plant or equipment to manufacture?",
          type: "string",
          enum: ["Yes", "No"],
          default: "Yes",
        },
      },
    },
    uiSchema: {
      mqPlaEquManu: {
        "ui:widget": "radio",
      },
    },
  },
  "Cooking Details": {
    schema: {
      title: "Cooking Details",
      type: "object",
      properties: {
        cookingType: {
          title:
            "What type of cooking equipment is used across your premise('s)?",
          description: "Answer these questions for all locations",
          type: "array",
          items: {
            type: "string",
            anyOf: [
              {
                const: "gas-cook-top",
                title: "Natural gas / LPG Cook top",
              },
              {
                const: "burner-wok",
                title: "Wok burner",
              },
              {
                const: "gas-hot-plate",
                title: "Natural gas LPG hot plate",
              },
              {
                const: "oven-electric",
                title: "Electric oven",
              },
              {
                const: "oven-gas",
                title: "Gas oven",
              },
              {
                const: "fryer-benchtop-deep",
                title: "Benchtop deep fat fryer",
              },
              {
                const: "fryer-floor-mounted",
                title: "Floor mounted deep fat fryer",
              },
              {
                const: "solid-fuel",
                title: "Solid Fuel cooking",
              },
              {
                const: "other",
                title: "Other",
              },
            ],
          },
          uniqueItems: true,
        },
        FMElecOver25L: {
          title:
            "Are any of the floor mounted electric deep fat fryers over 25L ?",
          type: "string",
          enum: ["Yes", "No"],
          default: "Yes",
        },
        exAtomCleanedFreq: {
          title:
            "How often is the ducting to the external atmosphere cleaned professionally?",
          type: "string",
          enum: ["Quarterly", "6 monthly", "Yearly", "Never"],
          default: "Quarterly",
        },
      },
    },
    uiSchema: {
      cookingType: {
        "ui:widget": "checkboxes",
      },
      FMElecOver25L: {
        "ui:widget": "radio",
      },
    },
  },
  "Portable Contents": {
    schema: {
      title: "Portable contents",
      type: "object",
      require: ["pcType"],
      properties: {
        pcType: {
          title: "Select property type",
          type: "string",
          enum: [
            "Hired out Property",
            "Laptop/s, Mobile Phone/s, Notepad/s and Tablet Computer/s",
            "Photographic Equipment",
            "Radio Equipment",
            "Sound Equipment and Musical Instruments",
            "Surveying Equipment",
            "Tools of Trade",
            "Other",
          ],
          default: "Hired out Property",
        },
      },
    },
    uiSchema: {},
  },
  "Claims History": {
    schema: {
      title: "Claims History",
      type: "object",
      required: ["hasPastClaim", "claimCover"],
      properties: {
        hasPastClaim: {
          title:
            "In the last five years, has the business made any claims or are there any pending " +
            "claims of loss and damage worth more than $5,000?",
          type: "string",
          enum: ["Yes", "No"],
          default: "Yes",
        },
        claimCover: {
          title: "Cover",
          type: "string",
          enum: [
            "Property",
            "Broadform Liability",
            "Business Interruption",
            "Theft",
            "Money, Glass",
            "Electronic Equipment",
            "Other",
          ],
          default: "Property",
        },
      },
    },
    uiSchema: {
      hasPastClaim: {
        "ui:widget": "radio",
      },
    },
  },
  "Disclosure Declaration": {
    schema: {
      title: "Disclosur eDeclaration",
      type: "object",
      required: ["bankrupt"],
      properties: {
        bankrupt: {
          title:
            "Has the business, or any of its partners or directors ever been declared bankrupt ?",
          type: "string",
          enum: ["Yes", "No"],
          default: "Yes",
        },
      },
    },
    uiSchema: {
      bankrupt: {
        "ui:widget": "radio",
      },
    },
  },
  "Submit for Referal": {
    schema: {
      title: "Submit for Referal ",
      type: "object",
      properties: {
        referralreason: {
          title: "What is the reason for Referral?",
          type: "array",
          items: {
            type: "string",
            anyOf: [
              {
                const: "amend-expiry",
                title: "Amend Expiry Date",
              },
              {
                const: "query-clauses",
                title: "Clauses query",
              },
              {
                const: "query-interested-party",
                title: "Interested Party Query",
              },
              {
                const: "adjustment-comissions-net",
                title: "Net Commissions Adjustment",
              },
              {
                const: "adjustment-premium",
                title: "Premium Adjustment",
              },
              {
                const: "verification-occupation",
                title: "Verify Occupation",
              },
              {
                const: "other",
                title: "Other",
              },
            ],
          },
          uniqueItems: true,
        },
        AdditionalInformation: {
          title: "Additional Information",
          type: "string",
        },
      },
    },
    uiSchema: {
      referralreason: {
        "ui:widget": "checkboxes",
      },
    },
  },
};

export const ClaimFormUISchema = {
  Disclosure1: {
    "ui:widget": "checkboxes",
    "ui:options": {
      inline: true,
    },
  },
  anyExtraLoc: {
    "ui:widget": "checkbox",
  },
  useMainLocation: {
    "ui:widget": "checkbox",
  },
  liCoverReq: {
    "ui:widget": "checkbox",
  },
};
