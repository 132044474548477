import { WidgetProps } from "@rjsf/utils";
import { handleInputValue } from "../../../../helpers";
import JfsInputControl from "./../jfsInputControl/jfsInputControl";
import TextFieldPrepend from "./prepend/textFieldPrepend";
import * as Styled from "./styles.css";

function JfsTextField({
  schema,
  id,
  grow,
  options,
  label,
  value,
  type,
  placeholder,
  required,
  disabled,
  readonly,
  autofocus,
  onChange,
  onBlur,
  onFocus,
  rawErrors,
  hideError,
  uiSchema,
  registry,
  size,
  formContext,
  ...otherProps
}: JfsTextFieldProps) {
  const onTextChange = ({
    target: { value: val },
  }: React.ChangeEvent<HTMLInputElement>) => {
    onChange(handleInputValue(val, options));
  };
  const onTextBlur = ({
    target: { value: val },
  }: React.FocusEvent<HTMLInputElement>) => onBlur(id, val);
  const onTextFocus = ({
    target: { value: val },
  }: React.FocusEvent<HTMLInputElement>) => onFocus(id, val);

  return (
    <JfsInputControl grow={grow} size={size} type={type}>
      <TextFieldPrepend
        format={schema.format}
        size={size}
        type={schema.type as string}
      />
      <Styled.TextField
        id={id}
        data-error={rawErrors}
        data-format={schema.format}
        label={label}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        error={rawErrors}
        onChange={onTextChange}
        onBlur={onTextBlur}
        onFocus={onTextFocus}
        type={type}
        $editable={true}
        $error={rawErrors}
        $size={size}
        $type={type}
        {...otherProps}
      />
    </JfsInputControl>
  );
}

interface JfsTextFieldProps extends WidgetProps {}

JfsTextField.defaultProps = {
  disabled: false,
  editable: true,
  error: false,
  fullWidth: true,
  paragraph: false,
  size: "md",
  type: "text",
  value: "",
};

export type TextFieldTypes = "email" | "text" | "password" | "tel";

export default JfsTextField;
