import { useContext, useState } from "react";
import ActivityService, {
  Activity,
} from "../../../services/activity/activity.service";
import AuthContext from "./../../../contexts/authContext";
import { Button, Grid, TabPanel, Tabs, Typography } from "./../../util";
import * as Styled from "./styles.css";
import { recordAnalyticsEvent } from "../../../analytics";
import safetycultureService from "../../../services/safetyculture/safetyculture.service";
import OnboardingTab from "./onboardingTab";

const OnboardingNewUser = () => {
  const auth = useContext(AuthContext);

  const [NCEDismissed, setNCEDismissed] = useState(
    auth.user.activity.some(
      (entry: Activity) =>
        entry.type === "Onboarding" &&
        entry.action === "New Client Experience dismissed",
    ),
  );

  const isiAuditorStepComplete = auth.user.activity.some((entry: Activity) => {
    if (entry.type === "iAuditor Inspection" && entry.action === "Completed") {
      return true;
    }
    return false;
  });

  const handleDismiss = () => {
    ActivityService.createUserActivity({
      type: "Onboarding",
      action: "New Client Experience dismissed",
    });
    setNCEDismissed(true);
  };

  if (NCEDismissed) {
    return <></>;
  }

  return (
    <Styled.OnboardingContainer corners="rounded" outlined>
      <Styled.Header title="Getting started with Care"></Styled.Header>
      <Grid container>
        <Tabs
          id="newuser"
          orientation="vertical"
          variant="contained"
          tabs={[
            <OnboardingTab
              complete={auth.user.activity.some(
                (entry: Activity) =>
                  entry.type === "Self Assessment" &&
                  entry.action === "Completed",
              )}
              icon="checklist"
              id="self-assessment"
              label={"Complete self assessment"}
              onComplete={["disabled", "restricted"]}
            />,
            <OnboardingTab
              complete={isiAuditorStepComplete}
              icon="tasklist"
              id="iauditor"
              label={`Complete first inspection`}
              onComplete={["disabled", "restricted"]}
            />,
            <OnboardingTab
              complete={auth.user.activity.some(
                (entry: Activity) =>
                  entry.type === "Virtual survey" && entry.action === "Booked",
              )}
              icon="calendar"
              id="risk-assessment"
              label={"Schedule virtual survey"}
              onComplete={["disabled", "restricted"]}
            />,
            <OnboardingTab
              hidden
              icon="check"
              id="complete"
              label={"Onboarding Complete"}
            />,
          ]}
        >
          <TabPanel id="self-assessment">
            <Typography element="h3" paragraph variant="heading">
              Complete your first self assessment
            </Typography>
            <Styled.PanelBodyText paragraph>
              Identify ways to make your business safer with easy to implement
              recommendations while comparing your risk to the industry average
              and best practice.
            </Styled.PanelBodyText>
            <Button
              color="accent"
              href={{
                pathname: "/risk-and-safety",
                state: { tabs: { score: "self-assessment" } },
              }}
              router
              variant="contained"
            >
              Conduct assessment
            </Button>
          </TabPanel>

          <TabPanel id="iauditor">
            <Styled.PanelHeading element="h3" paragraph variant="heading">
              Start your first inspection
            </Styled.PanelHeading>
            <Styled.PanelBodyText paragraph>
              Your SafetyCulture account is connected! You can now complete your
              first inspection and enhance the compliance and safety of your
              business.
            </Styled.PanelBodyText>
            <Button
              color="accent"
              variant="contained"
              isNewTab
              href={safetycultureService.getiAuditorTemplateLink()}
              onClick={() => {
                recordAnalyticsEvent("action", "iAuditor", {
                  type: "OnBoarding widget",
                  action: "Start inspection",
                });
              }}
            >
              Start inspection
            </Button>
          </TabPanel>

          <TabPanel id="risk-assessment">
            <Typography paragraph variant="heading">
              Book your first virtual survey
            </Typography>
            <Styled.PanelBodyText paragraph>
              SafetyCulture is a mobile and web inspection app. By using Care’s
              tailored SafetyCulture templates, you will be able to easily
              improve safety and quality standards across your business.
            </Styled.PanelBodyText>
            <Button
              color="accent"
              href="/book-assessment"
              router
              variant="contained"
            >
              Book survey
            </Button>
          </TabPanel>
          <TabPanel id="complete">
            <Typography paragraph variant="heading">
              You're all set with Care
            </Typography>
            <Styled.PanelBodyText>
              Thank you for getting started with Care.
            </Styled.PanelBodyText>
            <Styled.PanelBodyText paragraph>
              Come back regularly to continue mitigating risks in your business.
            </Styled.PanelBodyText>
            <Button onClick={() => handleDismiss()} variant="outlined">
              Dismiss
            </Button>
          </TabPanel>
        </Tabs>
      </Grid>
    </Styled.OnboardingContainer>
  );
};

export default OnboardingNewUser;
