import styled from "styled-components";
import { breakpoints, theme } from "../../../../../styles";
import { Button, Tabs } from "../../../../util";

export const FlowTabs = styled(Tabs).attrs((props: any) => ({}))`
  align-items: center;
  background-color: ${theme.color.surface.background.default.default};
  border-bottom: 1px solid ${theme.color.surface.border.weak.default};
  box-shadow: none;
  display: flex;
  flex-direction: row;
  height: 56px;
  left: 256px;
  margin-bottom: 0px;
  position: fixed;
  right: 0;
  text-decoration: center;
  top: 0;
  width: auto;
  z-index: 999;

  @media ${breakpoints.up("lg")} {
    padding: 0px 48px;
  }

  & ul {
    flex-grow: 1;
    justify-content: center;
  }
`;

export const BackButton = styled(Button).attrs((props: any) => ({}))`
  padding-left: 0px;
  color: ${theme.color.accent.text.default.default};
  span {
    margin-right: 8px;
  }
`;
