import styled from "styled-components";
import { breakpoints, theme } from "../../styles";
import {
  Button,
  Container as container,
  Footer,
  FormRow as Formrow,
  Grid,
  List as FurList,
  ListItem as FurListItem,
  Typography,
} from "../../components/util";
import { Logo as FurLogo } from "../../components";
import { Header } from "../../components/layout";
import { GridProps } from "@material-ui/core";
export interface StyledGridProps extends GridProps {
  $fullHeight?: boolean;
}

export const Sidebar = styled("aside").attrs((props: any) => ({
  $isSidebarVisible: props.$isSidebarVisible,
}))`
  flex-basis: 0;
  width: 100%;

  ${(props: any) => {
    if (props.$isSidebarVisible) {
      return `
        @media ${breakpoints.up("xl")} {
          position: fixed;
          left: 0;
          top: 0;
          right: 0;
          flex-basis: auto;
          background-color: ${theme.color.surface.background.weakest.default};
          height: 100%;
          width: 500px;
        }
      `;
    }
  }}
`;

export const Back = styled("header")<BackProps>`
  min-height: 52px;
  padding: 8px 0 8px 18px;
  ${(props: any) => {
    if (props.$isSidebarVisible) {
      return `
        @media ${breakpoints.up("xl")} {
        }
      `;
    }
  }}

  @media ${breakpoints.up("sm")} {
    padding: 16px 0 16px 26px;
  }

  @media ${breakpoints.up("xl")} {
    margin-bottom: 8px;
  }

  a {
    align-items: center;
    display: flex;
    height: 36px;
    text-decoration: none;
  }
  span {
    margin-right: 6px;
  }
`;

export const Container = styled("div").attrs(props => ({}))`
  @media ${breakpoints.up("xl")} {
    padding: 0 70px;
  }
`;

export const LoginContainer = styled(container)`
  text-align: center;
  margin-top: 50px;
`;
export const Logo = styled(FurLogo)`
  margin-top: 68px;
  margin-bottom: 50px;
`;

export const List = styled(FurList).attrs(props => ({}))`
  margin-top: 40px;
`;

export const ListItem = styled(FurListItem).attrs(props => ({}))`
  flex-wrap: top;
  &:not(last-of-type) {
    margin-bottom: 24px;
    color: ${theme.color.surface.text.strong.default};
  }
  p {
    margin-top: 0px;
  }
`;

export const Figure = styled("figure").attrs((props: any) => ({}))`
  margin-top: 60px;
  margin-bottom: 25px;
  font-size: 16px;
  font-style: oblique 10deg;
  line-height: 24px;
`;

export const Person = styled(Grid).attrs((props: any) => ({}))`
  &:first-of-type {
    margin-right: 16px;
  }
`;

export const AuthHeader = styled(Header).attrs((props: any) => ({}))`
  padding-top: 32px;
  margin-bottom: 16px;
  & h1 {
    -webkit-line-clamp: 2;
  }
`;

export const AuthContent = styled(Grid).attrs((props: any) => ({
  $isFooterVisible: props.$isFooterVisible,
  $isSidebarVisible: props.$isSidebarVisible,
  $isStickyFooter: props.$isStickyFooter,
}))`
  margin: 0 auto;
  max-width: 480px;
  padding: 36px 20px 36px 20px;
  width: 100%;

  @media ${breakpoints.up("sm")} {
    padding-bottom: 60px;
    padding-top: 60px;
  }

  @media ${breakpoints.up("xl")} {
    padding-bottom: 112px;
    padding-top: 112px;
  }

  ${(props: any) => {
    if (props.$isSidebarVisible) {
      return `
        @media ${breakpoints.up("xl")} {
          padding-left: 520px;
          max-width: 980px;
        }
      `;
    }
  }}

  ${(props: any) => {
    if (props.$isStickyFooter) {
      return `
        padding-top: 0;
        padding-bottom: 0;
        flex: 1 0 auto;
      `;
    }
  }}
`;

export const AuthFooter = styled(Footer).attrs((props: any) => ({
  $isStickyFooter: props.$isStickyFooter,
}))`
  margin: 0 auto;
  max-width: 480px;
  width: 100%;

  ${(props: any) => {
    if (props.$isStickyFooter) {
      return `
        flex-shrink: 0;

      `;
    }
  }}
`;
export const FooterTypography = styled(Typography)`
  margin-bottom: 24px;
`;
export const VerificationFormSubheading = styled(Typography)`
  margin-top: 24px;
  margin-bottom: 10px;
`;
export const ResendVerificationFormSubheading = styled(Typography)`
  margin-top: 24px;
`;
export const FormRow = styled(Formrow)`
  margin-top: 24px;
`;
export const ResendPasswordButton = styled(Button)`
  margin-top: 15px;
`;

export const ListItemBoldText = styled("span")`
  font-weight: 600;
`;

export const ListItemLightText = styled("span")`
  font-weight: 400;
  font-size: 16px;
  &::before {
    content: " ";
    white-space: pre;
  }
`;

export interface BackProps {
  $isSidebarVisible: boolean;
}
