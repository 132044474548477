import { useState } from "react";
import { Container, IconButton } from "../../util";
import { EditInline, EditAddress, EditInlineSelect } from "../../content";
import EditUserRole from "../forms/edit/editUserRole";

const Editable = ({
  address,
  children,
  getList,
  listPruner,
  fieldValue,
  hasPermission,
  select,
  userRole,

  ...otherProps
}: iEditableProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const toggleEditing = () => setIsEditing(!isEditing);

  if (!isEditing) {
    return (
      <>
        <Container element="span">{fieldValue}</Container>
        {hasPermission && (
          <IconButton
            icon="pencil"
            iconColor="accent"
            onClick={() => toggleEditing()}
          />
        )}
      </>
    );
  }
  if (address) {
    return (
      <EditAddress
        onClose={() => toggleEditing()}
        toggleEditing={() => toggleEditing()}
        {...otherProps}
      />
    );
  }
  if (userRole) {
    return (
      <EditUserRole
        onClose={() => toggleEditing()}
        toggleEditing={() => toggleEditing()}
        {...otherProps}
      />
    );
  }
  if (getList) {
    return (
      <EditInlineSelect
        getList={getList}
        listPruner={listPruner}
        onClose={() => toggleEditing()}
        toggleEditing={() => toggleEditing()}
        {...otherProps}
      />
    );
  }
  return (
    <EditInline
      onClose={() => toggleEditing()}
      toggleEditing={() => toggleEditing()}
      {...otherProps}
    />
  );
};

interface iEditableProps {
  address?: boolean;
  children?: React.ReactNode;
  getList?: any;
  listPruner?: any;
  field: string;
  fieldValue: string | React.ReactNode;
  hasPermission?: boolean;
  record: Record<string, any>;
  recordType: string;
  select?: boolean;
  onSubmitService: (any) => Promise<any>;
  onSubmitRefresh: () => Promise<void>;
  userRole?: boolean;
  validationSchema: any;
}

Editable.defaultValues = {
  hasPermission: false,
};

export default Editable;
