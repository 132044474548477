import {
  ActivityTable,
  AssociatedClaims,
  PolicyLabel,
  Summary,
  Toolkit,
} from "../../../components/content";
import { IPolicy } from "../../../types";
import { Header } from "../../../components/layout";
import {
  Link,
  TableRow,
  TableCell,
  TableRowSubhead,
} from "../../../components/util";
import * as Styled from "./styles.css";
import { Hidden } from "@material-ui/core";
import { commaSeparated, formatDateTime } from "../../../helpers";
import PolicyTabs from "./policyTabs";
import Invites from "./invites";

const Bpk = ({ policy, back_text }: { policy: IPolicy; back_text: string }) => {
  const { policy_data } = policy;
  const { policy_number } = policy_data;
  return (
    <>
      <Header
        title={policy_number}
        titleIcon="file"
        titleIconPosition="end"
        type="article"
      >
        <Hidden xsDown>
          <Styled.SubHeader element="span" variant="heading">
            {policy_data.property_damage_cover
              ? commaSeparated([
                  policy_data.customer_last_name,
                  policy_data.customer_first_name,
                ])
              : policy_data.customer_insured_name}
          </Styled.SubHeader>
        </Hidden>
      </Header>

      <Summary
        data={[
          {
            Type: <PolicyLabel type={policy.type} />,
          },
          {
            Created: formatDateTime(
              policy_data.policy_created_date ||
                policy_data.policy_inception_date ||
                "",
              "verbose",
            ),
          },
          {
            Incepted: formatDateTime(
              policy_data.policy_inception_date || "",
              "verbose",
            ),
          },
          {
            Expiry: formatDateTime(
              policy_data.policy_expiry_date || "",
              "verbose",
            ),
          },
        ]}
      />
      <PolicyTabs policy={policy} policyNumber={policy_number}>
        <>
          <Header title="Policy" type="section" />
          <Styled.Table
            columns={[
              { title: "", expand: true },
              { title: "", expand: true },
            ]}
            orientation="horizontal"
          >
            <TableRowSubhead>Insured details</TableRowSubhead>
            <TableRow>
              <TableCell>Policy number</TableCell>
              <TableCell>{policy_number}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Insured name</TableCell>
              <TableCell>{policy_data.customer_insured_name}</TableCell>
            </TableRow>
            {policy_data.billing_entity && (
              <TableRow>
                <TableCell>Billing entity</TableCell>
                <TableCell>{policy_data.billing_entity}</TableCell>
              </TableRow>
            )}
            {policy_data.occupation && (
              <TableRow>
                <TableCell>Occupation</TableCell>
                <TableCell>{policy_data.occupation}</TableCell>
              </TableRow>
            )}
            {policy_data.anzsic_code !== undefined && (
              <TableRow>
                <TableCell>ANZSIC code</TableCell>
                <TableCell>{policy_data.anzsic_code}</TableCell>
              </TableRow>
            )}
            {policy_data.transaction_type && (
              <TableRow>
                <TableCell>Transaction type</TableCell>
                <TableCell>{policy_data.transaction_type}</TableCell>
              </TableRow>
            )}
            <TableRow></TableRow>
            <TableRow>
              <TableCell>Team</TableCell>
              <TableCell>{policy_data.team_id}</TableCell>
            </TableRow>

            <TableRowSubhead>Contact details</TableRowSubhead>
            {policy_data.customer_first_name && (
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>
                  {`${policy_data.customer_first_name} ${policy_data.customer_last_name}`}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>
                <Link href={`mailto:${policy_data.customer_email}`}>
                  {policy_data.customer_email}
                </Link>
              </TableCell>
            </TableRow>

            <TableRowSubhead>Agent details</TableRowSubhead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>{policy_data.agent_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>{policy_data.agent_email}</TableCell>
            </TableRow>
            {policy_data.agent_role && (
              <TableRow>
                <TableCell>Role</TableCell>
                <TableCell>{policy_data.agent_role}</TableCell>
              </TableRow>
            )}
            {(policy_data.broker || policy_data.network) && (
              <>
                <TableRowSubhead>Broker details</TableRowSubhead>
                <TableRow>
                  <TableCell>Broker name</TableCell>
                  <TableCell>{policy_data.broker}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Broker network</TableCell>
                  <TableCell>{policy_data.network}</TableCell>
                </TableRow>
              </>
            )}
          </Styled.Table>
          {!policy.customer_org_id && !policy.invitations && (
            <Invites client={policy} organisation_id={policy.broker_org_id} />
          )}
          <AssociatedClaims
            policyNumber={policy_number}
            policyId={policy.id}
            claims={undefined}
            policyType={policy.type}
          />
          {policy.customer_org_id && <Toolkit orgId={policy.customer_org_id} />}
          {policy && <ActivityTable policy_id={policy.id} />}
        </>
      </PolicyTabs>
    </>
  );
};

export default Bpk;
