import { Grid, Typography } from "../../util";
import * as Styled from "./styles.css";

const NewsItem = ({ title, image, link, text }: NewsItemProps) => {
  return (
    <Grid element="li" item xs={12} sm={6} md={4} xl={3} key={title}>
      <Styled.NewsLink href={link}>
        <Styled.NewsImage aspectRatio={"16:9"} src={image} alt={title} />
        <Typography
          paragraph
          variant="subtitle2"
          styled={{ text: { color: "surface" } }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          styled={{ text: { color: "surface", weight: "default" } }}
        >
          {text}
        </Typography>
      </Styled.NewsLink>
    </Grid>
  );
};

export interface NewsItemProps {
  image: string;
  key: string | number;
  link: string;
  text: string;
  title: string;
}

NewsItem.defaultProps = {
  key: 1,
};

export default NewsItem;
