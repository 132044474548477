import { Typography } from "./../../../util";
import * as Styled from "./styles.css";

const Footer = () => {
  return (
    <Styled.AppFooter>
      <Typography variant="body2">
        Insurance products are issued by SafetyCulture Care Australia Pty Ltd
        ABN 54 662 653 303 AFSL 544306 (SafetyCulture Care) as agent for the
        insurer Allianz Australia Insurance Limited ABN 15 000 122 850 AFS
        Licence No 234708 (Allianz). SafetyCulture Care has a binding authority
        to issue, renew, vary and cancel contracts of insurance and to handle
        and settle claims on behalf of Allianz.
      </Typography>

      {process.env.REACT_APP_VERSION && (
        <Styled.Version>
          release: {process.env.REACT_APP_VERSION}
        </Styled.Version>
      )}
    </Styled.AppFooter>
  );
};
export default Footer;
