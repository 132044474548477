import { TableRowProps } from "./tableRow";
import { TableCell, Typography } from "..";
import * as Styled from "./styles.css";

const TableRowSubhead = ({
  children,
  ...otherProps
}: iTableRowSubheadProps) => {
  return (
    <Styled.TableRowSubhead element="tr" {...otherProps}>
      <TableCell colspan="100%">
        <Typography variant="subHeading">{children}</Typography>
      </TableCell>
      <TableCell colspan="100%"></TableCell>
    </Styled.TableRowSubhead>
  );
};

interface iTableRowSubheadProps extends TableRowProps {}

export default TableRowSubhead;
