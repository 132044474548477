import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { IFRAMEDEFAULTS } from "./constants";
import { theme } from "../../../styles";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    marginBottom: theme.spacing,
  },
  iframe: {},
  proportionateContainer: {
    position: "relative",
    paddingBottom: "56.25%" /* 16:9 */,
    height: "0",
  },
  proportionateIframe: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },
}));

interface IframeProps {
  type?: "youtube";
  src?: string;
  title?: string;
  classes?: {
    container?: any;
    iframe?: any;
  };
}

const Iframe = ({ type, src, title, classes }: IframeProps) => {
  const styles = useStyles();

  return (
    <div
      className={clsx(
        styles.container,
        {
          [styles.proportionateContainer]: (type = "youtube"),
        },
        classes?.container,
      )}
    >
      <iframe
        src={src}
        className={clsx(
          styles.iframe,
          {
            [styles.proportionateIframe]: (type = "youtube"),
          },
          classes?.iframe,
        )}
        title={title}
        {...IFRAMEDEFAULTS[type]}
      />
    </div>
  );
};

Iframe.defaultProps = {
  type: "default",
  title: "iframe embed",
};
export default Iframe;
