import styled from "styled-components";
import { breakpoints, theme } from "./../../../../styles";
import { Footer, Typography } from "../../../util";

export const AppFooter = styled(Footer)`
  margin-top: 48px;
  max-width: 1304px;
  padding: 0 20px;

  @media ${breakpoints.up("md")} {
    padding: 0 32px;
  }
  @media ${breakpoints.up("xl")} {
    padding: 0 48px;
  }
  @media ${breakpoints.up("xxl")} {
    max-width: 1464px; /* 1304 + 112 + 48 */
    padding-left: 112px;
    padding-right: 48px;
  }

  p {
    border-top: 1px solid ${theme.color.surface.border.weak.default};
    padding-bottom: 20px;
    padding-top: 20px;
    color: ${theme.color.surface.text.weakest.default};
  }
`;

export const Version = styled(Typography).attrs(() => ({
  textAlign: "right",
  variant: "body2",
}))`
  border-top: none !important;
  padding-top: 0px !important;
  padding-bottom: 5px !important;
  font-size: 7pt;
`;
