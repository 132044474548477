import styled from "styled-components";
import { breakpoints, theme } from "../../../styles";
import { Button, Container, Icon, Image, Tab, Typography } from "../../util";
import { Header as FurHeader } from "../../layout";
import { Grid } from "@material-ui/core";

export const OnboardingContainer = styled(Container).attrs(
  (props: any) => ({}),
)`
  margin-top: 40px;
  margin-left: -21px;
  margin-right: -21px;
  @media ${breakpoints.up("md")} {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const OnboardingTab = styled(Tab).attrs((props: any) => ({
  $complete: props.$complete,
}))`
  &[disabled] {
    background-color: ${(props: any) =>
      props.$complete
        ? theme.color.positive.background.weakest.default
        : "transparent"};
  }
`;

export const Header = styled(FurHeader).attrs((props: any) => ({}))`
  border-bottom: 1px solid ${theme.color.surface.border.default.default};
  flex-direction: row;
  margin: 0;
  padding: 20px 24px;

  && > div {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

export const PanelHeading = styled(Typography).attrs((props: any) => ({}))`
  margin-bottom: 12px;
`;

export const PanelBodyText = styled(Typography).attrs((props: any) => ({
  $paragraph: props.paragraph,
}))`
  color: ${theme.color.surface.text.strong.default};

  ${({ $paragraph }) =>
    $paragraph &&
    `
      margin-bottom: 16px;
    `}
`;

export const SubmitButton = styled(Button).attrs((props: any) => ({}))`
  margin-right: 8px;
`;

export const LogoHeader = styled(Image)`
  padding-top: 10px;
  justify-content: flex-start;
  vertical-align: bottom;
`;
export const ModalBody = styled(Container)`
  padding: 0px 24px;
  line-height: 20px;
  color: ${theme.color.surface.text.strong.default};
`;
export const ModalFooter = styled(Container)`
  display: table;
  padding-left: 23px;
  padding-right: 23px;
  padding-top: 10px;
  @media ${breakpoints.up("md")} {
    display: flex;
    padding-top: 30px;
    padding-left: 0px;
    padding-bottom: 24px;
    padding-right: 0px;
  }
`;
export const ModalFooterGrid = styled(Grid)`
  padding-top: 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${theme.color.surface.border.default.default};
  @media ${breakpoints.up("md")} {
    padding-top: 0px;
    padding-bottom: 0px;
    padding: 0px 22px;
    border-left: 1px solid ${theme.color.surface.border.default.default};
    border-bottom: none;
  }
`;
export const ModalFooterButtton = styled(Button)`
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 5px 13px;
  @media ${breakpoints.up("md")} {
    margin-top: 20px;
    margin-bottom: 0px;
  }
`;

export const SyncIcon = styled(Icon)`
  margin-right: 8px;
`;
