import styled from "styled-components";

import { Container } from "..";

export const FormSection = styled("div").attrs((props: any) => ({}))`
  padding-top: 1em;
`;

export const ButtonContainer = styled(Container)`
  display: flex;
  gap: 8px;
`;
