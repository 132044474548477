import { FieldProps } from "@rjsf/utils";

function JfsMarkup({ ...otherProps }: JfsMarkupProps) {
  // JfsField for rendering desciptions / labels in forms, no inputs
  // Use "description" to render text and make sure fields are not required
  return null;
}

interface JfsMarkupProps extends FieldProps {}

JfsMarkup.defaultProps = {};

export default JfsMarkup;
