import React from "react";
import { TStyleSize } from "../../../types";
import { ContainerProps } from "../container/container";
import * as Styled from "./styles.css";

const ButtonBase = React.forwardRef(
  (
    {
      children,
      onClick,
      componentType = "button",
      ...otherProps
    }: ButtonBaseProps,
    ref,
  ) => {
    return (
      <Styled.ButtonBase
        element="button"
        onClick={onClick}
        componentType={componentType}
        ref={ref as any}
        {...otherProps}
      >
        {children && children}
      </Styled.ButtonBase>
    );
  },
);

export interface ButtonBaseProps extends ContainerProps {
  onClick?: React.ReactEventHandler;
  size?: TStyleSize;
}

ButtonBase.defaultProps = {
  size: "md",
  componentType: "button",
};

export default ButtonBase;
