import React from "react";
import { DeepdiveKeyStatisticsInterface } from "../../../services/prismic/prismic.service";
import { Box, Divider, makeStyles } from "@material-ui/core";
import { Card, Grid, Typography } from "../../../components/util";
import { theme } from "../../../styles";

interface StatPropsInterface {
  stats?: DeepdiveKeyStatisticsInterface[];
}

const useStyles = makeStyles(() => ({
  subtitle: {
    color: theme.color.surface.text.default.default,
    margin: `${theme.spacing} 0`,
  },
  divider: {
    margin: `${theme.spacing} 0`,
  },
}));

const Stats = ({ stats }: StatPropsInterface) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Card isInGrid isPadded>
        <Typography variant="displaySmall">Key statistics</Typography>
        {stats &&
          stats.map((stat, i) => (
            <Box key={i}>
              <Typography variant="displaySmall">{stat.title}</Typography>
              <Typography>{stat.description}</Typography>
              {i + 1 !== stats.length && (
                <Divider classes={{ root: classes.divider }} />
              )}
            </Box>
          ))}
      </Card>
    </Grid>
  );
};

export default Stats;
