import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../styles";

const SidebarContext = React.createContext<SidebarContextInterface>({
  isHeaderVisible: true,
  isSidebarVisible: true,
  toggleSidebar: () => {},
});

interface SidebarContextInterface {
  isHeaderVisible: boolean;
  isSidebarVisible: boolean;
  toggleSidebar: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

interface SidebarContextProviderProps {
  children: React.ReactNode;
}

export const SidebarContextProvider = ({
  children,
}: SidebarContextProviderProps) => {
  const isDesktop = useMediaQuery({ query: breakpoints.up("xl") });
  const [isSidebarVisible, setIsSidebarVisible] = useState(isDesktop);
  const [isHeaderVisible, setIsHeaderVisible] = useState(!isDesktop);

  useEffect(() => {
    if (isDesktop) {
      setIsSidebarVisible(true);
      setIsHeaderVisible(false);
    }
    if (!isDesktop) {
      setIsSidebarVisible(false);
      setIsHeaderVisible(true);
    }
  }, [isDesktop]);

  const toggleSidebar = (
    event: React.KeyboardEvent | React.MouseEvent,
  ): void => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setIsSidebarVisible(!isSidebarVisible);
  };

  return (
    <SidebarContext.Provider
      value={{
        isHeaderVisible: isHeaderVisible,
        isSidebarVisible: isSidebarVisible,
        toggleSidebar: toggleSidebar,
      }}
    >
      {children && children}
    </SidebarContext.Provider>
  );
};

export default SidebarContext;
