import { ListItemProps } from "../../lists/listItem";
import * as Styled from "./styles.css";

const SelectOption = ({
  children,
  selected,
  ...otherProps
}: SelectOptionProps) => {
  return (
    <Styled.SelectOption role="option" {...otherProps}>
      {selected && <Styled.ItemIcon icon="check" />}
      {children && children}
    </Styled.SelectOption>
  );
};

interface SelectOptionProps extends ListItemProps {}

SelectOption.defaultProps = {
  completed: false,
  component: "li",
  selected: false,
  size: "md",
  variant: "plain",
};

export default SelectOption;
