import { useEffect, useContext } from "react";
import { AuthContext } from "../../contexts";
import authService from "../../services/auth/auth.service";
import { CircularProgress } from "../../components/util";

const Logout = () => {
  const auth = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      auth.removeUser();
      authService.signOut();
    })();
  }, [auth]);

  return (
    <>
      <CircularProgress />
    </>
  );
};

export default Logout;
