import Joi from "joi";
import AuthService, {
  organisationSize,
  industry,
} from "../services/auth/auth.service";
import zxcvbn from "zxcvbn";
import { organisationTypeMap } from "../types/organisation.type";

const maxCharacterLimitSingleLine: number = 150;
const invalidEmailErrorMsg =
  'Invalid email address. If you think your account may already exist, please <a href="/login">Sign in</a>.';

export const textDefaultSchema = Joi.string();
export const textNumberDefaultSchema = Joi.alternatives(
  Joi.string(),
  Joi.number,
);

const customMessage = (
  msgName: string,
  field?: string,
): Record<string, string> => {
  let errors: Record<string, string> = {
    "string.empty": `${msgName} is required`,
    "string.max": `${msgName} is too long`,
  };
  if (field === "email") {
    errors["string.email"] = invalidEmailErrorMsg;

    errors["any.external"] = invalidEmailErrorMsg;
  }
  if (field === "industry") {
    errors["any.only"] = `${msgName} is required`;
  }
  return errors;
};

export const fieldRequiredSchema = (value: string) =>
  Joi.string()
    .required()
    .max(maxCharacterLimitSingleLine)
    .messages(customMessage(value));

export const passwordRequiredSchema = Joi.string()
  .max(maxCharacterLimitSingleLine)
  .messages(customMessage("Password"));

export const schemeRequiredSchema = Joi.string()
  .max(maxCharacterLimitSingleLine)
  .messages(customMessage("Scheme code"));

export const nameSchema = Joi.string()
  .max(maxCharacterLimitSingleLine)
  .messages(customMessage("Name"));

export const firstNameSchema = Joi.string()
  .max(maxCharacterLimitSingleLine)
  .messages(customMessage("First name"));

export const lastNameSchema = Joi.string()
  .max(maxCharacterLimitSingleLine)
  .messages(customMessage("Last name"));

export const emailSchema = Joi.string()
  .email({ tlds: { allow: false } })
  .trim()
  .max(maxCharacterLimitSingleLine)
  .messages(customMessage("Email address", "email"));

export const isExistingAuth0UserSchema = Joi.boolean();

export const emailExistSchema = Joi.string()
  .email({ tlds: { allow: false } })
  .trim()
  .external(async val => {
    const emailExists: any = await AuthService.emailExist(val);
    if (emailExists.data) {
      throw new Joi.ValidationError(
        "string.email.exist",
        [
          {
            message: invalidEmailErrorMsg,
            path: ["email"],
            type: "email.already.exists",
            context: {
              key: "email",
              label: "email",
              val,
            },
          },
        ],
        val,
      );
    }
  })
  .max(maxCharacterLimitSingleLine)
  .messages(customMessage("Email address", "email"));

export const passwordSchema = Joi.string()
  .pattern(/(?=.*[a-z])/, "lowercase")
  .pattern(/(?=.*[A-Z])/, "uppercase")
  .pattern(/(?=.*\d)/, "number")
  .pattern(/(?=.*\W)/, "special")
  .min(12)
  .max(255)
  .required()
  .custom((value, helper) => {
    if (value?.length > 0) {
      const fdsf = zxcvbn(value);
      if (fdsf?.feedback?.suggestions.length > 0) {
        return helper.error("common");
      }
    }
  })
  .custom((_value, helper) => {
    if (helper.original?.length > 0) {
      if (isIncluded(helper.original.toLowerCase(), helper?.prefs?.context)) {
        return helper.error("names");
      }
    }
  })
  .error((errors: any) => {
    errors.forEach((err: any, key: number) => {
      if (err.code === "string.min") {
        err.local.name = "min";
      }
      if (err.code === "string.max") {
        err.local.name = "max";
      }
      if (err.code === "common") {
        err.local.name = "common";
      }
      if (err.code === "names") {
        err.local.name = "names";
      }
      switch (err.local.name) {
        case "lowercase": {
          err.message = "One lowercase character";
          break;
        }
        case "uppercase": {
          err.message = "One uppercase character";
          break;
        }
        case "number": {
          err.message = "One number";
          break;
        }
        case "special": {
          err.message = "One special character";
          break;
        }
        case "min": {
          err.message = "12 characters minimum";
          break;
        }
        case "max": {
          err.message = "255 characters maximum";
          break;
        }
        case "names": {
          err.message = "No First or Last name";
          break;
        }
        case "common": {
          err.message = "Unique password";
          break;
        }
      }
    });
    return errors;
  })
  .messages(customMessage("Password", "password"));

export const passwordSchemaDefaultMessages = {
  lowercase: "One lowercase character",
  uppercase: "One uppercase character",
  number: "One number",
  special: "One special character",
  min: "12 characters minimum",
  max: "255 characters maximum",
  common: "Unique password",
  names: "No First or Last name",
};

export const isBrokerSchema = Joi.boolean().default(false);

export const companyNameSchema = Joi.string()
  .required()
  .max(maxCharacterLimitSingleLine)
  .messages(customMessage("Company name"));

export const industrySchema = Joi.string()
  .allow(...Object.values(industry))
  .only()
  .required()
  .messages(customMessage("Industry", "industry"));

export const orgTypeSchema = Joi.string()
  .allow(...organisationTypeMap.map(ele => ele.value))
  .only()
  .required()
  .messages(customMessage("Organisation type"));

export const phoneNumberSchema = Joi.string()
  .max(maxCharacterLimitSingleLine)
  .trim()
  .allow("", null)
  .messages(customMessage("Phone number"));

export const organisationSizeSchema = Joi.string()
  .allow(...Object.values(organisationSize))
  .only()
  .messages(customMessage("Company size"));

export const addressLineOneSchema = Joi.string()
  .required()
  .max(maxCharacterLimitSingleLine)
  .messages(customMessage("Address"));

export const addressLineTwoSchema = Joi.string()
  .max(maxCharacterLimitSingleLine)
  .allow(null, "")
  .messages(customMessage("Secondary address"));

export const addressCitySchema = Joi.string()
  .required()
  .max(maxCharacterLimitSingleLine)
  .messages(customMessage("City"));

export const addressStateSchema = Joi.string()
  .required()
  .max(maxCharacterLimitSingleLine)
  .messages(customMessage("State"));

export const addressPostcodeSchema = Joi.string()
  .required()
  .max(maxCharacterLimitSingleLine)
  .messages(customMessage("Postcode"));

export const addressCountrySchema = Joi.string()
  .required()
  .max(maxCharacterLimitSingleLine)
  .messages(customMessage("Country"));

export const brokerNameSchema = Joi.string()
  .required()
  .max(maxCharacterLimitSingleLine)
  .messages(customMessage("Name"));

export const isIncluded = (val: string, data: any) => {
  if (!data) {
    return false;
  }
  if (
    (data.firstName &&
      data.firstName.toLowerCase() &&
      val.includes(data.firstName.toLowerCase())) ||
    (data.lastName &&
      data.lastName.toLowerCase() &&
      val.includes(data.lastName.toLowerCase()))
  ) {
    return true;
  }
  return false;
};
