import { ComponentProps, useState } from "react";

import {
  ActionMeta,
  MultiValueGenericProps,
  OptionProps,
  components,
} from "react-select";

import AsyncSelect from "react-select/async";
import { ComponentSize } from "../../../types";
import { default as Control } from "./components/control";
import { default as ValueContainer } from "./components/valueContainer";

import * as Styled from "./styles.css";

function AsyncCombobox({
  onChange,
  placeholder,
  size,
  value,
  isMulti,
  cacheOptions,
  defaultOptions,
  loadOptions,
  getOptionLabel,
  getOptionSubLabel,
  getOptionMultiLabel,
  getOptionValue,
  initialSelected,
  ...otherProps
}: AsyncComboboxProps) {
  const [selected, setSelected] = useState<any>(() => {
    selectOption(initialSelected);

    return initialSelected;
  });

  const Option = getOptionSubLabel
    ? props => (
        <Styled.Option {...props}>
          <Styled.OptionLabel>{getOptionLabel(props.data)}</Styled.OptionLabel>

          <Styled.OptionSubLabel>
            {getOptionSubLabel(props.data)}
          </Styled.OptionSubLabel>
        </Styled.Option>
      )
    : components.Option;

  const MultiValueLabel = getOptionMultiLabel
    ? (props: MultiValueGenericProps) => (
        <components.MultiValueLabel {...props}>
          {getOptionMultiLabel(props.data)}
        </components.MultiValueLabel>
      )
    : components.MultiValueLabel;

  const handleChange = (
    selected: any,
    { action }: ActionMeta<OptionProps>,
  ): void => {
    switch (action) {
      case "select-option":
      case "remove-value":
        return selectOption(selected, true);
      case "clear":
        return clearOption();
    }
  };

  function selectOption(selected: any, updateLocalState = false): void {
    if (selected == null) return;

    if (isMulti && Array.isArray(selected)) {
      // If remove-value, selected is an empty array, so we need to set undefined instead
      const value = selected.length ? selected : undefined;

      updateLocalState && setSelected(value);
      onChange(value?.map(option => getOptionValue(option)));
    } else if (!isMulti && !Array.isArray(selected)) {
      updateLocalState && setSelected(selected);
      onChange(getOptionValue(selected));
    }
  }

  const clearOption = () => {
    setSelected(undefined);
    onChange(undefined);
  };

  return (
    <Styled.AsyncComboBox
      components={{ Control, Option, MultiValueLabel, ValueContainer }}
      classNamePrefix="combobox"
      name={otherProps?.schema?.title || otherProps.id}
      onChange={handleChange}
      placeholder={placeholder || isMulti ? "Select any..." : "Select one..."}
      loadingMessage={({ inputValue }) => `Searching for ${inputValue}...`}
      size={size}
      value={selected}
      getOptionLabel={getOptionLabel}
      getOptionSubLabel={getOptionSubLabel}
      getOptionMultiLabel={getOptionMultiLabel}
      getOptionValue={getOptionValue}
      $isValid={otherProps.isValid}
      isMulti={isMulti}
      isClearable
      isSearchable
      cacheOptions={cacheOptions}
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      initialSelected={initialSelected}
      {...otherProps}
    />
  );
}

export type AsyncComboboxProps<O = any> = ComponentProps<typeof AsyncSelect> & {
  id: string;
  isSearchable?: boolean;
  isValid?: boolean;
  onChange: any;
  placeholder?: string;
  schema?: Record<any, any>;
  size?: ComponentSize;
  value: any;
  loadOptions?: any;
  cacheOptions?: string | number | boolean | object | null;
  defaultOptions?: ComboboxOption[] | boolean;
  initialSelected?: O | O[];
  getOptionLabel: (option: O) => string;
  getOptionSubLabel?: (option: O) => string;
  getOptionMultiLabel?: (option: O) => string;
  getOptionValue: (option: O) => string;
};

export type ComboboxValue = string | string[] | undefined;

export type ComboboxOption = {
  value: string;
  label: string;
};

AsyncCombobox.defaultProps = {
  isMulti: false,
  isSearchable: false,
  isValid: true,
  size: "md",
};

export default AsyncCombobox;
