import { ScoreFactor } from "../../../../services/scores/scores.service";
import { Orientation } from "../../../../types";
import { Button } from "../../../util";
import Factor from "./factor";
import * as Styled from "./styles.css";

const ScoreFactors = ({
  showActions,
  data,
  factors,
  orientation,
}: ScoreFactorsProps) => {
  const formattedFactors = transformFactorDetails(factors, data);
  return (
    <Styled.FactorsContainer element="ul" container $orientation={orientation}>
      {data &&
        formattedFactors.map((factor: ScoreFactorDetails, i: number) => {
          return (
            <Factor
              current={factor.current}
              item
              key={i}
              max={factor.max}
              title={factor.title}
              xs={12}
              sm={orientation === "horizontal" ? 6 : 12}
              actions={
                showActions &&
                factor.actions &&
                factor.actions.map((action: ScoreFactorAction, i: number) => {
                  return (
                    <Button
                      href={action.href}
                      router={action.router}
                      variant="outlined"
                      key={i}
                    >
                      {action.text}
                    </Button>
                  );
                })
              }
            />
          );
        })}
    </Styled.FactorsContainer>
  );
};

export const transformFactorDetails = (
  factors: Record<string, ScoreFactorDetails>,
  data: ScoreFactor[],
): ScoreFactorDetails[] => {
  data &&
    data.forEach((factor: ScoreFactor, i: number) => {
      if (factor.name && factors[factor.name]) {
        factor.title = factors[factor.name].title;
        factor.actions = factors[factor.name].actions;
      }
    });
  return data as ScoreFactorDetails[];
};

export interface ScoreFactorDetails extends ScoreFactor {
  actions?: ScoreFactorAction[];
}

export interface ScoreFactorAction {
  href: string | object;
  text: string;
  router: boolean;
}

export interface ScoreFactorsProps {
  showActions?: boolean;
  data: ScoreFactor[];
  factors: Record<string, ScoreFactorDetails>;
  orientation?: Orientation;
}

ScoreFactors.defaultProps = {
  showActions: false,
  orientation: "vertical",
};

export default ScoreFactors;
