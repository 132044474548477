import styled from "styled-components";
import { Image, Link } from "../../util";

export const NewsLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

export const NewsImage = styled(Image)`
  margin-bottom: 12px;
`;
