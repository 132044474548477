import prismicDom from "prismic-dom";
import parser from "html-react-parser";
import { BenchmarksCostsInterface } from "../../../services/prismic/prismic.service";
import { Card, Grid, Typography } from "../../../components/util";

const Costs = ({
  costs,
}: Record<string, BenchmarksCostsInterface | undefined>) => {
  return (
    <Grid item xs={12} lg={4}>
      <Card isInGrid isPadded>
        <Typography paragraph variant="displaySmall">
          What could cost you the most
        </Typography>
        {parser(prismicDom.RichText.asHtml(costs))}
      </Card>
    </Grid>
  );
};

export default Costs;
