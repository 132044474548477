import { useState } from "react";
import authService from "../../../services/auth/auth.service";
import {
  passwordSchemaDefaultMessages,
  passwordSchema,
  fieldRequiredSchema,
  changePasswordFormSchema,
} from "../../../validations";
import { Form, FormRow, TextField } from "../../../components/util";
import AuthContainer from "../authContainer";
import AuthFooter from "../authFooter";
import ChangePasswordSuccess from "./changePasswordSuccess";

const ChangePassword = () => {
  const [changeSuccess, setChangeSuccess] = useState<boolean>(false);

  if (changeSuccess) {
    return <ChangePasswordSuccess />;
  }

  return (
    <AuthContainer
      headerTitle="Create a new password"
      footerContent={<AuthFooter />}
      stickyFooter
      backLink
      preventRedirect
    >
      <Form
        fullWidth
        onSubmit={{
          service: authService.changePassword,
          onSuccess: () => {
            setChangeSuccess(true);
          },
        }}
        validationErrors={[ForgotPasswordErrorsInit]}
        validationFields={[ForgotPasswordDataInit]}
        validationForm={changePasswordFormSchema}
        submitText="Reset password"
        prefill={formPrefill}
      >
        <FormRow>
          <TextField
            autoComplete="new-password"
            id="password"
            type="password"
            label="New password"
            size="lg"
            validationOnChange
            validationOnFocus
            validationType={passwordSchema}
            validationList={passwordSchemaDefaultMessages}
          />
        </FormRow>
        <FormRow>
          <TextField
            autoComplete="new-password-confirm"
            id="passwordConfirm"
            type="password"
            label="Confirm new password"
            size="lg"
            validationOnChange
            validationType={fieldRequiredSchema("Password Confirmation")}
          />
        </FormRow>
      </Form>
    </AuthContainer>
  );
};

export default ChangePassword;

export const ForgotPasswordErrorsInit: Record<string, boolean> = {
  password: true,
  passwordConfirm: true,
  token: true,
};

export const ForgotPasswordDataInit: Record<string, string | boolean> = {
  password: "",
  passwordConfirm: "",
  token: "",
};

const formPrefill = (
  urlParams: Record<string, any> | undefined,
  formData?: Record<string, any>[],
  formErrors?: Record<string, boolean>[],
): Record<string, any>[] | Record<string, boolean>[] | undefined => {
  if (formData && urlParams?.token) {
    formData[0].token = urlParams.token as string;
  }
  if (formErrors && urlParams?.token) {
    formErrors[0].token = false;
  }

  return formErrors ? formErrors : formData;
};
