import { DescriptionFieldProps } from "@rjsf/utils";
import { containsHTML } from "../../../../../helpers";
import * as Styled from "./styles.css";

const JfsDescription = ({
  description,
  ...otherProps
}: JfsDescriptionProps) => {
  return (
    <Styled.DescriptionContainer {...otherProps}>
      {description && (
        <Styled.Description
          dangerousChildren={
            typeof description === "string" && containsHTML(description)
          }
        >
          {description}
        </Styled.Description>
      )}
    </Styled.DescriptionContainer>
  );
};

export interface JfsDescriptionProps extends DescriptionFieldProps {}

JfsDescription.defaultProps = {};

export default JfsDescription;
