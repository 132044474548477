import { TStyleConnotation } from "../../../types";
import { Alert, FormRow, Link, Typography } from "../../util";

export const AlertBox = ({ message, color }: iAlertBoxProps) => {
  let includeLink = false;
  let includeLogin = false;

  const contactUs = (
    <Link href="https://care.safetyculture.com/contact">contact Care</Link>
  );

  const login = (
    <>
      {" "}
      <Link href="/login">Login</Link>
    </>
  );

  if (message.includes("contact Care")) {
    includeLink = true;
    message = message.replaceAll("contact Care", " ");
  } else if (message.toLowerCase().includes("account already exists")) {
    includeLogin = true;
  }

  return (
    <>
      <FormRow>
        <Alert color={color}>
          <Typography>
            {message}
            {includeLink && contactUs}
            {includeLogin && login}
          </Typography>
        </Alert>
      </FormRow>
    </>
  );
};

export interface iAlertBoxProps {
  message: any | string;
  color: TStyleConnotation;
}
