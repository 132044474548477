import { makeStyles } from "@material-ui/core";
import { theme } from "../../../styles";

const useStyles = makeStyles(() => ({
  content: {
    padding: theme.spacing,
  },
  title: {
    margin: `${theme.spacing} 0`,
  },
  textTitle: {
    marginBottom: theme.spacing,
  },
  picture: {
    margin: `-${theme.spacing} -${theme.spacing} ${theme.spacing} -${theme.spacing}`,
    width: `calc(100% + ${theme.spacing}*2)`,
  },
  h3: {
    marginBottom: `${theme.spacing}`,
  },
}));

export default useStyles;
