import styled from "styled-components";
import { breakpoints, theme } from "../../../styles";
import { Grid } from "@material-ui/core";
import { Button, Container, List } from "..";

export const TabPanel = styled(Grid).attrs((props: any) => ({
  $orientation: props.$orientation,
  $spaced: props.$spaced,
  $variant: props.$variant,
}))`
  ${(props: any) => {
    switch (props.$variant) {
      case "open":
        return `
        `;
      case "contained":
        return `
          padding: 24px 20px;
          @media ${breakpoints.up("lg")} {
            padding: 32px 40px;
          }
        `;
    }
  }}

  ${(props: any) => {
    switch (props.$orientation) {
      case "horizontal":
        return `
        `;
      case "vertical":
        return `
          padding: 32px 40px;
        `;
    }
  }}

  ${(props: any) =>
    props.$spaced &&
    `
      margin-top: 24px;
      @media ${breakpoints.up("md")} {
        margin-top: 40px;
      }
    `}
`;

export const Tabs = styled(Container).attrs((props: any) => ({
  component: props.component,
  $orientation: props.$orientation,
  $scrolling: props.$scrolling,
  $variant: props.$variant,
}))`
  display: flex;
  justify-content: space-between;
  color: ${theme.color.surface.text.weaker.default};
  min-height: 44px;

  ${(props: any) => {
    switch (props.$orientation) {
      case "horizontal":
        return `
            width: 100%;
            @media ${breakpoints.up("md")} {
              margin-bottom: 8px;
            }
        `;
      case "vertical":
        return `
          & .MuiTabs-flexContainer {
            flex-direction: column;
          }
        `;
    }
  }}

  ${(props: any) => {
    switch (props.$variant) {
      case "open":
        return `
          ${
            props.$orientation === "horizontal" &&
            `
              border-bottom: 1px solid ${
                theme.color.surface.border.weak.default
              };
              margin-left: -20px;
              margin-right: -20px;
              padding-left: 20px;

              @media ${breakpoints.up("sm")} {
                margin-left: 0;
                margin-right: 0;
                padding-left: 0;
              }
          `
          };
      `;
    }
  }}

  ${(props: any) =>
    props.$scrolling &&
    `
      justify-content: left;
      width: auto;
      overflow-x: auto;
      overflow-y: hidden;
      ::-webkit-scrollbar {
        width: 0;
        background: transparent;
        display: none;
      }

      @media ${breakpoints.up("sm")} {
        width: 100%;
      }
  `};
`;

export const TabList = styled(List).attrs((props: any) => ({
  $orientation: props.orientation,
  $scrolling: props.$scrolling,
  $variant: props.$variant,
}))``;

export const Tab = styled(Container).attrs((props: any) => ({
  $complete: props.$complete,
  $hidden: props.$hidden,
  $orientation: props.$orientation,
  $scrolling: props.$scrolling,
  $selected: props.$selected,
  $variant: props.$variant,
}))`
  color: ${theme.color.surface.text.weaker.default};
  line-height: ${theme.typography.body.lineHeight};
  min-height: 44px;
  min-width: auto;
  opacity: 1;
  position: relative;
  text-transform: none;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    background-color: transparent;
    z-index: 9;
  }

  &:hover {
    &:before {
      background-color: ${theme.color.surface.background.weakest.default};
    }
  }

  &:active {
    &:before {
      background-color: ${theme.color.surface.background.weakest.pressed};
    }
  }

  &[disabled] {
    opacity: 1;
    pointer-events: none;

    &:before {
      background-color: transparent;
    }

    &:hover {
      &:before {
        background-color: transparent;
      }
    }
  }

  ${(props: any) =>
    props.$selected &&
    `
    background-color: ${theme.color.surface.background.default.default};
    &:before {
      background-color: ${theme.color.accent.border.default.default};
    }
    &:hover {
      background-color: transparent;
      &:before {
        background-color: ${theme.color.accent.border.default.hover};
      }
    }
  `};

  ${(props: any) => {
    switch (props.$variant) {
      case "unstyled":
        return `
          &:before {
            display: none;
          }
        `;
      case "open":
      case "contained":
      default:
        return `
          &:before {
            display: inline-block;
          }
        `;
    }
  }}

  && {
    ${(props: any) => {
      switch (props.$variant) {
        case "open":
          return `
            ${
              props.$orientation === "horizontal" &&
              `
                margin-right: 20px;
                &:not(:first-of-type) {
                  padding-left: 12px;
                  &:before {
                    left: 12px;
                  }
                }
                &:last-of-type {
                  &:before {
                    ${
                      props.$scrolling &&
                      `
                        right: 20px;
                      `
                    }
                  }
                }
            `
            };
        `;
        case "contained":
          return `
            ${
              props.$orientation === "horizontal" &&
              `
                flex-grow: 1;
                font-size: 0;
                justify-content: center;

                &:not(:first-of-type) {
                  border-left: 1px solid ${
                    theme.color.surface.border.default.default
                  };
                }

                &:before {
                  top: 0;
                }

                ${
                  !props.$selected &&
                  `
                    border-bottom: 1px solid ${theme.color.surface.border.default.default};
                  `
                }
              `
            };

            ${
              props.$orientation === "vertical" &&
              `
              justify-content: left;
            `
            };
        `;
        case "unstyled":
          return `
            &:before {
              display: none;
            }
          `;
      }
    }}
  }

  && {
    ${(props: any) => {
      switch (props.$orientation) {
        case "horizontal":
          return `
            color: ${theme.color.surface.text.strong.default};

            &:before {
              bottom: -1px;
              right: 0;
              left: 0;
              height: 3px;
            }

            button {
              padding-left: 0;
              padding-right: 0;
            }

            &:hover {
              color: ${theme.color.surface.text.strong.hover};
            }
        `;
        case "vertical":
          return `
            background-color: ${theme.color.surface.background.weakest.default};
            border-top: 1px solid ${theme.color.surface.border.default.default};
            border-right: 1px solid ${
              theme.color.surface.border.default.default
            };
            display: block;
            max-width: 300px;

            &:first-of-type {
              border-top: 0px solid transparent;
              button {
                border-top: 1px solid transparent;
              }
            }

            &:before {
              left: 0;
              top: 0;
              width: 3px;
            }

            &:hover {
              background-color: ${theme.color.surface.background.weakest.hover};
              &:before {
                background-color: ${
                  theme.color.surface.background.weaker.hover
                };
              }
            }

            &:active {
              &:before {
                background-color: ${
                  theme.color.surface.background.weaker.pressed
                };
              }
            }

            &[disabled] {
              background-color: ${
                props.$complete
                  ? theme.color.positive.background.weakest.default
                  : "transparent"
              };
            }

            ${
              props.$selected &&
              `
              background-color: ${theme.color.surface.background.default.default};
              border-right-color: transparent;

              &:before {
                background-color: ${theme.color.accent.border.default.default};
              }

              &:hover {
                background-color: transparent;
                &:before {
                  background-color: ${theme.color.accent.border.default.hover};
                }
              }
              `
            };

            span {
              text-align: left;
            }
        `;
      }
    }}
  }

  && {
    ${(props: any) =>
      props.$hidden &&
      `
        display: none;
    `};
  }
`;

export const TabButton = styled(Button).attrs((props: any) => ({
  $orientation: props.$orientation,
  $selected: props.$selected,
  $variant: props.$variant,
}))`
  color: ${theme.color.surface.text.weaker.default};
  justify-content: center;
  @media ${breakpoints.up("lg")} {
    justify-content: left;
  }

  &:hover {
    color: ${theme.color.surface.text.weaker.hover};
  }

  & span {
    display: flex;
    margin-right: 8px;
    & > span {
      svg {
        height: inherit;
        width: inherit;
      }
    }
  }

  ${(props: any) =>
    props.$selected &&
    `
    color: ${theme.color.accent.text.default.default};
    &:hover {
      color: ${theme.color.accent.text.default.hover};
    }
  `};

  ${(props: any) => {
    switch (props.$variant) {
      case "open":
        return `
          ${
            props.$orientation === "horizontal" &&
            `
              & span {
                display: block;
                max-width: 250px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            `
          };
      `;
      case "contained":
        return `
          background-color: transparent;
          padding: 20px 23px;
          width: 100%;

          ${
            props.$orientation === "horizontal" &&
            `
              font-size: 0;
              & span {
                justify-content: center;
              }
          `
          };

          ${
            props.$orientation === "vertical" &&
            `
              & span {
                align-items: left;
                justify-content: left;

                & > span {
                  margin-right: 16px;
                }
              }
          `
          };

          @media ${breakpoints.up("lg")} {
            font-size: initial;
          }
      `;
      case "unstyled":
        return `
        `;
    }
  }}

  @media ${breakpoints.up("lg")} {
    & span {
      & > span {
        margin-right: 16px;
      }
    }
  }
`;
export const WidgetTabContainer = styled(Container)`
  padding: 0px;
`;
