import { JfsSchema, JfsUiSchema } from "../../../types";
import { JfsAsyncCombobox } from "../../../components/util";
import { IndustrySearchAllRow } from "../../../services/industries/industries.service";
import { Toolkit } from "../../../services/toolkits/toolkits.service";

export const withUiSchema = ({
  industries,
  toolkits,
}: WithUiSchemaParams): JfsSchema => ({
  ...uiSchema,
  industries: {
    ...uiSchema.industries,
    "ui:options": {
      ...uiSchema.industries["ui:options"],
      ...industries,
    },
  },
  toolkits: {
    ...uiSchema.toolkits,
    "ui:options": {
      ...uiSchema.toolkits["ui:options"],
      ...toolkits,
    },
  },
});

export const schema: JfsSchema = {
  type: "object",
  required: ["name", "industries", "toolkits"],
  properties: {
    name: {
      type: "string",
      title: "Name",
    },
    industries: {
      type: "array",
      items: {
        type: "string",
      },
      title: "Industries (ANZSIC)",
    },
    toolkits: {
      type: "array",
      items: {
        type: "string",
      },
      title: "Toolkits",
    },
    enabled: {
      type: "boolean",
      title: "Enabled",
      default: false,
      enum: [false, true],
    },
  },
};

const uiSchema: JfsUiSchema = {
  "ui:order": ["name", "industries", "toolkits", "enabled"],
  name: {
    "ui:autofocus": true,
  },
  industries: {
    "ui:widget": JfsAsyncCombobox,
    "ui:options": {
      debounce: 750,
      isMulti: true,
    },
  },
  toolkits: {
    "ui:widget": JfsAsyncCombobox,
    "ui:options": {
      debounce: 750,
      isMulti: true,
    },
  },
};

type WithUiSchemaParams = {
  industries: {
    search: (input: string) => Promise<IndustrySearchAllRow[]>;
    getOptionLabel: (item: IndustrySearchAllRow) => string;
    getOptionSubLabel: (item: IndustrySearchAllRow) => string;
    getOptionValue: (item: IndustrySearchAllRow) => string;
  };
  toolkits: {
    search: (input: string) => Promise<Toolkit[]>;
    getOptionLabel: (item: Toolkit) => string;
    getOptionSubLabel: (item: Toolkit) => string;
    getOptionMultiLabel: (item: Toolkit) => string;
    getOptionValue: (item: Toolkit) => string;
  };
};
