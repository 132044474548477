import React from "react";
import { DeepDivePointInterface } from "../../../services/prismic/prismic.service";
import { Box, makeStyles } from "@material-ui/core";
import { Icon } from "../../../components/util";
import { theme } from "../../../styles";

interface PointPropsInterface {
  point: DeepDivePointInterface;
}

const useStyles = makeStyles(() => ({
  icon: {
    marginRight: `calc(${theme.spacing}/2)`,
  },
  point: {
    display: "flex",
    alignItems: "flex-start",
    margin: `calc(${theme.spacing}/2) 0`,
  },
  tickColor: {
    color: theme.color.positive.background.default.default,
  },
  errorColor: {
    color: theme.color.negative.background.default.default,
  },
}));

const Point = ({ point }: PointPropsInterface) => {
  const classes = useStyles();

  return (
    <Box className={classes.point}>
      {point.type === "tick" ? (
        <Icon icon="check-circle" color="accent" />
      ) : (
        <Icon icon="stop" color="accent" />
      )}
      {point.text}
    </Box>
  );
};

export default Point;
