import { TStyleConnotation } from "../../../types";
import { ContainerProps } from "../container/container";
import Typography from "../typography";
import * as Styled from "./styles.css";

const ListItemText = ({
  children,
  color,
  truncate,
  ...otherProps
}: ListItemIconProps) => {
  return (
    <Styled.ListItemText {...otherProps}>
      <Typography truncate={truncate}>{children && children}</Typography>
    </Styled.ListItemText>
  );
};

interface ListItemIconProps extends ContainerProps {
  color?: TStyleConnotation;
}

ListItemText.defaultProps = {
  size: "md",
};

export default ListItemText;
