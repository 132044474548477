import styled from "styled-components";
import { GridProps } from "@material-ui/core";
import { breakpoints, theme } from "../../../styles";
import {
  Button,
  Grid as ComponentGrid,
  Link as ComponentLink,
  SelectMenu,
  SelectOption,
  Table as ComponentTable,
  TableCell,
  Tabs as inTabs,
  Typography,
  IconButton,
  TabPanel,
} from "../../../components/util";
import { buildBoxShadow } from "../../../helpers";

export interface StyledGridProps extends GridProps {
  $fullHeight?: boolean;
}

export const SubHeader = styled(Typography)`
  margin-top: 2em;
  margin-bottom: 0.5em;
  color: ${theme.color.surface.text.strong.default};
`;

export const AddOrgIcon = styled(Typography)`
  padding-right: 1em;
`;
export const EmptyOrgMessage = styled(Typography)`
  text-align: center;
`;

export const Link = styled(ComponentLink)`
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media ${breakpoints.up("md")} {
    max-width: 100px;
  }
  @media ${breakpoints.up("lg")} {
    max-width: 200px;
  }
`;

export const Grid = styled(ComponentGrid)<StyledGridProps>`
  height: ${props => (props.$fullHeight ? "100%" : "initial")};
`;

export const Table = styled(ComponentTable)`
  @media ${breakpoints.down("sm")} {
    grid-template-columns: 1fr;
  }
  th:not(:first-of-type) {
    display: none;
    @media ${breakpoints.up("md")} {
      display: flex;
    }
  }
  td:not(:first-of-type) {
    display: none;
    @media ${breakpoints.up("md")} {
      display: flex;
    }
  }
`;

export const AddressCell = styled(TableCell)`
  height: auto;
  min-height: 48px;
`;

export const MoreOptionSelectMenu = styled(SelectMenu)`
  transform: translate3d(10px, 10px, 0);
  z-index: 99;
  border-radius: 6px;
  box-shadow: ${buildBoxShadow("high")};
`;

export const MoreOptionSelectOption = styled(SelectOption)`
  padding: 8px 15px;
  background-color: ${theme.color.surface.background.default.default};
`;

export const MoreOptionOrgSectionSelectOption = styled(SelectOption)`
  padding: 0px;
  background-color: ${theme.color.surface.background.default.default};
`;

export const MoreOptionIconButton = styled(Button)`
  transform: rotate(90deg);
`;

export const MoreOptionButton = styled(Button)`
  padding-left: 10px;
  padding-right: 80px;
  padding-top: 5px;
  padding-bottom: 5px;
  align-text: left;
`;

export const OrgMoreOptionIconButton = styled(IconButton)`
  transform: rotate(90deg);
`;

export const LinkButton = styled(ComponentLink)`
  text-decoration: none;
  color: inherit;
  &:visited {
    color: inherit;
  }
`;

export const Tabs = styled(Grid)``;
export const InnerTabs = styled(inTabs)`
  margin-bottom: 0px;
`;

export const STTabpanel = styled(TabPanel)`
  margin-top: 0px;
`;
