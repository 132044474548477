import { LinearProgressProps as MuiLinearProgressProps } from "@material-ui/core";
import * as Styled from "./styles.css";

interface LinearProgressProps extends Omit<MuiLinearProgressProps, ""> {}

const LinearProgress = (props: LinearProgressProps) => {
  return <Styled.LinearProgress {...props} />;
};

LinearProgress.defaultProps = {
  value: 0,
  variant: "determinate",
};
export default LinearProgress;
