import React from "react";
import { CaseStudiesInterface } from "../../../services/prismic/prismic.service";
import { Card, Grid, Image, Typography } from "../../../components/util";

interface CaseStudiesPropsInterface {
  caseStudies?: CaseStudiesInterface[];
}

const CaseStudies = ({ caseStudies }: CaseStudiesPropsInterface) => {
  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        {caseStudies &&
          caseStudies.map((caseStudy, i) => (
            <Grid item xs={12} sm={12} md={6} key={i}>
              <Card isInGrid isPadded>
                <Image src={caseStudy.image} isInCard />
                <Typography variant="displaySmall">
                  {caseStudy.title}
                </Typography>
                <Typography>{caseStudy.description}</Typography>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

export default CaseStudies;
