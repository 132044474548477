import { useContext } from "react";
import { AuthContext, LocationContext } from "../../../../contexts";
import { recordAnalyticsEvent } from "../../../../analytics";
import { Link, ListItemIcon, ListItemText } from "../../../util";
import { ContainerProps } from "../../../util/container/container";
import { SidebarItemProps } from "./sidebarItems";
import * as Styled from "./styles.css";

const NavItem = ({ item }: INavItemProps) => {
  const { location } = useContext(LocationContext);
  const { user } = useContext(AuthContext);
  const selected: boolean =
    item.href === location.pathname ||
    (item.href !== "" && location.pathname.startsWith(`${item.href}/`));

  const handleNavChange = () => {
    recordAnalyticsEvent("action", "Nav Menu Click", {
      path: item.href,
      title: item.title,
      user_type: user.type,
    });
  };

  return (
    <Styled.NavItem selected={selected}>
      <Link
        href={item.href}
        router={!item.externalLink}
        isNewTab={item.externalLink}
        onClick={() => handleNavChange()}
      >
        <ListItemIcon
          color={selected ? "accent" : "surface"}
          icon={item.icon}
          selected={selected}
        />
        {item.component && item.component}
        <ListItemText truncate>{item.title}</ListItemText>
      </Link>
    </Styled.NavItem>
  );
};

interface INavItemProps extends ContainerProps {
  item: SidebarItemProps;
}

export default NavItem;
