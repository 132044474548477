import { useContext } from "react";
import { useQuery } from "react-query";
import AuthContext from "./../../../contexts/authContext";
import prismicService from "./../../../services/prismic/prismic.service";
import { CircularProgress, Grid } from "./../../util";
import { Header } from "./../../layout";
import { ListMissing } from "./../../content";
import NewsItem from "../../layout/newsItem";

const LatestNews = () => {
  const auth = useContext(AuthContext);

  const industry = auth.user.organisations[0].industry.name;
  const { isLoading, isError, data } = useQuery(
    "RecommendationTileCards",
    () => prismicService.getRecommendationTileCards(industry),
    { retry: 0 },
  );

  if (isLoading) {
    return <CircularProgress contained />;
  } else if (isError || data == null) {
    return (
      <ListMissing
        icon="pulse"
        message="Your industy doesn't have any information at the moment, please check back later."
        type="news"
      />
    );
  }

  return (
    <>
      <Header title="Latest updates" type="section" />
      <Grid element="ul" container spacing={4} item xs={12}>
        {data.map((tile: any, i: number) => {
          return (
            <NewsItem
              image={tile.image}
              key={i}
              link={tile.link}
              text={tile.description}
              title={tile.title}
            />
          );
        })}
      </Grid>
    </>
  );
};

export default LatestNews;
