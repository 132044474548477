import * as React from "react";
import { Automation } from "../../../../types/automations.type";
import {
  Table,
  TableCell,
  TableRow,
  TabPanel,
  Typography,
} from "../../../../components/util";
import { Header } from "../../../../components/layout";

type Props = { automation: Automation };

export const AutomationDetailsAnzsicTabPanel: React.FC<Props> = ({
  automation,
}) => {
  return (
    <TabPanel id="details">
      <Header title="ANZSICs" type="section" />
      <Table
        columns={[
          { title: "Occupation", expand: true },
          { title: "Industry" },
          { title: "ANZSIC" },
        ]}
      >
        {automation.industries?.map(industry => (
          <TableRow key={automation.id}>
            <TableCell>
              <Typography styled={{ text: { case: "title" } }}>
                {industry.occupation}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography styled={{ text: { case: "title" } }}>
                {industry.name}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography styled={{ text: { case: "title" } }}>
                {industry.anzsic}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </TabPanel>
  );
};
