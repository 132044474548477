import React from "react";
import { GridProps } from "@material-ui/core";
import { ScoreFactor } from "../../../../services/scores/scores.service";
import { Grid } from "../../../util";
import * as Styled from "./styles.css";

const Factor = ({
  title,
  current,
  max,
  actions,
  ...otherProps
}: ScoreFactorProps) => {
  return (
    <Styled.Factor element="li" container {...otherProps}>
      <Grid item xs={12} md>
        <Styled.Title variant="heading">{title}</Styled.Title>
        <Styled.Current element="span" variant="displayMedium">
          {current}
        </Styled.Current>
        {max && (
          <Styled.Max
            element="span"
            variant="heading"
            styled={{ text: { shade: "weak" } }}
          >
            /{max}
          </Styled.Max>
        )}
      </Grid>
      {actions && <Grid item>{actions}</Grid>}
    </Styled.Factor>
  );
};

interface ScoreFactorProps extends Omit<ScoreFactor, "actions">, GridProps {
  actions: React.ReactNode;
}

Factor.defaultProps = {};
export default Factor;
