import styled from "styled-components";
import { theme } from "./../../../../styles";
import { buildBoxShadow } from "./../../../../helpers";
import { List } from "./../..";
import JfsInput from "./../jfsInput/jfsInput";

export const JfsRadioList = styled(List).attrs((props: any) => ({}))`
  overflow: hidden;
`;

export const JfsRadioButton = styled(JfsInput).attrs((props: any) => ({
  $error: props.$error,
  $size: props.$size,
}))`
  background-color: ${theme.color.surface.background.default.default};
  border: 1px solid ${theme.color.surface.border.weak.default};
  border-radius: 100%;
  box-shadow: ${buildBoxShadow("low")};
  cursor: pointer;
  height: 18px;
  margin-right: 12px;
  width: 18px;
  vertical-align: top;
  position: relative;

  &::before {
    display: block;
    border-radius: 100%;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    background-color: ${theme.color.surface.background.default.default};
  }

  :checked {
    &,
    :focus,
    :hover {
      border: 5px solid ${theme.color.accent.border.default.hover};
    }
  }
  :focus {
    border: 2px solid ${theme.color.accent.border.default.pressed};
  }
  :hover {
    border: 2px solid ${theme.color.accent.border.default.hover};
  }
`;
