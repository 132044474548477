import { ContainerProps } from "../container/container";
import { Alignment, Placement } from "../../../types";
import * as Styled from "./styles.css";

const Marker = ({
  alignment,
  children,
  placement,
  ...otherProps
}: MarkerProps) => {
  return (
    <Styled.Marker
      $alignment={alignment}
      $placement={placement}
      {...otherProps}
    />
  );
};

export interface MarkerProps extends ContainerProps {
  alignment?: Alignment;
  placement?: Placement;
}

Marker.defaultProps = {
  alignment: "center",
  placement: "left",
};

export default Marker;
