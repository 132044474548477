import { Link } from "../../components/util";
import * as Styled from "./styles.css";

const AuthFooter = () => {
  return (
    <Styled.FooterTypography
      variant="body2"
      styled={{ text: { shade: "weak" } }}
    >
      By continuing, you agree to Care’s{" "}
      <Link href="https://care.safetyculture.com/terms-and-conditions" isNewTab>
        terms
      </Link>{" "}
      and{" "}
      <Link href="https://care.safetyculture.com/privacy-policy/" isNewTab>
        privacy policy
      </Link>
      .
    </Styled.FooterTypography>
  );
};

export default AuthFooter;
