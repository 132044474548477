import { useContext, useState, ReactNode } from "react";
import JSONSchemaForm from "@rjsf/core";
import { RegistryWidgetsType } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import { JfsFormData, JfsSchema } from "../../../types";
import { TabsContext } from "../../../contexts";
import { ContainerProps } from "./../container/container";

import {
  JfsCombobox,
  JfsTextField,
  JfsRadioButton,
  JfsCheckBox,
  JfsTextarea,
} from "..";

import {
  JfsArrayField,
  JfsArrayFieldItem,
  JfsButtons,
  JfsDescription,
  JfsFormRow,
  JfsObjectField,
  JfsHeader,
} from "./templates";

import { JfsCheckBoxes, JfsDateTime } from "./widgets";
import { JfsMarkup, JfsNumber } from "./fields";
import * as Styled from "./styles.css";

const JfsForm = ({
  id,
  formData,
  disabled,
  readonly,
  onError,
  onChange,
  onSubmit,
  schema,
  cancelButton,
  submitButtons,
  steppedForm,
  customErrorMsg,
  uiSchema,
  width,
  ...otherProps
}: JfsFormProps) => {
  const { setTabStatus } = useContext(TabsContext);
  const templates = {
    ArrayFieldTemplate: JfsArrayField,
    ArrayFieldItemTemplate: JfsArrayFieldItem,
    ButtonTemplates: JfsButtons,
    FieldTemplate: JfsFormRow, // Container for each form row
    ObjectFieldTemplate: JfsObjectField,
    DescriptionFieldTemplate: JfsDescription, // Container for each form row
    BaseInputTemplate: JfsTextField,
    TitleFieldTemplate: JfsHeader,
  };
  const fields = {
    NumberField: JfsNumber,
    MarkupField: JfsMarkup,
  };
  const customFormats = {
    dollar: /^[0-9]*(\.[0-9]{0,2})?$/,
    "phone-au":
      /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/,
  };
  const validator = customizeValidator({ customFormats });

  const widgets: RegistryWidgetsType = {
    CheckboxWidget: JfsCheckBox,
    CheckboxesWidget: JfsCheckBoxes,
    RadioWidget: JfsRadioButton,
    SelectWidget: JfsCombobox,
    TextareaWidget: JfsTextarea,
    DateTimeWidget: JfsDateTime,
  };

  const handleError = (_error: any) => {
    if (steppedForm) {
      setTabStatus(id as string, "error");
    }
    if (onError) {
      if (steppedForm) {
        onError(id);
      } else {
        onError(id);
      }
    }
  };

  const handleErrorMsg = (errors: any) => {
    return errors.map(error => {
      if (error.name === "required") {
        error.message = "is required";
      } else if (customErrorMsg) {
        const errList =
          customErrorMsg[error.property] && customErrorMsg[error.property];
        if (errList)
          errList.forEach(item => {
            if (item.type === error.name) error.message = item.errorMsg;
          });
      }

      return error;
    });
  };

  const formSubmit = (formData: Record<any, any>): void => {
    if (onSubmit) {
      if (steppedForm) {
        onSubmit(formData, id);
      } else {
        onSubmit(formData);
      }
    }
  };

  return (
    <Styled.FormSection {...otherProps}>
      <JSONSchemaForm
        noHtml5Validate
        schema={schema}
        templates={templates}
        formData={formData}
        fields={fields}
        widgets={widgets}
        uiSchema={uiSchema}
        disabled={disabled}
        readonly={readonly}
        showErrorList={false}
        transformErrors={handleErrorMsg}
        validator={validator}
        onChange={onChange}
        onError={handleError}
        onSubmit={(data: Record<any, any>) => {
          if (data.formData) {
            formSubmit(data.formData);
          }
        }}
      >
        <Styled.ButtonContainer>
          {cancelButton}
          {submitButtons}
        </Styled.ButtonContainer>
      </JSONSchemaForm>
    </Styled.FormSection>
  );
};

interface JfsFormProps extends ContainerProps {
  formData?: JfsFormData;
  onChange: ({ formData }: { formData?: any }) => void;
  onError?: any;
  onSubmit?: any;
  schema: JfsSchema;
  steppedForm?: boolean;
  cancelButton?: ReactNode;
  submitButtons?: any;
  customErrorMsg?: CustomErrorMsg;
  uiSchema?: Record<string, any>;
  validator?: any;
  disabled?: boolean;
  readonly?: boolean;
  liveValidate?: boolean;
  width?: string;
}

JfsForm.defualtProps = {
  disabled: false,
  readonly: false,
  width: "700px",
};

export default JfsForm;

export interface CustomErrorMsg {
  [key: string]: [{ type: string; errorMsg: string }];
}
