import { Modal } from "../../../../components/util";
import * as Styled from "./styles.css";
import PolicyOrg from "./jsfOrgModal";

const OrgModal = ({
  data,
  handleModal,
  refetchQuery,
  modalType,
}: OrgModalProps) => {
  return (
    <Modal isOpen={true} hideHeader={true} onClose={() => handleModal()}>
      <Styled.ModalBody>
        <PolicyOrg
          oldOrgId={data?.org_id || ""}
          orgName={data?.org_name || ""}
          policyId={data?.policy_id || ""}
          modalType={modalType || ""}
          refetchQuery={refetchQuery || ""}
          handleModal={handleModal}
        />
      </Styled.ModalBody>
    </Modal>
  );
};

interface OrgModalProps {
  data?: OrgInfo;
  handleModal: () => void;
  refetchQuery?: string;
  modalType: tOrgModalType;
}

export type tOrgModalType = "Add" | "Update" | "Remove" | undefined;

export interface OrgInfo {
  policy_id?: string;
  org_id?: string;
  org_name?: string;
  old_org_id?: string;
}

export default OrgModal;
