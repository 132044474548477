import styled from "styled-components";
import { theme } from "../../../styles/themes";
import Container from "../container/container";
import { ListItemIconProps } from "./listItemIcon";

export const List = styled(Container).attrs((props: any) => ({
  component: props.component,
  $orientation: props.$orientation,
  $paragraph: props.$paragraph,
  $selected: props.$selected,
  $size: props.$selected,
  $variant: props.$variant,
}))`
  padding: 0px;

  ${(props: any) => {
    switch (props.$variant) {
      case "sidebar":
        return `
        `;
      case "ordered":
        return `
          padding-left: 20px;
        `;
      case "unordered":
        return `
          padding-left: 20px;
        `;
      default:
        return `
        `;
    }
  }}

  ${(props: any) => {
    switch (props.$orientation) {
      case "horizontal":
        return `
          display: flex;
          flex-direction: row;
        `;
      case "vertical":
        return `
        `;
    }
  }}

  ${({ $paragraph }) =>
    $paragraph &&
    `
      margin-bottom: 16px;
   `}
`;

export const ListItem = styled(Container).attrs((props: any) => ({
  $color: props.$color,
  $completed: props.$completed,
  $paragraph: props.$paragraph,
  $selected: props.$selected || false,
  $size: props.$size,
  $variant: props.$variant || "plain",
}))`
  opacity: ${props => (props.$completed ? "0.4" : "inherit")};
  padding: 0;
  position: relative;

  ${(props: any) => {
    switch (props.$variant) {
      case "ordered":
        return `
          display: list-item;
          list-style: decimal;
        `;
      case "unordered":
        return `
          display: list-item;
          list-style: disc;
          font-size: ${theme.typography.body.desktop.fontSize};
          letter-spacing: ${theme.typography.body.desktop.letterSpacing};
          line-height: ${theme.typography.body.desktop.lineHeight};
        `;
      default:
        return `
          display: flex;
        `;
    }
  }}

  color: ${({ $color }) => {
    switch ($color) {
      case "primary":
        return theme.color.accent.text.default.default;
      case "error":
        return theme.color.negative.text.default.default;
      case "text":
        return "inherit";
      case "light":
        return theme.color.neutral.text.weak.default;
      case "white":
        return theme.color.neutral.text.inverse.default;
      default:
        return "inherit";
    }
  }};

  &[disabled] {
    cursor: default;
    opacity: 0.4;
    pointer-events: none;
  }
`;

export const ListItemText = styled(Container)`
  flex: 1 1 auto;
  min-width: 0;
`;

interface ListItemIconStyleProps extends ListItemIconProps {}

export const ListItemIcon = styled(Container).attrs(
  (props: ListItemIconStyleProps) => ({
    color: props.color || "inherit",
    size: props.size || "md",
    placement: props.placement || "middle",
  }),
)<ListItemIconStyleProps>`
  display: inline-flex;
  margin-right: ${props => (props.$size === "sm" ? "4px" : "16px")};
  min-width: 16px;
  flex-shrink: 0;
  align-items: ${({ $placement }) => {
    switch ($placement) {
      case "top":
        return "top";
      default:
        return "center";
    }
  }};
  // color: #1d2330;
  color: ${({ $placement }) => {
    switch ($placement) {
      case "top":
        return "#1d2330";
      default:
        return "inherit";
    }
  }};
  //sign-up page list uses this for best alignment possible
  margin-top: ${({ $placement }) => {
    switch ($placement) {
      case "top":
        return "5px";
      default:
        return "0px";
    }
  }};
`;
