import { FormRow, Grid, TextField } from "../../../util";
import {
  addressCitySchema,
  addressCountrySchema,
  addressLineOneSchema,
  addressLineTwoSchema,
  addressPostcodeSchema,
  addressStateSchema,
} from "../../../../validations";
import * as Styled from "./styles.css";

const AddressGroup = ({ hidden, ...otherProps }: AddressGroupProps) => {
  return (
    <Styled.AddressFieldsGroup $hidden={hidden}>
      <FormRow>
        <TextField
          autoComplete="address_1"
          id="address_1"
          label="Street address"
          size="lg"
          type="text"
          validationOnBlur
          validationOnChange
          validationType={addressLineOneSchema}
        />
      </FormRow>
      <FormRow>
        <TextField
          autoComplete="address_2"
          id="address_2"
          label="Apartment, unit, suite, or floor #"
          optional
          size="lg"
          type="text"
          validationOnBlur
          validationOnChange
          validationType={addressLineTwoSchema}
        />
      </FormRow>
      <FormRow container>
        <Grid item xs={6}>
          <TextField
            autoComplete="city"
            id="city"
            label="City"
            size="lg"
            type="text"
            validationOnBlur
            validationOnChange
            validationType={addressCitySchema}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            autoComplete="state"
            id="state"
            label="State"
            size="lg"
            type="text"
            validationOnBlur
            validationOnChange
            validationType={addressStateSchema}
          />
        </Grid>
      </FormRow>
      <FormRow container>
        <Grid item xs={6}>
          <TextField
            autoComplete="postcode"
            // hidden={hidden}
            id="post_code"
            label="Postcode"
            size="lg"
            type="text"
            validationOnBlur
            validationOnChange
            validationType={addressPostcodeSchema}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            autoComplete="country"
            id="country"
            label="Country"
            size="lg"
            type="text"
            validationOnBlur
            validationOnChange
            validationType={addressCountrySchema}
          />
        </Grid>
      </FormRow>
    </Styled.AddressFieldsGroup>
  );
};

interface AddressGroupProps {
  hidden?: boolean;
}

export default AddressGroup;
