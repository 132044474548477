import { ReactNode } from "react";
import { useMediaQuery } from "react-responsive";

import { breakpoints } from "../../../styles";
import { ContainerProps } from "../container/container";
import { Orientation, TTabsVariant } from "../../../types";
import * as Styled from "./styles.css";

const TabsContainer = ({
  children,
  tabsRight,
  id,
  orientation,
  scrolling,
  variant,
  ...otherProps
}: TabsContainerProps) => {
  const isMediaLGUp = useMediaQuery({ query: breakpoints.up("lg") });
  const tabLists = [children, tabsRight].filter(Boolean);

  return (
    <Styled.Tabs
      element="nav"
      role="tabs"
      $orientation={
        !isMediaLGUp && orientation === "vertical" && variant === "contained"
          ? "horizontal"
          : orientation
      }
      $scrolling={scrolling}
      $variant={variant}
      {...otherProps}
    >
      {tabLists?.map(tabs => (
        <Styled.TabList
          constrained={false}
          orientation={
            !isMediaLGUp &&
            orientation === "vertical" &&
            variant === "contained"
              ? "horizontal"
              : orientation
          }
          $scrolling={scrolling}
          $variant={variant}
        >
          {tabs}
        </Styled.TabList>
      ))}
    </Styled.Tabs>
  );
};

export interface TabsContainerProps extends ContainerProps {
  id: string;
  orientation: Orientation;
  scrolling?: boolean;
  variant: TTabsVariant;
  tabsRight?: ReactNode[];
}

TabsContainer.defaultProps = {
  orientation: "horizontal",
  scrolling: false,
  variant: "open",
};

export default TabsContainer;
