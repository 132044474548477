export const IFRAMEDEFAULTS: Record<string, Record<string, any>> = {
  youtube: {
    title: "YouTube video player",
    frameBorder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowFullScreen: true,
  },
  default: {},
};
export default IFRAMEDEFAULTS;
