import { useContext } from "react";
import { FormContext } from "../../../contexts";
import { InputProps } from "./index";
import InputHelpText from "./inputHelpText";
import InputLabel from "./inputLabel";
import * as Styled from "./styles.css";

const CheckBox = ({
  checked,
  children,
  component,
  error,
  helpText,
  id,
  label,
  onBlur,
  onChange,
  optional,
  paragraph,
  size,
  ...otherProps
}: CheckBoxProps) => {
  const { formData, formStep, setFormData } = useContext(FormContext);
  const inputLabelId = label && id ? `${id}-label` : undefined;
  return (
    <>
      <Styled.CheckboxContainer>
        <Styled.Checkbox
          id={id}
          checked={checked ? checked : formData[formStep - 1][id]}
          value={formData[formStep - 1][id]}
          onChange={async (event: any) => {
            if (onChange) {
              onChange(event);
            }
            setFormData(id, !formData[formStep - 1][id]);
          }}
          $error={error}
          $size={size}
          {...otherProps}
        >
          {children && children}
        </Styled.Checkbox>
        {checked
          ? checked
          : formData[formStep - 1][id] && (
              <Styled.CheckboxIcon shade="inverse" icon="check" />
            )}
      </Styled.CheckboxContainer>
      {label && (
        <InputLabel
          htmlFor={id}
          id={inputLabelId}
          inline
          showOptional={optional}
        >
          {label}
        </InputLabel>
      )}
      {helpText && <InputHelpText error={error} helpList={helpText} />}
    </>
  );
};

interface CheckBoxProps extends InputProps {
  checked?: boolean;
  helpText?: string;
  id: string;
  label?: string;
  onBlur?: any;
  onChange?: any;
  optional: boolean;
  paragraph: boolean;
  size: SizeTypes;
}

CheckBox.defaultProps = {
  checked: false,
  disabled: false,
  error: false,
  fullWidth: false,
  optional: false,
  paragraph: false,
  size: "md",
  type: "checkbox",
};

export type SizeTypes = "md" | "lg";

export default CheckBox;
