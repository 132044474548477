import React from "react";
import {
  Accordion as MuiAccordion,
  AccordionProps as MuiAccordionProps,
  makeStyles,
  Theme,
} from "@material-ui/core";

const styles = makeStyles((theme: Theme) => ({
  root: {
    "&.Mui-expanded": {
      margin: "0px",
      "&::before": {
        opacity: 0.75,
      },
    },
    "&.Mui-expanded + &": {
      "&::before": {
        display: "block",
      },
    },
  },
  expanded: {},
}));

export interface AccordionProps extends MuiAccordionProps {}

const Accordion = (props: AccordionProps) => {
  const classes = styles();
  return (
    <MuiAccordion classes={{ ...classes, ...props.classes }} {...props}>
      {props.children && props.children}
    </MuiAccordion>
  );
};

Accordion.defaultProps = {};
export default Accordion;
