import { useContext } from "react";
import { RouteInterface } from "../../../routes";
import { ContainerProps } from "../../util/container/container";
import { AuthContext, SidebarContext } from "./../../../contexts";
import * as Styled from "./styles.css";

const Main = ({ children, route }: MainProps) => {
  const auth = useContext(AuthContext);
  const { isHeaderVisible } = useContext(SidebarContext);
  return (
    <Styled.Main
      id="main"
      element="main"
      $isDesktop={!isHeaderVisible}
      $isAppFramed={!auth.user || !route.roles}
      $pageType={route.pageType}
    >
      {children && children}
    </Styled.Main>
  );
};

interface MainProps extends ContainerProps {
  route: RouteInterface;
}

export default Main;
