import styled from "styled-components";
import Select from "react-select";
import { theme } from "../../../styles/themes";
import { buildBoxShadow } from "./../../../helpers";
import { RolesProps } from ".";

export const Roles = styled(Select).attrs((props: any) => ({
  isValid: props.isValid,
}))<RolesProps>`
  &:hover {
    cursor: pointer;
  }
  & [class*="control"] {
    border-radius: ${theme.border.radius.md};
    border-color: ${theme.color.surface.border.weak.default};
    box-shadow: ${buildBoxShadow("low")};

    &:hover {
      border-color: ${theme.color.surface.border.default.hover};
      cursor: pointer;
    }

    &[class*="--menu-is-open"] {
      border-width: 2px;
      border-color: ${theme.color.accent.border.default.default};
      padding: 0px;
    }

    ${(props: any) => {
      if (!props.isValid) {
        return `
          border-width: 2px;
          border-color: ${theme.color.negative.border.default.default};
          padding: 0px;
        `;
      }
    }}
  }

  & [class*="menu"] {
    & [class*="-list"] {
    }
    &[class*="--is-selected"] {
      background-color: ${theme.color.accent.background.default.default};
    }
  }
  & [class*="option"] {
    &:hover {
      cursor: pointer;
    }
    &[class*="--is-focused"] {
      background-color: ${theme.color.accent.background.weaker.default};
    }
    &[class*="--is-selected"] {
      background-color: ${theme.color.accent.background.default.default};
    }
  }
`;
