import styled from "styled-components";
import { breakpoints, theme } from "../../styles";
import { Container, Grid, Typography, Button } from "../../components/util";

export const Header = styled(Grid)``;

export const Tabs = styled(Grid)``;

export const Title = styled(Typography)`
  margin-bottom: 10px;
`;

export const SelfAssessmentMessage = styled(Typography)`
  color: ${theme.color.surface.text.strong.default};
  margin-top: 16px;
  max-width: 520px;
`;

export const FactorsContainer = styled(Grid)`
  margin-top: 32px;
  @media ${breakpoints.up("md")} {
    &.MuiGrid-grid-md-6 {
      margin-top: 0px;
    }
  }
`;

export const Max = styled(Typography)`
  color: ${theme.color.surface.text.strong.default};
  display: inline-block;
`;

export const PaddedContainer = styled(Grid)`
  padding: 32px;
  text-align: center;
`;

export const CompleteContainer = styled(Grid)`
  height: calc(100vh - 56px);
  margin-top: 80px;
  min-height: 500px;
  padding: 32px;
  text-align: center;
  @media ${breakpoints.up("md")} {
    height: 700px;
  }
`;

export const ViewButton = styled(Button)`
  margin-top: 40px;
  margin-right: 15px;
`;

export const ResetButton = styled(Button)`
  margin-top: 40px;
`;

export const TypeformContainer = styled(Container)`
  bottom: 0;
  top: 56px;
  z-index: 99;
  iframe {
    height: calc(100vh - 56px);
    min-height: 500px;
    @media ${breakpoints.up("md")} {
      height: 700px;
    }
  }
`;
