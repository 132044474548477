import { IPalette } from "../../types";

const scPalette: IPalette = {
  grey: {
    white: "#FFFFFF",
    5: "#F8FAFC",
    10: "#E9EEF6",
    20: "#DBE0EB",
    30: "#BFC6D4",
    40: "#ABB5C4",
    50: "#828EA0",
    60: "#687587",
    70: "#545F70",
    80: "#3F495A",
    90: "#2C3544",
    100: "#1F2533",
    black: "#070B12",
  },
  purple: {
    5: "#ECEDFE",
    10: "#e0e4ff",
    20: "#cfd2fc",
    30: "#b3b3ff",
    40: "#7470f5",
    50: "#675df4",
    60: "#564be7",
    70: "#4740d4",
    80: "#3628a4",
    90: "#261b79",
    100: "#1D1461",
  },
  blue: {
    5: "#E5FAFF",
    10: "#DBF8FF",
    20: "#C2F0FF",
    30: "#85DAFF",
    40: "#2BB0F3",
    50: "#0E9EEC",
    60: "#0E8AD8",
    70: "#0D75B5",
    80: "#0C5F92",
    90: "#0E4976",
    100: "#0A3661",
  },
  green: {
    5: "#E8FCF5",
    10: "#DAFBEF",
    20: "#C8F4E4",
    30: "#99E5C9",
    40: "#1EAE7E",
    50: "#17966C",
    60: "#04855D",
    70: "#007A52",
    80: "#006141",
    90: "#00422C",
    100: "#003322",
  },
  red: {
    5: "#FFF0F1",
    10: "#FFE5E9",
    20: "#FED2D6",
    30: "#FBACB2",
    40: "#DB4249",
    50: "#C62F35",
    60: "#B62A32",
    70: "#A8242A",
    80: "#7B1419",
    90: "#5C080C",
    100: "#450407",
  },
  orange: {
    5: "#FFFAE5",
    10: "#FFF1CC",
    20: "#FFDF9E",
    30: "#FFCA7A",
    40: "#E78932",
    50: "#D97502",
    60: "#C96500",
    70: "#BD5800",
    80: "#9E4A00",
    90: "#612D00",
    100: "#472100",
  },
};

export default scPalette;
