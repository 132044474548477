import ScoreFactors, { ScoreFactorDetails, ScoreFactorsProps } from ".";

const SelfAssessmentFactors = ({
  v2,
  ...otherProps
}: SelfAssessmentFactorsProps) => {
  return (
    <ScoreFactors
      factors={v2 ? scoreFactorsDataV2 : scoreFactorsData}
      {...otherProps}
    />
  );
};

interface SelfAssessmentFactorsProps
  extends Omit<ScoreFactorsProps, "factors"> {
  v2: boolean;
}

const scoreFactorsData: Record<string, ScoreFactorDetails> = {
  management: {
    title: "Risk mitigation practice",
    actions: [
      {
        href: "/book-assessment",
        text: "Power up your risk mitigation",
        router: true,
      },
    ],
  },
  inspections: {
    title: "Visibility and productivity",
    actions: [
      {
        href: "https://public-library.safetyculture.io/search/?q=mitti&page=1t",
        text: "View templates",
        router: false,
      },
    ],
  },
  external_assessment: {
    title: "External review",
    actions: [
      {
        href: "/book-assessment",
        text: "Book a virtual survey",
        router: true,
      },
    ],
  },
  insurance: {
    title: "Risk protection",
    actions: [
      {
        href: {
          pathname: "/risk-and-safety-insights",
          state: { tabs: { insights: "deepdive" } },
        },
        text: "See risk and safety insights",
        router: true,
      },
    ],
  },
};

const scoreFactorsDataV2: Record<string, ScoreFactorDetails> = {
  management: {
    title: "Risk Management Strategy",
    actions: [
      {
        href: "/book-assessment",
        text: "Power up your risk mitigation",
        router: true,
      },
    ],
  },
  inspections: {
    title: "Onsite Inspections",
    actions: [
      {
        href: "https://public-library.safetyculture.io/search/?q=mitti&page=1t",
        text: "View templates",
        router: false,
      },
    ],
  },
  external_assessment: {
    title: "Statutory Requirements",
    actions: [
      {
        href: "/book-assessment",
        text: "Book a virtual survey",
        router: true,
      },
    ],
  },
  insurance: {
    title: "Insurance History Review",
    actions: [
      {
        href: {
          pathname: "/risk-and-safety-insights",
          state: { tabs: { insights: "deepdive" } },
        },
        text: "See risk and safety insights",
        router: true,
      },
    ],
  },
};

SelfAssessmentFactors.defaultProps = {};

export default SelfAssessmentFactors;
