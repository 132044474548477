import styled from "styled-components";
import { breakpoints, theme } from "../../../styles";

import { Container, IconButton, Select, SelectOption, Typography } from "..";

export const PaginationContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding-top: 12px;

  @media ${breakpoints.down("sm")} {
    flex-direction: column;
  }
`;

export const NavContainer = styled(Container)`
  display: flex;
`;

export const NavLink = styled(IconButton)``;

export const ControlContainer = styled(Container)`
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media ${breakpoints.down("sm")} {
    flex-direction: column;
  }
`;

export const ShowingTypography = styled(Typography)`
  margin-right: 16px;
`;

export const PageLimitSelect = styled(Select)`
  background-color: ${theme.color.surface.background.weakest.default};
  border: 1px solid ${theme.color.surface.border.weak.default};
  border-radius: 6px;
  display: flex;
  height: 48px;
  margin: 6px;
  padding: 0 16px;
  width: 150px;
`;

export const PageLimitOption = styled(SelectOption)`
  background-color: ${theme.color.surface.background.default.default};
  padding: 8px 15px;
`;
