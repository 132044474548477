import styled from "styled-components";
import { theme } from "./../../../../styles";
import Label from "./../../label/label";
import Typography from "./../../typography";

export const JfsInputLabel = styled(Label).attrs((props: any) => ({
  $inline: props.inline,
}))`
  align-items: center;
  font-weight: 500;
  display: ${(props: any) => (props.$inline ? "inline-block" : "block")};
  position: relative;
  vertical-align: baseline;
  pointer-events: none;
  width: auto;
  -webkit-tap-highlight-color: transparent;
  ${(props: any) =>
    props.$inline
      ? ``
      : `margin-bottom: 8px;
      color: ${theme.color.surface.text.weak.default};
    `};

  &[disabled] {
    opacity: 1;
  }
`;

export const JfsOptional = styled(Typography).attrs((props: any) => ({}))`
  color: ${theme.color.surface.text.weaker.default};
  font-weight: 400;
`;
