import styled from "styled-components";
import { breakpoints } from "../../../../../styles";
import { Container } from "../../../../util";
import Grid from "../../../../util/grid";

export const HeaderContainer = styled(Grid).attrs((props: any) => ({
  $actions: props.$actions,
  $back: props.$back,
  $type: props.$type,
}))`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const ContentContainer = styled(Container).attrs((props: any) => ({
  $type: props.$type,
}))`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  & h1,
  & h2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  & > :not(h1):not(h2) {
    margin-top: 4px;
  }

  @media ${breakpoints.up("md")} {
    flex-direction: column;
    margin-bottom: 32px;
    & h1,
    & h2 {
      -webkit-line-clamp: 1;
    }
    & > :not(h1):not(h2) {
      margin-top: 8px;
    }

    ${(props: any) => {
      switch (props.$type) {
        case "plain":
          return `
          margin-bottom: 0px;
        `;
        case "section":
          return `
          flex-direction: column;
          justify-content: space-between;
          margin-top: 24px;

          & > :not(:first-child) {
            margin-top: 12px;
          }

          @media ${breakpoints.up("md")} {
            flex-direction: row;
            margin-top: 40px;
            & > :not(:first-child) {
              margin-top: 0px;
            }
          }
        `;
        case "sectionMeter":
          return `
          flex-direction: column;
          justify-content: space-between;
          margin-bottom: 16px;
          margin-top: 24px;

          & > :not(:first-child) {
            margin-top: 12px;
          }

          @media ${breakpoints.up("md")} {
            flex-direction: row;
            margin-bottom: 24px;
            margin-top: 40px;
            & > :not(:first-child) {
              margin-top: 0px;
            }
          }
        `;
        default:
          return ``;
      }
    }}
  }
`;

export const ActionsContainer = styled(Container).attrs((props: any) => ({
  $back: props.$back,
  $type: props.$type,
}))`
  ${(props: any) => {
    if (props.$back) {
      return `
          margin-top: 36px;
        `;
    }
  }}
  ${(props: any) => {
    switch (props.$type) {
      case "section":
        return `
          margin-top: 40px;
      `;
    }
  }}
`;
