import { ListProps } from "../../../util/lists/list";
import * as Styled from "./styles.css";

const JfsList = ({ children, ...otherProps }: JfsListProps) => {
  return (
    <Styled.JfsList {...otherProps}>{children && children}</Styled.JfsList>
  );
};

interface JfsListProps extends ListProps {}

JfsList.defaultProps = {};

export default JfsList;
