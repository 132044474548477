import { ReactNode } from "react";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";

import { IPolicy, tInvitationAction, TStyleConnotation } from "../../../types";
import InviteButton from "./inviteButton";

import InvitationService, {
  Invitation,
  InvitationPrefill,
} from "../../../services/invitation/invitation.service";

import { tInvitationContext } from "../../../types/invitation.type";
import { IOrganisation } from "../../../services/admin/admin.service";

const InviteUser = ({
  action,
  button,
  context,
  invitation,
  orgId,
  policyId,
  type,
  successRoute,
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();

  const cancelInvite = async () => {
    if (invitation) {
      await InvitationService.cancelInvitation(invitation);
      queryClient.refetchQueries("organisation-portfolio-client-list");
      queryClient.refetchQueries(
        `portfolio-client-${invitation.policy_number}`,
      );
    } else {
      throw new Error("No invitation to cancel");
    }
  };

  const buttonAction = (): void => {
    let url = "/invitations/create";
    let data: InvitationPrefill | undefined;

    switch (action) {
      case "cancel":
        cancelInvite();
        break;
      default:
        if (invitation) {
          data = {
            fields: {
              firstname: invitation.firstname,
              lastname: invitation.lastname,
              email: invitation.email,
              policy_number: invitation.policy_number,
              customer_org_name: invitation.customer_org_name,
              sender_org_id: invitation.sender_org_id,
              sender_first_name: invitation.sender_first_name,
              sender_last_name: invitation.sender_last_name,
              email_cc: invitation.email_cc,
              role: invitation.role,
              scheme: invitation.data?.scheme,
            },
            meta: {
              sender_org_name: invitation.sender_org_name ?? "",
            },
          };
        } else if (orgId != null) {
          url += `?orgId=${orgId}`;
        } else if (policyId != null) {
          url += `?policyId=${policyId}`;
        }

        history.push(url, {
          type,
          data,
          successRoute: successRoute ?? location,
        });
    }
  };

  const buttonColor = (): TStyleConnotation => {
    switch (action) {
      case "send":
        if (context === "list-header") {
          return "surface";
        }
        return "accent";
      case "resend":
        return "accent";
      default:
        return "surface";
    }
  };

  const buttonText = (): string | undefined => {
    if (!action) return;

    switch (action) {
      case "send":
        return `Invite ${type}`;
      case "resend":
        return "Resend invite";
      case "cancel":
        return "Cancel invite";
    }
  };

  const buttonVariant = (): "text" | "contained" => {
    switch (context) {
      case "list":
      case "overflow":
        return "text";
      default:
        if (action === "cancel") {
          return "text";
        }
        return "contained";
    }
  };

  return (
    <>
      {button ?? (
        <InviteButton
          context={context}
          color={buttonColor()}
          onClick={buttonAction}
          text={buttonText()}
          type={type}
          variant={buttonVariant()}
        />
      )}
    </>
  );
};

interface Props {
  action: tInvitationAction;
  button?: ReactNode;
  context?: tInvitationContext;
  invitation?: Invitation;
  orgId?: IOrganisation["id"];
  policyId?: IPolicy["id"];
  type: "user" | "client";
  successRoute?: string;
}

InviteUser.defaults = {
  action: "send",
  type: "client",
};

export default InviteUser;
