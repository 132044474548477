import styled from "styled-components";
import { Grid as ComponentGrid } from "../../components/util";
import { GridProps } from "@material-ui/core";

export interface StyledGridProps extends GridProps {
  $fullHeight?: boolean;
}

export const Grid = styled(ComponentGrid)<StyledGridProps>`
  height: ${props => (props.$fullHeight ? "100%" : "initial")};
`;
