import styled from "styled-components";
import { transparentize } from "polished";
import { breakpoints, theme } from "../../styles";
import { People as PeopleIcon } from "@styled-icons/octicons";
import {
  Grid as ComponentGrid,
  Link as ComponentLink,
  IconButton,
  SelectMenu,
  SelectOption,
  Table,
  Typography,
} from "../../components/util";
import { GridProps } from "@material-ui/core";
const dropShadow = `0px 1px 5px ${transparentize(
  0.7,
  theme.color.surface.background.inverse.default,
)}`;
export interface StyledGridProps extends GridProps {
  $fullHeight?: boolean;
}

export const People = styled(PeopleIcon).attrs(props => ({
  size: props.size || 40,
}))`
  background-color: ${theme.color.surface.background.weakest.default};
  border-radius: 6px;
  padding: 6px;
  margin-bottom: ${theme.spacing / 3}px;
`;

export const SubHeader = styled(Typography)`
  margin-top: 8px;
  color: ${theme.color.surface.text.strong.default};
  font-weight: normal;
`;

export const EmptyHeader = styled(Typography).attrs(() => ({
  variant: "displaySmall",
}))`
  margin: ${theme.spacing / 3}px 0px;
`;

export const EmptyBody = styled(Typography).attrs(() => ({
  variant: "body1",
}))`
  margin: ${theme.spacing / 3}px 0px;
`;

export const Link = styled(ComponentLink)`
  text-decoration: none;
`;
export const LinkButton = styled.a`
  text-decoration: none;
  color: inherit;
  &:visited {
    color: inherit;
  }
`;

export const Grid = styled(ComponentGrid)<StyledGridProps>`
  height: ${props => (props.$fullHeight ? "100%" : "initial")};
`;

export const MoreOptionSelectMenu = styled(SelectMenu)`
  transform: translate3d(10px, 10px, 0);
  z-index: 99;
  border-radius: 6px;
  box-shadow: ${dropShadow};
`;
export const MoreOptionSelectOption = styled(SelectOption)`
  padding: 8px 15px;
  background-color: ${theme.color.surface.background.default.default};
`;
export const MoreOptionIconButton = styled(IconButton)`
  transform: rotate(90deg);
`;
export const MoreOptionLaunchIconButton = styled(IconButton)`
  padding: 0px;
  vertical-align: center;
  padding-left: 10px;
`;

export const ResultCount = styled(Typography).attrs(() => ({
  variant: "body1",
}))`
  font-weight: 600;
  margin: ${theme.spacing}px 0;
`;

export const PortfolioListTable = styled(Table)`
  @media ${breakpoints.down("sm")} {
    grid-template-columns: 1fr;
  }
  th:not(:first-of-type) {
    display: none;
    @media ${breakpoints.up("md")} {
      display: flex;
    }
  }
  td:not(:first-of-type) {
    display: none;
    @media ${breakpoints.up("md")} {
      display: flex;
    }
  }
`;
