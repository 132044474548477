import axios from "../../axios";
import toastEvent from "../../components/util/toast/toast.event";

import {
  commaSeparated,
  hasAllowedRoles,
  hasMorePrivilege,
} from "../../helpers";

import { tUserRole, tAnyPolicy } from "../../types";
import { UserRolesResponse, iRole } from "../../types/user.type";
import { User } from "../auth/auth.service";

const AdminService = {
  async fetchAllOrgs(): Promise<IOrganisation[] | undefined> {
    try {
      const { data } = await axios.get("/admin/organisations/all");
      return data;
    } catch (err) {
      toastEvent.error("Something went wrong, try again");
    }
  },

  async fetchOrgById(id: string): Promise<IOrganisation | undefined> {
    try {
      const { data: org } = await axios.get(`/admin/organisations/${id}`);

      if (org == null) return;

      org.derived = {};

      org.derived.contactName = commaSeparated([
        org.users?.[0]?.lastname,
        org.users?.[0]?.firstname,
      ]);

      org.derived.evariId = (
        org.externalMappings?.find((em: any) => em.name === "evari") as any
      )?.external_id;

      org.derived.policyNumber = (
        org.externalMappings?.find((em: any) => em.name === "policy") as any
      )?.external_id;

      return org;
    } catch (err) {
      toastEvent.error("Something went wrong, try again");
    }
  },

  // Roles
  async fetchAllRoles(): Promise<UserRolesResponse | undefined> {
    try {
      const { data } = await axios.get("/admin/roles");
      return data;
    } catch (err) {
      toastEvent.error("Something went wrong, try again");
    }
  },

  async fetchAllRolesAllowedForUser(user: User) {
    return (await this.fetchAllRoles())
      ?.filter(
        (role: any) =>
          hasAllowedRoles(user.roles, { allowed: ["super_admin"] }) ||
          hasMorePrivilege(user.roles[0], role.name),
      )
      .map((role: any) => ({ value: role.id, label: role.name }));
  },

  async updateUserRole({
    user_id,
    role_id,
  }: Record<string, string>): Promise<void | undefined> {
    try {
      const { data } = await axios.patch(`/admin/users/${user_id}/role`, {
        role_id,
      });
      return data;
    } catch (err) {
      toastEvent.error("Something went wrong, try again");
    }
  },

  async fetchAllUsers(): Promise<IUser[] | undefined> {
    try {
      const { data } = await axios.get("/admin/users/all");
      return data;
    } catch (err) {
      toastEvent.error("Something went wrong, try again");
    }
  },

  async updateUser(userData: IUser): Promise<any> {
    try {
      const data = await axios.patch(`/admin/users/${userData.id}`, userData);
      return data;
    } catch (err) {
      toastEvent.error("Something went wrong, try again");
    }
  },
  async updateOrgaisation(organisationData: IOrganisation): Promise<any> {
    try {
      const data = await axios.patch(
        `/admin/organisations/${organisationData.id}`,
        organisationData,
      );
      return data;
    } catch (err) {
      toastEvent.error("Something went wrong, try again");
    }
  },

  async fetchUserById(id): Promise<IUser | undefined> {
    try {
      const { data } = await axios.get(`/admin/users/${id}`);
      return data;
    } catch (err) {
      toastEvent.error("Something went wrong, try again");
    }
  },

  async fetchAllPolicies(type: string): Promise<tAnyPolicy[] | undefined> {
    try {
      const { data } = await axios.get("/admin/policies/all", {
        params: { type },
      });

      return data;
    } catch (err) {
      toastEvent.error("Something went wrong, try again");
    }
  },

  async fetchPolicyByPolicyNumber(
    policyNumber,
  ): Promise<tAnyPolicy | undefined> {
    try {
      const { data } = await axios.get(`/admin/policies/${policyNumber}`);

      return data;
    } catch (err) {
      toastEvent.error("Something went wrong, try again");
    }
  },
};

export interface IOrganisation {
  id: string; // uuid
  crm_id: string;
  channel: string;
  type: string;
  name: string;
  industry_id: string; // uuid
  abn: string;
  url: string;
  email: string;
  phone: string;
  city: string;
  state: string;
  address_1: string;
  address_2: string;
  country: string;
  post_code: string;
  logo_url: string;
  is_active: true;
  typeform_id: string;
  organisation_size: string;
  users?: IUser[];
  externalMappings?: IExternalMapping[];
  score: Object;
  industry: IIndustry;
  activity: IActivity[];
  derived?: IOrgDerived;
  childOrgMap?: string[] | null;
  parentOrgMap?: string[] | null;
  associated_policies: [{ policy_id: string }];

  created_at: string; // ISO-8601
  updated_at: string; // ISO-8601
}

export interface IExternalMapping {
  id: string; // uuid
  related_id: string; // uuid
  related_model: string;
  name: string;
  external_id: string; // uuid
  created_at: string; // ISO-8601
  updated_at: string; // ISO-8601
}

export interface IOrgDerived {
  contactName?: string;
  evariId?: string;
  iAuditorId?: string;
  policyNumber?: string;
}

export interface IIndustry {
  id: string; // uuid
  name: string;
  anzsic: string;
  occupation: string;
}

export interface IActivity {
  type: string;
  action: string;
  date: string; // ISO-8601
}

export interface IUser {
  id: string; // uuid
  crm_id?: string; // uuid
  type?: string;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  city?: string;
  state?: string;
  address_1?: string;
  address_2?: string;
  country?: string;
  post_code?: string;
  profile_picture_url?: string;
  is_active?: boolean;
  is_marketable?: boolean;
  roles?: tUserRole[];
  user_roles: iRole[];
  organisations?: IOrganisation[];
  externalMappings?: Object[];
  activity?: Object[];
  created_at?: string;
  updated_at?: string;
}

export default AdminService;
