import styled from "styled-components";
import { components } from "react-select";
import Icon from "./../../icons/icon";

export const ControlContainer = styled(components.Control).attrs(
  (props: any) => ({}),
)`
  padding: 1px;
  line-height: 24px;
`;

export const ControlPrependIcon = styled(Icon).attrs((props: any) => ({}))`
  margin-left: 15px;
`;

export const ValueContainer = styled(components.ValueContainer).attrs(
  (props: any) => ({
    $size: props.$size,
  }),
)`
  &[class*="ValueContainer"] {
    ${(props: any) => {
      switch (props.$size) {
        case "sm":
          return `padding: 0px 12px;`;
        case "md":
        default:
          return `
            min-height: 40px;
            padding: 8px 15px 8px 11px;
          `;
      }
    }}
  }
`;
