import { useQuery } from "react-query";
import {
  IAuditorMeter,
  ScoreMeter,
  SelfAssessmentFactors,
} from "../../../components/content";
import { Section } from "../../../components/layout";
import ScoresService from "../../../services/scores/scores.service";
import { CircularProgress } from "../../../components/util/progress/styles.css";

const ScoreTab = ({ policy_id }: ScoreTabProps) => {
  const { data, isLoading } = useQuery(`scores_${policy_id}`, async () => {
    const score = await ScoresService.getIauditorScoreByPolicyId(policy_id);
    const iAuditorScore: { isError: boolean; score: any } = {
      isError: false,
      score: {},
    };

    if (score.error) {
      iAuditorScore.isError = true;
      iAuditorScore.score = score;
    }
    return {
      scores: await ScoresService.getScoreByPolicyId(policy_id),
      iAuditorScore: iAuditorScore,
    };
  });

  return (
    <>
      {!isLoading && data && (
        <>
          <Section bordered={false}>
            <ScoreMeter
              titleVariant="section"
              score={data.scores.scores}
              title="Self assessment score"
            />
            <SelfAssessmentFactors
              data={data.scores.factors}
              v2={true}
              orientation="horizontal"
            />
          </Section>
          <Section>
            <IAuditorMeter
              data={data.iAuditorScore.score}
              isError={data.iAuditorScore.isError}
              isBroker={true}
            />
          </Section>
        </>
      )}
      {isLoading && <CircularProgress />}
    </>
  );
};

interface ScoreTabProps {
  policy_id: string;
}

export default ScoreTab;
