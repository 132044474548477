import styled from "styled-components";
import { breakpoints, theme } from "../../../styles";
import { Chip, Link, Table } from "../../util";

export const ActivityTable = styled(Table)`
  @media ${breakpoints.down("sm")} {
    grid-template-columns: 1fr;
  }
  th:not(:first-of-type) {
    display: none;
    @media ${breakpoints.up("md")} {
      display: flex;
    }
  }
  td:not(:first-of-type) {
    display: none;
    @media ${breakpoints.up("md")} {
      display: flex;
    }
  }
`;

export const ActionStatus = styled(Chip)`
  margin-left: 8px;
  font-weight: 500;
  vertical-align: middle;
  line-height: 20px;
`;

export const ActionLink = styled(Link)`
  padding: 5px;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 170px;
  color: ${theme.color.surface.text.default.default};

  &:active {
    pointer-events: none;
  }
  &:hover {
    color: ${theme.color.surface.text.default.default};
    text-decoration: none;
  }
  &:visited {
    color: ${theme.color.surface.text.default.default};
    text-decoration: none;
  }
  @media ${breakpoints.up("md")} {
    max-width: 250px;
  }
  @media ${breakpoints.up("lg")} {
    max-width: 500px;
  }
`;

export const UserNameLink = styled(Link)`
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${theme.color.surface.text.default.default};

  &:active {
    pointer-events: none;
  }
  &:hover {
    color: ${theme.color.surface.text.default.hover};
    text-decoration: none;
  }
  &:visited {
    color: ${theme.color.surface.text.default.hover};
    text-decoration: none;
  }

  @media ${breakpoints.up("md")} {
    max-width: 100px;
  }
  @media ${breakpoints.up("lg")} {
    max-width: 200px;
  }
`;
