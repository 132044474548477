import React from "react";

export const getAdjacentKey = (
  currentKey: string,
  object: Record<string | number, any>,
  direction: "next" | "previous",
): string | number => {
  const keys = Object.keys(object);
  const nextIndex = keys.indexOf(currentKey) + (direction === "next" ? 1 : -1);
  const nextItem = keys[nextIndex];
  return nextItem;
};

export const getObjectByString = (
  o: Record<any, any>,
  s: string,
): string | Record<any, any> | React.ReactNode | undefined => {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  const a: string[] = s.split(".");
  for (let i = 0, n: number = a.length; i < n; ++i) {
    const k: string = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};
