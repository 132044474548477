import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "../../components/util/";
import { FaqInterface } from "../../services/prismic/prismic.service";

const Faq = ({ question, answer }: FaqInterface) => {
  return (
    <Accordion>
      <AccordionSummary>
        <Typography>{question}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{answer}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

Faq.defaultProps = {};
export default Faq;
