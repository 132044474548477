import styled from "styled-components";
import { List, ListItem } from "./../..";

export const JfsButtonList = styled(List).attrs((props: any) => ({}))`
  display: inline-flex;
  overflow: hidden;
`;

export const JfsButtonListItem = styled(ListItem).attrs((props: any) => ({}))`
  & button {
    border-radius: 0px;
    min-width: 80px;
  }
  &:first-of-type {
    & button {
      border-radius: 7px 0 0 7px;
    }
  }
  &:last-of-type {
    & button {
      border-radius: 0 7px 7px 0;
    }
  }
  &:not(:last-of-type) {
    & button {
      border-right-width: 0px;
    }
  }
`;
