import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../styles";
import { LocationContext } from ".";
import { ITab, TTabStatus, Orientation, TTabsVariant } from "../types";
import { ContainerProps } from "../components/util/container/container";

export const TabsContextProvider = ({
  children,
  id,
  ...otherProps
}: TabsContextProviderProps) => {
  const { location, urlParams } = useContext(LocationContext);
  const isMediaLGUp = useMediaQuery({ query: breakpoints.up("lg") });
  const [tabs, setTabs] = useState<ITab[]>(otherProps.tabs);
  const [tabsId, setTabsId] = useState<string>(id);
  const [currentTab, setCurrentTab] = useState<string | undefined>();
  const [orientation, setOrientation] = useState<Orientation>(
    otherProps.orientation || "horizontal",
  );
  const [variant, setVariant] = useState<TTabsVariant>(
    otherProps.variant || "open",
  );

  // Return TargetTab, otherwise First viable tab
  const handleTargetOrFirst = useCallback(
    (inputTabs: ITab[], targetTab?: string): string => {
      if (!targetTab && !inputTabs[0].restricted) {
        return inputTabs[0].title;
      }
      const tabName: ITab | undefined = inputTabs.find((tab: ITab) => {
        if (tab.restricted) return false;
        if (!targetTab) return true;
        return tab.title === targetTab;
      });
      return tabName ? tabName.title : inputTabs[0].title;
    },
    [],
  );

  // #1 Set Tab on location search param
  useEffect(() => {
    if (urlParams?.t && tabs) {
      setCurrentTab(handleTargetOrFirst(tabs, urlParams.t));
    }
  }, [handleTargetOrFirst, tabs, urlParams?.t]);

  // #2 Set Tab on location state (when no search param)
  useEffect(() => {
    if (!urlParams?.t && tabs && location.state?.tabs?.[tabsId]) {
      setCurrentTab(handleTargetOrFirst(tabs, location.state.tabs[tabsId]));
    }
  }, [handleTargetOrFirst, location.state, tabs, tabsId, urlParams?.t]);

  // #3 Set Initial Tab if no location state or search param present
  useEffect(() => {
    if (
      tabs &&
      !location.state?.tabs?.[tabsId] &&
      !urlParams?.t &&
      !currentTab
    ) {
      setCurrentTab(handleTargetOrFirst(tabs));
    }
  }, [
    currentTab,
    handleTargetOrFirst,
    location.state?.tabs,
    tabs,
    tabsId,
    urlParams?.t,
  ]);

  const handleTabStatus = useCallback(
    (targetTab: string, status: TTabStatus): void => {
      if (tabs) {
        const newTabs = tabs.map(tab => {
          if (tab.title === targetTab) {
            return { ...tab, status: status };
          }
          return tab;
        });
        setTabs([...newTabs]);
      }
    },
    [tabs],
  );

  // Wait for useEffect hooks to determine active tab (prevent flicker)
  if (!currentTab) {
    return <></>;
  }

  return (
    <TabsContext.Provider
      value={{
        currentTab: currentTab,
        // Force Horizontal orientation for Contained tabs at smaller sizes
        orientation:
          !isMediaLGUp && orientation === "vertical" && variant === "contained"
            ? "horizontal"
            : orientation,
        tabsId: tabsId,
        variant: variant,
        setCurrentTab: setCurrentTab,
        setOrientation: setOrientation,
        setTabStatus: handleTabStatus,
        setTabs: setTabs,
        setTabsId: setTabsId,
        setVariant: setVariant,
        tabs: tabs,
      }}
    >
      {children && children}
    </TabsContext.Provider>
  );
};

const TabsContext = React.createContext<TabsContextInterface>({
  currentTab: "",
  orientation: "horizontal",
  tabsId: "",
  variant: "open",
  setCurrentTab: () => {},
  setOrientation: () => {},
  setTabStatus: () => {},
  setTabs: () => {},
  setTabsId: () => {},
  setVariant: () => {},
  tabs: undefined,
});

interface TabsContextInterface {
  currentTab: string | undefined;
  orientation: Orientation;
  tabsId: string;
  variant: TTabsVariant;
  setCurrentTab: (id: string | undefined) => void;
  setOrientation: (orientation: Orientation) => void;
  setTabStatus: (id: string, status: TTabStatus) => void;
  setTabs: (tabs: ITab[]) => void;
  setTabsId: (id: string) => void;
  setVariant: (variant: TTabsVariant) => void;
  tabs: ITab[] | undefined;
}

export interface TabsContextProviderProps extends Omit<ContainerProps, "id"> {
  id: string;
  orientation: Orientation;
  tabs: ITab[];
  variant: TTabsVariant;
}

export default TabsContext;
