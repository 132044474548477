import { Chip } from "../../util";
import { iChipProps } from "../../util/chip";

const Label = ({ children, ...otherProps }: iLabelProps) => {
  return <Chip {...otherProps}>{children && children}</Chip>;
};

export interface iLabelProps extends Omit<iChipProps, "isStatus"> {}

Label.defaultValues = {};

export default Label;
