import styled from "styled-components";
import { transparentize } from "polished";
import { theme } from "../../../../styles";
import { buildBoxShadow } from "../../../../helpers";
import { Container, List, ListItem } from "./../..";
import JfsInput from "./../jfsInput/jfsInput";

const checkBoxHoverFocus = `0px 0px 0px 2px ${transparentize(
  0,
  theme.color.accent.border.default.default,
)}`;

export const JfsInlineCheckboxContainer = styled(Container).attrs(
  (props: any) => ({}),
)`
  display: flex;
`;

export const JfsCheckboxContainer = styled("span").attrs((props: any) => ({}))`
  display: flex;
  height: 18px;
  margin-right: 12px;
  margin-top: 3px;
  position: relative;
  width: 18px;
  vertical-align: top;

  span {
    left: 1.5px;
    pointer-events: none;
    position: absolute;
    top: 1px;
  }
`;

export const JfsCheckbox = styled(JfsInput).attrs(props => ({
  disabled: props.disabled,
}))`
  border: 1px solid ${theme.color.surface.border.weak.default};
  border-radius: 6px;
  box-shadow: ${buildBoxShadow("low")};
  cursor: pointer;
  display: block;
  height: 18px;
  width: 18px;
  vertical-align: text-top;

  :checked {
    background-color: ${theme.color.accent.background.default.default};
    border-color: ${theme.color.accent.border.default.default};
  }
  :focus {
    box-shadow: ${checkBoxHoverFocus};
    outline-width: 3px;
    outline-style: solid;
    outline-color: ${transparentize(
      0.65,
      theme.color.accent.border.default.pressed,
    )};
  }
  ${props =>
    props.disabled
      ? undefined
      : `:hover {
            border-color: ${theme.color.accent.border.default.hover};
            &:focus,
            &:checked {
              border-color: ${theme.color.accent.border.default.hover};
            }
          }`}
`;

export const JfsCheckBoxList = styled(List).attrs((props: any) => ({}))`
  overflow: hidden;
`;

export const JfsCheckBoxListItem = styled(ListItem).attrs((props: any) => ({
  $checked: props.$checked,
}))`
  align-items: center;
  padding: 12px 16px;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.color.surface.border.default.default};
  }

  &:hover {
    cursor: pointer;
    background-color: ${theme.color.surface.background.default.default};
  }

  ${(props: any) => {
    if (props.$checked) {
      return `
        background-color: ${theme.color.surface.background.default.default};
      `;
    }
  }}
`;
