import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQueryClient, useQuery } from "react-query";
import { useFlag } from "@unleash/proxy-client-react";

import { Button, CircularProgress, Grid } from "../../../components/util";
import { Header, Section } from "../../../components/layout";

import ToolkitService, {
  Toolkit,
} from "../../../services/toolkits/toolkits.service";

import { JfsFormData } from "../../../types";
import Form from "../../../components/content/flow/monoFlow";
import { schema, uiSchema } from "./createSchema";
import { PageNotFound } from "../..";

const ToolkitEdit = () => {
  const portalToolkitsCreate = useFlag("portal.toolkits.create");
  const { id } = useParams<Params>();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState<JfsFormData>();
  const create = id == null;

  const {
    data: toolkit,
    isError,
    isFetching,
  } = useQuery(["toolkit", id], async () => ToolkitService.getById(id), {
    enabled: !create,
  });

  if (!portalToolkitsCreate) return <PageNotFound />;
  if (isError) return <PageNotFound />;

  const title = create ? "Create New Toolkit" : `Edit ${toolkit?.name}`;

  const handleChange = ({ formData }: { formData?: any }) =>
    setFormData(formData);

  const onSubmit = async (submitData: JfsFormData): Promise<void> => {
    if (create) {
      const id = await ToolkitService.create(submitData);

      history.replace(`/toolkits/${id}`);
    } else {
      await ToolkitService.update(id, toolkit?.tools[0]?.id, submitData);
      await queryClient.invalidateQueries(["toolkit", id]);
      history.replace(`/toolkits/${id}`);
    }
  };

  const prefill = toolkit =>
    toolkit != null
      ? {
          name: toolkit.name,
          code: toolkit.code,
          // TODO: tools[0] (1st tool) only, because Toolkit hasMany Tools,
          // TODO: and Edit Toolkit page only supports editing one Tools record
          tools: toolkit.tools[0]?.data?.map(tool => ({
            name: tool.name,
            template_id: tool.template_id,
          })),
        }
      : undefined;

  if (isFetching) return <CircularProgress />;

  return (
    <Grid>
      <Header
        title={title}
        titleIcon="mail"
        titleIconPosition="end"
        type="article"
        withLink={{ path: "/toolkits", text: "All Toolkits" }}
      />

      <Section>
        <Form
          id="toolkit-edit"
          name={title}
          schema={schema}
          onChange={handleChange}
          onSubmit={onSubmit}
          formData={formData ?? prefill(toolkit)}
          uiSchema={uiSchema}
          cancelButton={
            create ? undefined : (
              <Button
                color="surface"
                router
                href={`/toolkits/${id}`}
                variant="contained"
              >
                Cancel
              </Button>
            )
          }
          submitButtonText={create ? "Submit" : "Update Toolkit"}
        />
      </Section>
    </Grid>
  );
};

type Params = Pick<Toolkit, "id">;

export default ToolkitEdit;
