import styled from "styled-components";
import { theme } from "../../../styles/themes";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const StyledSkeleton = styled(Skeleton)`
  background: ${theme.color.surface.background.weakest.default};
  cursor: wait;
  pointer-events: none;
`;
