import { WidgetProps } from "@rjsf/utils";
import { convertDateTime } from "../../../../../helpers";
import { Chronology } from "./../../..";

const JfsDateTime = ({
  id,
  onChange,
  value,
  ...otherProps
}: JfsDateTimeProps) => {
  const onTextChange = (val: string) => {
    const formattedDate = convertDateTime(val, "ISO");
    onChange(formattedDate);
  };

  return (
    <Chronology
      id={id}
      showTimeSelect
      minDate={otherProps?.schema?.formatMinimum}
      maxDate={otherProps?.schema?.formatMaximum}
      todayButton="Today"
      value={value}
      onChange={(val: string) => onTextChange(val)}
      {...otherProps}
    />
  );
};

interface JfsDateTimeProps extends WidgetProps {}

JfsDateTime.defaultProps = {};

export default JfsDateTime;
