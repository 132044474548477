import { useContext } from "react";
import { useQuery } from "react-query";
import { AuthContext } from "../../../contexts";
import {
  Scores,
  ScoreFactor,
  ScoreInfo,
} from "../../../services/scores/scores.service";
import safetycultureService from "../../../services/safetyculture/safetyculture.service";
import { Grid } from "../../../components/util";
import ScoreBreakdown from "./scoreBreakdown";
import IAuditorBreakdown from "./iAuditorBreakdown";
import * as Styled from "./styles.css";

const Breakdown = ({
  score,
  selfAssessmentScore,
  selfAssessmentScoreFactors,
}: BreakdownProps) => {
  const auth = useContext(AuthContext);

  const {
    data: iAuditorData,
    isError: isIAuditorError,
    isLoading: isIAuditorLoading,
  } = useQuery(
    "iAuditorScore",
    () => safetycultureService.fetchIAuditorRiskAndSafetyScore(auth.user.id),
    { retry: 0, refetchOnWindowFocus: false },
  );
  return (
    <>
      <Grid item xs={12}>
        <ScoreBreakdown
          score={selfAssessmentScore}
          scoreFactors={selfAssessmentScoreFactors}
          scoreMessage={
            "Identify ways to make your business safer with easy to implement recommendations while comparing your risk to the industry average and best practice."
          }
          scoreVersion={score?.version}
        />
      </Grid>
      <Styled.IAuditorBreakdownContainer item xs={12}>
        <IAuditorBreakdown
          data={iAuditorData}
          isError={isIAuditorError}
          isLoading={isIAuditorLoading}
        />
      </Styled.IAuditorBreakdownContainer>
    </>
  );
};

interface BreakdownProps {
  score?: ScoreInfo;
  selfAssessmentScoreFactors?: ScoreFactor[];
  selfAssessmentScore?: Scores;
}

export default Breakdown;
