import styled from "styled-components";
import { theme } from "./../../../../styles";
import { ListItem } from "./../../../util";

export const JfsListItem = styled(ListItem).attrs((props: any) => ({
  $checked: props.$checked,
}))`
  align-items: center;
  padding: 12px 16px;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.color.surface.border.weaker.default};
  }

  &:hover {
    cursor: pointer;
    background-color: ${theme.color.surface.background.weakest.hover};
  }

  ${(props: any) => {
    if (props.$checked) {
      return `
        background-color: ${theme.color.surface.background.default.default};
      `;
    }
  }}
`;
