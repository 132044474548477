import styled from "styled-components";
import { SelectMenu } from "../../../util";

export const AddressFieldsGroup = styled("div").attrs((props: any) => ({
  $hidden: props.$hidden,
}))`
  display: ${props => (props.$hidden ? "none" : "inherit")};
`;

export const FixedSelectMenu = styled(SelectMenu)`
  position: sticky;
  bottom: 0;
  max-height: 160px;
  overflow-y: scroll;
`;
