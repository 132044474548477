import styled from "styled-components";
import { Form } from "../../../util";

export const InlineForm = styled(Form).attrs((props: any) => ({}))`
  align-items: center;
  width: 100%;
`;

export const GroupForm = styled(Form).attrs((props: any) => ({}))`
  align-items: center;
  margin-top: 16px;
  width: 100%;
`;
