import styled from "styled-components";
import { theme } from "./../../../../styles";
import Typography from "./../../typography";
import List from "./../../lists/list";

export const JfsInputHelpTypography = styled(Typography)`
  margin-top: 8px;
  span {
    margin-right: 8px;
    vertical-align: middle;
  }
`;

export const JfsInputHelpList = styled(List).attrs((props: any) => ({
  $color: props.$color,
  $columns: props.$columns,
}))`
  color: ${theme.color.surface.text.strong.default};
  margin-top: 16px;
  ${theme.typography.small};
  li {
    display: inline-block;
  }
  ${(props: any) => {
    if (props.$columns === 2) {
      return `
        columns: 2 auto;
        li, li div {
          vertical-align: top;
        }
      `;
    }
  }}
`;
