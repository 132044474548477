import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext, LocationContext } from "../../../../../contexts";
import { Icon, IconButton } from "../../../../util";
import { TabsProps } from "../../../../util/tabs/tabs";
import * as Styled from "./styles.css";

const FlowTabs = ({
  children,
  orientation,
  name,
  ...otherProps
}: FlowTabsProps) => {
  const history = useHistory();
  let { user } = useContext(AuthContext);
  const { urlParams } = useContext(LocationContext);

  const backUrl = (urlParams: Record<any, any> | undefined): string => {
    let id = urlParams?.o || urlParams?.p || "";
    if (user.roles.includes("admin") | user.roles.includes("super_admin")) {
      return (
        `/${urlParams?.o ? "organisations" : ""}${
          urlParams?.p ? "policies" : ""
        }/` + id
      );
    }
    if (user.roles.includes("broker")) {
      return "/portfolio/general/" + id;
    }
    return "";
  };

  return (
    <Styled.FlowTabs
      childPrepend={
        <Styled.BackButton
          color="accent"
          variant="unstyled"
          href={backUrl(urlParams)}
          router
          onClick={(evt: any) => history.goBack()}
        >
          <Icon icon="arrow-left" color="accent" size="sm" />
          Exit
        </Styled.BackButton>
      }
      childAppend={<IconButton disabled icon="kebab-horizontal" size="sm" />}
      {...otherProps}
    >
      {!!children && children}
    </Styled.FlowTabs>
  );
};

export interface FlowTabsProps extends TabsProps {
  name: string;
}

FlowTabs.defaultProps = {
  variant: "unstyled",
  orientation: "horizontal",
  scrolling: true,
};

export default FlowTabs;
