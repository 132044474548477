import { useContext } from "react";
import { hasAllowedRoles } from "../../../../helpers";
import { AuthContext, SidebarContext } from "../../../../contexts";
import { Chip } from "../../../util";
import Logo from "../../../content/logo";
import { Dash, X } from "@styled-icons/octicons";
import * as Styled from "./styles.css";

const AppBar = () => {
  const { user } = useContext(AuthContext);
  const { isHeaderVisible, isSidebarVisible, toggleSidebar } =
    useContext(SidebarContext);

  return (
    <Styled.AppBar $isVisible={isHeaderVisible}>
      <Styled.Hamburger
        color="default"
        $isOpen={isSidebarVisible}
        onClick={toggleSidebar}
      >
        {isSidebarVisible ? (
          <X />
        ) : (
          <>
            <Dash />
            <Dash />
          </>
        )}
      </Styled.Hamburger>
      <Styled.Logo href="/" router>
        <Logo size="sm" />
        {hasAllowedRoles(user?.roles, { allowed: ["admin"] }) && (
          <Chip label={"Admin"} size="md" color="information" />
        )}
        {hasAllowedRoles(user?.roles, { allowed: ["super_admin"] }) && (
          <Chip label={"S-Tier Admin"} size="md" color="negative" />
        )}
      </Styled.Logo>
    </Styled.AppBar>
  );
};
export default AppBar;
