import { ComponentSize } from "../../../../../types";
import { Typography } from "./../../..";
import * as Styled from "./styles.css";

function TextFieldPrepend({
  format,
  size,
  type,
  ...otherProps
}: TextFieldPrependProps) {
  const getCharacter = (type?: string, format?: string): string | undefined => {
    switch (type) {
      case "number":
        switch (format) {
          case "dollar":
            return "$";
          default:
            return "#";
        }
      case "string":
        switch (format) {
          case "email":
            return "@";
          case "phone-au":
            return "#";
          default:
            return undefined;
        }
      default:
        return undefined;
    }
  };

  if (!getCharacter(type, format)) {
    return <></>;
  }

  return (
    <Styled.Prepend $size={size}>
      <Typography element="span">{getCharacter(type, format)}</Typography>
    </Styled.Prepend>
  );
}

interface TextFieldPrependProps {
  format?: string;
  size: ComponentSize;
  type?: string;
}

TextFieldPrepend.defaultProps = {};

export default TextFieldPrepend;
