import { BenchmarksIssuesInterface } from "../../../services/prismic/prismic.service";
import {
  Button,
  Card,
  Grid,
  Image,
  Typography,
} from "../../../components/util";

const Issues = ({
  issues,
}: Record<string, BenchmarksIssuesInterface | undefined>) => {
  return (
    <Grid item xs={12} lg={8}>
      <Card isInGrid isPadded>
        <Typography variant="displaySmall">What commonly goes wrong</Typography>
        <Image src={issues && issues.url} alt={issues && issues.alt} />
        <Button
          href="/risk-and-safety?t=my-tasks"
          router
          variant="outlined"
          fullWidth
        >
          Prevent these from happening
        </Button>
      </Card>
    </Grid>
  );
};

export default Issues;
