import React, { useContext } from "react";
import { Button } from "..";
import { ComponentSize } from "../../../types/attributes.type";
import { FormContext } from "./../../../contexts";
import * as Styled from "./styles.css";
const FormSubmit = ({
  error,
  errorMessage,
  hideSubmitButton,
  formStepLength,
  miscButton,
  miscButtonName,
  miscButtonOnClick,
  isFormSubmitted,
  isLoading,
  size,
  steppedForm,
  submitAlign,
  submitDisabled,
  submitInline,
  submitText,
  submitButtonAccent,
  ...otherProps
}: FormSubmitProps) => {
  const { isSubmitDisabled, formStep, setFormStep } = useContext(FormContext);

  return (
    <>
      <Styled.SubmitButton
        color={submitButtonAccent ? "neutral" : "accent"}
        $textColor={submitButtonAccent}
        $visibility={hideSubmitButton}
        disabled={
          isSubmitDisabled || isLoading || isFormSubmitted || submitDisabled
        }
        fullWidth={!submitInline}
        isLoading={isLoading}
        onClick={event =>
          steppedForm && formStep < formStepLength
            ? setFormStep(event, "next")
            : undefined
        }
        size={size}
        type={formStep < formStepLength ? "button" : "submit"}
        variant="contained"
      >
        {submitText ? submitText : "Submit"}
      </Styled.SubmitButton>

      {miscButton && (
        <Button variant="outlined" type="button" onClick={miscButtonOnClick}>
          {miscButtonName}
        </Button>
      )}
    </>
  );
};

export type FormError = undefined | string | React.ReactHTMLElement<any>;

interface FormSubmitProps {
  children?: React.ReactNode | React.ReactNodeArray;
  className?: string;
  error?: boolean;
  hideSubmitButton?: boolean;
  errorMessage?: any;
  formStepLength: number;
  inline?: boolean;
  miscButton?: boolean;
  miscButtonName?: string;
  miscButtonOnClick?: any;
  isFormSubmitted: boolean;
  isLoading: boolean;
  isSubmitDisabled?: boolean;
  formStep?: number;
  size?: ComponentSize;
  steppedForm?: boolean;
  submitAlign?: "left" | "center" | "right";
  submitDisabled?: boolean;
  submitInline?: boolean;
  submitText?: string;
  submitButtonAccent?: tSubmitButtonAccent;
}

FormSubmit.defualtProps = {
  error: false,
  hideSubmitButton: false,
  fullWidth: false,
  isSubmitDisabled: true,
  steppedForm: false,
  submitInline: false,
  submitAlign: "center",
  submitText: "Submit",
};

export default FormSubmit;

export type tSubmitButtonAccent = "warning" | "";
