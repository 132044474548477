import { transparentize } from "polished";
import { theme } from "../styles";
import { ButtonColor, Elevation } from "../types";

type ButtonState = "default" | "hover" | "focus" | "pressed" | "disabled";
const sColor = (color: ButtonColor, shade: number): string =>
  theme.color.surface.background.inverse.default;
// theme.palette[color][shade];

const cShadow = (
  color: ButtonColor = "neutral",
  shade: number = 700,
  opacity: number = 0.92,
): string => `${transparentize(opacity, sColor(color, shade))}`;

const cShadowFocus = (
  color: ButtonColor = "primary",
  shade: number = 700,
): string => `${transparentize(0.65, sColor(color, shade))}`;

const sShadowOuterLow: string = "0px 1px 5px";
const sShadowOuterHigh: string = "0px -1px 20px";
const sShadowInnerHigh: string = "0px 1px 1px";
const sShadowInnerLow: string = "0px 2px 8px";
const sShadowFocus = "0 0 0 3px";

export const buildBoxShadow = (
  outer: Elevation,
  inner?: ButtonState,
  color?: ButtonColor,
): string => {
  let boxShadow: string = "";
  switch (outer) {
    case "flat":
      boxShadow += "none";
      break;
    case "low":
      boxShadow += `${sShadowOuterLow} ${cShadow()}`;
      break;
    case "high":
      boxShadow += `${sShadowOuterLow} ${cShadow()}, ${sShadowOuterHigh} ${cShadow(
        "neutral",
        500,
        0.85,
      )}`;
      break;
  }
  if (inner) {
    if (outer !== "flat") {
      boxShadow += ", ";
    }
    switch (inner) {
      case "default":
        boxShadow += `${sShadowInnerHigh} ${cShadow()}`;
        break;
      case "hover":
        boxShadow += `${sShadowInnerLow} ${cShadow(
          color,
        )}, ${sShadowInnerHigh} ${cShadow()}`;
        break;
      case "focus":
        boxShadow += `${sShadowFocus} ${cShadowFocus(color, 700)}`;
        break;
      case "pressed":
        boxShadow += `${sShadowInnerHigh} ${cShadow()}`;
        break;
      case "disabled":
        break;
    }
  }
  return boxShadow;
};
