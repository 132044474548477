import { useContext } from "react";
import { TabProps } from "../../../../util/tabs/tab";
import { TabsContext } from "../../../../../contexts";
import {
  TIconShape,
  TStyleConnotation,
  TTabStatus,
} from "../../../../../types";
import * as Styled from "./styles.css";

const FlowTab = ({ i, id, ...otherProps }: FlowTabProps) => {
  const { tabs, currentTab } = useContext(TabsContext);

  const getIcon = (stepStatus: TTabStatus): TIconShape => {
    switch (stepStatus) {
      case "completed":
        if (currentTab === id) {
          return "check-circle-fill";
        }
        return "check-circle";
      case "error":
        return "stop";
      case "unstarted":
      case "started":
      case "warning":
      default:
        return "circle";
    }
  };

  const getIconColor = (stepStatus: TTabStatus): TStyleConnotation => {
    switch (stepStatus) {
      case "completed":
        if (currentTab === id) {
          return "accent";
        }
        return "surface";
      case "error":
        return "negative";
      case "unstarted":
      case "started":
      case "warning":
      default:
        if (currentTab === id) {
          return "accent";
        }
        return "surface";
    }
  };

  if (tabs?.[i]) {
    return (
      <Styled.FlowTab
        id={id}
        complete={tabs[i].status === "completed"}
        icon={getIcon(tabs[i].status)}
        iconColor={getIconColor(tabs[i].status)}
        iconSize="sm"
        $status={tabs[i].status}
        $selected={currentTab === id}
        {...otherProps}
      />
    );
  }

  return <></>;
};

export interface FlowTabProps extends Omit<TabProps, "id"> {
  id: string;
  i: number;
}

FlowTab.defaultProps = {};

export default FlowTab;
