import React from "react";
import { TabsContextProvider } from "../../../contexts";
import TabsContainer, { TabsContainerProps } from "./tabsContainer";
import { ITab } from "../../../types";

const Tabs = ({
  children,
  scrolling,
  tabs,
  tabsRight,
  ...otherProps
}: TabsProps) => {
  const tabsWithKeysLeft = React.Children.map(tabs, tabKeyMapper);
  const tabsWithKeysRight = React.Children.map(tabsRight, tabKeyMapper);

  const childrenTabs: ITab[] = [
    ...(tabsWithKeysLeft ?? []),
    ...(tabsWithKeysRight ?? []),
  ].map((child: any) => ({
    complete: child.props.complete,
    disabled:
      child.props.disabled ||
      (child.props.onComplete?.includes("disabled") && child.props.complete),
    hidden: !!child.props.hidden,
    title: child.props.id,
    restricted:
      child.props.restricted ||
      (child.props.onComplete?.includes("restricted") && child.props.complete),
    status: "unstarted",
  }));

  return (
    <TabsContextProvider
      id={otherProps.id}
      orientation={otherProps.orientation}
      tabs={childrenTabs}
      variant={otherProps.variant}
    >
      <TabsContainer
        scrolling={scrolling}
        tabsRight={tabsWithKeysRight}
        {...otherProps}
      >
        {tabsWithKeysLeft}
      </TabsContainer>

      {children && children}
    </TabsContextProvider>
  );
};

const tabKeyMapper = (tab: any) => {
  if (React.isValidElement(tab)) {
    let tabProps: any = tab.props;
    return React.cloneElement(tab, { key: tabProps.id });
  }

  return tab;
};

export interface TabsProps extends TabsContainerProps {
  tabs: any;
  tabsRight?: any;
}

Tabs.defaultProps = {
  orientation: "horizontal",
  variant: "open",
};

export default Tabs;
