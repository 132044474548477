import axios from "../../axios";

export interface FaqInterface {
  question: string;
  answer: string;
}

export interface TaskInterface {
  id: string;
  title: string;
  description: string;
  complete: boolean;
}

export interface BenchmarksOverviewInterface {
  description: any;
}

export interface BenchmarksCostsInterface {
  description: any;
}

export interface BenchmarksIssuesInterface {
  url: string;
  alt: string;
}

export interface BenchmarksCaseStudiesInterface {
  title: string;
  description: string;
  image: {
    url: string;
    alt: string;
  };
}

export interface BenchmarksInterface {
  overview: BenchmarksOverviewInterface | undefined;
  costs: BenchmarksCostsInterface | undefined;
  issues: BenchmarksIssuesInterface | undefined;
  caseStudies: BenchmarksCaseStudiesInterface[] | undefined;
}

export interface CompareOverviewInterface {
  image: string;
  id: string;
}

export interface CompareBenchmarksInterface {
  id: string;
  title: string;
  text: string;
}

export interface CompareMetricsInterface {
  id: string;
  metrics: MetricInterface[];
}

export interface MetricInterface {
  image: string;
  title: string;
  text: string;
}

export interface CompareRecommendationsInterface {
  id: string;
  title: string;
  text: string;
  recommendations: RecommendationInterface[];
}

export interface RecommendationInterface {
  image: string;
  title: string;
  text: string;
  button: Record<string, string>;
}

export interface CompareInterface {
  overview: CompareOverviewInterface | undefined;
  benchmarks: CompareBenchmarksInterface | undefined;
  recommendations: CompareRecommendationsInterface | undefined;
  metrics: CompareMetricsInterface | undefined;
}

export interface DeepdiveOverviewInterface {
  title: string;
  description: string;
  youtubeId: string;
}

export interface DeepDivePointInterface {
  type: string;
  text: "tick" | "exclamation_mark";
}
export interface DeepdiveTyskInterface {
  type: "heading_and_text" | "heading_bullet_text" | "heading_bullet_text";
  title: string;
  description?: string;
  points?: DeepDivePointInterface[];
}

export interface DeepdiveKeyStatisticsInterface {
  title: string;
  description: string;
}

export interface DeepdiveBestPracticesInterface {
  points: DeepDivePointInterface[];
  button: {
    link: string;
    text: string;
  };
}

export interface CaseStudiesInterface {
  title: string;
  description: string;
  image: string;
}

export interface TileCardInterface {
  title: string;
  description: string;
  image: string;
  link: string;
}

export interface DeepdiveInterface {
  overview: DeepdiveOverviewInterface | undefined;
  tysks: DeepdiveTyskInterface[] | undefined;
  stats: DeepdiveKeyStatisticsInterface[] | undefined;
  bestPractices: DeepdiveBestPracticesInterface | undefined;
  caseStudies: CaseStudiesInterface[] | undefined;
}

const prismicService = {
  async getFaqs(): Promise<FaqInterface[]> {
    const result = await axios.get("/prismic/faqs");
    const faqs = result.data[0].data.question_and_answer.map(
      ({ question, answer }: any): FaqInterface => ({
        question: question[0].text,
        answer: answer[0].text,
      }),
    );
    return faqs as FaqInterface[];
  },

  async getRiskTasks(
    industry: string = "all",
  ): Promise<TaskInterface[] | undefined> {
    let tasks: TaskInterface[] | undefined;
    try {
      const result = await axios.get("/prismic/risk-tasks", {
        params: {
          industry,
        },
      });
      if (result.data[0]) {
        tasks = result.data.map(
          ({ id, data }: any): TaskInterface => ({
            id,
            title: data.title[0].text,
            description: data.description,
            complete: false,
          }),
        );
      }
    } catch (error) {
      throw new Error("Prismic | Risk Tasks not found");
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return tasks as TaskInterface[] | undefined;
    }
  },

  async getCompareOverview(
    industry: string,
  ): Promise<CompareOverviewInterface | undefined> {
    try {
      const result = await axios.get("/prismic/how-do-i-compare-overviews", {
        params: {
          industry,
        },
      });
      if (result.data[0]) {
        return {
          id: result.data[0].data.image.alt,
          image: result.data[0].data.image.url,
        };
      }
    } catch (error) {
      throw new Error("Prismic | Compare Overview not found");
    }
  },

  async getCompareBenchmarks(
    industry: string,
  ): Promise<CompareBenchmarksInterface | undefined> {
    let benchmarks: CompareBenchmarksInterface | undefined;
    try {
      const result = await axios.get("/prismic/how-do-i-compare-benchmarks", {
        params: {
          industry,
        },
      });
      if (result.data[0]) {
        benchmarks = {
          id: result.data[0].id,
          title: result.data[0].data.title[0].text,
          text: result.data[0].data.description[0].text,
        };
      }
    } catch (error) {
      throw new Error("Prismic | Compare Benchmarks not found");
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return benchmarks as CompareBenchmarksInterface | undefined;
    }
  },

  async getCompareRecommendations(
    industry: string,
  ): Promise<CompareRecommendationsInterface | undefined> {
    let recommendations: CompareRecommendationsInterface | undefined;
    try {
      const result = await axios.get(
        "/prismic/how-do-i-compare-recommendations",
        {
          params: {
            industry,
          },
        },
      );
      if (result.data[0]) {
        recommendations = {
          id: result.data[0].id,
          title: result.data[0].data.main_title[0].text,
          text: result.data[0].data.main_description[0].text,
          recommendations: result.data[0].data.recommendations.map(
            (rec: any): any => ({
              image: rec.image.url,
              title: rec.title[0].text,
              text: rec.description[0].text,
              button: {
                title: rec.button_text,
                href: rec.button_link.url,
              },
            }),
          ),
        };
      }
    } catch (error) {
      throw new Error("Prismic | Compare Recommendations not found");
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return recommendations as CompareRecommendationsInterface | undefined;
    }
  },

  async getCompareMetrics(
    industry: string,
  ): Promise<CompareMetricsInterface | undefined> {
    let metrics: CompareMetricsInterface | undefined;
    try {
      const result = await axios.get("/prismic/how-do-i-compare-metrics", {
        params: {
          industry,
        },
      });
      if (result.data[0]) {
        metrics = {
          id: result.data[0].id,
          metrics: result.data[0].data.metric.map((met: any): any => ({
            image: met.image.url,
            title: met.title[0].text,
            text: met.description[0].text,
          })),
        };
      }
    } catch (error) {
      throw new Error("Prismic | Compare Metrics not found");
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return metrics as CompareMetricsInterface | undefined;
    }
  },

  async getCompare(industry: string = "all"): Promise<CompareInterface> {
    const result = await Promise.all([
      this.getCompareOverview(industry),
      this.getCompareBenchmarks(industry),
      this.getCompareRecommendations(industry),
      this.getCompareMetrics(industry),
    ]);

    const compare = {
      overview: result[0],
      benchmarks: result[1],
      recommendations: result[2],
      metrics: result[3],
    };

    return compare as CompareInterface;
  },

  async getIndustryBenchmarkOverview(
    industry: string,
  ): Promise<BenchmarksOverviewInterface | undefined> {
    let description: BenchmarksOverviewInterface | undefined;
    try {
      const result = await axios.get("/prismic/industry-benchmarks-overviews", {
        params: {
          industry,
        },
      });
      if (result.data[0]) {
        description = result.data[0].data.description;
      }
    } catch (error) {
      throw new Error("Prismic | Industry Benmarks not found");
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return description as BenchmarksOverviewInterface | undefined;
    }
  },

  async getIndustryBenchmarkCommonIssues(
    industry: string,
  ): Promise<BenchmarksIssuesInterface | undefined> {
    let image;
    try {
      const result = await axios.get(
        "/prismic/industry-benchmarks-common-issues",
        {
          params: {
            industry,
          },
        },
      );
      if (result.data[0]) {
        image = {
          url: result.data[0].data.image.url,
          alt: result.data[0].data.image.alt,
        };
      }
    } catch (error) {
      throw new Error("Prismic | Benchmarks Common Issues not found");
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return image as BenchmarksIssuesInterface | undefined;
    }
  },

  async getIndustryBenchmarksWhatCostsMost(
    industry: string,
  ): Promise<BenchmarksCostsInterface | undefined> {
    let costs: BenchmarksCostsInterface | undefined;
    try {
      const result = await axios.get(
        "/prismic/industry-benchmarks-what-costs-mosts",
        {
          params: {
            industry,
          },
        },
      );
      if (result.data[0]) {
        costs = result.data[0].data.description;
      }
    } catch (error) {
      throw new Error("Prismic | Benmarks Costs not found");
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return costs as BenchmarksCostsInterface | undefined;
    }
  },

  async getIndustryBenchmarkCaseStudies(
    industry: string,
  ): Promise<BenchmarksCaseStudiesInterface[] | undefined> {
    let caseStudies: BenchmarksCaseStudiesInterface[] | undefined;
    try {
      const result = await axios.get(
        "/prismic/industry-benchmarks-case-studies",
        {
          params: {
            industry,
          },
        },
      );
      if (result.data[0]) {
        caseStudies = result.data.map((caseStudy: any) => ({
          image: {
            url: caseStudy.data.image.url,
            alt: caseStudy.data.image.alt,
          },
          title: caseStudy.data.title[0].text,
          description: caseStudy.data.description,
        }));
      }
    } catch (error) {
      throw new Error("Prismic | Benmarks Case Studies not found");
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return caseStudies as BenchmarksCaseStudiesInterface[] | undefined;
    }
  },

  async getBenchmarks(industry: string): Promise<BenchmarksInterface> {
    const [overview, costs, issues, caseStudies] = await Promise.all([
      prismicService.getIndustryBenchmarkOverview(industry),
      prismicService.getIndustryBenchmarksWhatCostsMost(industry),
      prismicService.getIndustryBenchmarkCommonIssues(industry),
      prismicService.getIndustryBenchmarkCaseStudies(industry),
    ]);

    return {
      overview,
      costs,
      issues,
      caseStudies,
    };
  },

  async getDeepdiveOverview(
    industry: string,
  ): Promise<DeepdiveOverviewInterface | undefined> {
    let overview: DeepdiveOverviewInterface | undefined;
    try {
      const result = await axios.get("/prismic/risk-spotlight-overviews", {
        params: {
          industry,
        },
      });
      if (result.data[0]) {
        overview = {
          title: result.data[0].data.title[0].text,
          description: result.data[0].data.body_text[0].text,
          youtubeId: result.data[0].data.youtube_video_id,
        };
      }
    } catch (error) {
      throw new Error("Prismic | Deepdive Overview not found");
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return overview as DeepdiveOverviewInterface | undefined;
    }
  },

  async getDeepdiveTysk(
    industry: string,
  ): Promise<DeepdiveTyskInterface[] | undefined> {
    let tysks: DeepdiveTyskInterface[] | undefined;
    try {
      const result = await axios.get("/prismic/risk-spotlight-things-ysks", {
        params: {
          industry,
        },
      });
      if (result.data[0]) {
        tysks = result.data[0].data.body.map(
          (item: any): DeepdiveTyskInterface => {
            switch (item.slice_type) {
              case "heading_bullet_text":
                return {
                  type: item.slice_type,
                  title: item.primary.subtitle[0].text,
                  points: item.items.map(
                    (i: any): DeepDivePointInterface => ({
                      type: i.bullet_point_type,
                      text: i.bullet_text[0].text,
                    }),
                  ),
                };
              case "heading_and_text":
              default:
                // eslint-disable-next-line no-case-declarations
                const slice = item.items[0];
                return {
                  type: item.slice_type,
                  title: slice.subtitle[0].text,
                  description: slice.body_text[0].text,
                };
            }
          },
        );
      }
    } catch (error) {
      throw new Error("Prismic | Deepdive Tysks not found");
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return tysks;
    }
  },

  async getDeepdiveKeyStatistics(
    industry: string,
  ): Promise<DeepdiveKeyStatisticsInterface[] | undefined> {
    let statistics: DeepdiveKeyStatisticsInterface[] | undefined;
    try {
      const result = await axios.get(
        "/prismic/risk-spotlight-key-statistics?",
        {
          params: {
            industry,
          },
        },
      );
      if (result.data[0]) {
        statistics = result.data[0].data.statistic.map(
          (stat: any): DeepdiveKeyStatisticsInterface => ({
            title: stat.title[0].text,
            description: stat.body_text[0].text,
          }),
        );
      }
    } catch (error) {
      throw new Error("Prismic | Deepdive Key Statistics not found");
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return statistics;
    }
  },

  async getDeepdiveBestPractices(
    industry: string,
  ): Promise<DeepdiveBestPracticesInterface | undefined> {
    let bestPractices: DeepdiveBestPracticesInterface | undefined;
    try {
      const result = await axios.get("/prismic/risk-spotlight-best-practices", {
        params: {
          industry,
        },
      });
      if (result.data[0]) {
        bestPractices = {
          points: result.data[0].data.bullet_points.map(
            (point: any): DeepDivePointInterface => ({
              type: point.icon,
              text: point.description[0].text,
            }),
          ),
          button: {
            link: result.data[0].data.button_link.url,
            text: result.data[0].data.button_text[0].text,
          },
        };
      }
    } catch (error) {
      throw new Error("Prismic | Deepdive Best Practices not found");
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return bestPractices;
    }
  },

  async getDeepdiveCaseStudies(
    industry: string,
  ): Promise<CaseStudiesInterface[] | undefined> {
    let caseStudies: CaseStudiesInterface[] | undefined;
    try {
      const result = await axios.get("/prismic/risk-spotlight-case-studies", {
        params: {
          industry,
        },
      });
      if (result.data[0]) {
        caseStudies = result.data.map(
          (caseStudy: any): CaseStudiesInterface => ({
            title: caseStudy.data.title[0].text,
            description: caseStudy.data.description[0].text,
            image: caseStudy.data.image.url,
          }),
        );
      }
    } catch (error) {
      throw new Error("Prismic | Deepdive Case Studies not found");
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return caseStudies;
    }
  },

  async getDeepdive(industry: string): Promise<DeepdiveInterface> {
    const [overview, tysks, stats, bestPractices, caseStudies] =
      await Promise.all([
        this.getDeepdiveOverview(industry),
        this.getDeepdiveTysk(industry),
        this.getDeepdiveKeyStatistics(industry),
        this.getDeepdiveBestPractices(industry),
        this.getDeepdiveCaseStudies(industry),
      ]);

    return {
      overview,
      tysks,
      stats,
      bestPractices,
      caseStudies,
    };
  },

  async getRecommendationTileCards(
    industry: string,
  ): Promise<TileCardInterface[] | undefined> {
    let tiles: TileCardInterface[] | undefined;
    try {
      const result = await axios.get("/prismic/recommendation-tile-cards", {
        params: {
          industry,
        },
      });
      if (result.data[0]) {
        tiles = result.data.map(
          (tile: any): TileCardInterface => ({
            link: tile.data.button_link,
            title: tile.data.title[0].text,
            description: tile.data.body_text[0].text,
            image: tile.data.image.url,
          }),
        );
      }
    } catch (error) {
      throw new Error("Prismic | Recommendation Tile Cards not found");
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return tiles;
    }
  },
};

export default prismicService;
