import styled from "styled-components";
import { breakpoints, theme } from "../../../styles";
import { transparentize } from "polished";
import Grid from "../grid";
import Typography from "../typography";

export const ModalHeaderGrid = styled(Grid).attrs((props: any) => ({
  $headerDivider: props.$headerDivider,
}))`
  ${({ $headerDivider }) =>
    $headerDivider &&
    `
    border-bottom: 1px solid ${theme.color.surface.border.weak.default};
  margin-bottom: 10px;`}
  padding: 20px 24px;
`;
export const ModalTitle = styled(Typography)`
  padding: 5px 0px;
`;

export const StyledModal = styled.div`
  background-color: ${theme.color.surface.background.default.default};
  border-radius: 10px;
  position: fixed;
  height: auto;
  opacity: 1;
  z-index: 99999;

  @media ${breakpoints.up("sm")} {
    width: 450px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media ${breakpoints.down("sm")} {
    width: 100%;
    bottom: 0px;
    left: 0px;
    transform: translate(0px, 0px);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const StyledOverlay = styled.div`
  position: fixed;
  pointer-events: visible;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: fixed;
  justify-content: center;
  background-color: ${transparentize(
    0.7,
    theme.color.surface.background.inverse.default,
  )};
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 99999;
`;
