import styled from "styled-components";
import Container from "../../container/container";

export const JfsInputControl = styled(Container).attrs((props: any) => ({
  $grow: props.$grow,
  $size: props.$size,
  $type: props.$type,
}))`
  position: relative;

  ${(props: any) => {
    if (props.$grow) {
      return `
        flex-grow: 1;
      `;
    }
  }}

  ${(props: any) => {
    switch (props.$type) {
      case "password":
        return `
          input {
            padding-right: 56px;
          }
          button {
            position: absolute;
            right: 10px;
            top: ${props.$size === "md" ? "2px" : "4px"};
          }
        `;
    }
  }}
`;
