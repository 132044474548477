import { useState } from "react";
import authService from "../../../services/auth/auth.service";
import {
  Form,
  FormRow,
  Link,
  TextField,
  Typography,
} from "../../../components/util";
import { emailSchema } from "../../../validations";
import AuthContainer from "../authContainer";
import AuthFooter from "../authFooter";
import ResetPasswordSuccess from "./resetPasswordSuccess";
import * as Styled from "./styles.css";

const ResetPassword = () => {
  const [resetSuccess, setResetSuccess] = useState<string | undefined>();

  if (resetSuccess) {
    return <ResetPasswordSuccess email={resetSuccess} />;
  }

  return (
    <AuthContainer
      headerTitle="Reset your password"
      preventRedirect
      footerContent={<AuthFooter />}
      stickyFooter
    >
      <Typography paragraph styled={{ text: { shade: "weak" } }}>
        Enter the email address associated with your account, and we’ll send you
        a link to reset your password.
      </Typography>

      <Form
        errorMessage={
          <Typography styled={{ text: { color: "negative" } }}>
            We were not able to send your reset password code, please try again
            or{" "}
            <Link href="https://care.safetyculture.com/contact/">
              contact Care
            </Link>
          </Typography>
        }
        onSubmit={{
          service: authService.requestPasswordReset,
          onSuccess: (formData: Array<Record<string, any>>) => {
            setResetSuccess(formData[0].email);
          },
        }}
        validationErrors={[forgotPasswordErrorsInit]}
        validationFields={[forgotPasswordDataInit]}
        submitText="Continue"
      >
        <FormRow>
          <TextField
            autoComplete="username"
            id="email"
            label="Email address"
            size="lg"
            type="email"
            validationOnBlur
            validationOnChange
            validationType={emailSchema}
          />
        </FormRow>
      </Form>
      <Typography styled={{ text: { align: "center" } }}>
        <Styled.ReturnLink router href="/">
          Return to log in
        </Styled.ReturnLink>
      </Typography>
    </AuthContainer>
  );
};

const forgotPasswordDataInit: Record<string, string> = {
  email: "",
};

const forgotPasswordErrorsInit: Record<string, boolean> = {
  email: true,
};

export default ResetPassword;
