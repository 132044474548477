import { useContext, useState } from "react";
import { Hidden } from "@material-ui/core";
import { useUnleashClient } from "@unleash/proxy-client-react";

import { hasAllowedRoles } from "../../../../helpers";
import NavItem from "./navItem";
import {
  sidebarMainItems,
  sidebarFooterItems,
  SidebarItemProps,
} from "./sidebarItems";
import Logo from "../../../content/logo";
import { List } from "../../../util";
import { AuthContext, SidebarContext } from "../../../../contexts";
import * as Styled from "./styles.css";

const Sidebar = () => {
  const { user } = useContext(AuthContext);
  const unleashClient = useUnleashClient();

  const { isHeaderVisible, isSidebarVisible, toggleSidebar } =
    useContext(SidebarContext);

  const [sidebarProfileItem] = useState<SidebarItemProps>({
    title: `${user?.firstname} ${user?.lastname}` || "Profile",
    component:
      (hasAllowedRoles(user?.roles, { allowed: ["admin"] }) && (
        <Styled.RoleChip color="warning">AD</Styled.RoleChip>
      )) ||
      (hasAllowedRoles(user?.roles, { allowed: ["super_admin"] }) && (
        <Styled.RoleChip color="negative" size="sm">
          SA
        </Styled.RoleChip>
      )),
    href: "/profile",
    icon: "person",
  });

  return (
    <Styled.Drawer
      className="drawer"
      onClick={toggleSidebar}
      $isDesktop={!isHeaderVisible}
      $isOpen={isSidebarVisible}
    >
      <Styled.Sidebar
        onClick={e => {
          e.stopPropagation();
        }}
        $isDesktop={!isHeaderVisible}
        $isOpen={isSidebarVisible}
      >
        <Styled.MainNav>
          <Hidden lgDown>
            <Styled.Logo href="/" router title="SCCare Logo">
              <Logo />
            </Styled.Logo>
          </Hidden>
          <List>
            {sidebarMainItems
              .filter(
                item =>
                  (item.featureFlag == null ||
                    unleashClient.isEnabled(item.featureFlag)) &&
                  hasAllowedRoles(user?.roles, item.roles),
              )
              .map((item, i) => (
                <NavItem key={i} item={item} />
              ))}
          </List>
        </Styled.MainNav>
        <Styled.FooterNav>
          <List>
            {sidebarFooterItems.map((item, i) => {
              return <NavItem key={i} item={item} />;
            })}
            {hasAllowedRoles(user?.roles, {
              allowed: ["customer", "broker", "admin", "super_admin"],
              disallowed: ["unverified"],
            }) && <NavItem item={sidebarProfileItem} />}
          </List>
        </Styled.FooterNav>
      </Styled.Sidebar>
    </Styled.Drawer>
  );
};

export default Sidebar;
