import {
  MetricInterface,
  CompareMetricsInterface,
} from "../../../services/prismic/prismic.service";
import { Card, Grid, Image, Typography } from "../../../components/util";

const Metrics = ({
  data,
}: Record<string, CompareMetricsInterface | undefined>) => {
  const renderGraphMetric = (metric: any) => {
    return (
      <Card isInGrid isPadded>
        <Image src={metric.image} alt={metric.title} />
        <Typography variant="displaySmall">{metric.title}</Typography>
        <Typography paragraph>{metric.text}</Typography>
      </Card>
    );
  };

  const renderInfoMetric = (metric: MetricInterface) => {
    return (
      <Card isInGrid isPadded>
        <Typography variant="displaySmall">{metric.title}</Typography>
        <Grid container spacing={3}>
          <Grid item>
            <Image src={metric.image} alt={metric.title} />
          </Grid>
          <Grid item xs>
            <Typography paragraph>{metric.text}</Typography>
          </Grid>
        </Grid>
      </Card>
    );
  };

  return data ? (
    <>
      <Grid item xs={12} md={6} lg={4}>
        {renderGraphMetric(data.metrics[0])}
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        {renderGraphMetric(data.metrics[1])}
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {renderInfoMetric(data.metrics[2])}
          </Grid>
          <Grid item xs={12}>
            {renderInfoMetric(data.metrics[3])}
          </Grid>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
};

export default Metrics;
