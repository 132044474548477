import { transparentize } from "polished";
import styled, { keyframes } from "styled-components";
import { theme } from "../../../styles/themes";

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0px 0px ${transparentize(
      1,
      theme.color.negative.background.default.default,
    )};
  }
  30% {
    box-shadow: 0 0 0px 0px ${transparentize(
      0.5,
      theme.color.negative.background.default.default,
    )};
  }
  100% {
    box-shadow: 0 0 0px 20px ${transparentize(
      1,
      theme.color.negative.background.default.default,
    )};
  }
`;

export const Marker = styled("span").attrs((props: any) => ({
  $placement: props.$placement,
  $alignment: props.$alignment,
  markerSize: 8,
  animationLength: 1.5,
}))`
  animation-duration: ${(props: any) => props.animationLength}s;
  animation-iteration-count: infinite;
  animation-direction: ease-out;
  animation-name: ${pulse};
  background-color: ${theme.color.negative.background.default.default};
  border-radius: 100%;
  display: block;
  height: ${(props: any) => props.markerSize}px;
  position: absolute;
  width: ${(props: any) => props.markerSize}px;
  z-index: 9999;

  ${(props: any) => {
    switch (props.$placement) {
      case "left":
        return `
            left: -${props.markerSize / 2}px;
        `;
      case "right":
        return `
            right: -${props.markerSize / 2}px;
        `;
      case "top":
        return `
            top: -${props.markerSize / 2}px;
        `;
      case "bottom":
        return `
            bottom: -${props.markerSize / 2}px;
        `;
    }
  }}

  ${(props: any) => {
    switch (props.$alignment) {
      case "center":
        return `
            ${
              (props.$placement === "left" || props.$placement === "right") &&
              `
              top: calc(50% - ${props.markerSize / 2}px);
            `
            };
            ${
              (props.$placement === "top" || props.$placement === "bottom") &&
              `
              left: calc(50% - ${props.markerSize / 2}px);
            `
            };
        `;
      case "start":
        return `
            ${
              (props.$placement === "left" || props.$placement === "right") &&
              `
              top: -${props.markerSize / 2}px;
            `
            };
            ${
              (props.$placement === "top" || props.$placement === "bottom") &&
              `
              left: -${props.markerSize / 2}px;
            `
            };
        `;
      case "end":
        return `
            ${
              (props.$placement === "left" || props.$placement === "right") &&
              `
              bottom: -${props.markerSize / 2}px;
            `
            };
            ${
              (props.$placement === "top" || props.$placement === "bottom") &&
              `
              right: -${props.markerSize / 2}px;
            `
            };
        `;
    }
  }}
`;
