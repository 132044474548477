import React from "react";
import { QueryClientProvider } from "react-query";
import { Router, routes } from "./routes";
import { AuthContextProvider, GuidanceContextProvider } from "./contexts";
import { Toast } from "./components/util/toast/toast";
import Styles from "./styles";
import { FlagProvider } from "@unleash/proxy-client-react";
import unleash from "./config/unleash.config";
import { queryClient } from "./config/react-query.config";
import { ReactQueryDevtools } from "react-query/devtools";

function App() {
  return (
    <Styles>
      <FlagProvider config={unleash}>
        <QueryClientProvider client={queryClient}>
          <AuthContextProvider>
            <GuidanceContextProvider>
              <Router routes={routes} />
            </GuidanceContextProvider>
          </AuthContextProvider>
          <Toast />
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
      </FlagProvider>
    </Styles>
  );
}

export default App;
