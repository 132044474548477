import styled from "styled-components";
import { transparentize } from "polished";
import { breakpoints, theme } from "../../../styles";
import { ButtonVariant, TStyleConnotation, TStyleSize } from "../../../types";
import { Link } from "react-scroll";
import CircularProgress from "../progress/circular";
import Container from "../container/container";
import { buildBoxShadow } from "../../../helpers";

export const ButtonBase = styled(Container).attrs((props: any) => ({}))`
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  border-width: 0px;
  border-radius: 6px;
  box-shadow: none;
  cursor: pointer;
  min-width: auto;
  padding: 6px 6px;
  text-transform: none;
  width: auto;

  svg {
    fill: ${theme.color.surface.icon.weaker.default};
  }

  &:hover {
    background-color: transparent;
    svg {
      fill: ${theme.color.surface.icon.weaker.hover};
    }
  }
  &:active {
    svg {
      fill: ${theme.color.accent.icon.default.pressed};
    }
  }
  &:disabled {
    cursor: default;
    opacity: 0.25;
  }
`;

interface IButtonStyle {
  $color: TStyleConnotation;
  $size: TStyleSize;
  $variant: ButtonVariant;
  $fullWidth: boolean;
}

export const Button = styled(ButtonBase)<IButtonStyle>`
  border-style: solid;
  border-radius: ${({ $variant }) => {
    switch ($variant) {
      case "unstyled":
        return `0px`;
      case "text":
      case "outlined":
      case "contained":
        return `7px`;
    }
  }};
  border-width: 1px;
  box-sizing: border-box;
  text-transform: none;
  font-size: 16px;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  line-height: 1.75;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: ${({ $variant }) => {
    switch ($variant) {
      case "unstyled":
        return `left`;
      case "text":
      case "outlined":
      case "contained":
        return `center`;
    }
  }};

  text-decoration: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  background-color: ${({ $color, $variant }) => {
    switch ($variant) {
      case "unstyled":
      case "text":
        return `transparent`;
      case "outlined":
        return `${theme.color.surface.background.default.default};`;
      case "contained":
        return `${theme.color[$color]?.background.default.default};`;
    }
  }};

  border-color: ${({ $color, $variant }) => {
    switch ($variant) {
      case "unstyled":
      case "text":
        return `transparent`;
      case "outlined":
      case "contained":
        return `${theme.color[$color]?.border.default.default};`;
    }
  }};

  box-shadow: ${({ $variant }) => {
    switch ($variant) {
      case "unstyled":
      case "text":
        return `none`;
      case "outlined":
      case "contained":
        return `${buildBoxShadow("low", "default")};`;
    }
  }};

  color: ${({ $color, $variant }) => {
    switch ($variant) {
      case "unstyled":
        return `${theme.color.surface.text.strong.default};`;
      case "text":
      case "outlined":
        return `${theme.color[$color]?.text.default.default};`;
      case "contained":
        switch ($color) {
          case "accent":
          case "positive":
          case "negative":
          case "warning":
          case "information":
            return `${theme.color[$color]?.text.inverse.default};`;
          case "surface":
          default:
            return `${theme.color.surface.text.default.default};`;
        }
      default:
        return `${theme.color.surface.text.default.default};`;
    }
  }}};

  &:hover {
    background-color: ${({ $color, $variant }) => {
      switch ($variant) {
        case "unstyled":
          return ``;
        case "text":
          return `${theme.color.surface.background.default.hover};`;
        case "outlined":
          return `${theme.color.surface.background.default.hover};`;
        case "contained":
          return `${theme.color[$color]?.background.default.hover};`;
      }
    }};

    border-color: ${({ $color, $variant }) => {
      switch ($variant) {
        case "unstyled":
        case "text":
          return `transparent`;
        case "outlined":
        case "contained":
          return `${theme.color[$color]?.border.default.hover};`;
      }
    }};

    box-shadow: ${({ $color, $variant }) => {
      switch ($variant) {
        case "unstyled":
        case "text":
          return `none`;
        case "outlined":
        case "contained":
          return `${buildBoxShadow("low", "hover", $color)};`;
      }
    }};

    color: ${({ $color, $variant }) => {
      switch ($variant) {
        case "unstyled":
        case "text":
        case "outlined":
          return `${theme.color[$color]?.text.default.hover};`;
        case "contained":
          switch ($color) {
            case "accent":
            case "positive":
            case "negative":
            case "warning":
            case "information":
              return `${theme.color[$color]?.text.inverse.hover};`;
            case "surface":
            default:
              return `${theme.color.surface.text.stronger.hover};`;
          }
      }
    }};
  }

  &:focus {
    background-color: ${({ $color, $variant }) => {
      switch ($variant) {
        case "unstyled":
        case "text":
          return `transparent`;
        case "outlined":
          return `${theme.color[$color]?.background.weakest.pressed};`;
        case "contained":
          return ``;
      }
    }};

    border-color: ${({ $color, $variant }) => {
      switch ($variant) {
        case "unstyled":
          return `transparent`;
        case "text":
        case "outlined":
          return `${theme.color[$color]?.border.default.pressed};`;
        case "contained":
          switch ($color) {
            case "negative":
              return `${theme.color.negative.border.default.pressed};`;
            default:
              return ``;
          }
        default:
          return `${theme.color.surface.border.default.pressed};`;
      }
    }};

    outline-width: 3px;
    outline-style: solid;
    outline-color: ${({ $color }) => {
      return `${transparentize(
        0.65,
        theme.color[$color]?.border.default.pressed,
      )}`;
    }}};
  }

  // ACTIVE
  &:active {
    background-color: ${({ $color, $variant }) => {
      switch ($variant) {
        case "text":
        case "outlined":
          return `${theme.color[$color]?.background.weakest.pressed};`;
        case "contained":
          return `${theme.color[$color]?.background.default.pressed};`;
      }
    }};

    border-color: ${({ $color, $variant }) => {
      switch ($variant) {
        case "text":
        case "outlined":
          return ``;
        case "contained":
          return `${theme.color[$color]?.border.default.pressed};`;
      }
    }};

    color: ${({ $color, $variant }) => {
      switch ($variant) {
        case "text":
        case "outlined":
          return `${theme.color[$color]?.text.default.pressed};`;
        case "contained":
          switch ($color) {
            case "accent":
            case "positive":
            case "negative":
            case "warning":
              return `${theme.color.surface.text.inverse.pressed};`;
            case "surface":
            default:
              return `${theme.color.surface.text.stronger.pressed};`;
          }
      }
    }};
  }

  ${({ $size }) => {
    switch ($size) {
      case "sm":
        return `
          padding: 4px 10px;
        `;
      case "md":
      default:
        return `
          padding: 6px 15px;
        `;
    }
  }}

  ${({ $fullWidth }) => {
    if ($fullWidth) {
      return `width: 100%;`;
    }
  }}
`;

export const ScrollLink = styled(Link).attrs((props: any) => ({}))`
  text-decoration: none;
`;

export const IconButton = styled(ButtonBase)`
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  border-width: 0px;
  border-radius: 7px;
  box-shadow: none;
  min-width: auto;
  padding: 6px;
  text-transform: none;
  width: auto;
  svg {
    fill: ${theme.color.surface.icon.weaker.default};
  }

  &:hover {
    background-color: transparent;
    svg {
      fill: ${theme.color.surface.icon.weaker.hover};
    }
  }
  &:focus {
    background-color: transparent;
  }
  &:active {
    svg {
      fill: ${theme.color.accent.icon.default.default};
    }
  }
`;

export const ButtonLoading = styled(CircularProgress)`
  margin: 2px auto;
`;

export const ButtonGroup = styled("div")`
  display: flex;
  flex-wrap: wrap;

  @media ${breakpoints.up("sm")} {
    flex-wrap: none;
  }

  & button:last-of-type {
    margin-right: 15px;
  }

  & .MuiGrid-root {
    display: inline-flex;
    margin: 3px 10px 3px 0;
  }

  & .MuiButton-root {
    display: inline-flex;
  }

  & > div {
    align-items: center;
    color: ${theme.color.surface.icon.weaker.default};
    display: inline-flex;
    margin: 6px 15px;
    font-size: ${theme.typography.button.fontSize};
    line-height: ${theme.typography.button.lineHeight};

    @media ${breakpoints.up("sm")} {
      flex-basis: auto;
    }
  }
`;
