import styled from "styled-components";
import { math } from "polished";
import { theme } from "../../../../styles";
import { buildBoxShadow } from "../../../../helpers";
import Container from "../../container/container";

export const TextareaContainer = styled(Container).attrs((props: any) => ({
  $size: props.$size,
  $value: props.$value,
}))`
  display: grid;

  & textarea,
  &:after {
    // Dimensions must must match exactly
    border: 1px solid ${theme.color.surface.border.weak.default};
    grid-area: 1 / 1 / 2 / 2;
    line-height: ${theme.typography.body.desktop.lineHeight};
    white-space: pre-wrap;
    word-break: break-word;
  }

  &:after {
    border-width: 2px;
    content: attr(data-value) " "; // Space needed to preventy jumpy behavior
    padding: ${(props: any) => `
      ${theme.input.text.padding.vertical[props.$size] - 1}px
      ${theme.input.text.padding.horizontal[props.$size] - 1}px
    `};
    visibility: hidden;
  }
`;

export const Textarea = styled("textarea").attrs((props: any) => ({
  $error: props.$error,
  $size: props.$size,
  $editable: props.$editable,
  $type: props.$type,
}))`
  border-radius: ${theme.border.radius.md};
  box-shadow: ${buildBoxShadow("low")};
  max-height: 1000px;
  min-height: ${(props: any) =>
    math(
      `
        ${theme.typography.body.desktop.lineHeight}
        * 3
        + (
          ${theme.input.text.padding.vertical[props.$size] + 1}px
          * 2
        )
      `,
    )};
  padding: ${(props: any) => `
    ${theme.input.text.padding.vertical[props.$size]}px
    ${theme.input.text.padding.horizontal[props.$size]}px
  `};
  resize: vertical;

  :focus,
  &[data-error] {
    border-width: 2px;
    ${(props: any) => `
      padding:
        ${theme.input.text.padding.vertical[props.$size] - 1}px
        ${theme.input.text.padding.horizontal[props.$size] - 1}px;
    `}
  }

  :focus {
    border-color: ${theme.color.accent.border.default.default};
  }

  &[data-error] {
    background-color: ${theme.color.negative.background.weakest.default};
    border-color: ${theme.color.negative.border.default.default};
    &:focus {
      background-color: transparent;
    }
  }

  &:disabled {
    background-color: ${theme.color.surface.background.weakest.default};
    box-shadow: ${buildBoxShadow("flat")};
    color: ${theme.color.surface.text.default.disabled};
  }
`;
