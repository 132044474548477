import styled from "styled-components";
import { theme } from "./../../../styles";
import { Button, Link, Typography } from "./../../../components/util";

export const ReturnLink = styled(Link).attrs((props: any) => ({}))`
  text-decoration: none;
`;

export const LogInButton = styled(Button).attrs((props: any) => ({}))`
  margin-top: 32px;
`;

export const FooterTypography = styled(Typography).attrs((props: any) => ({}))`
  & a {
    color: ${theme.color.surface.text.weak.default};
  }
`;
