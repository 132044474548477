import { Link } from "../../util";

export default function ApptivoLink(props) {
  const { crmId } = props;

  return (
    <Link
      href={`https://www.apptivo.com/app/customers.jsp#/view/${crmId}/overview`}
      isNewTab
    >
      {crmId}
    </Link>
  );
}
