import { useQuery } from "react-query";
import { useFlag } from "@unleash/proxy-client-react";
import { Header, Section } from "../../../layout";
import { Button, CircularProgress, TableCell, TableRow } from "../../../util";
import { formatDateTime } from "../../../../helpers";
import { PaginatedResults } from "../..";
import claimService from "../../../../services/claim/claim.service";
import * as Styled from "./styles.css";
import { IClaim } from "../../../../types";

const AssociatedClaims = ({
  policyNumber,
  policyId,
  policyType,
  ...otherProps
}: AssociatedClaimsProps) => {
  const claimsRow = (claimsSlice: IClaim[]) => {
    return (
      <>
        {claimsSlice.map((claim: IClaim, i: number) => (
          <TableRow key={i}>
            <TableCell>
              <a href={`/claims/${claim.id}`}>{claim.claim_number}</a>{" "}
            </TableCell>
            <TableCell>
              <span style={{ textTransform: "capitalize" }}>
                {claim.versions[0].claim_data.create.issue.claim_type}
              </span>
            </TableCell>
            <TableCell>
              {formatDateTime(
                claim.versions[0].claim_data.details.incident_date,
              )}
            </TableCell>
            <TableCell>{formatDateTime(claim.created_at)}</TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  const associatedId: string | undefined = policyId;

  const { data, isLoading } = useQuery(`claims_${policyId}`, async () => {
    if (!policyId) {
      return;
    }

    let claims = await claimService.get_by_policy_id(policyId);
    claims.sort(
      (a, b) =>
        Number.parseInt(a.claim_number.replace(/\D/gm, "")) -
        Number.parseInt(b.claim_number.replace(/\D/gm, "")),
    );
    return claims;
  });

  return (
    <>
      {useFlag("portal.claims.create") && (
        <Section>
          <Header
            title="Claims"
            type="section"
            actions={
              <>
                {policyType && policyType === "cpx" && (
                  <Button
                    href={`/claims/create${policyNumber ? "?p=" : ""}${
                      associatedId && associatedId
                    }`}
                    router
                  >
                    Create a claim
                    <Styled.PlusIcon icon="plus" size="sm" />
                  </Button>
                )}
              </>
            }
          />
          {!isLoading && (
            <PaginatedResults
              id={`claims-associated-${policyId}`}
              inputResults={{
                hits: data ? data : [],
                total: data ? data.length : 0,
              }}
              tableColumns={[
                { title: "Claim", expand: true },
                { title: "Type", expand: true },
                { title: "Date of incident", expand: true },
                { title: "Created", expand: true },
              ]}
              tableRows={claimsRow}
              missing={{
                icon: "shield",
                message:
                  policyType && policyType === "cpx"
                    ? `To create your first claim, click “Create a claim”."`
                    : `To create a claim please visit https://www.mitti.com.au/claims`,
                type: "claims",
              }}
            />
          )}
          {isLoading && <CircularProgress contained />}
        </Section>
      )}
    </>
  );
};

interface AssociatedClaimsProps {
  orgId?: string;
  policyNumber?: string;
  policyId?: string;
  policyType?: string;
  claims?: IClaim[];
}

AssociatedClaims.defaultProps = {};

export default AssociatedClaims;
