import { makeStyles } from "@material-ui/core";
import { Card, Grid, Typography } from "../../components/util";
import TypeForm from "./typeform";
import { theme } from "../../styles";

const styles = makeStyles(() => ({
  container: {
    alignItems: "stretch",
    textAlign: "center",
  },
  h3: {
    marginBottom: `calc(${theme.spacing}/3*2)`,
  },
  img: {
    marginBottom: `${theme.spacing}`,
    maxHeight: "200px",
  },
  info: {
    padding: `${theme.spacing}`,
  },
  paragraph: {
    textAlign: "left",
  },
}));

const SelfAssessment = () => {
  const classes = styles();

  return (
    <Grid item xs={12} classes={{ root: classes.container }}>
      <Grid container spacing={3}>
        <Grid item lg={8} xs={12}>
          <TypeForm />
        </Grid>
        <Grid item lg={4} xs={12}>
          <Card isInGrid classes={{ root: classes.info }}>
            <Typography paragraph variant="displaySmall">
              Risk self assessment
            </Typography>
            <Typography paragraph>
              Provide details of your current risk and compliance practice to
              calculate your self assessment score.
            </Typography>
            <Typography paragraph>
              Complete this as part of your internal quarterly risk reviews to
              track your progress over time.
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default SelfAssessment;
