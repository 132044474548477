import styled from "styled-components";
import { transparentize } from "polished";
import { theme } from "../../../styles/themes";
import { Card as MuiCard } from "@material-ui/core";

interface StyleProps {
  $isFlat?: boolean;
  $isInGrid?: boolean;
  $isPadded?: boolean;
  $noOutline?: boolean;
}

export const Card = styled(MuiCard)<StyleProps>`
  border: 1px solid ${theme.color.surface.border.weak.default};
  box-shadow: 0px 1px 5px
    ${transparentize(0.92, theme.color.surface.text.weak.default)};

  ${({ $isFlat }) =>
    $isFlat &&
    `
      box-shadow: none;
    `}

  ${({ $isInGrid }) =>
    $isInGrid &&
    `
      height: 100%;
      width: 100%;
    `}

  ${({ $noOutline }) =>
    $noOutline &&
    `
     border:0px;
    `}


  ${({ $isPadded }) =>
    $isPadded &&
    `
      padding: 24px;
    `}
`;
