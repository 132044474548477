import Joi from "joi";
import { userRolesPrivate, userRolesPublic } from "../helpers/roles";

const customMessage = (
  msgName: string,
  field?: string,
): Record<string, string> => {
  let errors: Record<string, string> = {
    "any.required": `${msgName} is required`,
  };
  return errors;
};

export const userRoleSchema = Joi.string()
  .allow(...Object.values(userRolesPublic), ...Object.values(userRolesPrivate))
  .only()
  .required()
  .messages(customMessage("Role", "role"));

export const rolePublicSchema = Joi.string()
  .allow(...Object.values(userRolesPublic))
  .only()
  .required()
  .messages(customMessage("Role", "role"));

export const rolePrivateSchema = Joi.string()
  .allow(...Object.values(userRolesPrivate))
  .only()
  .required()
  .messages(customMessage("Role", "role"));
