import styled from "styled-components";
import { theme } from "../../../styles";
import {
  CircularProgress as MuiCircularProgress,
  LinearProgress as MuiLinearProgress,
} from "@material-ui/core";
import Grid from "../grid";

export const CircularContainer = styled(Grid)`
  margin: 24px 0 48px 0;
  text-align: center;
  width: 100%;
`;

export const CircularProgress = styled(MuiCircularProgress)`
  &.MuiCircularProgress-root {
    color: ${theme.color.neutral.background.weaker.default};
  }
`;

export const LinearProgress = styled(MuiLinearProgress)`
  border-radius: 999px;
  &.MuiLinearProgress-root {
    height: 12px;
  }
  &.MuiLinearProgress-colorPrimary {
    background-color: ${theme.color.accent.background.weaker.default};
  }
  & .MuiLinearProgress-barColorPrimary {
    background-color: ${theme.color.accent.background.default.default};
  }
`;
