import react from "react";
import { Header, Section } from "../../../components/layout";
import { IOrganisation } from "../../../services/admin/admin.service";
import { InvitationsList } from "../../invitations/InvitationsList";

type Props = {
  orgId: IOrganisation["id"];
};

const InvitationsTab: React.FC<Props> = ({ orgId }) => (
  <>
    <Header
      title="Invitations"
      titleIcon="mail"
      titleIconPosition="start"
      type="section"
    />

    <Section>
      <InvitationsList org_id_filter={orgId} />
    </Section>
  </>
);

export default InvitationsTab;
