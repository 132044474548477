import { useHistory } from "react-router-dom";
import { Hidden } from "@material-ui/core";
import { Avatar, Grid, Icon, Link, Typography } from "../../components/util";
import { BlueFit } from "../../assets/images";
import * as Styled from "./styles.css";

const AuthSidebar = ({ backLink, isSidebarVisible }: AuthSidebarProps) => {
  const history = useHistory();

  return (
    <Styled.Sidebar $isSidebarVisible={isSidebarVisible}>
      <Styled.Back $isSidebarVisible={isSidebarVisible}>
        {backLink &&
          (backLink ? (
            backLink
          ) : (
            <Link href={"#"} onClick={(evt: any) => history.goBack()}>
              <Icon color="accent" icon="arrow-left" size="sm" />
              Back
            </Link>
          ))}
      </Styled.Back>
      {isSidebarVisible && (
        <Hidden lgDown>
          <Styled.Container>
            <Link href={"https://care.safetyculture.com/"}>
              <Styled.Logo />
            </Link>
            <Typography element="article" variant="displaySmall">
              Improve your business. Don’t just insure it.
            </Typography>
            <Styled.List>
              <Styled.ListItem
                icon="people"
                iconAlignment="top"
                iconShade="strongest"
              >
                <Typography element="aside" variant="subtitle1">
                  <Styled.ListItemBoldText>
                    Fast and reliable support
                  </Styled.ListItemBoldText>
                  <Styled.ListItemLightText>
                    whenever you need it
                  </Styled.ListItemLightText>
                </Typography>
              </Styled.ListItem>
              <Styled.ListItem
                icon="globe"
                iconAlignment="top"
                iconShade="strongest"
              >
                <Typography element="aside" variant="subtitle1">
                  <Styled.ListItemBoldText>
                    Local knowledge,
                  </Styled.ListItemBoldText>
                  <Styled.ListItemLightText>
                    global backing
                  </Styled.ListItemLightText>
                </Typography>
              </Styled.ListItem>
              <Styled.ListItem
                icon="star"
                iconAlignment="top"
                iconShade="strongest"
              >
                <Typography element="span" variant="subtitle1">
                  <Styled.ListItemBoldText>
                    Trusted experts
                  </Styled.ListItemBoldText>
                  <Styled.ListItemLightText>
                    in risk management and insurance
                  </Styled.ListItemLightText>
                </Typography>
              </Styled.ListItem>
              <Styled.ListItem
                icon="graph"
                iconAlignment="top"
                iconShade="strongest"
                color="surface"
              >
                <Typography element="span" variant="subtitle1">
                  <Styled.ListItemBoldText>
                    In-depth safety ratings
                  </Styled.ListItemBoldText>
                  <Styled.ListItemLightText>
                    aligned with industry standards
                  </Styled.ListItemLightText>
                </Typography>
              </Styled.ListItem>
              <Styled.ListItem
                icon="verified"
                iconAlignment="top"
                iconShade="strongest"
              >
                <Typography element="span" variant="subtitle1">
                  <Styled.ListItemBoldText>
                    Proactive safety
                  </Styled.ListItemBoldText>
                  <Styled.ListItemLightText>
                    checks powered by the SafetyCulture app
                  </Styled.ListItemLightText>
                </Typography>
              </Styled.ListItem>
            </Styled.List>
            <Styled.Figure>
              <Typography
                paragraph
                element="blockquote"
                styled={{ text: { shade: "weaker", weight: "light" } }}
              >
                It was enlightening to view the risk profile of our business
                from another perspective, a perspective backed by statistics and
                claims data. As a result, your recommendations will provide a
                greater level of accountability to our customers, our
                stakeholders and the entire BlueFit organisation.
              </Typography>
            </Styled.Figure>
            <Grid container element="figcaption">
              <Styled.Person item>
                <Avatar src={BlueFit} />
              </Styled.Person>
              <Styled.Person item xs>
                <Typography
                  styled={{ text: { shade: "weakest", weight: "heavy" } }}
                >
                  Jason S
                </Typography>
                <Typography
                  variant="body2"
                  styled={{ text: { shade: "weak", weight: "light" } }}
                >
                  National OHS Manager, BlueFit PTY LTD
                </Typography>
              </Styled.Person>
            </Grid>
          </Styled.Container>
        </Hidden>
      )}
    </Styled.Sidebar>
  );
};

interface AuthSidebarProps {
  backLink?: any;
  isSidebarVisible: boolean;
}

AuthSidebar.defaultProps = {};

export default AuthSidebar;
