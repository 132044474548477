import { Typography } from "../../../components/util";
import AuthContainer from "../authContainer";
import AuthErrorFooter from "./authErrorFooter";

const InvitationEmail = () => {
  return (
    <AuthContainer
      headerTitle="Your currently logged in"
      footerContent={<AuthErrorFooter />}
      preventRedirect
    >
      <Typography styled={{ text: { shade: "weak" } }}>
        You're currently logged into Care, and the email attached to your
        account does not match the invitee's email.
      </Typography>
      <Typography styled={{ text: { shade: "weak" } }}>
        <br />
        <a href="/logout">Logout</a>
      </Typography>
    </AuthContainer>
  );
};

export default InvitationEmail;
