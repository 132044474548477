import * as React from "react";
import { tInvitationStatus } from "../../../types";
import { Chip, Typography } from "../../../components/util";

interface Props {
  status: tInvitationStatus;
}

export const InviteStatusChip: React.FC<Props> = ({ status }) => {
  return (
    <Chip
      color={color[status]}
      size="xs"
      data-testid={`invite-status-chip-${color[status]}`}
    >
      <Typography styled={{ text: { case: "title" } }}>
        {statusText[status]}
      </Typography>
    </Chip>
  );
};

const color: {
  [key in tInvitationStatus]: "information" | "surface" | "positive";
} = { active: "information", claimed: "positive", cancelled: "surface" };

const statusText: {
  [key in tInvitationStatus]: string;
} = { active: "Unclaimed", claimed: "Claimed", cancelled: "Cancelled" };
