import {
  Placement,
  TIconShape,
  TStyleConnotation,
  TStyleShade,
} from "../../../types";
import { ContainerProps } from "../container/container";
import Icon from "../icons/icon";
import * as Styled from "./styles.css";

const ListItemIcon = ({
  children,
  color,
  icon,
  selected,
  size,
  shade,
  placement,
  ...otherProps
}: ListItemIconProps) => {
  const shadeVal = shade ? shade : selected ? "default" : "weak";
  return (
    <Styled.ListItemIcon $size={size} $placement={placement} {...otherProps}>
      <Icon icon={icon} color={color} shade={shadeVal}>
        {children && children}
      </Icon>
    </Styled.ListItemIcon>
  );
};

export interface ListItemIconProps extends ContainerProps {
  color?: TStyleConnotation;
  icon: TIconShape;
  selected: boolean;
  size: "md" | "sm";
  shade?: TStyleShade;
  placement?: Placement;
}

ListItemIcon.defaultProps = {
  size: "md",
  selected: false,
};

export default ListItemIcon;
