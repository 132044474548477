import { useContext, useState, useEffect } from "react";
import { useQuery } from "react-query";
import AuthContext from "../../../contexts/authContext";
import prismicService, {
  CompareOverviewInterface,
  CompareBenchmarksInterface,
  CompareMetricsInterface,
  CompareRecommendationsInterface,
} from "../../../services/prismic/prismic.service";
import { makeStyles, Theme } from "@material-ui/core";
import { CircularProgress, Grid } from "../../../components/util";
import { ListMissing } from "../../../components/content";
import Overview from "./overview";
import Benchmarks from "./benchmarks";
import Metrics from "./metrics";
import Recommendations from "./recommendations";

const styles = makeStyles((theme: Theme) => ({
  container: {
    alignItems: "stretch",
  },
}));

const Compare = () => {
  const classes = styles();
  const auth = useContext(AuthContext);
  const [overview, setOverview] =
    useState<CompareOverviewInterface | undefined>();
  const [benchmarks, setBenchmarks] =
    useState<CompareBenchmarksInterface | undefined>();
  const [metrics, setMetrics] = useState<CompareMetricsInterface | undefined>();
  const [recommendations, setRecommendations] =
    useState<CompareRecommendationsInterface | undefined>();

  const { isLoading, isError, data } = useQuery(
    "InsightsCompare",
    () => prismicService.getCompare(auth.user.organisations[0].industry.name),
    { retry: 0 },
  );

  useEffect(() => {
    if (!isLoading && data) {
      setOverview(data.overview);
      setBenchmarks(data.benchmarks);
      setMetrics(data.metrics);
      setRecommendations(data.recommendations);
    }
  }, [isLoading, data]);

  if (isLoading) {
    return <CircularProgress contained />;
  } else if (isError || data == null) {
    return (
      <ListMissing
        icon="pulse"
        message="Your industy doesn't have any information at the moment, please check back later."
        type="information"
      />
    );
  }

  return (
    <Grid container spacing={3} classes={{ root: classes.container }}>
      {overview && <Overview data={overview} />}
      {benchmarks && <Benchmarks data={benchmarks} />}
      {metrics && <Metrics data={metrics} />}
      {recommendations && <Recommendations data={recommendations} />}
    </Grid>
  );
};

export default Compare;
