import { useContext, useEffect } from "react";
import useLocalStorageState from "use-local-storage-state";
import { AuthContext, GuidanceContext } from "../../../contexts";
import { VirtualSurveyWidget } from "../../../components/content";
import { Grid } from "./styles.css";
import { IPolicy } from "../../../types";

const getPolicyHolderInfo = (policy: IPolicy) => {
  switch (policy.type) {
    case "bpk":
      return {
        email: policy.policy_data.customer_email,
        organisation_id: policy.broker_org_id,
        name: `${policy.policy_data.customer_first_name} ${policy.policy_data.customer_last_name}`,
      };
    case "cpx":
      return {
        email: policy.policy_data.CUSTOMER_EMAIL,
        organisation_id: policy.broker_org_id,
        name: `${policy.policy_data.CUSTOMER_FIRST_NAME} ${policy.policy_data.CUSTOMER_LAST_NAME}`,
      };
    default: {
      return {
        email: "",
        organisation_id: "",
        name: "",
      };
    }
  }
};

const VirtualSurveyTab = ({ policy }: VirtualSurveyTabProps) => {
  const { setNewBrokerVirtualSurvey } = useContext(GuidanceContext);
  const { user } = useContext(AuthContext);

  const [BCVSVisited, setBCVSVisited] = useLocalStorageState<boolean>(
    `${user.id}-BCVSVisited`,
    true,
  );

  useEffect(() => {
    setNewBrokerVirtualSurvey(true);

    if (!BCVSVisited) {
      setBCVSVisited(true);
    }
  }, [BCVSVisited, setBCVSVisited, setNewBrokerVirtualSurvey, user.id]);

  const { email, organisation_id, name } = getPolicyHolderInfo(policy);

  return (
    <Grid item container spacing={3}>
      <Grid item md={12} xs={12}>
        <VirtualSurveyWidget
          email={email}
          name={name}
          organisation_id={organisation_id}
          user_id={user.id}
        />
      </Grid>
    </Grid>
  );
};

interface VirtualSurveyTabProps {
  policy: IPolicy;
}

export default VirtualSurveyTab;
