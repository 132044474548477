import styled from "styled-components";
import { breakpoints, theme } from "../../../styles";

import {
  Chip,
  Grid as ComponentGrid,
  Link as ComponentLink,
  Table as ComponentTable,
  IconButton,
  Tabs as inTabs,
  SelectMenu,
  SelectOption,
  TableCell,
  TabPanel,
  Typography,
  Icon,
  Form,
  TextField,
} from "../../../components/util";

import { GridProps } from "@material-ui/core";
import transparentize from "polished/lib/color/transparentize";

const dropShadow = `0px 1px 5px ${transparentize(
  0.7,
  theme.color.surface.background.inverse.default,
)}`;
export interface StyledGridProps extends GridProps {
  $fullHeight?: boolean;
}

export const SubHeader = styled(Typography)`
  margin-bottom: 0.5em;
  color: ${theme.color.surface.text.weak.default};
`;

export const Link = styled(ComponentLink)`
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media ${breakpoints.down("md")} {
    max-width: 100px;
  }
  @media ${breakpoints.down("lg")} {
    max-width: 200px;
  }
`;

export const Grid = styled(ComponentGrid)<StyledGridProps>`
  height: ${props => (props.$fullHeight ? "100%" : "initial")};
`;

export const Table = styled(ComponentTable)`
  @media ${breakpoints.down("sm")} {
    grid-template-columns: 1fr;
  }
  th:not(:first-of-type) {
    display: none;
    @media ${breakpoints.up("md")} {
      display: flex;
    }
  }
  td:not(:first-of-type) {
    display: none;
    @media ${breakpoints.up("md")} {
      display: flex;
    }
  }
`;

export const AddressCell = styled(TableCell)`
  height: auto;
  min-height: 48px;
`;

export const SummaryOrgTypeChip = styled(Chip)`
  width: max-content;
  height: 33px;
  margin-right: 15px;
`;
export const OrgTypeChip = styled(Chip)`
  width: max-content;
`;

export const Tabs = styled(Grid)``;
export const InnerTabs = styled(inTabs)`
  margin-bottom: 0px;
`;

export const STTabpanel = styled(TabPanel)`
  margin-top: 0px;
`;

export const MoreOptionIconButton = styled(IconButton)`
  transform: rotate(90deg);
`;

export const MoreOptionSelectMenu = styled(SelectMenu)`
  transform: translate3d(10px, 10px, 0);
  z-index: 99;
  border-radius: 6px;
  box-shadow: ${dropShadow};
`;

export const SpacedGrid = styled(Grid)`
  padding-top: 50px;
`;

export const MoreOptionLaunchIconButton = styled(IconButton)`
  padding: 0px;
  vertical-align: center;
  padding-left: 10px;
`;

export const MoreOptionSelectOption = styled(SelectOption)`
  padding: 8px 15px;
  background-color: ${theme.color.surface.background.default.default};
`;

export const LinkButton = styled.a`
  text-decoration: none;
  color: inherit;
  &:visited {
    color: inherit;
  }
`;

export const PlusIcon = styled(Icon)`
  margin-left: 8px;
`;

export const ModalBody = styled(Grid)`
  padding: 24px;
  padding-top: 12px;
  padding-bottom: 0px;
`;
export const ModalBodyTitle = styled(Grid)<StyledGridProps>`
  padding-top: 0px;
  padding-bottom: 25px;
`;

export const ModalForm = styled(Form)`
  & > div:nth-last-child(2) {
    margin-bottom: 48px;
  }
`;

export const ModalTextField = styled(TextField)`
  height: 44px;
`;
