import styled from "styled-components";
import { theme } from "../../../styles/themes";
import { Link as RouterLink } from "react-router-dom";
import { Link as MuiLink } from "@material-ui/core";
import UnstyledIcon from "../icons/icon";
import { IComponentStyleProps } from "../../../types";

interface LinkStyleProps {
  $styled?: IComponentStyleProps;
  $isButton?: boolean;
}

export const ExternalLink = styled(MuiLink)<LinkStyleProps>`
  color: ${theme.color.accent.text.default.default};
  font-weight: ${theme.typography.weight.heavy};
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }

  ${({ $isButton }) =>
    $isButton &&
    `
    text-decoration: none;
    .MuiButton-label {
      text-decoration: none;
    }
  `}

  ${({ $styled }) =>
    $styled?.state === "disabled" &&
    `
      pointer-events: none;
    `}
`;

export const InternalLink = styled(RouterLink)<LinkStyleProps>`
  color: ${theme.color.accent.text.default.default};
  font-weight: ${theme.typography.weight.heavy};
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }

  ${({ $isButton }) =>
    $isButton &&
    `
    text-decoration: none;
    .MuiButton-label {
      text-decoration: none;
    }
  `}

  ${({ $styled }) =>
    $styled?.state === "disabled" &&
    `
      pointer-events: none;
    `}
`;

export const Icon = styled(UnstyledIcon)`
  margin-left: 8px;
`;
