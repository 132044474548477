import { recordAnalyticsEvent } from "../../analytics";
import { Header, Section } from "../../components/layout";
import Button from "../../components/util/button";
import { useFlag } from "@unleash/proxy-client-react";
import ClientList from "../portfolio/clientList";
import * as Styled from "./styles.css";

const Policies = () => {
  const handleQuoteClick = () => {
    recordAnalyticsEvent("action", "Create Quote", {
      path: process.env.REACT_APP_MITTI_BROKER_LOGIN_LINK || "",
    });
  };

  return (
    <Styled.Grid $fullHeight={true}>
      <Header
        actions={
          useFlag("portal.quotebutton") && (
            <Button
              color="accent"
              href={process.env.REACT_APP_MITTI_BROKER_LOGIN_LINK}
              isNewTab
              onClick={() => handleQuoteClick()}
              variant="contained"
            >
              Create quote
            </Button>
          )
        }
        title="Policies"
        titleIcon="file"
        titleIconPosition="start"
        type="article"
      />

      <Section>
        <ClientList />
      </Section>
    </Styled.Grid>
  );
};

export default Policies;
