import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormRow, FormSubmit, Typography } from "..";
import { getFormSchemaErrors } from "../../../validations";
import { AlertBox } from "../../content/alertBox/FormAlertBox";
import Header from "../../layout/header";
import { FormContext } from "./../../../contexts";
import * as Styled from "./styles.css";
import { tSubmitButtonAccent } from "./formSubmit";
import { closeModalFn } from "../modal";

const FormBody = ({
  children,
  enableSubmitAlways,
  error,
  errorMessage,
  footer,
  fullWidth,
  inline,
  miscButton,
  miscButtonName,
  miscButtonOnClick,
  onSubmit,
  steppedForm,
  submitAlign,
  submitDisabled,
  submitInline,
  submitText,
  stickySubmit,
  submitButtonAccent,
  hideSubmitButton,
  validationForm,
  ...otherProps
}: FormBodyProps) => {
  const {
    formData,
    formStep,
    formSubtitle,
    formTitle,
    resetForm,
    setFormSubtitle,
    setFormTitle,
    setSubmitErrors,
  } = useContext(FormContext);
  const history = useHistory();
  const [message, setMessage] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isSubmitError, setIsSubmitError] = useState<boolean>(false);
  const [submitError, setSubmitError] =
    useState<string | undefined>(errorMessage);

  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  const [stepFooter, setStepFooter] =
    useState<React.ReactNode | undefined>(footer);
  const [submitStepText, setSubmitStepText] =
    useState<string | undefined>(submitText);

  useEffect(() => {
    const state = history.location.state as { message?: string };
    if (state && state.message) {
      setMessage(state.message);
    }
  }, [message, history]);

  useEffect(() => {
    let stepChild = React.Children.toArray(children)[formStep - 1];

    if (React.isValidElement(stepChild)) {
      setSubmitStepText(stepChild.props.submitText);
      setFormSubtitle(stepChild.props.subtitle);
      setFormTitle(stepChild.props.title);
      //@ts-ignore
      setStepFooter(stepChild.props.footer);
    }

    return () => {
      setSubmitStepText("");
      setFormSubtitle("");
      setFormTitle("");
      setStepFooter("");
    };
  }, [children, formStep, setFormSubtitle, setFormTitle, steppedForm]);

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setIsSubmitError(false);

    if (validationForm) {
      const helpList = await getFormSchemaErrors(
        validationForm,
        formData[formStep - 1],
      );

      if (helpList) {
        setSubmitErrors(helpList);
        setIsLoading(false);
        return;
      }
    }

    try {
      let tmpObject = {};
      let combinedStepsData = Object.assign({}, tmpObject, ...formData);
      if (onSubmit.service) {
        await onSubmit.service(combinedStepsData);

        //reset only if the form is in submit only mode
        if (!enableSubmitAlways) {
          resetForm();
        }

        if (onSubmit.onSuccessMsg) {
          setMessage(onSubmit.onSuccessMsg);
        }
        if (onSubmit.onSuccess) {
          onSubmit.onSuccess(formData);
        }
      }
    } catch (error: any) {
      setSubmitError(error.message);
      setIsSubmitError(true);
      setIsLoading(false);
      return;
    }

    //common close modal fn no need to include in other function calls
    closeModalFn();

    setIsLoading(false);

    //making sumbit button available for submit-continueto-edit
    setFormSubmitted(!enableSubmitAlways);
  };

  return (
    <Styled.Form
      onSubmit={handleFormSubmit}
      $fullWidth={fullWidth}
      $inline={inline}
      {...otherProps}
    >
      {(formTitle || formSubtitle) && (
        <Header type="plain">
          {steppedForm && (
            <Typography
              paragraph
              variant="body2"
              styled={{ text: { shade: "weak" } }}
            >
              Step {formStep} of {formData.length}
            </Typography>
          )}
          {formTitle && (
            <Typography element="h1" paragraph variant="displayMedium">
              {formTitle}
            </Typography>
          )}
          {formSubtitle && (
            <Typography styled={{ text: { shade: "weak" } }}>
              {formSubtitle}
            </Typography>
          )}
        </Header>
      )}

      {(isSubmitError && <AlertBox message={submitError} color="negative" />) ||
        (message && <AlertBox message={message} color="information" />)}

      {children && !steppedForm && children}

      {children &&
        steppedForm &&
        React.Children.map<ReactNode, ReactNode>(children, (child, i) => {
          if (React.isValidElement(child) && formStep === i + 1) {
            return React.cloneElement(child, {});
          }
        })}

      {(() => {
        const Submit = () => (
          <FormSubmit
            formStep={formStep}
            formStepLength={formData.length}
            hideSubmitButton={hideSubmitButton}
            miscButton={miscButton}
            miscButtonName={miscButtonName}
            miscButtonOnClick={miscButtonOnClick}
            inline={inline}
            isFormSubmitted={formSubmitted}
            isLoading={isLoading}
            steppedForm={steppedForm}
            size={inline ? "sm" : undefined}
            submitAlign={submitAlign}
            submitInline={submitInline}
            submitText={steppedForm ? submitStepText : submitText}
            submitDisabled={submitDisabled}
            submitButtonAccent={submitButtonAccent}
          />
        );

        return (
          <>
            {inline ? (
              <Submit />
            ) : (
              <FormRow textAlign={submitAlign}>
                <Submit />
              </FormRow>
            )}
          </>
        );
      })()}

      {stepFooter && <FormRow>{stepFooter}</FormRow>}
    </Styled.Form>
  );
};

export type FormError = undefined | string | React.ReactHTMLElement<any>;

interface FormBodyProps {
  children?: React.ReactNode | React.ReactNodeArray;
  className?: string;
  submitDisabled?: boolean;
  enableSubmitAlways?: boolean;
  error?: boolean;
  errorMessage?: any;
  footer?: React.ReactNode;
  fullWidth?: boolean;
  inline?: boolean;
  miscButton?: boolean;
  miscButtonName?: string;
  miscButtonOnClick?: any;
  onSubmit: any;
  steppedForm?: boolean;
  submitAlign?: "left" | "center" | "right";
  submitInline?: boolean;
  submitText?: string;
  stickySubmit?: boolean;
  submitButtonAccent?: tSubmitButtonAccent;
  hideSubmitButton?: boolean;
  validationForm?: any;
}

FormBody.defualtProps = {
  error: false,
  fullWidth: false,
  steppedForm: false,
  submit: "Submit",
  stickySubmit: false,
};

export default FormBody;
