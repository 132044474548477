import { ContainerProps } from "../container/container";
import * as Styled from "./styles.css";

const TableBody = ({ children, ...otherProps }: TableCellProps) => {
  return (
    <Styled.TableBody element="tbody" {...otherProps}>
      {children && children}
    </Styled.TableBody>
  );
};

interface TableCellProps extends ContainerProps {}

export default TableBody;
