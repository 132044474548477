import * as React from "react";
import { InvitationResponse } from "../../../services/invitation/invitation.service";
import { Typography } from "../../../components/util";
import * as Styled from "../styles.css";
import { tUserRole } from "../../../types";
import { hasAdminLevelPriviledge } from "../../../helpers";

interface Props {
  invitation: InvitationResponse;
  active_user_roles: tUserRole[];
}

export const InviteLink: React.FC<Props> = ({
  invitation,
  active_user_roles,
}) => {
  const invitationCellText = invitation.customer_org_name
    ? `${invitation.customer_org_name}`
    : ` ${invitation.firstname} ${invitation.lastname}`;

  const hasAdminPrivilege = hasAdminLevelPriviledge(active_user_roles);
  return (
    <>
      {hasAdminPrivilege ? (
        <Styled.Link
          href={`/invitations/${invitation.id}`}
          router
          title={invitationCellText}
        >
          <Typography styled={{ text: { case: "title" } }}>
            To: {invitationCellText}
          </Typography>
        </Styled.Link>
      ) : (
        <Typography styled={{ text: { case: "title" } }}>
          To: {invitationCellText}
        </Typography>
      )}
    </>
  );
};
