import { ButtonGroupProps as MuiButtonGroupProps } from "@material-ui/core";
import { CircularProgress } from "../";
import * as Styled from "./styles.css";

interface ButtonProps extends Omit<MuiButtonGroupProps, ""> {
  isLoading: boolean;
  statusText: string;
}

const ButtonGroup = ({
  children,
  isLoading,
  statusText,
  ...otherProps
}: ButtonProps) => {
  return (
    <Styled.ButtonGroup role="group" aria-label="button group" {...otherProps}>
      {children && children}
      {isLoading && (
        <div>
          <CircularProgress contained size={24} />
          <span>{statusText}</span>
        </div>
      )}
    </Styled.ButtonGroup>
  );
};

ButtonGroup.defaultProps = {
  isNewTab: false,
  isLoading: false,
  statusText: "Loading",
};
export default ButtonGroup;
