import { WidgetProps } from "@rjsf/utils";
import { Button } from "./../..";
import * as Styled from "./styles.css";

const JfsButtonGroup = ({
  onChange,
  id,
  type,
  value,
  ...otherProps
}: JfsButtonGroupProps) => {
  const handleChange = (e?: any) => {
    onChange(e.target ? e.target.value : e); // this is the Form's onChange prop
  };
  const options = otherProps.options.enumOptions;

  return (
    <Styled.JfsButtonList
      id={id}
      corners="rounded"
      elevation="flat"
      orientation="horizontal"
    >
      {options &&
        options.map((button: any, i: number) => (
          <Styled.JfsButtonListItem
            key={`${id}-${i}`}
            onClick={() => handleChange(button.value)}
          >
            <Button
              color={value === button.value ? "accent" : "surface"}
              onClick={(e: any) => handleChange(button.value)}
            >
              {button.label}
            </Button>
          </Styled.JfsButtonListItem>
        ))}
    </Styled.JfsButtonList>
  );
};

interface JfsButtonGroupProps extends WidgetProps {}

JfsButtonGroup.defaultProps = {};

export default JfsButtonGroup;
