import { Orientation } from "../../../types";
import { ContainerProps } from "../container/container";
import * as Styled from "./styles.css";

const List = ({
  children,
  element,
  orientation,
  paragraph,
  variant,
  ...otherProps
}: ListProps) => {
  const Component: any =
    element || (variant && variantMapping[variant]) || "div";
  return (
    <Styled.List
      element={Component}
      $orientation={orientation}
      $paragraph={paragraph}
      $variant={variant}
      {...otherProps}
    >
      {children && children}
    </Styled.List>
  );
};

export interface ListProps extends ContainerProps {
  orientation?: Orientation;
  paragraph?: boolean;
  variant?: ListVariants;
}

List.defaultProps = {
  orientation: "vertical",
  paragraph: false,
  variant: "plain",
};

export type ListVariants = "ordered" | "unordered" | "plain";
export type HTMLTags = "div" | "ul" | "ol";

const variantMapping: Record<ListVariants, any> = {
  ordered: "ol",
  unordered: "ul",
  plain: "ul",
};

export default List;
