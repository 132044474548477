import styled from "styled-components";
import { theme } from "../../../styles";
import { Container, Icon, Typography } from "../../../components/util";

export const ResultCount = styled(Typography)`
  margin: ${theme.spacing}px 0;
  text-align: end;
  width: 50%;
`;

export const SearchTab = styled(Typography)`
  margin: ${theme.spacing}px 0;
  width: 50%;
`;

export const HeaderContainer = styled(Container)`
  margin: 0px;
  display: flex;
  width: 100%;
`;

export const EmptyState = styled(Typography)`
  padding: 10%;
  text-align: center;
`;

export const NoResult = styled(Typography)`
  padding-top: 24px;
`;

export const EmptySearchIcon = styled(Icon)`
  font-size: 24px;
`;
