import { TableCell, TableRow, Typography } from "../../components/util";
import { PaginatedResults } from "../../components/content";
import { IUser } from "../../services/admin/admin.service";
import userService from "../../services/user/user.service";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import * as Styled from "./styles.css";

const UsersList = ({ users }: IUsersListProps) => {
  const { search } = useLocation();
  const urlParams: Record<string, any> = useMemo(
    () => queryString.parse(search),
    [search],
  );

  const [searchTerm, setSearchTerm] = useState<string>(
    urlParams?.searchTerm ? urlParams?.searchTerm : "",
  );

  useEffect(() => {
    setSearchTerm(urlParams?.searchTerm);
  }, [urlParams?.searchTerm]);

  const userRow = (usersSlice: IUser[]) => {
    return (
      <>
        {usersSlice.map((user: IUser, i: number) => (
          <TableRow key={`user.id-${i}`}>
            <TableCell>
              <Styled.Link
                href={`/users/${user.id}`}
                router
                title={`${user.firstname} ${user.lastname}`}
              >
                {user.firstname} {user.lastname}
              </Styled.Link>
            </TableCell>

            <TableCell>{user.email}</TableCell>
            <TableCell>
              <Typography styled={{ text: { case: "sentence" } }}>
                {user?.user_roles &&
                  user.user_roles.length > 0 &&
                  CamelCaseToNormalCase(user?.user_roles[0]?.name)}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <PaginatedResults
      id="users-all"
      searchTerm={searchTerm}
      inputResults={{
        hits: users ? users : [],
        total: users ? users.length : 0,
      }}
      tableColumns={[
        { title: "Name", expand: true },
        { title: "Email", expand: true },
        { title: "Role", expand: true },
      ]}
      service={userService.getAllUsers}
      searchService={userService.getAllUsersBySearchTerm}
      tableRows={userRow}
      missing={{
        icon: "people",
        type: "users",
        message: "This is where we’ll show all of the system's uers.",
      }}
    />
  );
};

interface IUsersListProps {
  users?: IUser[] | null;
}

export default UsersList;

const CamelCaseToNormalCase = (val: string) => {
  return val.replace(/([A-Z])/g, " $1").trim();
};
