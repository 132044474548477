import { TStyleConnotation, TStyleSize } from "../../../types";
import { ContainerProps } from "../../util/container/container";
import * as Styled from "./styles.css";

const Logo = ({ color, height, size, width, ...otherProps }: LogoProps) => {
  const logoHeight = (pSize: TStyleSize, pHeight?: number): number => {
    if (pHeight) {
      return pHeight;
    }
    switch (pSize) {
      case "sm":
        return 28;
      case "md":
      default:
        return 52;
    }
  };

  const logoWidth = (pSize: TStyleSize, pWidth?: number): number => {
    if (pWidth) {
      return pWidth;
    }
    switch (pSize) {
      case "sm":
        return 53;
      case "md":
      default:
        return 99;
    }
  };

  return (
    <Styled.LogoSvg
      as="svg"
      viewBox={`0 0 ${width} ${logoHeight(size, height)}`}
      $color={color}
      $height={logoHeight(size, height)}
      $width={logoWidth(size, width)}
      $size={size}
      {...otherProps}
    >
      <>
        <g clipPath="url(#clip0_140_3303)">
          <path
            d="M95.292 3.64958C94.6883 3.05929 93.8834 2.75073 92.9174 2.75073C92.2869 2.75073 91.6966 2.85806 91.1466 3.08612C90.5965 3.31419 90.127 3.62275 89.7513 3.99839C89.3623 4.38745 89.0671 4.84358 88.8525 5.38021C88.6378 5.91683 88.5171 6.48029 88.5171 7.084C88.5171 7.67429 88.6512 8.22433 88.9196 8.7073C89.1879 9.19026 89.5635 9.57932 90.0599 9.86105C90.5563 10.1428 91.1063 10.2903 91.7234 10.2903C92.6357 10.2903 93.4675 10.0623 94.2053 9.59273C94.8895 9.16343 95.3457 8.72071 95.6945 7.99627C95.7347 7.92919 95.6811 7.83528 95.6006 7.83528H93.4406C93.4004 7.83528 93.3736 7.84869 93.3467 7.87553C93.1992 8.06334 93.0918 8.10359 92.8504 8.22433C92.5955 8.34508 92.3271 8.34507 92.032 8.34507C91.6161 8.34507 91.2941 8.23775 91.0661 8.0231C90.8648 7.83528 90.7575 7.55355 90.7307 7.19132C90.7307 7.12425 90.7843 7.084 90.838 7.084H95.9628C96.0165 7.084 96.0567 7.04375 96.0701 7.00351C96.1774 6.60103 96.2311 6.2254 96.2311 5.86317C96.2043 4.99115 95.9091 4.23987 95.292 3.64958ZM93.8699 5.75585H91.2002C91.1197 5.75585 91.0661 5.66194 91.1063 5.59486C91.2673 5.32654 91.4551 5.11189 91.6966 4.9509C91.9783 4.74967 92.3137 4.65576 92.6894 4.65576C93.065 4.65576 93.3736 4.74967 93.6016 4.93749C93.8029 5.09848 93.9236 5.33996 93.9907 5.63511C93.9907 5.68877 93.937 5.75585 93.8699 5.75585Z"
            fill="#293745"
          />
          <path
            d="M30.9638 2.75073C30.3333 2.75073 29.743 2.85806 29.193 3.08612C28.6429 3.31419 28.1734 3.62275 27.7977 3.99839C27.4087 4.38745 27.1135 4.84358 26.8989 5.38021C26.6842 5.91683 26.5635 6.48029 26.5635 7.084C26.5635 7.67429 26.6976 8.22433 26.9659 8.7073C27.2343 9.19026 27.6099 9.57932 28.1063 9.86105C28.6027 10.1428 29.1527 10.2903 29.7698 10.2903C30.6821 10.2903 31.5139 10.0623 32.2517 9.59273C32.8823 9.19026 33.325 8.78779 33.6604 8.15726C33.7409 8.00968 33.6335 7.83528 33.4726 7.83528H31.648C31.5273 7.83528 31.42 7.88894 31.3395 7.96943C31.2321 8.07676 31.1114 8.13042 30.9236 8.22433C30.6687 8.34508 30.4004 8.34507 30.1052 8.34507C29.6893 8.34507 29.3674 8.23775 29.1393 8.0231C28.9381 7.83528 28.8307 7.55355 28.8039 7.19132C28.8039 7.12425 28.8441 7.07058 28.9112 7.07058H33.1908C33.7677 7.07058 34.2641 6.61445 34.2909 6.03758C34.2909 5.9705 34.2909 5.91683 34.2909 5.84976C34.2909 4.9509 33.9824 4.19963 33.3786 3.60934C32.7347 3.05929 31.9298 2.75073 30.9638 2.75073ZM31.9163 5.75585H29.2466C29.1661 5.75585 29.1125 5.66194 29.1527 5.59486C29.3137 5.32654 29.5015 5.11189 29.743 4.9509C30.0247 4.74967 30.3601 4.65576 30.7358 4.65576C31.1114 4.65576 31.42 4.74967 31.648 4.93749C31.8493 5.09848 31.97 5.33996 32.0371 5.63511C32.0371 5.68877 31.9834 5.75585 31.9163 5.75585Z"
            fill="#293745"
          />
          <path
            d="M26.8856 4.10571L27.0332 3.35443C27.06 3.22028 26.9527 3.08612 26.8185 3.08612H25.5172C25.4501 3.08612 25.3965 3.01904 25.4099 2.95196L25.477 2.60315C25.5172 2.41533 25.5977 2.26776 25.7185 2.16044C25.8526 2.05311 26.0002 1.99945 26.188 1.99945H26.3758C26.899 1.99945 27.3418 1.63722 27.4491 1.12743L27.5967 0.429813C27.6235 0.295656 27.5162 0.161499 27.382 0.161499H25.9465C25.2087 0.161499 24.605 0.349319 24.1488 0.738374C23.6927 1.11401 23.3975 1.66406 23.2634 2.37509L23.1426 2.99221C23.1292 3.04587 23.089 3.08612 23.0353 3.08612H22.4584C22.1499 3.08612 21.8816 3.30077 21.8145 3.60933L21.5998 4.70942C21.573 4.84357 21.6803 4.97773 21.8145 4.97773H22.5926C22.6597 4.97773 22.7133 5.04481 22.6999 5.11189L21.7608 9.8208C21.734 9.95495 21.8413 10.0891 21.9755 10.0891H23.1695C23.6927 10.0891 24.1354 9.72688 24.2427 9.20367L25.0745 5.07164C25.0879 5.01798 25.1282 4.97773 25.1818 4.97773H25.8124C26.3222 4.97773 26.7783 4.61551 26.8856 4.10571Z"
            fill="#293745"
          />
          <path
            d="M70.2855 0.42981H69.1049C68.5817 0.42981 68.139 0.792034 68.0317 1.31525L66.4352 9.311C66.3547 9.71347 66.6632 10.1025 67.0791 10.1025H68.4475C68.5549 10.1025 68.6354 10.0354 68.6622 9.92812L70.5136 0.698123C70.527 0.550551 70.4196 0.42981 70.2855 0.42981Z"
            fill="#293745"
          />
          <path
            d="M75.7184 2.99221H74.2829C74.2158 2.99221 74.1622 2.92513 74.1756 2.85806L74.511 1.18109C74.5378 1.04694 74.4305 0.912781 74.2963 0.912781H74.1622C74.1085 0.912781 74.0548 0.939611 74.0146 0.966443L70.218 4.41427L70.1643 4.696C70.1375 4.83016 70.2448 4.96432 70.379 4.96432H71.3046C71.3717 4.96432 71.4254 5.0314 71.412 5.09848C71.3717 5.29971 71.318 5.54119 71.2644 5.82292C71.1168 6.60103 71.0095 7.09741 70.9692 7.3389C70.929 7.56696 70.9022 7.7682 70.9022 7.92918C70.9022 8.58655 71.1168 9.12318 71.5327 9.51224C71.9486 9.90129 72.5255 10.0891 73.2633 10.0891H73.6926C74.2158 10.0891 74.6586 9.72689 74.7659 9.20368L74.9671 8.22433C74.9939 8.09017 74.8866 7.95602 74.7525 7.95602H74.1488C73.8939 7.95602 73.6926 7.90235 73.5585 7.78161C73.4243 7.66087 73.3572 7.49988 73.3572 7.27182C73.3572 7.16449 73.5048 6.42663 73.7865 5.04481C73.7999 4.99115 73.8402 4.9509 73.8939 4.9509H74.7122C75.2354 4.9509 75.6916 4.57526 75.7855 4.06547L75.9465 3.23369C75.9599 3.11295 75.866 2.99221 75.7184 2.99221Z"
            fill="#293745"
          />
          <path
            d="M40.0997 2.9922H38.6643C38.5972 2.9922 38.5435 2.92512 38.5569 2.85804L38.8923 1.16767C38.9192 1.03351 38.8118 0.899353 38.6777 0.899353H38.5435C38.4899 0.899353 38.4362 0.926184 38.3959 0.953015L34.5993 4.41426L34.5456 4.69599C34.5188 4.83015 34.6261 4.96431 34.7603 4.96431H35.686C35.7531 4.96431 35.8067 5.03139 35.7933 5.09846C35.7531 5.2997 35.6994 5.54118 35.6457 5.82291C35.4982 6.60102 35.3908 7.0974 35.3506 7.33888C35.3103 7.56695 35.2835 7.76819 35.2835 7.92917C35.2835 8.58654 35.4982 9.12317 35.914 9.51223C36.3299 9.90128 36.9068 10.0891 37.6447 10.0891H38.074C38.5972 10.0891 39.0399 9.72688 39.1472 9.20366L39.3485 8.22432C39.3753 8.09016 39.268 7.95601 39.1338 7.95601H38.5301C38.2752 7.95601 38.074 7.90234 37.9398 7.7816C37.8057 7.66086 37.7386 7.49987 37.7386 7.27181C37.7386 7.16448 37.8861 6.42662 38.1679 5.0448C38.1813 4.99114 38.2215 4.95089 38.2752 4.95089H39.0936C39.6168 4.95089 40.0729 4.57525 40.1668 4.06546L40.3278 3.23368C40.3412 3.11294 40.2339 2.9922 40.0997 2.9922Z"
            fill="#293745"
          />
          <path
            d="M83.419 2.99218H82.225C81.7018 2.99218 81.2457 3.36781 81.1518 3.87761L80.5615 6.82906C80.481 7.23153 80.32 7.55351 80.0651 7.80841C79.8102 8.06331 79.515 8.18405 79.1797 8.18405C78.5759 8.18405 78.2674 7.91573 78.2674 7.36569C78.2674 7.29861 78.2674 7.21812 78.2808 7.11079C78.3076 6.97664 78.5491 5.68873 79.0455 3.24707C79.0723 3.11292 78.965 2.97876 78.8308 2.97876H77.6368C77.1136 2.97876 76.6709 3.34098 76.5636 3.85078C76.5099 4.09226 76.4563 4.36057 76.4026 4.6423C76.1477 5.84972 76.0001 6.58758 75.9599 6.86931C75.9062 7.13762 75.8794 7.39252 75.8794 7.60717C75.8794 8.41211 76.1477 9.06948 76.6977 9.56586C77.2478 10.0622 77.9588 10.3171 78.8711 10.3171C79.9175 10.3171 80.7895 10.0488 81.4872 9.49879C82.1848 8.94874 82.6409 8.18405 82.8421 7.2047L83.6337 3.24707C83.6605 3.11292 83.5666 2.99218 83.419 2.99218Z"
            fill="#293745"
          />
          <path
            d="M66.6226 2.99218H65.4286C64.9054 2.99218 64.4493 3.36781 64.3554 3.87761L63.7651 6.82906C63.6846 7.23153 63.5236 7.55351 63.2687 7.80841C63.0138 8.06331 62.7187 8.18405 62.3833 8.18405C61.7796 8.18405 61.471 7.91573 61.471 7.36569C61.471 7.29861 61.471 7.21812 61.4844 7.11079C61.5113 6.97664 61.7527 5.68873 62.2491 3.24707C62.2759 3.11292 62.1686 2.97876 62.0345 2.97876H60.8405C60.3172 2.97876 59.8745 3.34098 59.7672 3.85078C59.7135 4.09226 59.6599 4.36057 59.6062 4.6423C59.3513 5.84972 59.2037 6.58758 59.1635 6.86931C59.1098 7.13762 59.083 7.39252 59.083 7.60717C59.083 8.41211 59.3513 9.06948 59.9014 9.56586C60.4514 10.0622 61.1624 10.3171 62.0747 10.3171C63.1211 10.3171 63.9931 10.0488 64.6908 9.49879C65.3884 8.94874 65.8445 8.18405 66.0458 7.2047L66.8373 3.24707C66.8641 3.11292 66.7568 2.99218 66.6226 2.99218Z"
            fill="#293745"
          />
          <path
            d="M88.6917 5.0314L89.0807 3.08612C89.0941 3.01904 89.0405 2.95197 88.9734 2.95197H88.4234C87.6587 2.95197 86.9879 3.16662 86.4378 3.60933C86.3708 3.663 86.2903 3.62275 86.2634 3.55567L86.1293 3.07271C86.1159 3.01904 86.0756 2.99221 86.0219 2.99221H84.6401C84.5865 2.99221 84.5462 3.03246 84.5328 3.08612L83.151 9.96837C83.1376 10.0354 83.1912 10.1025 83.2583 10.1025H85.3914C85.4451 10.1025 85.4853 10.0623 85.4987 10.0086L86.2366 6.35955C86.3305 5.93025 86.5049 5.60827 86.7464 5.42045C86.9879 5.23263 87.3233 5.12531 87.766 5.12531H88.5978C88.638 5.11189 88.6783 5.08506 88.6917 5.0314Z"
            fill="#293745"
          />
          <path
            d="M9.55245 0.69812C8.76093 0.69812 8.06331 0.818861 7.48644 1.06034C6.89615 1.30183 6.4266 1.67747 6.09121 2.18726C5.7424 2.69706 5.58141 3.31418 5.58141 4.02521C5.58141 4.91065 6.13145 5.5546 6.92298 5.90341C7.52669 6.15831 8.18406 6.25222 8.78776 6.46687C9.29756 6.64127 9.71344 7.01691 9.44513 7.58037C9.24389 8.00967 8.7341 8.21091 8.27796 8.21091C7.6206 8.21091 7.32545 7.64745 7.21812 7.3523C7.17788 7.24498 7.07055 7.19131 6.97664 7.21815L4.99112 7.6072C4.81672 7.63403 4.70939 7.79502 4.72281 7.96942C4.78989 8.58655 5.07161 9.27075 5.55458 9.67322C6.13145 10.1293 6.90956 10.3574 7.90233 10.3574C8.72068 10.3574 9.44513 10.2233 10.0757 9.95495C11.1758 9.4854 11.9673 8.45239 12.088 7.25839C12.1417 6.72176 12.0478 6.17172 11.766 5.71559C11.6185 5.4741 11.4307 5.27287 11.1892 5.11188C10.4647 4.6155 9.55245 4.54842 8.74751 4.23986C8.50603 4.14595 8.19747 4.02521 8.10356 3.7569C7.98282 3.40809 8.21089 3.05928 8.51945 2.89829C8.78776 2.76414 9.1634 2.72389 9.45855 2.79097C9.80735 2.87146 10.0086 3.13978 10.0488 3.46175C10.0623 3.58249 10.183 3.66299 10.3037 3.64957L12.2356 3.27393C12.3966 3.2471 12.5173 3.08611 12.5039 2.91171C12.3295 1.04693 10.4379 0.69812 9.55245 0.69812Z"
            fill="#293745"
          />
          <path
            d="M47.9617 2.99225H47.4787H46.7811C46.3921 2.99225 46.0433 3.19348 45.842 3.52888L44.0041 6.61448C43.9907 6.6279 43.9907 6.64132 43.9773 6.64132C43.9236 6.69498 43.8163 6.66815 43.8028 6.57424L43.2528 3.87768C43.1455 3.36789 42.7028 3.00566 42.1795 3.00566H41.1868C40.9051 3.00566 40.6904 3.27398 40.7575 3.55571L42.26 9.51227L41.9649 10.0087L41.4551 10.8673C41.3209 11.0819 41.2405 11.31 41.1868 11.5515C41.1734 11.6051 41.16 11.6722 41.16 11.7259C41.1197 12.0613 41.16 12.4101 41.2807 12.732C41.3075 12.8125 41.3478 12.9064 41.388 12.9869L41.5222 13.2552L41.7637 13.7114C41.8173 13.8053 41.9515 13.8321 42.0186 13.7516C42.032 13.7382 42.032 13.7382 42.0454 13.7248L42.3271 13.2687L42.6357 12.7589L43.2796 11.7259L43.387 11.5515L43.4675 11.4307L44.0175 10.5453L45.7884 7.72798L48.3239 3.68986C48.5118 3.36789 48.2971 2.99225 47.9617 2.99225Z"
            fill="#293745"
          />
          <path
            d="M55.6085 3.35444C55.8769 3.59592 56.0647 3.89107 56.1586 4.28012C56.1854 4.38745 56.2927 4.46794 56.4135 4.44111L58.2246 4.0923C58.399 4.06547 58.5063 3.90448 58.4929 3.73008C58.4258 3.18004 58.1307 2.02628 56.7623 1.15426C56.1586 0.778626 55.461 0.64447 54.6963 0.64447C53.9182 0.64447 53.1669 0.792042 52.4559 1.07377C51.7448 1.36892 51.1411 1.75797 50.6313 2.25435C50.1215 2.75073 49.7191 3.34102 49.4239 4.03864C49.1288 4.73625 48.9678 5.47412 48.9678 6.25223C48.9678 7.01692 49.1422 7.70112 49.4776 8.31825C50.4435 10.0489 52.6705 10.666 54.495 10.2367C56.0647 9.86105 58.1441 8.58656 58.4258 6.10466C58.4661 5.7961 58.1307 5.72902 57.9831 5.98391C56.8696 7.88894 53.7974 9.29759 52.1339 7.64746C51.007 6.53396 51.4094 5.0314 52.1607 4.07889C52.9254 3.11296 54.5889 2.469 55.6085 3.35444Z"
            fill="#293745"
          />
          <path
            d="M21.0093 2.99218H19.6409C19.5604 2.99218 19.4799 3.03242 19.4397 3.11292L19.2384 3.42148C19.158 3.55564 18.9835 3.56905 18.8628 3.47514C18.3396 3.00559 17.6822 2.77753 16.9041 2.77753C16.3407 2.77753 15.7906 2.88485 15.2674 3.11292C14.7442 3.32757 14.2881 3.63613 13.899 4.01177C13.5099 4.38741 13.2014 4.85696 12.9733 5.407C12.7452 5.95705 12.6245 6.53392 12.6245 7.16446C12.6245 8.11697 12.9197 8.88166 13.5099 9.45854C14.1002 10.0488 14.8515 10.3306 15.7504 10.3306C16.4882 10.3306 17.1724 10.1427 17.803 9.75369C17.9505 9.65978 18.1384 9.74027 18.1786 9.91468C18.2054 10.022 18.2993 10.1025 18.4201 10.1025H19.708C19.8287 10.1025 19.9226 10.022 19.9495 9.90126L21.2776 3.28732C21.2776 3.12634 21.1569 2.99218 21.0093 2.99218ZM17.7225 7.59376C17.3334 7.99623 16.8773 8.19746 16.3541 8.19746C15.9382 8.19746 15.6162 8.07672 15.3747 7.83524C15.1332 7.59376 15.0125 7.2852 15.0125 6.88273C15.0125 6.33269 15.2003 5.86314 15.5894 5.46066C15.9784 5.05819 16.4211 4.85696 16.9444 4.85696C17.3602 4.85696 17.6822 4.9777 17.9371 5.21918C18.192 5.46066 18.3128 5.78264 18.3128 6.1717C18.2993 6.72174 18.1115 7.19129 17.7225 7.59376Z"
            fill="#293745"
          />
          <path
            d="M98.512 47.814H65.7509C65.3887 49.0214 64.5569 50.0008 63.4971 50.5777L96.5533 49.8935C97.6132 49.8666 98.4986 49.1153 98.7133 48.0823C98.7535 47.9348 98.6462 47.814 98.512 47.814Z"
            fill="#FFD300"
          />
          <path
            d="M65.7508 47.814H32.9897V47.8274C32.6274 49.4642 30.8834 50.6447 29.9175 51.2753L48.297 50.8996L63.5104 50.5777C64.5702 49.9874 65.3886 49.008 65.7508 47.814Z"
            fill="#00CDFF"
          />
          <path
            d="M4.77632 47.814C3.16644 47.814 1.70413 48.6324 0.845525 49.9069C0.630874 50.2288 0.456471 50.5776 0.322314 50.9533C0.24182 51.1679 0.174742 51.3826 0.134495 51.6107C0.107664 51.7582 0.214989 51.879 0.362561 51.879L22.8473 51.4094L29.9173 51.2619C31.4333 50.7386 32.6273 49.4507 32.9895 47.814V47.8006H4.77632V47.814Z"
            fill="#6559FF"
          />
          <path
            d="M5.17897 23.545C6.54737 21.6266 8.25116 20.1375 10.2903 19.0776C12.3295 18.0178 14.5297 17.4946 16.9177 17.4946C19.9765 17.4946 22.3779 18.2593 24.1219 19.7752C25.5976 21.0631 26.5233 22.7938 26.899 24.9939C27.0063 25.6513 26.4965 26.2416 25.8391 26.2416H21.1571C20.6875 26.2416 20.285 25.933 20.1375 25.4903C19.896 24.7659 19.4935 24.189 18.9435 23.7194C18.2056 23.1157 17.1994 22.8072 15.9518 22.8072C14.1675 22.8072 12.6381 23.4511 11.377 24.7525C10.1159 26.0404 9.27075 27.8783 8.85487 30.2663C8.70729 31.0981 8.6268 31.8091 8.6268 32.4128C8.6268 34.1032 9.04269 35.4045 9.87446 36.3168C10.7062 37.229 11.8734 37.6718 13.3491 37.6718C15.6566 37.6718 17.4007 36.7058 18.5947 34.774C18.7825 34.4654 19.1313 34.2776 19.5069 34.2776H23.8939C24.7122 34.2776 25.2354 35.163 24.833 35.8741C23.7195 37.8193 22.2571 39.4158 20.4326 40.6635C18.1251 42.2465 15.4688 43.0246 12.4637 43.0246C9.33783 43.0246 6.84251 42.1392 4.99115 40.3683C3.12637 38.5975 2.20068 36.196 2.20068 33.1641C2.20068 32.2384 2.29459 31.2725 2.469 30.2663C2.8983 27.7039 3.81057 25.4635 5.17897 23.545Z"
            fill="#293745"
          />
          <path
            d="M69.4132 18.7154C70.5267 18.0714 71.6939 17.6689 72.9549 17.5348C73.505 17.4677 73.9745 17.9641 73.8672 18.5141L73.0489 23.1157C72.9549 23.6389 72.4988 24.028 71.9622 24.028H71.2377C69.3998 24.028 67.9106 24.4305 66.7971 25.2354C65.6836 26.0403 64.8787 27.3953 64.3689 29.3272C64.3689 29.3406 64.3689 29.354 64.3555 29.3674L62.1956 41.6696C62.1017 42.1794 61.6589 42.5551 61.1357 42.5551H57.0305C56.3597 42.5551 55.8634 41.9513 55.9707 41.294L59.9551 18.7556C60.0491 18.2324 60.5052 17.8433 61.0418 17.8433H65.0933C65.7775 17.8433 66.3008 18.4605 66.18 19.1313C66.0325 19.963 67.0252 20.5399 67.6692 19.9764C68.2326 19.4935 68.8095 19.0776 69.4132 18.7154Z"
            fill="#293745"
          />
          <path
            d="M95.9628 20.0301C97.8007 21.7742 98.7264 24.1085 98.7264 27.06C98.7264 28.0125 98.6459 28.8577 98.4984 29.6089C98.391 30.159 98.2703 30.6688 98.1495 31.1383C98.0288 31.6079 97.5995 31.9298 97.1165 31.9298C94.1383 31.9298 83.7277 31.9298 80.6957 31.9298C80.2128 31.9298 79.8103 32.3189 79.8103 32.8153V32.8287C79.8103 34.3715 80.2664 35.5923 81.1653 36.4644C82.0775 37.3364 83.2716 37.7791 84.7607 37.7791C86.0084 37.7791 87.0816 37.5108 87.9939 36.9473C88.7451 36.4912 89.3891 35.9143 89.8989 35.2167C90.1001 34.9484 90.4087 34.7874 90.7441 34.7874H95.131C95.9762 34.7874 96.4994 35.7265 96.0433 36.4375C94.9834 38.0877 93.5882 39.4963 91.8308 40.6635C89.5233 42.1929 86.9206 42.9575 83.996 42.9575C80.8433 42.9575 78.3212 42.0587 76.4161 40.2744C74.5111 38.4901 73.5586 36.0619 73.5586 33.0031C73.5586 32.1043 73.6525 31.1786 73.8269 30.1858C74.2696 27.6234 75.1819 25.383 76.5771 23.4511C77.9589 21.5193 79.6761 20.0301 81.7287 18.9837C83.7813 17.9373 86.0218 17.4275 88.4634 17.4275C91.6295 17.4275 94.1248 18.2995 95.9628 20.0301ZM92.3942 27.1002C92.3942 25.6781 91.9381 24.5512 91.0124 23.7597C90.0867 22.9548 88.8793 22.5523 87.4036 22.5523C85.8205 22.5523 84.4119 23.0487 83.1642 24.0414C82.3056 24.7256 81.608 25.5976 81.0982 26.6307C80.7628 27.3148 81.2592 28.1198 82.0239 28.1198H91.3612C91.9381 28.1332 92.3942 27.6771 92.3942 27.1002Z"
            fill="#293745"
          />
          <path
            d="M31.5809 23.4377C32.8956 21.5059 34.4921 20.0167 36.3837 18.9837C38.2753 17.9507 40.2608 17.4275 42.3671 17.4275C44.1916 17.4275 45.7345 17.8031 46.9955 18.541C47.5187 18.8496 47.9883 19.1984 48.4042 19.574C49.0213 20.1509 50.0409 19.8021 50.1885 18.9703L50.2287 18.7154C50.3226 18.2056 50.7653 17.83 51.2885 17.83H55.3535C56.0243 17.83 56.5207 18.4337 56.4133 19.091L52.4557 41.6294C52.3618 42.1392 51.9191 42.5148 51.3959 42.5148H46.6199C46.3247 42.5148 46.1101 42.2465 46.1638 41.9648C46.3382 41.0391 45.3051 40.3415 44.5136 40.8781C44.0307 41.2001 43.494 41.5086 42.9306 41.7904C41.3743 42.5417 39.684 42.9307 37.8594 42.9307C36.0617 42.9307 34.4653 42.5282 33.07 41.7099C31.6882 40.8915 30.6015 39.7378 29.8369 38.2352C29.0722 36.7327 28.6831 34.9886 28.6831 33.0031C28.6831 32.0774 28.777 31.1115 28.9514 30.1053C29.3941 27.5832 30.2662 25.3696 31.5809 23.4377ZM48.3908 28.4686C48.3908 26.7111 47.881 25.3427 46.8614 24.3634C45.8418 23.3841 44.5673 22.8877 43.0245 22.8877C41.8707 22.8877 40.7572 23.1694 39.684 23.7329C38.6107 24.2963 37.6716 25.1281 36.8667 26.2014C36.0752 27.288 35.5385 28.5759 35.2702 30.0517C35.1763 30.6151 35.1361 31.1517 35.1361 31.6615C35.1361 33.4458 35.6324 34.8411 36.6386 35.8741C37.6448 36.8937 38.9059 37.4169 40.4487 37.4169C41.6024 37.4169 42.7159 37.1217 43.816 36.5448C44.9027 35.968 45.8418 35.1228 46.6333 34.0227C47.4114 32.9226 47.9346 31.6347 48.2029 30.1456C48.3371 29.3943 48.3908 28.8308 48.3908 28.4686Z"
            fill="#293745"
          />
        </g>
      </>
    </Styled.LogoSvg>
  );
};

interface LogoProps extends ContainerProps {
  className?: string;
  color?: TStyleConnotation;
  href?: string;
  width: number;
  height: number;
  size: TStyleSize;
}

Logo.defaultProps = {
  height: 52,
  width: 99,
  size: "md",
};

export type LogoColors = "inherit" | "primary" | "neutral" | "white";

export default Logo;
