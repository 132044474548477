import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useFlag, useFlagsStatus } from "@unleash/proxy-client-react";

import { AuthContext, LocationContext } from "../../contexts";
import authServices from "../../services/auth/auth.service";
import { recordAnalyticsEvent } from "../../analytics";
import { UserNotFoundError } from "../../services/auth/auth.errors";
import { CircularProgress } from "../../components/util";
import LoginErr from "../../components/content/maintenance/login";

const AuthorizeLogin = () => {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const { flagsReady } = useFlagsStatus();
  const redirect = useFlag("portal.scheme_redirect");
  const { urlParams } = useContext(LocationContext);

  let code: string = urlParams?.code || "";
  let state: string = urlParams?.state || "";

  const [isError, setError] = useState<boolean>(false);

  useEffect(() => {
    if (flagsReady) {
      const verifyLogin = async () => {
        await authServices.verify(code, state);
        try {
          const user = await authServices.getMittiUser();
          auth.updateUser(user);
        } catch (error: any) {
          if (error instanceof UserNotFoundError) {
            history.replace("/sign-up");
          } else {
            throw error;
          }
        }
      };

      /**
       * This condition is needed to prevent it from running verifyLogin after verification
       * */
      if (!auth.user) {
        verifyLogin().catch(_error => {
          setError(true);
        });
      } else {
        recordAnalyticsEvent("identify", "Logged In", auth.user);

        recordAnalyticsEvent("action", "Logged In", {
          type: auth.user.type,
        });

        if (
          redirect &&
          auth.user.organisations.length > 0 &&
          auth.user.roles.includes("customer")
        ) {
          window.location.assign(process.env.REACT_APP_IAUDITOR_LINK as string);
        } else {
          history.replace("/");
        }
      }
    }
  }, [auth, auth.user, code, history, state, flagsReady, redirect]);

  return !isError ? (
    <CircularProgress />
  ) : (
    <LoginErr auto_redirect_to_login={true} />
  );
};

export default AuthorizeLogin;
