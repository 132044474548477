import React, {
  ReactChildren,
  ReactElement,
  ReactFragment,
  ReactNode,
  ReactPortal,
} from "react";
import {
  Alignment,
  ComponentProps,
  Elevation,
  Placement,
  TButtonType,
} from "../../../types";
import Marker from "../marker";
import * as Styled from "./styles.css";

const Container = React.forwardRef(
  (
    {
      children,
      childAppend,
      childPrepend,
      componentType,
      complete,
      constrained,
      corners,
      padding,
      dangerousChildren,
      disabled,
      element,
      elevation,
      onClick,
      outlined,
      markerAlignment,
      markerPlacement,
      ...otherProps
    }: ContainerProps,
    ref,
  ) => {
    const childrenByProps = (children: ReactNode): ReactElement => {
      return (
        <>
          {markerPlacement && (
            <Marker
              role="status"
              placement={markerPlacement}
              alignment={markerAlignment}
            />
          )}
          {childPrepend && childPrepend}
          {children && children}
          {childAppend && childAppend}
        </>
      );
    };

    const compiledChildren: ReactElement = childrenByProps(
      dangerousChildren
        ? React.Children.toArray(children).filter((child: ReactNode) => !!child)
        : children,
    );
    const setSafeOrDangerousChildren: ISOrDChildren = {
      dangerouslySetInnerHTML:
        children && dangerousChildren
          ? {
              __html: compiledChildren.props.children.filter(
                (children: ReactNode) => !!children,
              ),
            }
          : undefined,
      children: dangerousChildren ? undefined : compiledChildren,
    };

    return (
      // Disabled linter to pass children or dangerousChildren
      // eslint-disable-next-line react/no-danger-with-children
      <Styled.Container
        as={element}
        disabled={disabled}
        complete={complete}
        onClick={onClick}
        type={componentType}
        ref={ref}
        $constrained={constrained}
        $corners={corners}
        $padding={padding}
        $disabled={disabled}
        $elevation={elevation}
        $outlined={outlined}
        $markerAlignment={markerAlignment}
        $markerPlacement={markerPlacement}
        {...setSafeOrDangerousChildren}
        {...otherProps}
      />
    );
  },
);

export interface ContainerProps extends ComponentProps {
  childAppend?: any;
  childPrepend?: any;
  complete?: boolean;
  constrained?: boolean;
  corners?: "square" | "rounded" | "rounded-sm";
  padding?: boolean;
  elevation?: Elevation;
  disabled?: boolean;
  htmlFor?: string;
  markerPlacement?: Placement;
  markerAlignment?: Alignment;
  name?: string;
  onClick?: any;
  outlined?: boolean;
  role?: string;
  componentType?: TButtonType;
}

Container.defaultProps = {
  element: "div",
  constrained: false,
  corners: "square",
  disabled: false,
  elevation: "flat",
  outlined: false,
};

type ISOrDChildren = {
  children?: true | ReactChildren | ReactFragment | ReactPortal | ReactNode;
  dangerouslySetInnerHTML?: Record<any, any>;
};

export default Container;
