import styled from "styled-components";
import { Container, IconButton } from "../../../util";
import { theme } from "../../../../styles";
import { buildBoxShadow } from "../../../../helpers/styles";

export const Close = styled(IconButton).attrs((props: any) => ({
  $visibility: props.$visibility,
}))`
  margin-left: -32px;
  z-index: 1;
  ${(props: any) =>
    props.$visibility &&
    `
      display:none;
  `};
`;

export const SearchIcon = styled(IconButton).attrs((props: any) => ({}))`
  margin-right: -35px;
  z-index: 1;
`;

export const SearchTextfield = styled("input").attrs((props: any) => ({}))`
  padding-left: 40px !important;
  padding-bottom: 6px;
  font-size: 16px;
  padding-top: 6px;
  width: 30%;
  border: 1px solid ${theme.color.neutral.border.default.default};
  border-radius: 6px;
  box-shadow: ${buildBoxShadow("low")};

  &:not(:placeholder-shown) {
    outline: none;
    padding-bottom: 5px;
    padding-top: 5px;
    width: 70%;
    border: 2px solid ${theme.color.accent.border.default.pressed};
  }

  &:focus {
    outline: 0px;
    width: 70%;
    padding-bottom: 5px;
    padding-top: 5px;
    border: 2px solid ${theme.color.accent.border.default.pressed};
  }
`;

export const FormContainer = styled(Container).attrs((props: any) => ({}))`
  display: flex;
`;
