import { AxiosRequestConfig, AxiosInstance } from "axios";
import authService from "../services/auth/auth.service";

const registerAuthInterceptor = (axios: AxiosInstance) => {
  axios.interceptors.request.use(
    (config: AxiosRequestConfig): AxiosRequestConfig => {
      try {
        const result = authService.getCurrentSession();
        config.headers = {
          ...config.headers,
          ...{
            Authorization: `Bearer ${result.access_token}`,
          },
        };
        return config;
      } catch {
        // For the non logged in case
        return config;
      }
    },
  );

  axios.interceptors.response.use(
    response => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        await authService.refreshAuthToken();
        const result = authService.getCurrentSession();

        axios.defaults.headers.common["Authorization"] =
          "Bearer " + result.access_token;
        return axios(originalRequest);
      }
      return Promise.reject(error);
    },
  );
};

export default registerAuthInterceptor;
