import { useContext } from "react";
import { TabsContext } from "../../../contexts";
import { ContainerProps } from "../container/container";
import * as Styled from "./styles.css";

const TabPanel = ({ children, id, spaced, ...otherProps }: TabPanelProps) => {
  const { currentTab, orientation, variant } = useContext(TabsContext);

  return (
    <Styled.TabPanel
      item
      xs={orientation === "horizontal" ? 12 : true}
      role="tabpanel"
      hidden={currentTab !== id}
      $orientation={orientation}
      $spaced={spaced}
      $variant={variant}
      {...otherProps}
    >
      {currentTab === id && children}
    </Styled.TabPanel>
  );
};

interface TabPanelProps extends ContainerProps {
  id: string;
  spaced?: boolean;
}

TabPanel.defaultProps = {
  spaced: false,
};

export default TabPanel;
