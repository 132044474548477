import styled from "styled-components";
import { Container } from "..";
import { theme } from "./../../../styles/themes";

export const Footer = styled(Container).attrs((props: any) => ({}))`
  margin-top: 48px;
  padding-left: 20px;
  padding-right: 20px;

  p {
    padding-bottom: 20px;
    padding-top: 20px;
    color: ${theme.color.surface.text.default.disabled};
  }
`;
