import styled from "styled-components";
import { theme } from "./../../../../styles";
import { buildBoxShadow } from "./../../../../helpers";
import JfsInput from "./../jfsInput/jfsInput";

export const InputControl = styled("div").attrs((props: any) => ({
  $grow: props.$grow,
  $size: props.$size,
  $type: props.$type,
}))`
  position: relative;

  ${(props: any) => {
    if (props.$grow) {
      return `
        flex-grow: 1;
      `;
    }
  }}

  ${(props: any) => {
    switch (props.$type) {
      case "password":
        return `
          input {
            padding-right: 56px;
          }
          button {
            position: absolute;
            right: 10px;
            top: ${props.$size === "md" ? "2px" : "4px"};
          }
        `;
    }
  }}
`;

export const TextField = styled(JfsInput).attrs((props: any) => ({
  $error: props.$error,
  $size: props.$size,
  $editable: props.$editable,
  $type: props.$type,
}))`
  border-radius: 6px;
  ${(props: any) => {
    return `
      padding:
        ${theme.input.text.padding.vertical[props.$size]}px
        ${theme.input.text.padding.horizontal[props.$size]}px;
    `;
  }}

  ${(props: any) => {
    if (props.$editable) {
      return `
        border: 1px solid ${theme.color.surface.border.weak.default};
        box-shadow: ${buildBoxShadow("low")};
      `;
    } else {
      return `border: 1px solid ${theme.color.surface.border.weak.disabled};`;
    }
  }}

  &[type="password"] {
    letter-spacing: 1.75px;
  }

  &[type="email"],
  &[type="number"],
  &[data-format="phone-au"],
  &[data-format="dollar"] {
    padding-left: ${(props: any) =>
      theme.input.text.padding.horizontal[props.$size] + 20}px;
    &:focus,
    &[data-error] {
      padding-left: ${(props: any) =>
        theme.input.text.padding.horizontal[props.$size] + 20 - 1}px;
    }
  }

  :focus,
  &[data-error] {
    border-width: 2px;
    ${(props: any) => {
      return `
        padding:
          ${theme.input.text.padding.vertical[props.$size] - 1}px
          ${theme.input.text.padding.horizontal[props.$size] - 1}px;
      `;
    }}
  }

  :focus {
    border-color: ${theme.color.accent.border.default.default};
  }

  &[data-error] {
    background-color: ${theme.color.negative.background.weakest.default};
    border-color: ${theme.color.negative.border.default.default};
    &:focus {
      background-color: ${theme.color.surface.background.default.default};
    }
  }

  &:disabled {
    background-color: ${theme.color.surface.background.default.disabled};
    border-color: ${theme.color.surface.border.weak.disabled};
    box-shadow: ${buildBoxShadow("flat")};
    color: ${theme.color.surface.text.default.disabled};
  }
`;
