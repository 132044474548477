import { ListMissing } from "../..";
import { Scores } from "../../../../services/scores/scores.service";
import { Grid } from "../../../util";
import ScoreMeter from "./scoreMeter";

const SelfAssessmentMeter = ({
  data,
  footnote,
  isError,
  isLoading,
  titleVariant,
}: SelfAssessmentMeterProps) => {
  if (isLoading) {
    return (
      <ScoreMeter
        isLoading={isLoading}
        title="Self assessment score"
        titleVariant={titleVariant}
      />
    );
  } else if (isError || data == null) {
    return (
      <ListMissing
        icon="pulse"
        message="You don't have any score information at the moment, please check back later."
        type="information"
      />
    );
  }

  return (
    <Grid container>
      <ScoreMeter
        score={data && { ...data }}
        title="Self assessment score"
        titleVariant={titleVariant}
        footnote={footnote && footnote}
      />
    </Grid>
  );
};

interface SelfAssessmentMeterProps {
  data?: Scores;
  displayMessage?: boolean;
  footnote?: React.ReactNode;
  isError: boolean;
  isLoading?: boolean;
  titleVariant: "heading" | "section";
}

SelfAssessmentMeter.defaultProps = {
  titleVariant: "section",
  isLoading: false,
};
export default SelfAssessmentMeter;
