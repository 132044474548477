import {
  Alert,
  ArrowLeft,
  Book,
  Calendar,
  Check,
  CheckCircle,
  CheckCircleFill,
  Checklist,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronDown,
  Circle,
  Report,
  Dependabot,
  DeviceCameraVideo,
  Dot,
  DotFill,
  Duplicate,
  Eye,
  EyeClosed,
  File,
  FileDirectoryFill,
  FileBadge,
  Flame,
  Gear,
  Globe,
  Graph,
  History,
  Home,
  Info,
  KebabHorizontal,
  LinkExternal,
  ListUnordered,
  Mail,
  Organization,
  Pencil,
  People,
  Person,
  Plus,
  Pulse,
  Search,
  SignOut,
  Shield,
  ShieldCheck,
  Star,
  Stop,
  Sync,
  X,
  XCircleFill,
  Tasklist,
  Verified,
  Tools,
} from "@styled-icons/octicons";
import { IAuditorIcon } from "../../../assets/images";
import {
  ComponentProps,
  TIconShape,
  TStyleConnotation,
  TStyleShade,
  TStyleSize,
} from "../../../types";
import * as Styled from "./styles.css";

const Icon = ({ children, ...otherProps }: IconProps) => {
  return (
    <Styled.Icon element="span" {...otherProps}>
      {setIcon(otherProps.icon)}
    </Styled.Icon>
  );
};

export interface IconProps extends ComponentProps {
  color?: TStyleConnotation;
  shade?: TStyleShade;
  icon: TIconShape;
  size?: TStyleSize;
  role?: string;
}

Icon.defaultProps = {};

const setIcon = (icon: TIconShape) => {
  switch (icon) {
    case "alert":
      return <Alert />;
    case "arrow-left":
      return <ArrowLeft />;
    case "book":
      return <Book />;
    case "calendar":
      return <Calendar />;
    case "check":
      return <Check />;
    case "check-circle":
      return <CheckCircle />;
    case "check-circle-fill":
      return <CheckCircleFill />;
    case "checklist":
      return <Checklist />;
    case "chevron-left":
      return <ChevronLeft />;
    case "chevron-right":
      return <ChevronRight />;
    case "chevron-up":
      return <ChevronUp />;
    case "chevron-down":
      return <ChevronDown />;
    case "circle":
      return <Circle />;
    case "dependabot":
      return <Dependabot />;
    case "deviceCameraVideo":
      return <DeviceCameraVideo />;
    case "dot":
      return <Dot />;
    case "dot-fill":
      return <DotFill />;
    case "duplicate":
      return <Duplicate />;
    case "eye":
      return <Eye />;
    case "eye-closed":
      return <EyeClosed />;
    case "file":
      return <File />;
    case "file-badge":
      return <FileBadge />;
    case "file-directory-fill":
      return <FileDirectoryFill />;
    case "flame":
      return <Flame />;
    case "gear":
      return <Gear />;
    case "globe":
      return <Globe />;
    case "graph":
      return <Graph />;
    case "history":
      return <History />;
    case "home":
      return <Home />;
    case "iauditor":
      return <IAuditorIcon />;
    case "info":
      return <Info />;
    case "kebab-horizontal":
      return <KebabHorizontal />;
    case "link-external":
      return <LinkExternal />;
    case "list-unordered":
      return <ListUnordered />;
    case "mail":
      return <Mail />;
    case "organization":
      return <Organization />;
    case "pencil":
      return <Pencil />;
    case "people":
      return <People />;
    case "person":
      return <Person />;
    case "plus":
      return <Plus />;
    case "pulse":
      return <Pulse />;
    case "report":
      return <Report />;
    case "search":
      return <Search />;
    case "sign-out":
      return <SignOut />;
    case "shield":
      return <Shield />;
    case "shield-check":
      return <ShieldCheck />;
    case "star":
      return <Star />;
    case "stop":
      return <Stop />;
    case "sync":
      return <Sync />;
    case "tasklist":
      return <Tasklist />;
    case "verified":
      return <Verified />;
    case "x":
      return <X />;
    case "x-circle-fill":
      return <XCircleFill />;
    case "tools":
      return <Tools />;
    default:
      return <Eye />;
  }
};

export default Icon;
