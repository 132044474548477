import React from "react";
import { DeepdiveBestPracticesInterface } from "../../../services/prismic/prismic.service";
import { Box, makeStyles } from "@material-ui/core";
import { IllustrationMeasuringPost } from "../../../assets/illustrations";
import { Card, Button, Grid, Typography } from "../../../components/util";
import Point from "./point";
import { theme } from "../../../styles";
import * as Styled from "./styles.css";

interface BestPracticesPropsInterface {
  best?: DeepdiveBestPracticesInterface;
}

const useStyles = makeStyles(() => ({
  img: {
    maxHeight: "200px",
  },
  imgContainer: {
    textAlign: "center",
    margin: `${theme.spacing} 0`,
  },
  buttonContainer: {
    textAlign: "center",
    marginTop: theme.spacing,
  },
}));

const BestPractices = ({ best }: BestPracticesPropsInterface) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Card isInGrid isPadded>
        <Typography variant="displaySmall">
          Tips to ensure workplace safety
        </Typography>
        <Box className={classes.imgContainer}>
          <Styled.Image
            src={IllustrationMeasuringPost}
            alt={"analytics illustration"}
          />
        </Box>
        <Box>
          {best &&
            best.points.map((point, i) => <Point point={point} key={i} />)}
        </Box>
        {best && (
          <Box className={classes.buttonContainer}>
            <Button href={best.button.link} color="accent" fullWidth>
              {best.button.text}
            </Button>
          </Box>
        )}
      </Card>
    </Grid>
  );
};

export default BestPractices;
