import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import queryString from "query-string";
import { Hidden } from "@material-ui/core";
import claimService from "../../../services/claim/claim.service";
import { IClaim, IClaimContact } from "../../../types";
import {
  capitiliseFirsrtChar,
  commaSeparated,
  formatDateTime,
} from "../../../helpers";
import {
  Link,
  TableRow,
  TableCell,
  TableRowSubhead,
  Chip,
  Typography,
  Icon,
  CircularProgress,
} from "../../../components/util";
import { RecordMissing, Summary } from "../../../components/content";
import { Header, Section } from "../../../components/layout";
import * as Styled from "./styles.css";
import { ClaimContactType } from "../create/claimSchema";

const ClaimDetails = () => {
  const location = useLocation<any>();
  const { claimId } = useParams<IParams>();
  const query = queryString.parse(location.search);
  const backText = query?.backText as string;

  const newDate = new Date(Date.now());

  const {
    isLoading,
    isError,
    data: claimData,
  } = useQuery<IClaim | null | undefined>(["claims-claim", claimId], async () =>
    claimService.get_by_claim_id(claimId),
  );

  if (isLoading) {
    return <CircularProgress contained />;
  } else if (isError || claimData == null) {
    return (
      <RecordMissing
        icon="shield"
        message="Please check the claim number or navigate back to your claims."
        type="claim"
        back={{
          title: "Back to Claims",
          path: "/claims",
        }}
      />
    );
  }

  const claimFormData = claimData?.versions[0].claim_data;
  let contact_data: IClaimContact = {
    contact_name: "",
    phone_number: "",
    email: "",
  };
  if (claimFormData?.create) {
    contact_data = claimService.extractContactData(claimFormData);
  }

  return (
    <>
      <Styled.Grid>
        <Header
          title={`#${claimData.claim_number}`}
          titleIcon="file-directory-fill"
          titleIconPosition="end"
          type="article"
          withLink={{ path: "/claims", text: "All Claims" }}
        >
          <Hidden xsDown>
            <Styled.SubHeader element="span" variant="heading">
              {claimData?.versions[0].claim_data.create?.claim?.insured_name}
            </Styled.SubHeader>
          </Hidden>
        </Header>

        <Summary
          data={[
            {
              Status: <Chip>{"Review"}</Chip>,
            },
            {
              Type: claimData?.versions[0].claim_data.create?.issue?.claim_type,
            },
            {
              "Incident date": formatDateTime(
                claimData?.versions[0].claim_data.details?.incident_date,
                "verbose",
              ),
            },
            {
              Updated: formatDateTime(newDate.toISOString(), "verbose"),
            },
          ]}
        />

        <Section>
          <Header title="Claim" type="section" />

          <Styled.InfoAlert defaultIcon={false} isFullWidth>
            <Typography paragraph variant="heading">
              <Icon icon="info" color="information" /> Your claim is in review.
            </Typography>
            <Typography paragraph>
              This typically happens within 24 hours, and you’ll get an email
              from us soon.
            </Typography>
            <Typography paragraph>
              If you have any questions please contact us at{" "}
              <Link href={`mailto:${process.env.REACT_APP_CARE_CLAIMS_EMAIL}`}>
                {process.env.REACT_APP_CARE_CLAIMS_EMAIL}
              </Link>
            </Typography>
          </Styled.InfoAlert>

          <Styled.Table
            columns={[
              { title: "", expand: true },
              { title: "", expand: true },
            ]}
            orientation="horizontal"
          >
            <TableRowSubhead>Overview</TableRowSubhead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>
                <Chip>Review</Chip>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>
                {claimData?.versions[0].claim_data.create?.issue?.claim_type}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Incident date</TableCell>
              <TableCell>
                {formatDateTime(
                  claimData?.versions[0].claim_data.details?.incident_date,
                  "verbose",
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Created</TableCell>
              <TableCell>
                {formatDateTime(newDate.toISOString(), "verbose")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Updated</TableCell>
              <TableCell>
                {formatDateTime(newDate.toISOString(), "verbose")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell textOverflow>Summary</TableCell>
              <TableCell textOverflow>
                {claimData?.versions[0].claim_data.create?.issue?.summary}
              </TableCell>
            </TableRow>
            <TableRowSubhead>Details</TableRowSubhead>
            <TableRow>
              <TableCell>Estimated loss</TableCell>
              <TableCell>
                ${" "}
                {
                  claimData?.versions[0].claim_data.details?.what
                    ?.estimated_loss
                }
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell textOverflow>Officials Involved</TableCell>
              <TableCell textOverflow>
                {commaSeparated(
                  claimData?.versions[0].claim_data.details
                    ?.officials_involved || [""],
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell textOverflow>Special instructions</TableCell>
              <TableCell textOverflow>
                {
                  claimData?.versions[0].claim_data.details
                    ?.special_instructions?.anything_else
                }
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell textOverflow>Affected items</TableCell>
              <TableCell textOverflow>
                {
                  claimData?.versions[0].claim_data.details?.what
                    ?.affected_items
                }
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell textOverflow>Affected persons</TableCell>
              <TableCell textOverflow>
                {claimData?.versions[0].claim_data.details?.who?.how}
              </TableCell>
            </TableRow>

            <TableRowSubhead>Crinminal History</TableRowSubhead>
            <TableRow>
              <TableCell textOverflow>
                Has your client been charged with, or convicted of, any criminal
                offence in the last 10 years?
              </TableCell>
              <TableCell>
                {
                  claimData?.versions[0].claim_data.declarations
                    ?.charges_and_history?.criminal_offences
                }
              </TableCell>
            </TableRow>
            <TableRowSubhead>Insurance History</TableRowSubhead>
            <TableRow>
              <TableCell textOverflow>
                Has any insurer refused or cancelled cover or required special
                terms to insure your client?
              </TableCell>
              <TableCell>
                {
                  claimData?.versions[0].claim_data.declarations
                    ?.charges_and_history?.refused_cancelled
                }
              </TableCell>
            </TableRow>
          </Styled.Table>
        </Section>

        <Section>
          <Header title="Policy" type="section" />
          <Styled.Table
            columns={[
              { title: "", expand: true },
              { title: "", expand: true },
            ]}
            orientation="horizontal"
          >
            <TableRowSubhead>Policy details</TableRowSubhead>
            <TableRow>
              <TableCell textOverflow>Insured name</TableCell>
              <TableCell textOverflow>
                {claimData?.versions[0].claim_data.create.claim.insured_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell textOverflow>Policy number</TableCell>
              <TableCell textOverflow>
                <Link href={`/policies/${claimData?.policy_id}`} router>
                  {claimData?.versions[0].claim_data.create.claim.policy_number}
                </Link>
              </TableCell>
            </TableRow>
          </Styled.Table>
        </Section>

        <Section>
          <Header title="Contact" type="section" />
          <Styled.Table
            columns={[
              { title: "", expand: true },
              { title: "", expand: true },
            ]}
            orientation="horizontal"
          >
            <TableRow>
              <TableCell textOverflow>Contact name</TableCell>
              <TableCell textOverflow>{contact_data.contact_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell textOverflow>Contact email</TableCell>
              <TableCell textOverflow>{contact_data.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Contact number</TableCell>
              <TableCell>{contact_data.phone_number}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Contact method (preferred)</TableCell>
              <TableCell>
                {capitiliseFirsrtChar(
                  claimData?.versions[0].claim_data.create?.claim
                    ?.preferred_contact_method,
                )}
              </TableCell>
            </TableRow>
          </Styled.Table>
        </Section>

        <Section>
          <Header title="Associated documents" type="section" />
          <Typography
            variant="subtitle1"
            styled={{ text: { align: "center" } }}
          >
            <Typography element="strong" styled={{ text: { weight: "heavy" } }}>
              No documents yet.{" "}
            </Typography>
          </Typography>
        </Section>

        <Section>
          <Header title="Declarations" type="section" />
          <Styled.Table
            columns={[
              { title: "", expand: true },
              { title: "", expand: true },
            ]}
            orientation="horizontal"
          >
            <TableRow>
              <TableCell textOverflow>
                I acknowledge that I have read and agree to the privacy consent
                and authorisation.
              </TableCell>
              <TableCell textOverflow>
                {claimData?.versions[0].claim_data.declarations?.declarations
                  ?.privacy_consent
                  ? "Yes"
                  : "No"}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell textOverflow>
                I declare all the above details are true in every respect to the
                best of my knowledge.
              </TableCell>
              <TableCell textOverflow>
                {claimData?.versions[0].claim_data.declarations?.declarations
                  .truth
                  ? "Yes"
                  : "No"}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell textOverflow>
                I acknowledge that a claim may be refused and/or the policy may
                be cancelled if that answers or information provided is untrue,
                inaccurate, or concealed.
              </TableCell>
              <TableCell>
                {claimData?.versions[0].claim_data.declarations?.declarations
                  ?.refusability
                  ? "Yes"
                  : "No"}
              </TableCell>
            </TableRow>
          </Styled.Table>
        </Section>
      </Styled.Grid>
    </>
  );
};

interface IParams {
  claimId: string;
}

export default ClaimDetails;
