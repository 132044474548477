import { useHistory } from "react-router-dom";
import { pagix, PagixReturn } from "pagix";
import Combobox from "../combobox/combobox";
import * as Styled from "./styles.css";

export default function Pagination({
  pager,
  param,
  searchTerm,
  records,
  pageLimitsAllowed,
  pageLimit,
  onLimitChange,
  prevKey,
  nextKey,
}: IPaginationProps) {
  const history = useHistory();

  const handleLimitChange = (selectedValue: string | number) => {
    onLimitChange(selectedValue);
    history.replace(
      `?${param}=1${searchTerm ? "&searchTerm=" + searchTerm : ""}${
        history.location.hash
      }`,
    );
  };

  return (
    <Styled.PaginationContainer>
      <Styled.NavContainer>
        <Styled.NavLink
          href={`?${param}=${pager.current - 1}${
            searchTerm ? "&searchTerm=" + searchTerm : ""
          }${history.location.hash}`}
          icon="chevron-left"
          disabled={pager.current === 1}
          router
        />
        <Styled.NavLink
          href={`?${param}=${pager.current + 1}${
            searchTerm ? "&searchTerm=" + searchTerm : ""
          }${history.location.hash}`}
          icon="chevron-right"
          disabled={pager.current >= pager.total}
          router
        />
      </Styled.NavContainer>

      <Styled.ControlContainer>
        <Styled.ShowingTypography styled={{ text: { shade: "weak" } }}>
          Showing {pager.from} - {pager.to} of {records}
        </Styled.ShowingTypography>

        <Combobox
          id="pagination-limit"
          value={pageLimit}
          onChange={handleLimitChange}
          options={Object.values(pageLimitsAllowed).map((limit: any) => {
            return {
              label: `${limit} per page`,
              value: limit,
            };
          })}
          size="sm"
        />
      </Styled.ControlContainer>
    </Styled.PaginationContainer>
  );
}

export const createPager = (total: number, limit: number, current: number) =>
  pagix({ records: total, limit, current });

export const createSlice = (items: Record<any, any>[], pager) =>
  items?.slice(pager.from - 1, pager.to);

interface IPaginationProps {
  pager: PagixReturn;
  param: string;
  searchTerm?: string;
  records: number;
  pageLimitsAllowed: number[];
  pageLimit: number;
  onLimitChange(value): any;
  prevKey?: string;
  nextKey?: string;
}
