import { ReactHTMLElement } from "react";
import clsx from "clsx";
import { GridSize, GridProps as MuiGridProps } from "@material-ui/core";
import * as Styled from "./styles.css";

export interface GridProps extends Omit<MuiGridProps, ""> {
  element?: string | ReactHTMLElement<any>;
  classes?: any;
  xxl?: boolean | GridSize; // Beware that in fact, only sizes 6 and 12 are supported. See styles.css.ts
}

const Grid = ({
  children,
  classes,
  className,
  xxl,
  ...otherProps
}: GridProps) => {
  return (
    <Styled.Grid
      className={clsx(
        { [classes?.root]: classes?.root, [`MuiGrid-grid-xxl-${xxl}`]: xxl },
        className,
      )}
      {...otherProps}
    >
      {children && children}
    </Styled.Grid>
  );
};

Grid.defaultProps = {
  element: "div",
};
export default Grid;
