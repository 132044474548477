import * as React from "react";
import { Chip, Typography } from "../../components/util";

/**
 * Only types "customer" and "broker" render in a chip.
 * Other types render as plain text.
 */
interface Props {
  org_type: "customer" | "broker" | string;
}

/**
 *
 * @param param0
 * @returns
 */
export const OrgTypeChip: React.FC<Props> = ({ org_type }) => {
  if (org_type === "customer" || org_type === "broker") {
    return (
      <Chip
        color={org_type === "broker" ? "information" : "surface"}
        size="xs"
        data-testid="org-type-chip"
      >
        <Typography styled={{ text: { case: "title" } }}>{org_type}</Typography>
      </Chip>
    );
  }
  return (
    <Typography styled={{ text: { case: "title" } }}>{org_type}</Typography>
  );
};

export default OrgTypeChip;
