import { WidgetProps } from "@rjsf/utils";
import Icon from "../../icons/icon";
import Label from "../../label/label";
import * as Styled from "./styles.css";

const JfsCheckBox = ({
  onChange,
  id,
  title,
  type,
  value,
  ...otherProps
}: JfsCheckBoxProps) => {
  const handleChange = (e?: any) => {
    if (otherProps.multiple) {
      onChange(title);
    } else {
      onChange(!value);
    }
  };

  return (
    <Styled.JfsInlineCheckboxContainer>
      <Styled.JfsCheckboxContainer>
        <Styled.JfsCheckbox
          id={id}
          checked={value}
          value={value}
          onChange={(event: any) => {
            handleChange();
          }}
          type={"checkbox"}
          {...otherProps}
          name={title}
        />
        {value && <Icon shade="inverse" icon="check" size="sm" />}
      </Styled.JfsCheckboxContainer>
      <Label htmlFor={id} title={title || otherProps.label} inline />
    </Styled.JfsInlineCheckboxContainer>
  );
};

interface JfsCheckBoxProps extends WidgetProps {}

JfsCheckBox.defaultProps = {};

export default JfsCheckBox;
