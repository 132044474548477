import { ITheme } from "../../types";
import { palette } from "../palettes";

const sccareTheme: ITheme = {
  color: {
    surface: {
      background: {
        default: {
          default: palette.grey["white"],
          disabled: palette.grey[5],
          hover: palette.grey[5],
          pressed: palette.grey[10],
          placeholder: palette.grey[10],
        },
        strong: {
          default: palette.grey[40],
          disabled: palette.grey[40],
          hover: palette.grey[50],
          pressed: palette.grey[60],
          placeholder: palette.grey[40],
        },
        stronger: {
          default: palette.grey[50],
          disabled: palette.grey[50],
          hover: palette.grey[60],
          pressed: palette.grey[70],
          placeholder: palette.grey[50],
        },
        strongest: {
          default: palette.grey[60],
          disabled: palette.grey[60],
          hover: palette.grey[70],
          pressed: palette.grey[80],
          placeholder: palette.grey[60],
        },
        weak: {
          default: palette.grey[20],
          disabled: palette.grey[30],
          hover: palette.grey[30],
          pressed: palette.grey[40],
          placeholder: palette.grey[30],
        },
        weaker: {
          default: palette.grey[10],
          disabled: palette.grey[20],
          hover: palette.grey[20],
          pressed: palette.grey[30],
          placeholder: palette.grey[20],
        },
        weakest: {
          default: palette.grey[5],
          disabled: palette.grey[10],
          hover: palette.grey[10],
          pressed: palette.grey[20],
          placeholder: palette.grey[10],
        },
        inverse: {
          default: palette.grey[100],
          disabled: palette.grey[50],
          hover: palette.grey[90],
          pressed: palette.grey[80],
          placeholder: palette.grey[50],
        },
      },
      text: {
        default: {
          default: palette.grey[100],
          disabled: palette.grey[50],
          hover: palette.grey[100],
          pressed: palette.grey[100],
          placeholder: palette.grey[30],
        },
        strong: {
          default: palette.grey[70],
          disabled: palette.grey[50],
          hover: palette.grey[80],
          pressed: palette.grey[90],
          placeholder: palette.grey[30],
        },
        stronger: {
          default: palette.grey[80],
          disabled: palette.grey[40],
          hover: palette.grey[90],
          pressed: palette.grey[100],
          placeholder: palette.grey[40],
        },
        strongest: {
          default: palette.grey[90],
          disabled: palette.grey[50],
          hover: palette.grey[100],
          pressed: palette.grey[100],
          placeholder: palette.grey[50],
        },
        weak: {
          default: palette.grey[80],
          disabled: palette.grey[50],
          hover: palette.grey[90],
          pressed: palette.grey[100],
          placeholder: palette.grey[40],
        },
        weaker: {
          default: palette.grey[70],
          disabled: palette.grey[40],
          hover: palette.grey[80],
          pressed: palette.grey[90],
          placeholder: palette.grey[40],
        },
        weakest: {
          default: palette.grey[60],
          disabled: palette.grey[40],
          hover: palette.grey[70],
          pressed: palette.grey[80],
          placeholder: palette.grey[40],
        },
        inverse: {
          default: palette.grey["white"],
          disabled: palette.grey[50],
          hover: palette.grey[10],
          pressed: palette.grey[20],
          placeholder: palette.grey[50],
        },
      },
      border: {
        default: {
          default: palette.grey[30],
          disabled: palette.grey[30],
          hover: palette.grey[40],
          pressed: palette.grey[30],
          placeholder: palette.grey[30],
        },
        strong: {
          default: palette.grey[40],
          disabled: palette.grey[40],
          hover: palette.grey[50],
          pressed: palette.grey[40],
          placeholder: palette.grey[40],
        },
        stronger: {
          default: palette.grey[60],
          disabled: palette.grey[60],
          hover: palette.grey[70],
          pressed: palette.grey[60],
          placeholder: palette.grey[60],
        },
        strongest: {
          default: palette.grey[70],
          disabled: palette.grey[70],
          hover: palette.grey[80],
          pressed: palette.grey[70],
          placeholder: palette.grey[70],
        },
        weak: {
          default: palette.grey[20],
          disabled: palette.grey[20],
          hover: palette.grey[30],
          pressed: palette.grey[40],
          placeholder: palette.grey[20],
        },
        weaker: {
          default: palette.grey[10],
          disabled: palette.grey[10],
          hover: palette.grey[20],
          pressed: palette.grey[10],
          placeholder: palette.grey[10],
        },
        weakest: {
          default: palette.grey[5],
          disabled: palette.grey[5],
          hover: palette.grey[10],
          pressed: palette.grey[5],
          placeholder: palette.grey[5],
        },
        inverse: {
          default: palette.grey[90],
          disabled: palette.grey[90],
          hover: palette.grey[100],
          pressed: palette.grey[90],
          placeholder: palette.grey[90],
        },
      },
      icon: {
        default: {
          default: palette.grey[60],
          disabled: palette.grey[50],
          hover: palette.grey[70],
          pressed: palette.grey[80],
          placeholder: palette.grey[30],
        },
        strong: {
          default: palette.grey[70],
          disabled: palette.grey[50],
          hover: palette.grey[80],
          pressed: palette.grey[90],
          placeholder: palette.grey[30],
        },
        stronger: {
          default: palette.grey[80],
          disabled: palette.grey[40],
          hover: palette.grey[90],
          pressed: palette.grey[100],
          placeholder: palette.grey[40],
        },
        strongest: {
          default: palette.grey[90],
          disabled: palette.grey[50],
          hover: palette.grey[100],
          pressed: palette.grey[100],
          placeholder: palette.grey[50],
        },
        weak: {
          default: palette.grey[50],
          disabled: palette.grey[50],
          hover: palette.grey[40],
          pressed: palette.grey[50],
          placeholder: palette.grey[40],
        },
        weaker: {
          default: palette.grey[40],
          disabled: palette.grey[40],
          hover: palette.grey[50],
          pressed: palette.grey[60],
          placeholder: palette.grey[40],
        },
        weakest: {
          default: palette.grey[30],
          disabled: palette.grey[40],
          hover: palette.grey[40],
          pressed: palette.grey[50],
          placeholder: palette.grey[40],
        },
        inverse: {
          default: palette.grey["white"],
          disabled: palette.grey[50],
          hover: palette.grey[10],
          pressed: palette.grey[20],
          placeholder: palette.grey[50],
        },
      },
    },
    neutral: {
      background: {
        default: {
          default: palette.grey["white"],
          disabled: palette.grey[5],
          hover: palette.grey[10],
          pressed: palette.grey[20],
          placeholder: palette.grey[10],
        },
        strong: {
          default: palette.grey[50],
          disabled: palette.grey[40],
          hover: palette.grey[60],
          pressed: palette.grey[70],
          placeholder: palette.grey[40],
        },
        stronger: {
          default: palette.grey[60],
          disabled: palette.grey[50],
          hover: palette.grey[70],
          pressed: palette.grey[80],
          placeholder: palette.grey[50],
        },
        strongest: {
          default: palette.grey[70],
          disabled: palette.grey[60],
          hover: palette.grey[80],
          pressed: palette.grey[90],
          placeholder: palette.grey[60],
        },
        weak: {
          default: palette.grey[40],
          disabled: palette.grey[30],
          hover: palette.grey[50],
          pressed: palette.grey[60],
          placeholder: palette.grey[30],
        },
        weaker: {
          default: palette.grey[30],
          disabled: palette.grey[20],
          hover: palette.grey[40],
          pressed: palette.grey[50],
          placeholder: palette.grey[20],
        },
        weakest: {
          default: palette.grey[20],
          disabled: palette.grey[10],
          hover: palette.grey[30],
          pressed: palette.grey[40],
          placeholder: palette.grey[10],
        },
        inverse: {
          default: palette.grey[100],
          disabled: palette.grey[50],
          hover: palette.grey[90],
          pressed: palette.grey[80],
          placeholder: palette.grey[50],
        },
      },
      text: {
        default: {
          default: palette.grey[100],
          disabled: palette.grey[50],
          hover: palette.grey[90],
          pressed: palette.grey[80],
          placeholder: palette.grey[30],
        },
        strong: {
          default: palette.grey[100],
          disabled: palette.grey[50],
          hover: palette.grey[90],
          pressed: palette.grey[80],
          placeholder: palette.grey[30],
        },
        stronger: {
          default: palette.grey[50],
          disabled: palette.grey[40],
          hover: palette.grey[60],
          pressed: palette.grey[70],
          placeholder: palette.grey[40],
        },
        strongest: {
          default: palette.grey[60],
          disabled: palette.grey[50],
          hover: palette.grey[70],
          pressed: palette.grey[80],
          placeholder: palette.grey[50],
        },
        weak: {
          default: palette.grey[80],
          disabled: palette.grey[50],
          hover: palette.grey[70],
          pressed: palette.grey[60],
          placeholder: palette.grey[40],
        },
        weaker: {
          default: palette.grey[70],
          disabled: palette.grey[40],
          hover: palette.grey[60],
          pressed: palette.grey[50],
          placeholder: palette.grey[40],
        },
        weakest: {
          default: palette.grey[60],
          disabled: palette.grey[40],
          hover: palette.grey[50],
          pressed: palette.grey[40],
          placeholder: palette.grey[40],
        },
        inverse: {
          default: palette.grey["white"],
          disabled: palette.grey[50],
          hover: palette.grey[10],
          pressed: palette.grey[20],
          placeholder: palette.grey[50],
        },
      },
      border: {
        default: {
          default: palette.grey[30],
          disabled: palette.grey[30],
          hover: palette.grey[40],
          pressed: palette.grey[30],
          placeholder: palette.grey[30],
        },
        strong: {
          default: palette.grey[40],
          disabled: palette.grey[40],
          hover: palette.grey[50],
          pressed: palette.grey[40],
          placeholder: palette.grey[40],
        },
        stronger: {
          default: palette.grey[60],
          disabled: palette.grey[60],
          hover: palette.grey[70],
          pressed: palette.grey[60],
          placeholder: palette.grey[60],
        },
        strongest: {
          default: palette.grey[70],
          disabled: palette.grey[70],
          hover: palette.grey[80],
          pressed: palette.grey[70],
          placeholder: palette.grey[70],
        },
        weak: {
          default: palette.grey[20],
          disabled: palette.grey[20],
          hover: palette.grey[30],
          pressed: palette.grey[20],
          placeholder: palette.grey[20],
        },
        weaker: {
          default: palette.grey[10],
          disabled: palette.grey[10],
          hover: palette.grey[20],
          pressed: palette.grey[10],
          placeholder: palette.grey[10],
        },
        weakest: {
          default: palette.grey[5],
          disabled: palette.grey[5],
          hover: palette.grey[10],
          pressed: palette.grey[5],
          placeholder: palette.grey[5],
        },
        inverse: {
          default: palette.grey[90],
          disabled: palette.grey[90],
          hover: palette.grey[100],
          pressed: palette.grey[90],
          placeholder: palette.grey[90],
        },
      },
      icon: {
        default: {
          default: palette.grey[100],
          disabled: palette.grey[50],
          hover: palette.grey[90],
          pressed: palette.grey[80],
          placeholder: palette.grey[30],
        },
        strong: {
          default: palette.grey[100],
          disabled: palette.grey[50],
          hover: palette.grey[90],
          pressed: palette.grey[80],
          placeholder: palette.grey[30],
        },
        stronger: {
          default: palette.grey[50],
          disabled: palette.grey[40],
          hover: palette.grey[60],
          pressed: palette.grey[70],
          placeholder: palette.grey[40],
        },
        strongest: {
          default: palette.grey[60],
          disabled: palette.grey[50],
          hover: palette.grey[70],
          pressed: palette.grey[80],
          placeholder: palette.grey[50],
        },
        weak: {
          default: palette.grey[80],
          disabled: palette.grey[50],
          hover: palette.grey[70],
          pressed: palette.grey[60],
          placeholder: palette.grey[40],
        },
        weaker: {
          default: palette.grey[70],
          disabled: palette.grey[40],
          hover: palette.grey[60],
          pressed: palette.grey[50],
          placeholder: palette.grey[40],
        },
        weakest: {
          default: palette.grey[60],
          disabled: palette.grey[40],
          hover: palette.grey[50],
          pressed: palette.grey[40],
          placeholder: palette.grey[40],
        },
        inverse: {
          default: palette.grey["white"],
          disabled: palette.grey[50],
          hover: palette.grey[10],
          pressed: palette.grey[20],
          placeholder: palette.grey[50],
        },
      },
    },
    // overlay: {
    //   background: {
    //     default: {
    //       default: palette.grey[50],
    //     },
    //   },
    // },
    positive: {
      background: {
        default: {
          default: palette.green[50],
          disabled: palette.green[30],
          hover: palette.green[40],
          pressed: palette.green[60],
          placeholder: palette.green[30],
        },
        strong: {
          default: palette.green[60],
          disabled: palette.green[30],
          hover: palette.green[50],
          pressed: palette.green[70],
          placeholder: palette.green[30],
        },
        stronger: {
          default: palette.green[70],
          disabled: palette.green[30],
          hover: palette.green[60],
          pressed: palette.green[80],
          placeholder: palette.green[30],
        },
        strongest: {
          default: palette.green[80],
          disabled: palette.green[30],
          hover: palette.green[70],
          pressed: palette.green[90],
          placeholder: palette.green[30],
        },
        weak: {
          default: palette.green[40],
          disabled: palette.green[30],
          hover: palette.green[50],
          pressed: palette.green[60],
          placeholder: palette.green[30],
        },
        weaker: {
          default: palette.green[10],
          disabled: palette.green[30],
          hover: palette.green[5],
          pressed: palette.green[20],
          placeholder: palette.green[30],
        },
        weakest: {
          default: palette.green[5],
          disabled: palette.green[30],
          hover: palette.grey["white"],
          pressed: palette.green[10],
          placeholder: palette.green[30],
        },
        inverse: {
          default: palette.green[100],
          disabled: palette.green[50],
          hover: palette.green[90],
          pressed: palette.green[80],
          placeholder: palette.green[50],
        },
      },
      text: {
        default: {
          default: palette.green[70],
          disabled: palette.green[30],
          hover: palette.green[60],
          pressed: palette.green[80],
          placeholder: palette.green[50],
        },
        strong: {
          default: palette.green[70],
          disabled: palette.green[30],
          hover: palette.green[60],
          pressed: palette.green[80],
          placeholder: palette.green[50],
        },
        stronger: {
          default: palette.green[80],
          disabled: palette.green[30],
          hover: palette.green[70],
          pressed: palette.green[90],
          placeholder: palette.green[50],
        },
        strongest: {
          default: palette.green[90],
          disabled: palette.green[30],
          hover: palette.green[80],
          pressed: palette.green[100],
          placeholder: palette.green[50],
        },
        weak: {
          default: palette.green[40],
          disabled: palette.green[30],
          hover: palette.green[50],
          pressed: palette.green[60],
          placeholder: palette.green[50],
        },
        weaker: {
          default: palette.green[20],
          disabled: palette.green[30],
          hover: palette.green[30],
          pressed: palette.green[40],
          placeholder: palette.green[50],
        },
        weakest: {
          default: palette.green[5],
          disabled: palette.green[30],
          hover: palette.green[20],
          pressed: palette.green[30],
          placeholder: palette.green[50],
        },
        inverse: {
          default: palette.grey["white"],
          disabled: palette.green[50],
          hover: palette.green[20],
          pressed: palette.green[30],
          placeholder: palette.green[50],
        },
      },
      border: {
        default: {
          default: palette.green[60],
          disabled: palette.green[50],
          hover: palette.green[50],
          pressed: palette.green[70],
          placeholder: palette.green[50],
        },
        strong: {
          default: palette.green[70],
          disabled: palette.green[50],
          hover: palette.green[60],
          pressed: palette.green[80],
          placeholder: palette.green[50],
        },
        stronger: {
          default: palette.green[80],
          disabled: palette.green[50],
          hover: palette.green[70],
          pressed: palette.green[90],
          placeholder: palette.green[50],
        },
        strongest: {
          default: palette.green[90],
          disabled: palette.green[50],
          hover: palette.green[80],
          pressed: palette.green[100],
          placeholder: palette.green[50],
        },
        weak: {
          default: palette.green[40],
          disabled: palette.green[50],
          hover: palette.green[30],
          pressed: palette.green[50],
          placeholder: palette.green[50],
        },
        weaker: {
          default: palette.green[30],
          disabled: palette.green[50],
          hover: palette.green[20],
          pressed: palette.green[40],
          placeholder: palette.green[50],
        },
        weakest: {
          default: palette.green[20],
          disabled: palette.green[50],
          hover: palette.green[10],
          pressed: palette.green[30],
          placeholder: palette.green[50],
        },
        inverse: {
          default: palette.green[90],
          disabled: palette.green[50],
          hover: palette.green[80],
          pressed: palette.green[100],
          placeholder: palette.green[50],
        },
      },
      icon: {
        default: {
          default: palette.green[50],
          disabled: palette.green[30],
          hover: palette.green[60],
          pressed: palette.green[60],
          placeholder: palette.green[60],
        },
        strong: {
          default: palette.green[60],
          disabled: palette.green[30],
          hover: palette.green[70],
          pressed: palette.green[70],
          placeholder: palette.green[70],
        },
        stronger: {
          default: palette.green[70],
          disabled: palette.green[30],
          hover: palette.green[80],
          pressed: palette.green[80],
          placeholder: palette.green[80],
        },
        strongest: {
          default: palette.green[80],
          disabled: palette.green[30],
          hover: palette.green[90],
          pressed: palette.green[90],
          placeholder: palette.green[90],
        },
        weak: {
          default: palette.green[40],
          disabled: palette.green[30],
          hover: palette.green[50],
          pressed: palette.green[60],
          placeholder: palette.green[50],
        },
        weaker: {
          default: palette.green[30],
          disabled: palette.green[30],
          hover: palette.green[40],
          pressed: palette.green[50],
          placeholder: palette.green[40],
        },
        weakest: {
          default: palette.green[20],
          disabled: palette.green[30],
          hover: palette.green[30],
          pressed: palette.green[40],
          placeholder: palette.green[30],
        },
        inverse: {
          default: palette.green["white"],
          disabled: palette.green[50],
          hover: palette.green[10],
          pressed: palette.green[20],
          placeholder: palette.green[50],
        },
      },
    },
    negative: {
      background: {
        default: {
          default: palette.red[50],
          disabled: palette.red[30],
          hover: palette.red[60],
          pressed: palette.red[70],
          placeholder: palette.red[30],
        },
        strong: {
          default: palette.red[60],
          disabled: palette.red[30],
          hover: palette.red[70],
          pressed: palette.red[80],
          placeholder: palette.red[30],
        },
        stronger: {
          default: palette.red[70],
          disabled: palette.red[30],
          hover: palette.red[80],
          pressed: palette.red[90],
          placeholder: palette.red[30],
        },
        strongest: {
          default: palette.red[80],
          disabled: palette.red[30],
          hover: palette.red[90],
          pressed: palette.red[100],
          placeholder: palette.red[30],
        },
        weak: {
          default: palette.red[40],
          disabled: palette.red[30],
          hover: palette.red[50],
          pressed: palette.red[60],
          placeholder: palette.red[30],
        },
        weaker: {
          default: palette.red[10],
          disabled: palette.red[30],
          hover: palette.red[5],
          pressed: palette.red[20],
          placeholder: palette.red[30],
        },
        weakest: {
          default: palette.red[5],
          disabled: palette.red[30],
          hover: palette.grey[10],
          pressed: palette.red[20],
          placeholder: palette.red[30],
        },
        inverse: {
          default: palette.red[100],
          disabled: palette.red[50],
          hover: palette.red[90],
          pressed: palette.red[80],
          placeholder: palette.red[50],
        },
      },
      text: {
        default: {
          default: palette.red[70],
          disabled: palette.red[30],
          hover: palette.red[60],
          pressed: palette.red[80],
          placeholder: palette.red[50],
        },
        strong: {
          default: palette.red[70],
          disabled: palette.red[30],
          hover: palette.red[60],
          pressed: palette.red[80],
          placeholder: palette.red[50],
        },
        stronger: {
          default: palette.red[80],
          disabled: palette.red[30],
          hover: palette.red[70],
          pressed: palette.red[90],
          placeholder: palette.red[50],
        },
        strongest: {
          default: palette.red[90],
          disabled: palette.red[30],
          hover: palette.red[80],
          pressed: palette.red[100],
          placeholder: palette.red[50],
        },
        weak: {
          default: palette.red[70],
          disabled: palette.red[30],
          hover: palette.red[60],
          pressed: palette.red[80],
          placeholder: palette.red[50],
        },
        weaker: {
          default: palette.red[50],
          disabled: palette.red[30],
          hover: palette.red[40],
          pressed: palette.red[60],
          placeholder: palette.red[50],
        },
        weakest: {
          default: palette.red[40],
          disabled: palette.red[30],
          hover: palette.red[30],
          pressed: palette.red[50],
          placeholder: palette.red[50],
        },
        inverse: {
          default: palette.grey["white"],
          disabled: palette.red[50],
          hover: palette.red[20],
          pressed: palette.red[30],
          placeholder: palette.red[50],
        },
      },
      border: {
        default: {
          default: palette.red[60],
          disabled: palette.red[50],
          hover: palette.red[50],
          pressed: palette.red[70],
          placeholder: palette.red[50],
        },
        strong: {
          default: palette.red[70],
          disabled: palette.red[50],
          hover: palette.red[60],
          pressed: palette.red[80],
          placeholder: palette.red[50],
        },
        stronger: {
          default: palette.red[80],
          disabled: palette.red[50],
          hover: palette.red[70],
          pressed: palette.red[90],
          placeholder: palette.red[50],
        },
        strongest: {
          default: palette.red[90],
          disabled: palette.red[50],
          hover: palette.red[80],
          pressed: palette.red[100],
          placeholder: palette.red[50],
        },
        weak: {
          default: palette.red[40],
          disabled: palette.red[50],
          hover: palette.red[30],
          pressed: palette.red[50],
          placeholder: palette.red[50],
        },
        weaker: {
          default: palette.red[30],
          disabled: palette.red[50],
          hover: palette.red[20],
          pressed: palette.red[40],
          placeholder: palette.red[50],
        },
        weakest: {
          default: palette.red[20],
          disabled: palette.red[50],
          hover: palette.red[10],
          pressed: palette.red[30],
          placeholder: palette.red[50],
        },
        inverse: {
          default: palette.red[90],
          disabled: palette.red[50],
          hover: palette.red[80],
          pressed: palette.red[100],
          placeholder: palette.red[50],
        },
      },
      icon: {
        default: {
          default: palette.red[50],
          disabled: palette.red[30],
          hover: palette.red[60],
          pressed: palette.red[60],
          placeholder: palette.red[60],
        },
        strong: {
          default: palette.red[60],
          disabled: palette.red[30],
          hover: palette.red[70],
          pressed: palette.red[70],
          placeholder: palette.red[70],
        },
        stronger: {
          default: palette.red[70],
          disabled: palette.red[30],
          hover: palette.red[80],
          pressed: palette.red[80],
          placeholder: palette.red[80],
        },
        strongest: {
          default: palette.red[80],
          disabled: palette.red[30],
          hover: palette.red[90],
          pressed: palette.red[90],
          placeholder: palette.red[90],
        },
        weak: {
          default: palette.red[40],
          disabled: palette.red[30],
          hover: palette.red[50],
          pressed: palette.red[50],
          placeholder: palette.red[50],
        },
        weaker: {
          default: palette.red[30],
          disabled: palette.red[30],
          hover: palette.red[40],
          pressed: palette.red[40],
          placeholder: palette.red[40],
        },
        weakest: {
          default: palette.red[20],
          disabled: palette.red[30],
          hover: palette.red[30],
          pressed: palette.red[30],
          placeholder: palette.red[30],
        },
        inverse: {
          default: palette.red["white"],
          disabled: palette.red[50],
          hover: palette.red[10],
          pressed: palette.red[20],
          placeholder: palette.red[50],
        },
      },
    },

    warning: {
      background: {
        default: {
          default: palette.orange[50],
          disabled: palette.orange[30],
          hover: palette.orange[40],
          pressed: palette.orange[60],
          placeholder: palette.orange[30],
        },
        strong: {
          default: palette.orange[60],
          disabled: palette.orange[30],
          hover: palette.orange[50],
          pressed: palette.orange[70],
          placeholder: palette.orange[30],
        },
        stronger: {
          default: palette.orange[70],
          disabled: palette.orange[30],
          hover: palette.orange[60],
          pressed: palette.orange[80],
          placeholder: palette.orange[30],
        },
        strongest: {
          default: palette.orange[80],
          disabled: palette.orange[30],
          hover: palette.orange[70],
          pressed: palette.orange[90],
          placeholder: palette.orange[30],
        },
        weak: {
          default: palette.orange[40],
          disabled: palette.orange[30],
          hover: palette.orange[50],
          pressed: palette.orange[60],
          placeholder: palette.orange[30],
        },
        weaker: {
          default: palette.orange[10],
          disabled: palette.orange[30],
          hover: palette.orange[5],
          pressed: palette.orange[20],
          placeholder: palette.orange[30],
        },
        weakest: {
          default: palette.orange[5],
          disabled: palette.orange[30],
          hover: palette.grey["white"],
          pressed: palette.orange[10],
          placeholder: palette.orange[30],
        },
        inverse: {
          default: palette.orange[100],
          disabled: palette.orange[50],
          hover: palette.orange[90],
          pressed: palette.orange[80],
          placeholder: palette.orange[50],
        },
      },
      text: {
        default: {
          default: palette.orange[70],
          disabled: palette.orange[30],
          hover: palette.orange[60],
          pressed: palette.orange[80],
          placeholder: palette.orange[50],
        },
        strong: {
          default: palette.orange[70],
          disabled: palette.orange[30],
          hover: palette.orange[60],
          pressed: palette.orange[80],
          placeholder: palette.orange[50],
        },
        stronger: {
          default: palette.orange[80],
          disabled: palette.orange[30],
          hover: palette.orange[70],
          pressed: palette.orange[90],
          placeholder: palette.orange[50],
        },
        strongest: {
          default: palette.orange[90],
          disabled: palette.orange[30],
          hover: palette.orange[80],
          pressed: palette.orange[100],
          placeholder: palette.orange[50],
        },
        weak: {
          default: palette.orange[70],
          disabled: palette.orange[30],
          hover: palette.orange[60],
          pressed: palette.orange[80],
          placeholder: palette.orange[50],
        },
        weaker: {
          default: palette.orange[50],
          disabled: palette.orange[30],
          hover: palette.orange[40],
          pressed: palette.orange[60],
          placeholder: palette.orange[50],
        },
        weakest: {
          default: palette.orange[40],
          disabled: palette.orange[30],
          hover: palette.orange[30],
          pressed: palette.orange[50],
          placeholder: palette.orange[50],
        },
        inverse: {
          default: palette.grey["white"],
          disabled: palette.orange[50],
          hover: palette.orange[20],
          pressed: palette.orange[30],
          placeholder: palette.orange[50],
        },
      },
      border: {
        default: {
          default: palette.orange[60],
          disabled: palette.orange[50],
          hover: palette.orange[50],
          pressed: palette.orange[70],
          placeholder: palette.orange[50],
        },
        strong: {
          default: palette.orange[70],
          disabled: palette.orange[50],
          hover: palette.orange[60],
          pressed: palette.orange[80],
          placeholder: palette.orange[50],
        },
        stronger: {
          default: palette.orange[80],
          disabled: palette.orange[50],
          hover: palette.orange[70],
          pressed: palette.orange[90],
          placeholder: palette.orange[50],
        },
        strongest: {
          default: palette.orange[90],
          disabled: palette.orange[50],
          hover: palette.orange[80],
          pressed: palette.orange[100],
          placeholder: palette.orange[50],
        },
        weak: {
          default: palette.orange[40],
          disabled: palette.orange[50],
          hover: palette.orange[30],
          pressed: palette.orange[50],
          placeholder: palette.orange[50],
        },
        weaker: {
          default: palette.orange[30],
          disabled: palette.orange[50],
          hover: palette.orange[20],
          pressed: palette.orange[40],
          placeholder: palette.orange[50],
        },
        weakest: {
          default: palette.orange[20],
          disabled: palette.orange[50],
          hover: palette.orange[10],
          pressed: palette.orange[30],
          placeholder: palette.orange[50],
        },
        inverse: {
          default: palette.orange[90],
          disabled: palette.orange[50],
          hover: palette.orange[80],
          pressed: palette.orange[100],
          placeholder: palette.orange[50],
        },
      },
      icon: {
        default: {
          default: palette.orange[50],
          disabled: palette.orange[30],
          hover: palette.orange[60],
          pressed: palette.orange[60],
          placeholder: palette.orange[60],
        },
        strong: {
          default: palette.orange[60],
          disabled: palette.orange[30],
          hover: palette.orange[70],
          pressed: palette.orange[70],
          placeholder: palette.orange[70],
        },
        stronger: {
          default: palette.orange[70],
          disabled: palette.orange[30],
          hover: palette.orange[80],
          pressed: palette.orange[80],
          placeholder: palette.orange[80],
        },
        strongest: {
          default: palette.orange[80],
          disabled: palette.orange[30],
          hover: palette.orange[90],
          pressed: palette.orange[90],
          placeholder: palette.orange[90],
        },
        weak: {
          default: palette.orange[40],
          disabled: palette.orange[30],
          hover: palette.orange[50],
          pressed: palette.orange[50],
          placeholder: palette.orange[50],
        },
        weaker: {
          default: palette.orange[30],
          disabled: palette.orange[30],
          hover: palette.orange[40],
          pressed: palette.orange[40],
          placeholder: palette.orange[40],
        },
        weakest: {
          default: palette.orange[20],
          disabled: palette.orange[30],
          hover: palette.orange[30],
          pressed: palette.orange[30],
          placeholder: palette.orange[30],
        },
        inverse: {
          default: palette.orange["white"],
          disabled: palette.orange[50],
          hover: palette.orange[10],
          pressed: palette.orange[20],
          placeholder: palette.orange[50],
        },
      },
    },

    information: {
      background: {
        default: {
          default: palette.blue[50],
          disabled: palette.blue[30],
          hover: palette.blue[40],
          pressed: palette.blue[60],
          placeholder: palette.blue[30],
        },
        strong: {
          default: palette.blue[60],
          disabled: palette.blue[30],
          hover: palette.blue[50],
          pressed: palette.blue[70],
          placeholder: palette.blue[30],
        },
        stronger: {
          default: palette.blue[70],
          disabled: palette.blue[30],
          hover: palette.blue[60],
          pressed: palette.blue[80],
          placeholder: palette.blue[30],
        },
        strongest: {
          default: palette.blue[80],
          disabled: palette.blue[30],
          hover: palette.blue[70],
          pressed: palette.blue[90],
          placeholder: palette.blue[30],
        },
        weak: {
          default: palette.blue[40],
          disabled: palette.blue[30],
          hover: palette.blue[50],
          pressed: palette.blue[60],
          placeholder: palette.blue[30],
        },
        weaker: {
          default: palette.blue[10],
          disabled: palette.blue[30],
          hover: palette.blue[5],
          pressed: palette.blue[20],
          placeholder: palette.blue[30],
        },
        weakest: {
          default: palette.blue[5],
          disabled: palette.blue[30],
          hover: palette.grey["white"],
          pressed: palette.blue[10],
          placeholder: palette.blue[30],
        },
        inverse: {
          default: palette.blue[100],
          disabled: palette.blue[50],
          hover: palette.blue[90],
          pressed: palette.blue[80],
          placeholder: palette.blue[50],
        },
      },
      text: {
        default: {
          default: palette.blue[70],
          disabled: palette.blue[30],
          hover: palette.blue[60],
          pressed: palette.blue[80],
          placeholder: palette.blue[50],
        },
        strong: {
          default: palette.blue[70],
          disabled: palette.blue[30],
          hover: palette.blue[60],
          pressed: palette.blue[80],
          placeholder: palette.blue[50],
        },
        stronger: {
          default: palette.blue[80],
          disabled: palette.blue[30],
          hover: palette.blue[70],
          pressed: palette.blue[90],
          placeholder: palette.blue[50],
        },
        strongest: {
          default: palette.blue[90],
          disabled: palette.blue[30],
          hover: palette.blue[80],
          pressed: palette.blue[100],
          placeholder: palette.blue[50],
        },
        weak: {
          default: palette.blue[70],
          disabled: palette.blue[30],
          hover: palette.blue[60],
          pressed: palette.blue[80],
          placeholder: palette.blue[50],
        },
        weaker: {
          default: palette.blue[50],
          disabled: palette.blue[30],
          hover: palette.blue[40],
          pressed: palette.blue[60],
          placeholder: palette.blue[50],
        },
        weakest: {
          default: palette.blue[40],
          disabled: palette.blue[30],
          hover: palette.blue[30],
          pressed: palette.blue[50],
          placeholder: palette.blue[50],
        },
        inverse: {
          default: palette.grey["white"],
          disabled: palette.blue[50],
          hover: palette.blue[20],
          pressed: palette.blue[30],
          placeholder: palette.blue[50],
        },
      },
      border: {
        default: {
          default: palette.blue[60],
          disabled: palette.blue[50],
          hover: palette.blue[50],
          pressed: palette.blue[70],
          placeholder: palette.blue[50],
        },
        strong: {
          default: palette.blue[70],
          disabled: palette.blue[50],
          hover: palette.blue[60],
          pressed: palette.blue[80],
          placeholder: palette.blue[50],
        },
        stronger: {
          default: palette.blue[80],
          disabled: palette.blue[50],
          hover: palette.blue[70],
          pressed: palette.blue[90],
          placeholder: palette.blue[50],
        },
        strongest: {
          default: palette.blue[90],
          disabled: palette.blue[50],
          hover: palette.blue[80],
          pressed: palette.blue[100],
          placeholder: palette.blue[50],
        },
        weak: {
          default: palette.blue[40],
          disabled: palette.blue[50],
          hover: palette.blue[30],
          pressed: palette.blue[50],
          placeholder: palette.blue[50],
        },
        weaker: {
          default: palette.blue[30],
          disabled: palette.blue[50],
          hover: palette.blue[20],
          pressed: palette.blue[40],
          placeholder: palette.blue[50],
        },
        weakest: {
          default: palette.blue[20],
          disabled: palette.blue[50],
          hover: palette.blue[10],
          pressed: palette.blue[30],
          placeholder: palette.blue[50],
        },
        inverse: {
          default: palette.blue[90],
          disabled: palette.blue[50],
          hover: palette.blue[80],
          pressed: palette.blue[100],
          placeholder: palette.blue[50],
        },
      },
      icon: {
        default: {
          default: palette.blue[50],
          disabled: palette.blue[30],
          hover: palette.blue[60],
          pressed: palette.blue[60],
          placeholder: palette.blue[60],
        },
        strong: {
          default: palette.blue[60],
          disabled: palette.blue[30],
          hover: palette.blue[70],
          pressed: palette.blue[70],
          placeholder: palette.blue[70],
        },
        stronger: {
          default: palette.blue[70],
          disabled: palette.blue[30],
          hover: palette.blue[80],
          pressed: palette.blue[80],
          placeholder: palette.blue[80],
        },
        strongest: {
          default: palette.blue[80],
          disabled: palette.blue[30],
          hover: palette.blue[90],
          pressed: palette.blue[90],
          placeholder: palette.blue[90],
        },
        weak: {
          default: palette.blue[40],
          disabled: palette.blue[30],
          hover: palette.blue[50],
          pressed: palette.blue[50],
          placeholder: palette.blue[50],
        },
        weaker: {
          default: palette.blue[30],
          disabled: palette.blue[30],
          hover: palette.blue[40],
          pressed: palette.blue[40],
          placeholder: palette.blue[40],
        },
        weakest: {
          default: palette.blue[20],
          disabled: palette.blue[30],
          hover: palette.blue[30],
          pressed: palette.blue[30],
          placeholder: palette.blue[30],
        },
        inverse: {
          default: palette.blue["white"],
          disabled: palette.blue[50],
          hover: palette.blue[10],
          pressed: palette.blue[20],
          placeholder: palette.blue[50],
        },
      },
    },
    accent: {
      background: {
        default: {
          default: palette.purple[50],
          disabled: palette.purple[30],
          hover: palette.purple[60],
          pressed: palette.purple[70],
          placeholder: palette.purple[30],
        },
        strong: {
          default: palette.purple[60],
          disabled: palette.purple[30],
          hover: palette.purple[70],
          pressed: palette.purple[80],
          placeholder: palette.purple[30],
        },
        stronger: {
          default: palette.purple[70],
          disabled: palette.purple[30],
          hover: palette.purple[60],
          pressed: palette.purple[80],
          placeholder: palette.purple[30],
        },
        strongest: {
          default: palette.purple[80],
          disabled: palette.purple[30],
          hover: palette.purple[70],
          pressed: palette.purple[90],
          placeholder: palette.purple[30],
        },
        weak: {
          default: palette.purple[40],
          disabled: palette.purple[30],
          hover: palette.purple[50],
          pressed: palette.purple[60],
          placeholder: palette.purple[30],
        },
        weaker: {
          default: palette.purple[10],
          disabled: palette.purple[30],
          hover: palette.purple[5],
          pressed: palette.purple[20],
          placeholder: palette.purple[30],
        },
        weakest: {
          default: palette.purple[5],
          disabled: palette.purple[30],
          hover: palette.purple[10],
          pressed: palette.purple[10],
          placeholder: palette.purple[30],
        },
        inverse: {
          default: palette.purple[100],
          disabled: palette.purple[50],
          hover: palette.purple[90],
          pressed: palette.purple[80],
          placeholder: palette.purple[50],
        },
      },
      text: {
        default: {
          default: palette.purple[70],
          disabled: palette.purple[30],
          hover: palette.purple[80],
          pressed: palette.purple[90],
          placeholder: palette.purple[50],
        },
        strong: {
          default: palette.purple[70],
          disabled: palette.purple[30],
          hover: palette.purple[60],
          pressed: palette.purple[80],
          placeholder: palette.purple[50],
        },
        stronger: {
          default: palette.purple[80],
          disabled: palette.purple[30],
          hover: palette.purple[70],
          pressed: palette.purple[90],
          placeholder: palette.purple[50],
        },
        strongest: {
          default: palette.purple[90],
          disabled: palette.purple[30],
          hover: palette.purple[80],
          pressed: palette.purple[100],
          placeholder: palette.purple[50],
        },
        weak: {
          default: palette.purple[50],
          disabled: palette.purple[30],
          hover: palette.purple[60],
          pressed: palette.purple[70],
          placeholder: palette.purple[50],
        },
        weaker: {
          default: palette.purple[30],
          disabled: palette.purple[30],
          hover: palette.purple[40],
          pressed: palette.purple[50],
          placeholder: palette.purple[50],
        },
        weakest: {
          default: palette.purple[5],
          disabled: palette.purple[30],
          hover: palette.purple[10],
          pressed: palette.purple[20],
          placeholder: palette.purple[50],
        },
        inverse: {
          default: palette.grey["white"],
          disabled: palette.purple[50],
          hover: palette.purple["white"],
          pressed: palette.purple[30],
          placeholder: palette.purple[50],
        },
      },
      border: {
        default: {
          default: palette.purple[60],
          disabled: palette.purple[50],
          hover: palette.purple[50],
          pressed: palette.purple[70],
          placeholder: palette.purple[50],
        },
        strong: {
          default: palette.purple[70],
          disabled: palette.purple[50],
          hover: palette.purple[60],
          pressed: palette.purple[80],
          placeholder: palette.purple[50],
        },
        stronger: {
          default: palette.purple[80],
          disabled: palette.purple[50],
          hover: palette.purple[70],
          pressed: palette.purple[90],
          placeholder: palette.purple[50],
        },
        strongest: {
          default: palette.purple[90],
          disabled: palette.purple[50],
          hover: palette.purple[80],
          pressed: palette.purple[100],
          placeholder: palette.purple[50],
        },
        weak: {
          default: palette.purple[40],
          disabled: palette.purple[50],
          hover: palette.purple[30],
          pressed: palette.purple[50],
          placeholder: palette.purple[50],
        },
        weaker: {
          default: palette.purple[30],
          disabled: palette.purple[50],
          hover: palette.purple[20],
          pressed: palette.purple[40],
          placeholder: palette.purple[50],
        },
        weakest: {
          default: palette.purple[20],
          disabled: palette.purple[50],
          hover: palette.purple[10],
          pressed: palette.purple[30],
          placeholder: palette.purple[50],
        },
        inverse: {
          default: palette.purple[90],
          disabled: palette.purple[50],
          hover: palette.purple[80],
          pressed: palette.purple[100],
          placeholder: palette.purple[50],
        },
      },
      icon: {
        default: {
          default: palette.purple[50],
          disabled: palette.purple[30],
          hover: palette.purple[60],
          pressed: palette.purple[60],
          placeholder: palette.purple[60],
        },
        strong: {
          default: palette.purple[60],
          disabled: palette.purple[30],
          hover: palette.purple[70],
          pressed: palette.purple[70],
          placeholder: palette.purple[70],
        },
        stronger: {
          default: palette.purple[70],
          disabled: palette.purple[30],
          hover: palette.purple[80],
          pressed: palette.purple[80],
          placeholder: palette.purple[80],
        },
        strongest: {
          default: palette.purple[80],
          disabled: palette.purple[30],
          hover: palette.purple[90],
          pressed: palette.purple[90],
          placeholder: palette.purple[90],
        },
        weak: {
          default: palette.purple[40],
          disabled: palette.purple[30],
          hover: palette.purple[50],
          pressed: palette.purple[50],
          placeholder: palette.purple[50],
        },
        weaker: {
          default: palette.purple[30],
          disabled: palette.purple[30],
          hover: palette.purple[40],
          pressed: palette.purple[40],
          placeholder: palette.purple[40],
        },
        weakest: {
          default: palette.purple[20],
          disabled: palette.purple[30],
          hover: palette.purple[30],
          pressed: palette.purple[30],
          placeholder: palette.purple[30],
        },
        inverse: {
          default: palette.purple["white"],
          disabled: palette.purple[50],
          hover: palette.purple[10],
          pressed: palette.purple[20],
          placeholder: palette.purple[50],
        },
      },
    },
  },
  typography: {
    fontFamily: {
      sans: "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
    },
    weight: {
      lightest: 100,
      lighter: 200,
      light: 300,
      default: 400,
      heavy: 500,
      heavier: 600,
      heaviest: 700,
    },
    displayLarge: {
      fontSize: "26px",
      fontWeight: 500,
      lineHeight: "26px",
      letterSpacing: "-0.019em",
      desktop: {
        fontSize: "40px",
        fontWeight: 500,
        letterSpacing: "-0.02em",
        lineHeight: "50px",
      },
    },
    displayMedium: {
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "30px",
      letterSpacing: "-0.017em",
      desktop: {
        fontSize: "30px",
        fontWeight: 600,
        letterSpacing: "-0.019em",
        lineHeight: "36px",
      },
    },
    displaySmall: {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "28px",
      letterSpacing: "-0.013em",
      desktop: {
        fontSize: "24px",
        fontWeight: 500,
        letterSpacing: "-0.016em",
        lineHeight: "30px",
      },
    },
    heading: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "24px",
      letterSpacing: "-0.011em",
      desktop: {
        fontSize: "20px",
        fontWeight: 600,
        letterSpacing: "-0.011em",
        lineHeight: "26px",
      },
    },
    subheading: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "16px",
      letterSpacing: "0em",
      desktop: {
        fontSize: "14px",
        fontWeight: 600,
        letterSpacing: "0em",
        lineHeight: "20px",
      },
    },
    h6: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "20px",
      letterSpacing: "-0.0025em",
      desktop: {
        fontSize: "16px",
        fontWeight: 600,
        letterSpacing: "-0.0025em",
        lineHeight: "24px",
      },
    },
    button: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "20px",
      letterSpacing: "-0.006em",
      desktop: {
        fontSize: "16px",
        fontWeight: 500,
        letterSpacing: "-0.006em",
        lineHeight: "24px",
      },
    },
    body: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "-0.006em",
      desktop: {
        fontSize: "16px",
        fontWeight: 400,
        letterSpacing: "-0.006em",
        lineHeight: "24px",
      },
    },
    small: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "18px",
      letterSpacing: "0em",
      desktop: {
        fontSize: "14px",
        fontWeight: 400,
        letterSpacing: "0em",
        lineHeight: "20px",
      },
    },
  },
  border: {
    radius: {
      sm: "4px",
      md: "6px",
    },
  },
  spacing: 24, //px
  input: {
    text: {
      padding: {
        vertical: {
          sm: 5,
          md: 9,
          lg: 13,
        },
        horizontal: {
          sm: 15,
          md: 15,
          lg: 15,
        },
      },
    },
  },
};

export default sccareTheme;
