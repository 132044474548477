import { JfsFormData } from "../../../types";
import { Flow } from "./../../../components/content";
import { quoteSchema } from "./quoteSchema";

const CreateQuote = () => {
  const onSubmit = (submitData: JfsFormData): void => {
    alert("Submitted quote!");
  };
  return (
    <Flow
      id="quotes-create"
      name="New quote"
      onSubmit={onSubmit}
      schema={quoteSchema}
    />
  );
};

export default CreateQuote;
