import { Header, Section } from "../../components/layout";
import PersonalInfoCard from "./personalInfoCard";
import { Button } from "../../components/util";

export default function Profile() {
  return (
    <>
      <Header title="My profile" type="article" />
      <Section>
        <Header title="Account details" type="section" />
        <PersonalInfoCard />
        <Button
          href="/password/reset"
          router
          variant="contained"
          color="accent"
        >
          Reset password
        </Button>
      </Section>
    </>
  );
}
