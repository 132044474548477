import styled from "styled-components";
import { breakpoints, theme } from "../../../styles";
import { TStyleConnotation } from "../../../types";
import Icon from "../icons/icon";
import Container from "../container/container";

interface AlertStyleProps {
  $color: TStyleConnotation;
  $isFullWidth: boolean;
  $icon: boolean;
  $type: any;
}

export const Alert = styled(Container)<AlertStyleProps>`
  @media ${breakpoints.up("xs")} {
    ${({ $isFullWidth }) => {
      if ($isFullWidth) {
        return `
            border-radius: 0px;
            margin-left: -21px;
            margin-right: -21px;
          `;
      }
    }}
  }

  @media ${breakpoints.up("sm")} {
    border-radius: 6px;
    margin-left: 0px;
    margin-right: 0px;
  }

  ${({ $icon }) => {
    if ($icon) {
      return `
        display: flex;
      `;
    }
  }}

  ${({ $color, $type }) => {
    switch ($type) {
      case "text":
        return `
            color: ${theme.color[$color].text.strong.default};
          `;
      case "boxed":
      default:
        return `
            border-style: solid;
            border-width: 1px;
            padding: 16px 20px;
            background-color: ${theme.color[$color].background.weakest.default};
            border-color: ${theme.color[$color].border.default.default};
            color: ${theme.color[$color].text.strongest.default};
          `;
    }
  }}
`;

export const AlertIcon = styled(Icon).attrs((props: any) => ({}))`
  margin-right: 16px;
`;
