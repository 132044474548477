import Joi from "joi";

export const PasswordMessages = {
  lowercase: "One lowercase character",
  uppercase: "One uppercase character",
  number: "One number",
  special: "One special character",
  characters: "8 characters minimum",
};

export const uuidSchema = Joi.string()
  .required()
  .guid({ version: ["uuidv4"] });

export const getSchemaErrors = async (
  key: any,
  value: any,
  context?: any,
  event?: any,
) => {
  try {
    await key.validateAsync(value, {
      context,
      abortEarly: false,
    });
    return false;
  } catch (err: any) {
    let errorObj: Record<string, string> = {};
    err.details &&
      err.details.forEach((err: any) => {
        errorObj[err.context.name || "empty"] = err.message;
      });
    if (Object.keys(errorObj).length > 0) {
      return errorObj;
    }
  }
};

export const getFormSchemaErrors = async (
  formSchema: any,
  formData: Record<string, string>,
): Promise<false | Record<string, string>> => {
  try {
    await formSchema.validateAsync(formData);
    return false;
  } catch (err: any) {
    let errorObj: Record<string, string> = {};
    err.details &&
      err.details.forEach((err: any) => {
        errorObj[err.context.name || err.context.key || "empty"] = err.message;
      });
    if (Object.keys(errorObj).length > 0) {
      return errorObj;
    }
    return false;
  }
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
