import { Header, Section } from "../../components/layout";
import { InviteUser } from "../../components/content";
import UsersList from "./users";
import * as Styled from "./styles.css";

const Users = () => {
  return (
    <Styled.Grid $fullHeight={true}>
      <Header
        actions={<InviteUser action="send" type="user" context="header" />}
        title="Users"
        titleIcon="people"
        type="article"
      />

      <Section>
        <UsersList />
      </Section>
    </Styled.Grid>
  );
};

export default Users;
