import * as Styled from "./styles.css";

const Section = ({ bordered, children }: SectionProps) => {
  return (
    <Styled.Section element={"section"} item xs={12} $bordered={bordered}>
      {children}
    </Styled.Section>
  );
};

export interface SectionProps {
  children?: any;
  bordered?: boolean;
}

Section.defaultProps = {
  bordered: true,
};

export default Section;
