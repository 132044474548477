import { Link } from "../../util";
import IllustrationBeaver from "../../../assets/illustrations/mitti-illustration-beaver.svg";
import { BackLink } from "..";
import Missing, { IMissingProps } from "./missing";
import * as Styled from "./styles.css";

const PageMissing = ({
  message,
  type = "page",
  ...otherProps
}: IRecordMissingProps) => {
  return (
    <Missing
      illustration={<Styled.BeaverImage src={IllustrationBeaver} />}
      title={`Sorry, we can’t find that page`}
      message={
        <>
          Navigate to back to Care or{" "}
          <Link
            href={`mailto:${process.env.REACT_APP_CARE_SUPPORT_EMAIL}`}
            target={"_blank"}
          >
            contact our support team
          </Link>{" "}
          if you need any assistance.
        </>
      }
      {...otherProps}
    >
      <BackLink />
    </Missing>
  );
};

interface IRecordMissingProps extends IMissingProps {}

export default PageMissing;
