import styled from "styled-components";
import { breakpoints, theme } from "./../../../styles";
import { Grid } from "./../../util";

export const Section = styled(Grid).attrs((props: any) => ({
  $bordered: props.$bordered,
}))`
  ${(props: any) =>
    props.$bordered &&
    `
      border-top: 1px solid ${theme.color.surface.border.weak.default};
    `}
  margin-bottom: 32px;
  &:last-of-type {
    margin-bottom: 0;
  }
  @media ${breakpoints.up("md")} {
    margin-bottom: 48px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
