import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import IndustriesService, {
  IndustrySearchAllRow,
} from "../../../services/industries/industries.service";

import ToolkitsService, {
  Toolkit,
} from "../../../services/toolkits/toolkits.service";

import AutomationService from "../../../services/automation/automation.service";

import { Alert, Icon, Grid, Typography } from "../../../components/util";
import { Header, Section } from "../../../components/layout";
import { JfsFormData } from "../../../types";
import Form from "../../../components/content/flow/monoFlow";
import { schema, withUiSchema } from "./schema";
import { AutomationCreatePayload } from "../../../types/automations.type";

export const AutomationCreatePage = () => {
  const history = useHistory();
  const [formData, setFormData] = useState<JfsFormData>();

  const uiSchema = withUiSchema({
    industries: {
      search: async (input: string): Promise<IndustrySearchAllRow[]> =>
        (await IndustriesService.searchAll(input))?.hits,
      getOptionLabel: item => item.occupation ?? "",
      getOptionSubLabel: item => `${item.anzsic} ${item.name}`,
      getOptionValue: item => item.id,
    },
    toolkits: {
      search: async (input: string): Promise<Toolkit[]> =>
        (await ToolkitsService.getAll(input))?.hits,
      getOptionLabel: item => item.name,
      getOptionSubLabel: item => item.code ?? "",
      getOptionMultiLabel: item => `${item.name} (${item.code})`,
      getOptionValue: item => item.id,
    },
  });

  const { search, state = {} } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get("id"); // TODO: used on edit page
  const { successRoute } = state as State;

  const handleChange = ({ formData }: { formData?: JfsFormData }) =>
    setFormData(formData);

  const onSubmit = async (data: JfsFormData): Promise<void> => {
    const automation: AutomationCreatePayload = {
      name: data.name,
      industries: data.industries,
      toolkits: data.toolkits,
      enabled: data.enabled,
    };

    const created = await AutomationService.create(automation);

    if (created) {
      history.replace(successRoute ?? `/automations/${created.id}`);
    }
  };

  return (
    <Grid>
      <Header
        title="Create New Automation"
        titleIcon="dependabot"
        titleIconPosition="end"
        type="article"
        withLink={{ path: "/automations", text: "All Automations" }}
      />

      <Alert defaultIcon={false} isFullWidth>
        <Typography paragraph>
          <Icon icon="info" color="information" /> An Automation automatically
          assigns a Toolkit to a given policy based on its ANZSIC code.
        </Typography>
      </Alert>

      <Section>
        <Form
          id="automation-create"
          name="Create New Automation"
          schema={schema}
          uiSchema={uiSchema}
          onChange={handleChange}
          onSubmit={onSubmit}
          formData={formData}
        />
      </Section>
    </Grid>
  );
};

type State = {
  successRoute?: string;
};
