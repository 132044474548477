import { Typography } from "../../../components/util";
import AuthContainer from "../authContainer";
import * as Styled from "./styles.css";

const ResetPasswordSuccess = ({ email }: Record<string, string>) => {
  return (
    <AuthContainer
      headerTitle="Check your email for instructions"
      preventRedirect
    >
      <Typography paragraph styled={{ text: { shade: "weak" } }}>
        We’ve sent an email with a link to reset your password to:{" "}
        <Typography element="strong" styled={{ text: { weight: "heavy" } }}>
          {email}
        </Typography>
      </Typography>

      <Styled.LogInButton
        router
        href="/"
        variant="contained"
        color="accent"
        fullWidth
      >
        Return to log in
      </Styled.LogInButton>
    </AuthContainer>
  );
};

export default ResetPasswordSuccess;
