import { Link, TableCell, TableRow, Typography } from "../../components/util";
import { DateTime, PaginatedResults } from "../../components/content";
import { IClaim } from "../../types";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import claimService from "../../services/claim/claim.service";
import { useEffect, useMemo, useState } from "react";

const ClaimsList = () => {
  const { search } = useLocation();
  const urlParams: Record<string, any> = useMemo(
    () => queryString.parse(search),
    [search],
  );

  const [searchTerm, setSearchTerm] = useState<string>(
    urlParams?.searchTerm ? urlParams?.searchTerm : "",
  );

  useEffect(() => {
    setSearchTerm(urlParams?.searchTerm);
  }, [urlParams?.searchTerm]);

  const claimsRow = (claimsSlice: IClaim[]) => {
    return (
      <>
        {claimsSlice.map((claim: IClaim, i: number) => (
          <TableRow key={claim.id}>
            <TableCell>
              <Link
                href={`/claims/${claim.id}`}
                router
                title={claim.claim_number}
              >
                {claim.claim_number}
              </Link>
            </TableCell>

            <TableCell>
              <Typography styled={{ text: { shade: "weaker" } }}>
                <DateTime input={claim.created_at} />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography styled={{ text: { weight: "heavy" } }}>
                {claim.versions[0].claim_data?.create?.claim?.insured_name}
              </Typography>
            </TableCell>
            <TableCell>
              <DateTime
                input={claim?.versions[0].claim_data?.details?.incident_date}
              />
            </TableCell>
            <TableCell>
              <Typography styled={{ text: { case: "sentence" } }}>
                {claim.versions[0].claim_data?.create?.issue?.claim_type}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <PaginatedResults
      id="claims-all"
      searchTerm={searchTerm}
      tableColumns={[
        { title: "Claim" },
        { title: "Created" },
        { title: "Client", expand: true },
        { title: "Date of incient" },
        { title: "Type of claim" },
      ]}
      service={claimService.get_all}
      searchService={claimService.search_all}
      tableRows={claimsRow}
      missing={{
        icon: "shield",
        message:
          "To create your first claim for your client, navigate to their policy details from your portfolio and click “Create a claim”.",
        title: "No claims currently available",
        type: "claims",
      }}
    />
  );
};

export default ClaimsList;
