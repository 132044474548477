import styled from "styled-components";
import { transparentize } from "polished";

import { breakpoints, theme } from "../../../styles";

import {
  Grid as ComponentGrid,
  IconButton,
  Link as ComponentLink,
  SelectOption,
  SelectMenu,
  Table as ComponentTable,
  Typography,
} from "../../../components/util";

import { GridProps } from "@material-ui/core";

const dropShadow = `0px 1px 5px ${transparentize(
  0.7,
  theme.color.surface.background.inverse.default,
)}`;

export interface StyledGridProps extends GridProps {
  $fullHeight?: boolean;
}

export const SubHeader = styled(Typography)`
  margin-top: 2em;
  margin-bottom: 0.5em;
  color: ${theme.color.surface.text.strong.default};
`;

export const Link = styled(ComponentLink)`
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Grid = styled(ComponentGrid)<StyledGridProps>`
  height: ${props => (props.$fullHeight ? "100%" : "initial")};
`;

export const Table = styled(ComponentTable)`
  @media ${breakpoints.down("sm")} {
    grid-template-columns: 1fr;
  }
  th:not(:first-of-type) {
    display: none;
    @media ${breakpoints.up("md")} {
      display: flex;
    }
  }
  td:not(:first-of-type) {
    display: none;
    @media ${breakpoints.up("md")} {
      display: flex;
    }
  }
`;

export const InvitationSection = styled(Grid)`
  border-top: 1px solid ${theme.color.surface.border.weak.default};
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  padding-top: 40px;
  @media ${breakpoints.up("md")} {
    padding-bottom: 60px;
    padding-top: 60px;
  }
`;

export const MoreOptionIconButton = styled(IconButton)`
  transform: rotate(90deg);
`;

export const MoreOptionSelectMenu = styled(SelectMenu)`
  transform: translate3d(10px, 10px, 0);
  z-index: 99;
  border-radius: 6px;
  box-shadow: ${dropShadow};
`;

export const MoreOptionLaunchIconButton = styled(IconButton)`
  padding: 0px;
  vertical-align: center;
  padding-left: 10px;
`;

export const MoreOptionSelectOption = styled(SelectOption)`
  padding: 8px 15px;
  background-color: ${theme.color.surface.background.default.default};
`;

export const LinkButton = styled.a`
  text-decoration: none;
  color: inherit;
  &:visited {
    color: inherit;
  }
`;
