import { useContext, useEffect } from "react";
import { AuthContext, LocationContext } from "../../contexts";
import authService from "../../services/auth/auth.service";
import AuthContainer from "./authContainer";

const Login = () => {
  const { location } = useContext(LocationContext);
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (!auth.user && location.pathname !== "/sign-up") {
      authService.authorizeSafetyCultureAuth0("login", location.pathname);
    }
  }, [auth.user, location.pathname]);

  return <AuthContainer></AuthContainer>;
};

export default Login;
