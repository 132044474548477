import { BackLink } from "..";
import Missing, { IMissingProps } from "./missing";
import { IBackLinkProps } from "../backLink/backLink";

const RecordMissing = ({
  back = {
    title: "Back to Care",
    path: "/",
  },
  children,
  message,
  type = "record",
  ...otherProps
}: IRecordMissingProps) => {
  return (
    <Missing
      title={`Sorry, we can’t find that ${type}`}
      message={message}
      {...otherProps}
    >
      {children ? children : <BackLink title={back.title} path={back.path} />}
    </Missing>
  );
};

interface IRecordMissingProps extends IMissingProps {
  back?: IBackLinkProps;
}

export default RecordMissing;
