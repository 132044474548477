import { useContext } from "react";
import {
  CheckBox,
  FormRow,
  Grid,
  Link,
  TextField,
  Typography,
} from "../../../../components/util";
import { LocationContext } from "../../../../contexts";
import {
  passwordSchema,
  passwordSchemaDefaultMessages,
  firstNameSchema,
  lastNameSchema,
  emailExistSchema,
} from "../../../../validations";
import { MarketingCheckbox, MarketingCheckboxLabel } from "../style.css";
const PersonalInfo = ({ error, ...otherProps }: PersonalInfoProps) => {
  const { urlParams } = useContext(LocationContext);

  return (
    <>
      {error && <FormRow>{error}</FormRow>}
      <FormRow>
        <TextField
          autoComplete="username"
          autoFocus
          disabled={!!urlParams?.invite}
          id="email"
          label="Email address"
          size="lg"
          type="email"
          validationOnBlur
          validationOnChange
          validationType={emailExistSchema}
        />
      </FormRow>
      <FormRow container>
        <Grid item xs={6}>
          <TextField
            autoComplete="given-name"
            id="firstName"
            label="First name"
            size="lg"
            type="text"
            validationOnBlur
            validationOnChange
            validationType={firstNameSchema}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            autoComplete="family-name"
            id="lastName"
            label="Last name"
            size="lg"
            type="text"
            validationOnBlur
            validationOnChange
            validationType={lastNameSchema}
          />
        </Grid>
      </FormRow>
      <FormRow>
        <TextField
          autoComplete="new-password"
          id="password"
          label="Password"
          size="lg"
          type="password"
          dependentFields={["firstName", "lastName"]}
          validationList={passwordSchemaDefaultMessages}
          validationOnChange
          validationOnFocus
          validationType={passwordSchema}
        />
      </FormRow>

      <MarketingCheckbox>
        <CheckBox id="has_consent" label={""} optional={false}></CheckBox>
        <MarketingCheckboxLabel
          variant="body2"
          styled={{ text: { shade: "weak" } }}
        >
          I agree to receive updates, insights and offers from SafetyCulture,
          SafetyCulture Care and their affiliates by email to the above contact
          information. I understand I can withdraw my consent.
        </MarketingCheckboxLabel>
      </MarketingCheckbox>

      <FormRow>
        <Typography variant="body2" styled={{ text: { shade: "weak" } }}>
          By signing up to SafetyCulture Care, you agree to our{" "}
          <Link href="https://care.safetyculture.com/terms-and-conditions/">
            Terms and Conditions
          </Link>{" "}
          and{" "}
          <Link href="https://care.safetyculture.com/privacy-policy/">
            Privacy Policy
          </Link>
        </Typography>
      </FormRow>
      <FormRow>
        <Typography variant="body2" styled={{ text: { shade: "weak" } }}>
          By signing up you will also create an account with SafetyCulture and
          agree to SafetyCulture's{" "}
          <Link href="https://safetyculture.com/legal/terms-and-conditions/">
            Terms and Conditions
          </Link>{" "}
          and{" "}
          <Link href="https://safetyculture.com/legal/privacy-policy/">
            Privacy Policy
          </Link>
        </Typography>
      </FormRow>
    </>
  );
};

interface PersonalInfoProps {
  error?: React.ReactNode;
  footer?: React.ReactNode;
  submitText: string;
  subtitle: string;
  title: string;
}

export interface PersonalData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  has_consent: boolean;
}

export default PersonalInfo;
