import { Icon, Typography } from "..";
import {
  TIconShape,
  TStyleConnotation,
  TStyleSize,
  TypographyVariant,
} from "../../../types";
import { ContainerProps } from "../container/container";
import * as Styled from "./styles.css";

const Chip = ({
  children,
  color,
  label,
  isStatus,
  size,
  icon,
  ...otherProps
}: iChipProps) => {
  let typographySize: TypographyVariant = "body1";
  if (size === "sm") typographySize = "body2";
  if (size === "lg") typographySize = "displaySmall";
  return (
    <Styled.Chip
      element="span"
      $isStatus={isStatus}
      $color={color}
      $size={size}
      {...otherProps}
    >
      {icon && <Icon size="md" icon={icon} color={color} />}
      {label && <Typography variant={typographySize}>{label}</Typography>}
      {children && children}
    </Styled.Chip>
  );
};

export interface iChipProps extends ContainerProps {
  color: TStyleConnotation;
  label?: string;
  size: TStyleSize;
  alignright?: boolean;
  isStatus: boolean;
  icon?: TIconShape;
}

Chip.defaultProps = {
  color: "surface",
  size: "md",
  isStatus: false,
};

export default Chip;
