import axios from "../../axios";
import { ScoreFactorAction } from "../../components/content/score/scoreFactors";
import { IndustryFactor } from "../industries/industries.service";

const ScoresService = {
  async getScore(orgId: string): Promise<ScoreInfo> {
    const response = await axios.get(`/organisations/scores/${orgId}`);

    const score: ScoreInfo = {
      scores: formatScores(response.data),
      factors: formatFactors(response.data),
      version: response.data.score_factors ? 2 : 1,
    };

    const factorTotal = score.factors.reduce(
      (partialSum, factor) => partialSum + (factor.current || 0),
      0,
    );

    if (score.scores.current !== factorTotal) {
      score.scores.current = factorTotal;
    }

    return score as ScoreInfo;
  },

  async getScoreByPolicyId(policyId: string): Promise<ScoreInfo> {
    try {
      const response = await axios.get(`/scores/self_assessment/${policyId}`);

      const score: ScoreInfo = {
        scores: formatScores(response.data),
        factors: formatFactors(response.data),
        version:
          response.data.score_version_type &&
          response.data.score_version_type === "v1"
            ? 1
            : 2,
      };

      const factorTotal = score.factors.reduce(
        (partialSum, factor) => partialSum + (factor.current || 0),
        0,
      );

      if (score.scores.current !== factorTotal) {
        score.scores.current = factorTotal;
      }

      return score as ScoreInfo;
    } catch (error) {
      return {
        scores: {
          current: 0,
        },
        factors: [] as ScoreFactor[],
      } as ScoreInfo;
    }
  },

  async getIauditorScoreByPolicyId(policyId: String): Promise<any> {
    try {
      const response = await axios.get(`/scores/sc_score/${policyId}`);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return error.response.data;
      }
    }
  },
};

export const formatScoresIAuditor = ({
  current,
  median,
  best_practice,
}: any): Scores => {
  const score: Scores = {
    current: current,
    median: median,
    best_practice: best_practice,
  };
  return score as Scores;
};

export const formatScores = (scores: any): Scores => {
  return {
    current: Math.round(scores.score || 0),
    median: Math.round(scores.industry_stats?.average || scores.average || 0),
    best_practice: Math.round(
      scores.industry_stats?.best_practice || scores.best_practice || 0,
    ),
  } as Scores;
};

export const formatFactors = (factors: any): ScoreFactor[] => {
  let formattedFactors: any;

  // v2
  formattedFactors = factors.score_factors?.map(
    (factor: ScoreFactorV2Return) => {
      const max = Math.round(factor.score_weight) || 100;
      const weight = Math.round(factor.score_weight) || 100;
      const score = Math.round(factor.score);

      return {
        name: factor.scoreFactor || factor.name || undefined,
        current: score > 0 ? Math.round((score / max) * weight) : 0,
        max: max,
      };
    },
  );
  if (!formattedFactors) {
    // v1, remove when deprecated
    formattedFactors = formatIndustryFactors(
      factors.industry_stats?.industryStatFactors,
      factors.score_factors || factors.scoreFactors,
    );
  }

  return formattedFactors as ScoreFactor[];
};

export const formatFactorIAuditor = ({
  current,
  name,
  max,
  title,
}: ScoreFactor): ScoreFactor => {
  const score: ScoreFactor = {
    name: name,
  };
  if (current) {
    score.current = current;
  }
  if (max) {
    score.max = max;
  }
  if (title) {
    score.title = title;
  }
  return score as ScoreFactor;
};

const formatIndustryFactors = (
  indFactors: IndustryFactor[],
  orgFactors: ScoreFactor[],
) => {
  const tmpFactors: ScoreFactor[] = [];
  if (indFactors && indFactors[0]) {
    indFactors.forEach((indFactor: IndustryFactor): void => {
      if (parseInt(indFactor.score || "0") > 0) {
        const matchingFactor: ScoreFactor | undefined = orgFactors?.filter(
          (orgFactor: ScoreFactor) => {
            return orgFactor.name === indFactor.name;
          },
        )[0];
        tmpFactors.push({
          name: indFactor.name,
          title: "Risk mitigation practice",
          current: matchingFactor?.score
            ? Math.round(parseInt(matchingFactor.score || "0"))
            : 0,
          max: parseInt(indFactor.score || "0"),
        });
      }
    });
  }
  return tmpFactors as ScoreFactor[];
};

export interface ScoreInfo {
  scores: Scores;
  factors: ScoreFactor[];
  version?: 1 | 2;
}

export interface Scores {
  current: number;
  median: number;
  best_practice: number;
}

export interface ScoreFactor {
  actions?: ScoreFactorAction[];
  current?: number;
  name?: string;
  max?: number;
  title?: string;
  score?: string;
}

export interface ScoreV1Return {
  score: number;
  score_factors: ScoreFactorV2Return[];
  industry_stats: any;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
}

export interface ScoreV2Return {
  score: number;
  score_factors: ScoreFactorV2Return[];
  industry_stats: any;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
}

export interface ScoreFactorV2Return {
  score: number;
  name?: string;
  scoreFactor?: string;
  score_max: number;
  score_weight: number;
}

export default ScoresService;
