import Joi from "joi";

const maxCharacterLimitSingleLine: number = 150;
export const textDefaultSchema = Joi.string();
export const textNumberDefaultSchema = Joi.alternatives(
  Joi.string(),
  Joi.number,
);

const customMessage = (
  msgName: string,
  field?: string,
): Record<string, string> => {
  let errors: Record<string, string> = {
    "string.empty": `${msgName} is required`,
    "any.required": `${msgName} is required`,
    "string.required": `${msgName} is required`,
    "any.max": `${msgName} is too long`,
    "string.max": `${msgName} is too long`,
    "string.guid": `${msgName} must be a valid UUID`,
  };
  return errors;
};

export const policyNumberSchema = Joi.string()
  .allow("")
  .max(maxCharacterLimitSingleLine)
  .messages(customMessage("Policy Number", "policy_number"));

export const senderOrgIdSchema = Joi.string()
  .required()
  .guid({ version: ["uuidv4"] })
  .messages(customMessage("Sender Org Id", "sender_org_id"));

export const OrgIdSchema = Joi.string()
  .required()
  .uuid()
  .messages(customMessage("Org Id"));
