import styled from "styled-components";
import Container from "./../container/container";

export const Label = styled(Container).attrs((props: any) => ({
  $inline: props.$inline,
}))`
  cursor: pointer;
  display: flex;
  align-items: center;

  ${(props: any) =>
    props.$inline
      ? `
        display: inline-flex;`
      : `
        display: flex;
        margin-bottom: 8px;
    `}
`;
