import { Button, Link, Typography } from "../../util";
import * as Styled from "./styles.css";
import AuthContainer from "../../../pages/auth/authContainer";
import AuthFooter from "../../../pages/auth/authFooter";
import { IllustrationBeaver } from "../../../assets/illustrations";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../contexts";
import AuthService from "../../../services/auth/auth.service";

type Props = {
  auto_redirect_to_login?: boolean;
};

const LoginErr: React.FC<Props> = ({ auto_redirect_to_login = false }) => {
  const history = useHistory();
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (auto_redirect_to_login) {
      const timeout = setTimeout(() => {
        auth.removeUser();
        AuthService.signOut();
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [auto_redirect_to_login, auth]);

  return (
    <AuthContainer
      headerTitle="Trouble Logging in"
      preventRedirect
      footerContent={<AuthFooter />}
      stickyFooter
    >
      <Styled.MaintenanceContainer>
        <Styled.BeaverImage src={IllustrationBeaver} />
        <Typography paragraph variant="body1">
          {`Oops, something went wrong with this login attempt. You will be redirected to sign in again in a few seconds.`}
        </Typography>
      </Styled.MaintenanceContainer>
    </AuthContainer>
  );
};

export default LoginErr;
