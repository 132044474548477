import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext, LocationContext } from "../../../../contexts";
import authService, {
  signUpArgs,
} from "../../../../services/auth/auth.service";
import invitationService from "../../../../services/invitation/invitation.service";
import { Form, Link, Typography } from "../../../../components/util";
import AuthContainer from "../../authContainer";
import PersonalInfo, { PersonalData } from "./personalInfo";

const SignUp = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();

  if (auth.user) {
    history.push("/already-logged-in");
  }

  const { location, urlParams } = useContext(LocationContext);

  const [orgId] = useState<string>(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("orgId") || "";
  });

  // TODO: refactor into automatic param reading by form based on field ID
  const formPrefill = (
    urlParams: Record<string, any> | undefined,
    formData?: Record<string, any>[],
    formErrors?: Record<string, boolean>[],
  ): Record<string, any>[] | Record<string, boolean>[] | undefined => {
    if (formData && authService.isLoggedIn()) {
      formData[0].isExistingAuth0User = true;
    }
    if (formData && urlParams?.email) {
      formData[0].email = urlParams.email as string;
    }
    if (formErrors && urlParams?.email) {
      formErrors[0].email = false;
    }
    if (formData && urlParams?.fName) {
      formData[0].firstName = urlParams.fName as string;
    }
    if (formErrors && urlParams?.fName) {
      formErrors[0].firstName = false;
    }
    if (formData && urlParams?.lName) {
      formData[0].lastName = urlParams.lName as string;
    }
    if (formErrors && urlParams?.lName) {
      formErrors[0].lastName = false;
    }

    return formErrors ? formErrors : formData;
  };

  if (urlParams?.invite === "true") {
    (async () => {
      const inviteStatus = await invitationService.getInvitationStatus(
        urlParams.invId as string,
      );
      if (inviteStatus === "cancelled" || inviteStatus === "claimed") {
        history.push(`/auth/invitation-${inviteStatus}`);
      } else {
        try {
          const scim = await authService.getScimUserDetails();

          if (urlParams?.email === scim.userName) {
            history.push("/sign-up#step2");
          } else if (urlParams?.invId && urlParams?.email !== scim.userName) {
            history.push(`/auth/invitation-email`);
          }
        } catch {}
      }
    })();
  }

  useEffect(() => {}, [urlParams?.email, history]);

  const signUpService = async (data: PersonalData) => {
    try {
      const signUpData: signUpArgs = {
        ...data,
        industry: "",
        phoneNumber: "",
        isBroker: false,
        companyName: data.firstName + " " + data.lastName + " Organisation",
        organisationSize: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        post_code: "",
        country: "",
      };

      signUpData["invitation_id"] = (urlParams?.invId as string) || "";

      if (urlParams?.role && urlParams.role === "broker") {
        signUpData["isBroker"] = true;
      }

      if (!orgId?.length) {
        signUpData["companyName"] = urlParams?.orgName
          ? (urlParams.orgName as string)
          : data.firstName + " " + data.lastName + " Organisation";
        signUpData["industry"] = signUpData.isBroker ? "Other" : "";
        signUpData["policy"] = urlParams?.polNum
          ? (urlParams.polNum as string)
          : "";
        signUpData["orgId"] = orgId || "";
      }

      await authService.signUp(signUpData);
    } catch (error) {
      console.error(error);
    }
  };

  let validationErrors: any[] = [personalErrorsInit];
  let validationFields: any[] = [personalDataInit];

  return (
    <AuthContainer isSidebarVisible backLink={false} preventRedirect>
      <Form
        onSubmit={{
          service: signUpService,
          onSuccess: () => history.push(`/login`),
        }}
        validationErrors={validationErrors}
        validationFields={validationFields}
        prefill={formPrefill}
        submitText={"Create account"}
      >
        <PersonalInfo
          footer={
            <Typography variant="body2" styled={{ text: { align: "center" } }}>
              Already have an account?{" "}
              <Link href="/login" router>
                Sign in
              </Link>
            </Typography>
          }
          submitText="Create account"
          subtitle="Get access to SafetyCulture Care and start mitigating risk. Be a step ahead with a free SafetyCulture account."
          title="Get started"
        />
      </Form>
    </AuthContainer>
  );
};

const personalDataInit: Record<string, string | boolean> = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  isExistingAuth0User: false,
  has_consent: false,
};

export const organisationDataInit: Record<string, string | boolean> = {
  companyName: "",
  phoneNumber: "",
  industry: "",
  organisationSize: "",
  address_1: "",
  address_2: "",
  city: "",
  state: "",
  post_code: "",
  country: "",
  organisation_id: "",
  policy: "",
  isBroker: false,
};

const personalErrorsInit: Record<string, boolean> = {
  firstName: true,
  lastName: true,
  email: true,
  password: true,
  has_consent: false,
};

const organisationErrorsInit: Record<string, boolean> = {
  isBroker: false,
  companyName: true,
  industry: true,
  phoneNumber: false,
  address_1: true,
  address_2: false,
  city: true,
  state: true,
  post_code: true,
  country: true,
  organisation_id: false,
  policy: false,
};

export default SignUp;
