import styled from "styled-components";
import { theme } from "../../../styles/themes";
import { TStyleConnotation, TStyleSize } from "../../../types";
import Container from "../container/container";

interface IChipStyle {
  $color: TStyleConnotation;
  $isStatus: boolean;
  $size: TStyleSize;
}

export const Chip = styled(Container)<IChipStyle>`
  display: inline-flex;

  ${({ $isStatus }) => {
    switch ($isStatus) {
      case true:
        return `
          text-transform: capitalize;
          border-radius: 20px;
          line-height: 20px;
          height:24px;
        `;
      default:
        return `
          border-radius: 6px;
        `;
    }
  }}

  ${({ $color }) => {
    return `
      background-color: ${theme.color[$color]?.background.weaker.default};
      color: ${theme.color[$color]?.text.stronger.default};
    `;
  }}

  ${({ $size }) => {
    switch ($size) {
      case "xs":
        return `
          padding: 2px 8px;
          font-weight: 500;
          font-size:12px;

        `;
      case "sm":
        return `
          padding: 2px 8px;
          font-weight: inherit;
        `;
      case "lg":
        return `
          font-weight: 500;
          padding: 4px 12px;
        `;
      case "md":
      default:
        return `
          font-weight: 500;
          padding: 4px 12px;
        `;
    }
  }};
`;
